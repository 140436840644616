import { Locale, compareAsc } from 'date-fns'
import format from 'date-fns/format'
import { enGB, fi, sv } from 'date-fns/locale'
import i18n from '../i18n'

const availableLocales: { [key: string]: Locale } = { fi, en: enGB, sv }
export const DATE_FORMAT_FI = 'dd.MM.yyyy'
export const DATE_FORMAT_FI_SHORT = 'd.M.yyyy'
export const DATETIME_FORMAT_FI = 'dd.MM.yyyy HH.mm.ss'

export function useDate() {
  const formatDate = (date: Date, dateFormat: string) => {
    return format(date, dateFormat, { locale: availableLocales[i18n.language] })
  }

  // Format long timestamp string into DD.MM.YYYY HH:MM
  const stringToDateTime = (timestamp: string) => {
    // Find the index of the first '[' character
    const index = timestamp.indexOf('[')

    let subStringDate = index !== -1 ? timestamp.substring(0, index) : timestamp

    // Add 'Z' to string end if there is none and no '+' sign
    if (subStringDate.slice(-1) !== 'Z' && subStringDate.indexOf('+') === -1) {
      subStringDate += 'Z'
    }

    const date = new Date(subStringDate)

    // Date formatter for "Europe/Helsinki" time zone
    const finlandTimeZone = 'Europe/Helsinki'
    const finlandFormatter = new Intl.DateTimeFormat('fi-FI', {
      timeZone: finlandTimeZone,
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })

    const afterFormatting = finlandFormatter.format(date)
    // chrome adds here an unnecessary 'klo'
    return afterFormatting.replace('klo ', '')
  }

  const formatForDatePicker = (date: Date): string =>
    date.toLocaleDateString(getCurrentLocale().code, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'UTC',
    })

  /**
   * Compares two dates
   * @param date1
   * @param date2
   * @returns
   * -1 if date1 is before date2 |
   * 0 if date1 is the same as date2 |
   * 1 if date1 is after date2
   */
  const compareDates = (date1: Date, date2: Date) => {
    return compareAsc(date1, date2)
  }

  const toUTCDate = (date: Date): Date =>
    new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

  return { formatDate, stringToDateTime, formatForDatePicker, compareDates, toUTCDate }
}

export function getCurrentLocale(): Locale {
  return availableLocales[i18n.language]
}
