/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Type of the Organization.
 * @export
 */
export const OrganizationType = {
  Association: 'association',
  Company: 'company',
  Municipality: 'municipality',
  State: 'state',
  Unknown: 'unknown',
} as const
export type OrganizationType = (typeof OrganizationType)[keyof typeof OrganizationType]

export function OrganizationTypeFromJSON(json: any): OrganizationType {
  return OrganizationTypeFromJSONTyped(json, false)
}

export function OrganizationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OrganizationType {
  return json as OrganizationType
}

export function OrganizationTypeToJSON(value?: OrganizationType | null): any {
  return value as any
}
