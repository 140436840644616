/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Is the animal in Finland or abroad?
 * @export
 */
export const AnimalCountryType = {
  Finland: 'finland',
  Abroad: 'abroad',
} as const
export type AnimalCountryType = (typeof AnimalCountryType)[keyof typeof AnimalCountryType]

export function AnimalCountryTypeFromJSON(json: any): AnimalCountryType {
  return AnimalCountryTypeFromJSONTyped(json, false)
}

export function AnimalCountryTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalCountryType {
  return json as AnimalCountryType
}

export function AnimalCountryTypeToJSON(value?: AnimalCountryType | null): any {
  return value as any
}
