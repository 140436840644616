/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { AppUserDto, ErrorDto } from '../models'
import { AppUserDtoFromJSON, AppUserDtoToJSON, ErrorDtoFromJSON, ErrorDtoToJSON } from '../models'

/**
 *
 */
export class UserRestControllerApi extends runtime.BaseAPI {
  /**
   */
  async adminInfoRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppUserDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/info/admin`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => AppUserDtoFromJSON(jsonValue))
  }

  /**
   */
  async adminInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppUserDto> {
    const response = await this.adminInfoRaw(initOverrides)
    return await response.value()
  }

  /**
   */
  async userInfoRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AppUserDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/user/info/user`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => AppUserDtoFromJSON(jsonValue))
  }

  /**
   */
  async userInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AppUserDto> {
    const response = await this.userInfoRaw(initOverrides)
    return await response.value()
  }
}
