import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '.'
import { useAuthContext } from '../context/AuthProvider'
import { useAuthApi } from '../hooks/useAuthApi'
import { useMonitoringCache } from '../hooks/useMonitoringCache'

export function Logout() {
  const { logoutUser } = useAuthApi()
  const { setCurrentUser }: { setCurrentUser: any } = useAuthContext()
  const navigate = useNavigate()
  const { clear: clearMonitoringCache } = useMonitoringCache()
  useEffect(() => {
    clearMonitoringCache()
    setCurrentUser(null)
    logoutUser()
      .then(() => console.log('Logged out'))
      .catch((error) => console.error('Logout failed', error))
      .finally(() => navigate(AppRoutes.Root))
  }, [])
  return <></>
}
