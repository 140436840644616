/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface KeyValueDto
 */
export interface KeyValueDto {
  /**
   *
   * @type {string}
   * @memberof KeyValueDto
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof KeyValueDto
   */
  value?: string
}

/**
 * Check if a given object implements the KeyValueDto interface.
 */
export function instanceOfKeyValueDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function KeyValueDtoFromJSON(json: any): KeyValueDto {
  return KeyValueDtoFromJSONTyped(json, false)
}

export function KeyValueDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyValueDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    key: !exists(json, 'key') ? undefined : json['key'],
    value: !exists(json, 'value') ? undefined : json['value'],
  }
}

export function KeyValueDtoToJSON(value?: KeyValueDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    key: value.key,
    value: value.value,
  }
}
