import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ErrorDto } from '../generated/animare-management-api'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRegistration } from '../hooks/useRegistration'
import { AppRoutes } from '../routes'
import BackButton from './BackButton'
import { CHANGE_POSSESSOR_STEPS } from './ChangePossessor'
import Dog from './Dog'
import DogDates from './DogDates'
import DogMicrochipExtra from './DogMicrochipExtra'
import { FormViewStepper } from './FormViewStepper'
import Possessor from './Possessor'
import SpacerBox from './SpacerBox'
import TitleWithUnderline from './TitleWithUnderline'

interface Props {
  registrationUid: string
}
export default function ChangePossessorCompleted({ registrationUid }: Props) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { getRegistration } = useRegistration()

  const [apiError, setApiError] = useState<ErrorDto>()
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const { data: registration, error: registrationFetchError } = getRegistration({
    uid: registrationUid,
  })

  /**
   * Shows success notification on the top of the page
   */
  const showSuccessNotification = () => {
    setShowNotification(SnackBarTypes.SUCCESS)
  }

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  useEffect(() => {
    if (registrationFetchError) {
      setApiError(registrationFetchError)
      showErrorNotification()
      console.error(registrationFetchError)
    }
  }, [registrationFetchError])

  // If registration exists, show success notification
  useEffect(() => {
    if (registration) {
      showSuccessNotification()
    }
  }, [registration])

  return (
    <>
      <BackButton />
      <Box sx={{ backgroundColor: '#fff', padding: 2 }}>
        <FormViewStepper activeStepIndex={2} steps={CHANGE_POSSESSOR_STEPS} />
      </Box>
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        {!!registration && (
          <SnackBar
            open={showNotification === SnackBarTypes.SUCCESS}
            type={SnackBarTypes.SUCCESS}
            innerText={`${t('common.save-successful')}`}
            handleClose={() => setShowNotification(undefined)}
          />
        )}
        {!!registrationFetchError && (
          <SnackBar
            open={showNotification === SnackBarTypes.ERROR}
            type={SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              setShowNotification(undefined)
              setApiError(undefined)
            }}
          />
        )}

        <SpacerBox gap={3} variant="container">
          <TitleWithUnderline>{t('possessorChangeCompleted.title')}</TitleWithUnderline>
          {!!registration && (
            <>
              <SpacerBox component="section" gap={2}>
                <Typography variant="h2">{t('possessorChangeCompleted.possessorTitle')}</Typography>
                {registration?.possessor && <Possessor possessor={registration.possessor} />}
              </SpacerBox>

              <Divider />

              <SpacerBox component="section" gap={2}>
                <Typography variant="h2">
                  {t('possessorChangeCompleted.registrationTitle')}
                </Typography>
                <Dog registration={registration} showStartDate={false} />
                <Typography variant="h3" sx={{ mb: 2 }} data-qa="microchip-extra">
                  {t('common.animal.microchipExtra')}
                </Typography>
                <DogMicrochipExtra microchip={registration?.microchip} />

                <Typography variant="h3" sx={{ mb: 2 }} data-qa="date-title">
                  {t('common.animal.dateTitle')}
                </Typography>
                <DogDates registration={registration} />
              </SpacerBox>
            </>
          )}
          <Divider />
          <SpacerBox gap={2} orientation="horizontal">
            <Button variant="contained" type="button" onClick={() => navigate(AppRoutes.Dashboard)}>
              {t('registrationConfirmationPage.buttonSection.toFrontPage')}
            </Button>
          </SpacerBox>
        </SpacerBox>
      </Paper>
    </>
  )
}
