import { Skeleton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { OTHER_BREED } from '../../data/breeds'
import { ErrorDto } from '../../generated/openapi'

export interface Props {
  code?: string
  generator: ({ code, language }: { code: string; language: string }) => {
    value?: string
    error?: ErrorDto | null
    isPending?: boolean
  }
  unknownResult: string
}

export default function AbstractCode({ code, generator, unknownResult }: Props) {
  const { i18n } = useTranslation('common')

  const { value, error, isPending } = generator({ code: `${code}`, language: i18n.language })

  if (isPending) {
    return <Skeleton width={125} />
  }

  if (error) {
    return (
      <>
        {unknownResult} ({code})
      </>
    )
  }

  if (!!value) {
    return <>{value}</>
  } else {
    if (!code || code === OTHER_BREED) {
      return <>{unknownResult}</>
    }
    return (
      <>
        {unknownResult} ({code})
      </>
    )
  }
}
