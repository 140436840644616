import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CodeApiKeys } from '../data/apiKeys'
import { breedCodes } from '../data/breeds'
import { BreedsApi, Code, Configuration, Middleware } from '../generated/codes-app-api'
import { ErrorDto } from '../generated/openapi'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { CODES_API_BASE_URL } from '../setup'

export interface BreedType {
  label: string
  code: Code
}

function useBreeds() {
  const api = new BreedsApi({
    basePath: CODES_API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
  } as Configuration)

  const fetchBreeds = (): UseQueryResult<Code[], ErrorDto> => {
    const fetchBreedsQuery = useQuery<Code[], ErrorDto>({
      queryKey: [CodeApiKeys.BREEDS],
      queryFn: () => api.getBreeds(),
    })

    return fetchBreedsQuery
  }

  const defaultBreeds = () => breedCodes as Code[]

  /**
   *
   * @param code
   * @param language
   * @returns
   */
  const getLabel = ({ code, language }: { code?: Code; language: string }) => {
    if (!code) return ''
    return code.labels?.find((label) => label.lang === language)?.label
  }

  /**
   *
   * @param breedCode
   * @param language
   * @returns
   */
  const getBreedName = ({
    code: breedCode,
    language,
  }: {
    code: string
    language: string
  }): { value?: string; error?: ErrorDto | null; isPending?: boolean } => {
    const { data: breedList, error: breedListError, isPending } = fetchBreeds()

    if (isPending) {
      return { isPending }
    }

    if (!breedList || breedListError) {
      return { error: breedListError }
    }

    return {
      value: getLabel({
        code: breedList.find((breed) => breed.code === breedCode),
        language,
      }),
    }
  }

  return {
    fetchBreeds,
    defaultBreeds,
    getBreedName,
  }
}
export default useBreeds
