/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalStatusDto } from './AnimalStatusDto'
import {
  AnimalStatusDtoFromJSON,
  AnimalStatusDtoFromJSONTyped,
  AnimalStatusDtoToJSON,
} from './AnimalStatusDto'
import type { MicrochipDto } from './MicrochipDto'
import { MicrochipDtoFromJSON, MicrochipDtoFromJSONTyped, MicrochipDtoToJSON } from './MicrochipDto'

/**
 * Details of an animal.
 * @export
 * @interface AnimalDto
 */
export interface AnimalDto {
  /**
   *
   * @type {string}
   * @memberof AnimalDto
   */
  uid?: string
  /**
   * Animal name.
   * @type {string}
   * @memberof AnimalDto
   */
  name: string
  /**
   * Animal kennel name, if available.
   * @type {string}
   * @memberof AnimalDto
   */
  kennelName?: string
  /**
   * Animal birth date.
   * @type {Date}
   * @memberof AnimalDto
   */
  birthDate: Date
  /**
   * Animal death date.
   * @type {Date}
   * @memberof AnimalDto
   */
  deathDate?: Date
  /**
   * Animal birth country code (ISO 3166-1 numeric)
   * @type {string}
   * @memberof AnimalDto
   */
  birthCountryCode: string
  /**
   * Code of the animal breed
   * @type {string}
   * @memberof AnimalDto
   */
  breedCode?: string
  /**
   * Size of the animal, if breed not available.
   * @type {string}
   * @memberof AnimalDto
   */
  breedSize?: AnimalDtoBreedSizeEnum
  /**
   * Description of the animal, if breed not available.
   * @type {string}
   * @memberof AnimalDto
   */
  breedDescription?: string
  /**
   * Sex of the animal.
   * @type {string}
   * @memberof AnimalDto
   */
  sexCode: AnimalDtoSexCodeEnum
  /**
   * Colours of the animal.
   * @type {Array<string>}
   * @memberof AnimalDto
   */
  colours?: Array<AnimalDtoColoursEnum>
  /**
   * All microchips that are registered for this animal.
   * @type {Array<MicrochipDto>}
   * @memberof AnimalDto
   */
  microchips?: Array<MicrochipDto>
  /**
   *
   * @type {AnimalStatusDto}
   * @memberof AnimalDto
   */
  status?: AnimalStatusDto
}

/**
 * @export
 */
export const AnimalDtoBreedSizeEnum = {
  XSmall: 'x-small',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  XLarge: 'x-large',
  Unknown: 'unknown',
} as const
export type AnimalDtoBreedSizeEnum =
  (typeof AnimalDtoBreedSizeEnum)[keyof typeof AnimalDtoBreedSizeEnum]

/**
 * @export
 */
export const AnimalDtoSexCodeEnum = {
  Male: 'male',
  Female: 'female',
  Unknown: 'unknown',
} as const
export type AnimalDtoSexCodeEnum = (typeof AnimalDtoSexCodeEnum)[keyof typeof AnimalDtoSexCodeEnum]

/**
 * @export
 */
export const AnimalDtoColoursEnum = {
  Brown: 'brown',
  Black: 'black',
  White: 'white',
  Red: 'red',
  Gray: 'gray',
  Yellow: 'yellow',
} as const
export type AnimalDtoColoursEnum = (typeof AnimalDtoColoursEnum)[keyof typeof AnimalDtoColoursEnum]

/**
 * Check if a given object implements the AnimalDto interface.
 */
export function instanceOfAnimalDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'name' in value
  isInstance = isInstance && 'birthDate' in value
  isInstance = isInstance && 'birthCountryCode' in value
  isInstance = isInstance && 'sexCode' in value

  return isInstance
}

export function AnimalDtoFromJSON(json: any): AnimalDto {
  return AnimalDtoFromJSONTyped(json, false)
}

export function AnimalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimalDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    name: json['name'],
    kennelName: !exists(json, 'kennelName') ? undefined : json['kennelName'],
    birthDate: new Date(json['birthDate']),
    deathDate: !exists(json, 'deathDate') ? undefined : new Date(json['deathDate']),
    birthCountryCode: json['birthCountryCode'],
    breedCode: !exists(json, 'breedCode') ? undefined : json['breedCode'],
    breedSize: !exists(json, 'breedSize') ? undefined : json['breedSize'],
    breedDescription: !exists(json, 'breedDescription') ? undefined : json['breedDescription'],
    sexCode: json['sexCode'],
    colours: !exists(json, 'colours') ? undefined : json['colours'],
    microchips: !exists(json, 'microchips')
      ? undefined
      : (json['microchips'] as Array<any>).map(MicrochipDtoFromJSON),
    status: !exists(json, 'status') ? undefined : AnimalStatusDtoFromJSON(json['status']),
  }
}

export function AnimalDtoToJSON(value?: AnimalDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    name: value.name,
    kennelName: value.kennelName,
    birthDate: value.birthDate.toISOString().substr(0, 10),
    deathDate:
      value.deathDate === undefined ? undefined : value.deathDate.toISOString().substr(0, 10),
    birthCountryCode: value.birthCountryCode,
    breedCode: value.breedCode,
    breedSize: value.breedSize,
    breedDescription: value.breedDescription,
    sexCode: value.sexCode,
    colours: value.colours,
    microchips:
      value.microchips === undefined
        ? undefined
        : (value.microchips as Array<any>).map(MicrochipDtoToJSON),
    status: AnimalStatusDtoToJSON(value.status),
  }
}
