/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AnimalImportingSearchDto,
  AnimalProtectionSearchDto,
  ErrorDto,
  MonitoringSearchResponseDto,
  ProfessionalActivitySearchDto,
  PuppyMillingPossessorChangeSearchDto,
  PuppyMillingRegistrationsSearchDto,
} from '../models'
import {
  AnimalImportingSearchDtoFromJSON,
  AnimalImportingSearchDtoToJSON,
  AnimalProtectionSearchDtoFromJSON,
  AnimalProtectionSearchDtoToJSON,
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  MonitoringSearchResponseDtoFromJSON,
  MonitoringSearchResponseDtoToJSON,
  ProfessionalActivitySearchDtoFromJSON,
  ProfessionalActivitySearchDtoToJSON,
  PuppyMillingPossessorChangeSearchDtoFromJSON,
  PuppyMillingPossessorChangeSearchDtoToJSON,
  PuppyMillingRegistrationsSearchDtoFromJSON,
  PuppyMillingRegistrationsSearchDtoToJSON,
} from '../models'

export interface SearchForAnimalImportingRequest {
  animalImportingSearchDto?: AnimalImportingSearchDto
}

export interface SearchForAnimalProtectionRequest {
  animalProtectionSearchDto?: AnimalProtectionSearchDto
}

export interface SearchForProfessionalActivityRequest {
  professionalActivitySearchDto?: ProfessionalActivitySearchDto
}

export interface SearchForPuppyMillingPossessorChangesRequest {
  puppyMillingPossessorChangeSearchDto?: PuppyMillingPossessorChangeSearchDto
}

export interface SearchForPuppyMillingRegistrationsRequest {
  puppyMillingRegistrationsSearchDto?: PuppyMillingRegistrationsSearchDto
}

/**
 *
 */
export class MonitoringRestControllerApi extends runtime.BaseAPI {
  /**
   * Search for animal importing cases.
   */
  async searchForAnimalImportingRaw(
    requestParameters: SearchForAnimalImportingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MonitoringSearchResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/monitoring/animal-importing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AnimalImportingSearchDtoToJSON(requestParameters.animalImportingSearchDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MonitoringSearchResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Search for animal importing cases.
   */
  async searchForAnimalImporting(
    requestParameters: SearchForAnimalImportingRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MonitoringSearchResponseDto> {
    const response = await this.searchForAnimalImportingRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Search for animal protection cases.
   */
  async searchForAnimalProtectionRaw(
    requestParameters: SearchForAnimalProtectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MonitoringSearchResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/monitoring/animal-protection-suspicion`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AnimalProtectionSearchDtoToJSON(requestParameters.animalProtectionSearchDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MonitoringSearchResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Search for animal protection cases.
   */
  async searchForAnimalProtection(
    requestParameters: SearchForAnimalProtectionRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MonitoringSearchResponseDto> {
    const response = await this.searchForAnimalProtectionRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Search for recognizing professional activity. (Minimum number of current registrations in a specific municipality)
   */
  async searchForProfessionalActivityRaw(
    requestParameters: SearchForProfessionalActivityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MonitoringSearchResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/monitoring/professional-activity`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProfessionalActivitySearchDtoToJSON(requestParameters.professionalActivitySearchDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MonitoringSearchResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Search for recognizing professional activity. (Minimum number of current registrations in a specific municipality)
   */
  async searchForProfessionalActivity(
    requestParameters: SearchForProfessionalActivityRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MonitoringSearchResponseDto> {
    const response = await this.searchForProfessionalActivityRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Search for recognizing puppy milling by possessor change cases. (Minimum number of possessor changes in the last 12 months)
   */
  async searchForPuppyMillingPossessorChangesRaw(
    requestParameters: SearchForPuppyMillingPossessorChangesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MonitoringSearchResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/monitoring/puppy-milling/possessor-changes`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PuppyMillingPossessorChangeSearchDtoToJSON(
          requestParameters.puppyMillingPossessorChangeSearchDto,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MonitoringSearchResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Search for recognizing puppy milling by possessor change cases. (Minimum number of possessor changes in the last 12 months)
   */
  async searchForPuppyMillingPossessorChanges(
    requestParameters: SearchForPuppyMillingPossessorChangesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MonitoringSearchResponseDto> {
    const response = await this.searchForPuppyMillingPossessorChangesRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Search for recognizing puppy milling by registration count inside a defined time period in a specific municipality.
   */
  async searchForPuppyMillingRegistrationsRaw(
    requestParameters: SearchForPuppyMillingRegistrationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MonitoringSearchResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/monitoring/puppy-milling/registrations`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PuppyMillingRegistrationsSearchDtoToJSON(
          requestParameters.puppyMillingRegistrationsSearchDto,
        ),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MonitoringSearchResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Search for recognizing puppy milling by registration count inside a defined time period in a specific municipality.
   */
  async searchForPuppyMillingRegistrations(
    requestParameters: SearchForPuppyMillingRegistrationsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MonitoringSearchResponseDto> {
    const response = await this.searchForPuppyMillingRegistrationsRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }
}
