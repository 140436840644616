import { Box, Divider, Paper, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import BackButton from '../../../components/BackButton'
import SpacerBox from '../../../components/SpacerBox'
import { ShelterSearch } from './ShelterSearch'

export const ShelterSearchPage = () => {
  const { t } = useTranslation('common')
  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={3} variant="container">
          <Box component="section">
            <Typography variant="h1">{t('shelter.search.title')}</Typography>
          </Box>
          <Divider />

          <SpacerBox component="section" gap={2}>
            <ShelterSearch />
          </SpacerBox>
        </SpacerBox>
      </Paper>
    </>
  )
}
