import { Box, ThemeProvider } from '@mui/material'
import './App.css'
import './styles/globals.css'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { AuthGuard, AuthProvider } from './context/AuthProvider'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { StrictMode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Body from './components/Body'
import Footer from './components/Footer'
import EnvBanner from './components/Header/EnvBanner'
import Header from './components/Header/Header'
import ScrollToTop from './components/ScrollToTop'
import { Roles } from './data/roles'
import { useMatomo } from './matomo'
import { AppRoutes } from './routes'
import NotFound from './routes/404'
import { SecretsView } from './routes/admin/admin/SecretsView'
import DashboardHome from './routes/admin/dashboard'
import ChangePossessorView from './routes/admin/dashboard/change-possessor/change-possessor'
import ChangePossessorCompletedView from './routes/admin/dashboard/change-possessor/change-possessor-completed'
import EditPossessor from './routes/admin/dashboard/edit-possessor'
import RegistrationConfirmationView from './routes/admin/dashboard/registration/confirmation'
import EditRegistrationView from './routes/admin/dashboard/registration/edit'
import RegistrationFormView from './routes/admin/dashboard/registration/form'
import RegistrationSearch from './routes/admin/dashboard/registration/search'
import { ShelterSearchPage } from './routes/admin/shelter/search'
import DogInfoView from './routes/dog-view/dog-info'
import NewMicrochipView from './routes/dog-view/new-microchip'
import Login from './routes/login'
import { Logout } from './routes/logout'
import PossessorView from './routes/monitoring/PossessorView'
import MonitoringSearch from './routes/monitoring/monitoring-search'
import Virtu from './routes/virtu'
import theme from './styles/theme'

const App = () => {
  const queryClient = new QueryClient()
  const { i18n } = useTranslation('common')

  useEffect(() => {
    useMatomo()
    /* Sets html language attribute to match selected content */
    document.querySelector('html')?.setAttribute('lang', i18n.language)
  }, [])

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Box className="App">
            <Router>
              <ScrollToTop />
              <EnvBanner />
              <AuthProvider>
                <>
                  <Header />
                  <Body>
                    <Routes>
                      <Route path={AppRoutes.Root} element={<Login />} />
                      <Route path={AppRoutes.Logout} element={<Logout />} />
                      <Route path={AppRoutes.Virtu} element={<Virtu />} />
                      <Route element={<AuthGuard />}>
                        <Route element={<AuthGuard allowedRoles={[Roles.Admin]} />}>
                          <Route path={AppRoutes.SecretsView} element={<SecretsView />} />
                        </Route>
                        <Route
                          element={
                            <AuthGuard
                              allowedRoles={[Roles.WideViewer, Roles.Admin, Roles.Editor]}
                            />
                          }
                        >
                          <Route path={AppRoutes.Monitoring_Search}>
                            <Route index element={<MonitoringSearch />} />
                            <Route path={AppRoutes.Possessor}>
                              <Route index element={<PossessorView />} />
                              {/* NOTE: possessor edit and create registration is duplicated here */}
                              <Route
                                element={
                                  <AuthGuard
                                    allowedRoles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}
                                  />
                                }
                              >
                                <Route
                                  path={AppRoutes.Possessor_Edit}
                                  element={<EditPossessor />}
                                />
                                <Route
                                  path={AppRoutes.Registration_Form}
                                  element={<RegistrationFormView />}
                                />
                              </Route>
                              {/* NOTE: dog view stuff is duplicated here */}
                              <Route path={AppRoutes.Registration}>
                                <Route index element={<DogInfoView />} />
                                <Route
                                  element={
                                    <AuthGuard
                                      allowedRoles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}
                                    />
                                  }
                                >
                                  <Route
                                    path={AppRoutes.Registration_Edit}
                                    element={<EditRegistrationView />}
                                  />
                                  <Route
                                    path={AppRoutes.Registration_Microchips}
                                    element={<NewMicrochipView />}
                                  />
                                  <Route
                                    path={AppRoutes.ChangePossessor}
                                    element={<ChangePossessorView />}
                                  />
                                  <Route
                                    path={AppRoutes.ChangePossessor_Completed}
                                    element={<ChangePossessorCompletedView />}
                                  />
                                  <Route
                                    path={AppRoutes.Registration_Confirmation}
                                    element={<RegistrationConfirmationView />}
                                  />
                                </Route>
                              </Route>
                            </Route>
                          </Route>
                        </Route>
                        <Route path={AppRoutes.Dashboard} element={<DashboardHome />} />
                        <Route path={AppRoutes.Search}>
                          <Route index element={<RegistrationSearch />} />
                          <Route path={AppRoutes.Possessor}>
                            <Route index element={<PossessorView />} />
                            <Route
                              element={
                                <AuthGuard
                                  allowedRoles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}
                                />
                              }
                            >
                              <Route path={AppRoutes.Possessor_Edit} element={<EditPossessor />} />
                              <Route
                                path={AppRoutes.Registration_Form}
                                element={<RegistrationFormView />}
                              />
                            </Route>

                            <Route path={AppRoutes.Registration}>
                              <Route index element={<DogInfoView />} />
                              <Route
                                element={
                                  <AuthGuard
                                    allowedRoles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}
                                  />
                                }
                              >
                                <Route
                                  path={AppRoutes.Registration_Edit}
                                  element={<EditRegistrationView />}
                                />
                                <Route
                                  path={AppRoutes.Registration_Microchips}
                                  element={<NewMicrochipView />}
                                />
                                <Route
                                  path={AppRoutes.ChangePossessor}
                                  element={<ChangePossessorView />}
                                />
                                <Route
                                  path={AppRoutes.ChangePossessor_Completed}
                                  element={<ChangePossessorCompletedView />}
                                />
                                <Route
                                  path={AppRoutes.Registration_Confirmation}
                                  element={<RegistrationConfirmationView />}
                                />
                              </Route>
                            </Route>
                          </Route>
                          {/* NOTE: dog view stuff is duplicated here too, as we can navigate here trough direct search */}
                          <Route path={AppRoutes.Registration}>
                            <Route index element={<DogInfoView />} />
                            <Route
                              element={
                                <AuthGuard
                                  allowedRoles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}
                                />
                              }
                            >
                              <Route
                                path={AppRoutes.Registration_Edit}
                                element={<EditRegistrationView />}
                              />
                              <Route
                                path={AppRoutes.Registration_Microchips}
                                element={<NewMicrochipView />}
                              />
                              <Route
                                path={AppRoutes.ChangePossessor}
                                element={<ChangePossessorView />}
                              />
                              <Route
                                path={AppRoutes.ChangePossessor_Completed}
                                element={<ChangePossessorCompletedView />}
                              />
                              <Route
                                path={AppRoutes.Registration_Confirmation}
                                element={<RegistrationConfirmationView />}
                              />
                            </Route>
                          </Route>
                        </Route>
                      </Route>
                      <Route element={<AuthGuard allowedRoles={[Roles.Shelter, Roles.Admin]} />}>
                        <Route path={AppRoutes.Shelter} element={<ShelterSearchPage />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Body>
                </>
              </AuthProvider>
              <Footer />
            </Router>
          </Box>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  )
}

export default App
