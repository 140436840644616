import { Alert, AlertColor, IconButton } from '@mui/material'
import { ApiTypes, getErrorText } from '../utils/errorUtils'

import { ErrorOutlineSharp } from '@mui/icons-material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import Snackbar from '@mui/material/Snackbar'
import { ErrorDto } from '../generated/animare-management-api/models/ErrorDto'

export enum SnackBarTypes {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
}

type SnackBarProps = {
  type: SnackBarTypes
  errorCode?: ErrorDto
  innerText?: string
  handleClose: () => void
  open: boolean
  apiType?: ApiTypes
}

export const SnackBar = (props: SnackBarProps) => {
  const { type, innerText = '', handleClose, errorCode, open, apiType = ApiTypes.Animare } = props

  const message =
    type === SnackBarTypes.ERROR && errorCode ? getErrorText(errorCode, apiType) : innerText

  const getIcon = (type: AlertColor) => {
    switch (type) {
      case SnackBarTypes.ERROR:
        return <ErrorOutlineSharp sx={{ color: 'error.dark' }} />
      case SnackBarTypes.SUCCESS:
        return <CheckIcon sx={{ color: 'success.dark' }} />
      case SnackBarTypes.WARNING:
        return <WarningIcon sx={{ color: 'warning.dark' }} />
    }
  }

  const content = (
    <Alert
      variant="outlined"
      severity={type}
      icon={getIcon(type)}
      sx={{ alignItems: 'center' }}
      action={
        <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {message || ''}
    </Alert>
  )

  return (
    <Snackbar
      data-qa="snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      key={'top' + 'center'}
      open={open}
    >
      {content}
    </Snackbar>
  )
}
