import { Add, Edit } from '@mui/icons-material'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  PossessorDto,
  PossessorDtoTypeEnum,
  PossessorResponseDto,
  RegistrationDto,
} from '../../../../generated/animare-management-api'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppRoutes } from '../../..'
import BackButton from '../../../../components/BackButton'
import { AddNewOrganization } from '../../../../components/Form/AddNewOrganization'
import { AddNewPerson } from '../../../../components/Form/AddNewPerson'
import Possessor from '../../../../components/Possessor'
import RegistrationsTable from '../../../../components/RegistrationsTable'
import Search from '../../../../components/Search'
import SpacerBox from '../../../../components/SpacerBox'
import { ShowFor } from '../../../../context/AuthProvider'
import { Roles } from '../../../../data/roles'

const RegistrationSearch = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const [possessor, setPossessor] = useState<PossessorDto | undefined>(undefined)
  const [registrations, setRegistrations] = useState<RegistrationDto[] | undefined>(undefined)
  const [newPossessorIdentifier, setNewPossessorIdentifier] = useState<string | undefined>(
    undefined,
  )
  const [newPossessorType, setNewPossessorType] = useState<PossessorDtoTypeEnum | undefined>(
    undefined,
  )
  const [showResults, setShowResults] = useState<boolean>(false)

  const onPersonFound = (response: PossessorResponseDto) => {
    setPossessor(response.possessor)
    setRegistrations(response.registrations)
    setNewPossessorIdentifier(undefined)
    setShowResults(true)
  }

  const onPossessorFormReset = () => {
    setPossessor(undefined)
    setNewPossessorIdentifier(undefined)
    setShowResults(false)
  }

  const onOrganizationFound = (response: PossessorResponseDto) => {
    setPossessor(response.possessor)
    setRegistrations(response.registrations)
    setNewPossessorIdentifier(undefined)
    setShowResults(true)
  }

  const onIdentifierNotFound = (identifier: string, possessorType: PossessorDtoTypeEnum) => {
    setNewPossessorType(possessorType)
    setNewPossessorIdentifier(identifier)
    setPossessor(undefined)
    setShowResults(true)
  }

  const isPossessorFound = possessor && !newPossessorIdentifier
  const shouldAddNewPerson =
    newPossessorIdentifier && newPossessorType === PossessorDtoTypeEnum.Person && !possessor
  const shouldAddNewOrganization =
    newPossessorType === PossessorDtoTypeEnum.Organization && newPossessorIdentifier

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={3} variant="container">
          <Box component="section">
            <Typography variant="h1">{t('registrationSearchPage.title')}</Typography>
          </Box>
          <Divider />

          <SpacerBox component="section" gap={2}>
            <Typography variant="h2">{t('registrationSearchPage.searchSection.title')}</Typography>
            <Search
              hasIdentifierSearch
              hasMicrochipSearch
              hasCompanyIdentifierSearch
              onPersonFound={onPersonFound}
              onPersonFormReset={onPossessorFormReset}
              onOrganizationFound={onOrganizationFound}
              onOrganizationFormReset={onPossessorFormReset}
              onIdentifierNotFound={onIdentifierNotFound}
            />
            {showResults && (
              <>
                {isPossessorFound && (
                  <>
                    <Divider sx={{ mt: 2 }} />
                    <Box>
                      <Typography variant="h2"> {t('dogView.possessorInformation')} </Typography>
                      <Possessor possessor={possessor} />
                      <ShowFor roles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            data-qa="edit-possessor"
                            onClick={() =>
                              navigate(`possessor/${possessor.uid}/${AppRoutes.Possessor_Edit}`)
                            }
                            startIcon={<Edit />}
                          >
                            {t('common.edit')}
                          </Button>
                          <Button
                            variant="outlined"
                            data-qa="add-animal"
                            onClick={() => navigate(`possessor/${possessor.uid}/registration`)}
                            startIcon={<Add />}
                          >
                            {t('registrationSearchPage.animalsSection.button')}
                          </Button>
                        </Box>
                      </ShowFor>
                    </Box>
                    {registrations && (
                      <>
                        <Divider sx={{ mt: 2 }} />
                        <RegistrationsTable
                          possessorUid={possessor.uid}
                          registrations={registrations}
                        />
                      </>
                    )}
                  </>
                )}
                {!isPossessorFound && (
                  <Box data-qa="no-possessor-results">
                    <Typography variant="h3" sx={{ mt: 2 }}>
                      {t('registrationSearchPage.noResults.person.title')}
                    </Typography>
                    {t('registrationSearchPage.noResults.person.description')}
                  </Box>
                )}

                <ShowFor roles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}>
                  <Divider sx={{ mt: 2 }} />
                  {shouldAddNewPerson && <AddNewPerson personIdentifier={newPossessorIdentifier} />}
                  {shouldAddNewOrganization && (
                    <AddNewOrganization organizationIdentifier={newPossessorIdentifier} />
                  )}
                </ShowFor>
              </>
            )}
          </SpacerBox>
        </SpacerBox>
      </Paper>
    </>
  )
}

export default RegistrationSearch
