/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  AnimalStatusDto,
  ErrorDto,
  FullRegistrationDto,
  InvalidateChangeCodesDto,
  MicrochipDto,
  NewMicrochipRequestDto,
  PossessorChangeRequestDto,
  UpdateAnimalStatusDto,
} from '../models'
import {
  AnimalStatusDtoFromJSON,
  AnimalStatusDtoToJSON,
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  FullRegistrationDtoFromJSON,
  FullRegistrationDtoToJSON,
  InvalidateChangeCodesDtoFromJSON,
  InvalidateChangeCodesDtoToJSON,
  MicrochipDtoFromJSON,
  MicrochipDtoToJSON,
  NewMicrochipRequestDtoFromJSON,
  NewMicrochipRequestDtoToJSON,
  PossessorChangeRequestDtoFromJSON,
  PossessorChangeRequestDtoToJSON,
  UpdateAnimalStatusDtoFromJSON,
  UpdateAnimalStatusDtoToJSON,
} from '../models'

export interface ChangePossessorRequest {
  uid: string
  possessorChangeRequestDto?: PossessorChangeRequestDto
}

export interface ChangeRegistrationRequest {
  uid: string
  fullRegistrationDto?: FullRegistrationDto
}

export interface CreateRegistrationRequest {
  fullRegistrationDto?: FullRegistrationDto
}

export interface GetRegistrationRequest {
  uid: string
}

export interface GetRegistrationUidByMicrochipNumberRequest {
  microchipNumber: string
}

export interface InvalidateChangeCodesRequest {
  uid: string
  invalidateChangeCodesDto?: InvalidateChangeCodesDto
}

export interface NewMicrochipRequest {
  uid: string
  newMicrochipRequestDto?: NewMicrochipRequestDto
}

export interface SetRegistrationStatusRequest {
  uid: string
  updateAnimalStatusDto?: UpdateAnimalStatusDto
}

/**
 *
 */
export class RegistrationRestControllerApi extends runtime.BaseAPI {
  /**
   * Changes the possessor of the animal of the specified registration. The possessor must already exist in the registry and will be matched based on its uid.
   */
  async changePossessorRaw(
    requestParameters: ChangePossessorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FullRegistrationDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling changePossessor.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}/change-possessor`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PossessorChangeRequestDtoToJSON(requestParameters.possessorChangeRequestDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullRegistrationDtoFromJSON(jsonValue),
    )
  }

  /**
   * Changes the possessor of the animal of the specified registration. The possessor must already exist in the registry and will be matched based on its uid.
   */
  async changePossessor(
    requestParameters: ChangePossessorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FullRegistrationDto> {
    const response = await this.changePossessorRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async changeRegistrationRaw(
    requestParameters: ChangeRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FullRegistrationDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling changeRegistration.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: FullRegistrationDtoToJSON(requestParameters.fullRegistrationDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullRegistrationDtoFromJSON(jsonValue),
    )
  }

  /**
   */
  async changeRegistration(
    requestParameters: ChangeRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FullRegistrationDto> {
    const response = await this.changeRegistrationRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async createRegistrationRaw(
    requestParameters: CreateRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FullRegistrationDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: FullRegistrationDtoToJSON(requestParameters.fullRegistrationDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullRegistrationDtoFromJSON(jsonValue),
    )
  }

  /**
   */
  async createRegistration(
    requestParameters: CreateRegistrationRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FullRegistrationDto> {
    const response = await this.createRegistrationRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns details of the specified registration.
   */
  async getRegistrationRaw(
    requestParameters: GetRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FullRegistrationDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling getRegistration.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullRegistrationDtoFromJSON(jsonValue),
    )
  }

  /**
   * Returns details of the specified registration.
   */
  async getRegistration(
    requestParameters: GetRegistrationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FullRegistrationDto> {
    const response = await this.getRegistrationRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns registration with uid only by microchip number.
   */
  async getRegistrationUidByMicrochipNumberRaw(
    requestParameters: GetRegistrationUidByMicrochipNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FullRegistrationDto>> {
    if (
      requestParameters.microchipNumber === null ||
      requestParameters.microchipNumber === undefined
    ) {
      throw new runtime.RequiredError(
        'microchipNumber',
        'Required parameter requestParameters.microchipNumber was null or undefined when calling getRegistrationUidByMicrochipNumber.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.microchipNumber !== undefined) {
      queryParameters['microchipNumber'] = requestParameters.microchipNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/uid`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FullRegistrationDtoFromJSON(jsonValue),
    )
  }

  /**
   * Returns registration with uid only by microchip number.
   */
  async getRegistrationUidByMicrochipNumber(
    requestParameters: GetRegistrationUidByMicrochipNumberRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FullRegistrationDto> {
    const response = await this.getRegistrationUidByMicrochipNumberRaw(
      requestParameters,
      initOverrides,
    )
    return await response.value()
  }

  /**
   * Invalidate active possessor change codes for the specified active registration.
   */
  async invalidateChangeCodesRaw(
    requestParameters: InvalidateChangeCodesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling invalidateChangeCodes.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}/change-possessor`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: InvalidateChangeCodesDtoToJSON(requestParameters.invalidateChangeCodesDto),
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Invalidate active possessor change codes for the specified active registration.
   */
  async invalidateChangeCodes(
    requestParameters: InvalidateChangeCodesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.invalidateChangeCodesRaw(requestParameters, initOverrides)
  }

  /**
   * Register a new microchip for the animal of the registration and updates it as the current microchip of the registration.
   */
  async newMicrochipRaw(
    requestParameters: NewMicrochipRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MicrochipDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling newMicrochip.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}/microchip`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewMicrochipRequestDtoToJSON(requestParameters.newMicrochipRequestDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => MicrochipDtoFromJSON(jsonValue))
  }

  /**
   * Register a new microchip for the animal of the registration and updates it as the current microchip of the registration.
   */
  async newMicrochip(
    requestParameters: NewMicrochipRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MicrochipDto> {
    const response = await this.newMicrochipRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Update the current status of the animal. Only one status type can be changed at once.
   */
  async setRegistrationStatusRaw(
    requestParameters: SetRegistrationStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AnimalStatusDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling setRegistrationStatus.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/registration/{uid}/status`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UpdateAnimalStatusDtoToJSON(requestParameters.updateAnimalStatusDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => AnimalStatusDtoFromJSON(jsonValue))
  }

  /**
   * Update the current status of the animal. Only one status type can be changed at once.
   */
  async setRegistrationStatus(
    requestParameters: SetRegistrationStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AnimalStatusDto> {
    const response = await this.setRegistrationStatusRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
