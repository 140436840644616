import { Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { SnackBar, SnackBarTypes } from '../../components/SnackBar'

import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AppRoutes } from '..'
import { ErrorDto } from '../../generated/openapi/models/ErrorDto'
import { useAuthApi } from '../../hooks/useAuthApi'
import { ApiTypes } from '../../utils/errorUtils'

const Virtu = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { virtuLogin } = useAuthApi()
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams || Array.from(searchParams.keys()).length === 0) {
      navigate(AppRoutes.Root)
      return
    }

    virtuLogin(searchParams)
      .then(() => navigate(AppRoutes.Dashboard))
      .catch((error: any) => {
        setApiError(error)
        console.error(error)
      })
  }, [])
  return (
    <>
      {apiError && (
        <Box>
          <SnackBar
            type={SnackBarTypes.ERROR}
            apiType={ApiTypes.Management}
            open={!!apiError}
            errorCode={apiError}
            handleClose={() => navigate(AppRoutes.Root)}
          />
          <Button variant="text" onClick={() => navigate(AppRoutes.Root)}>
            {t('loginPage.title')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default Virtu
