import { Box, Typography, List, ListItem } from '@mui/material'
import { Roles } from '../../../data/roles'
import { useTranslation } from 'react-i18next'

interface Props {
  roles: Array<string>
}

export default function PermissionList({ roles }: Props) {
  const { t } = useTranslation('common')

  return (
    <Box data-testid="permission-list">
      <Typography variant="h2" mb={2}>
        {t('homePage.permissions.title')}
      </Typography>
      <Typography>{t('homePage.permissions.subtitle')}</Typography>
      {(roles.includes(Roles.Admin) ||
        roles.includes(Roles.ServiceDesk) ||
        roles.includes(Roles.WideViewer) ||
        roles.includes(Roles.Editor)) && (
        <>
          <List>
            <ListItem>{t('homePage.permissions.possessorAllInfo')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsDogs')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsFormerDogs')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsDogHistory')}</ListItem>
            <ListItem>{t('homePage.permissions.chippingInformation')}</ListItem>
            <ListItem>{t('homePage.permissions.rightToSearch')}</ListItem>
          </List>
        </>
      )}
      {roles.includes(Roles.NarrowViewer) && (
        <>
          <List>
            <ListItem>{t('homePage.permissions.possessorAllInfo')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsDogs')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsFormerDogs')}</ListItem>
            <ListItem>{t('homePage.permissions.possessorsDogHistory')}</ListItem>
            <ListItem>{t('homePage.permissions.chippingInformation')}</ListItem>
          </List>
        </>
      )}
      {roles.includes(Roles.Shelter) && (
        <List>
          <ListItem>{t('shelter.homePage.permissions.microchipSearch')}</ListItem>
          <ListItem>{t('shelter.homePage.permissions.searchResults')}</ListItem>
        </List>
      )}
    </Box>
  )
}
