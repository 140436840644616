import { useEffect } from 'react'

interface Props {
  selector: string
}

export default function ScrollTo({ selector }: Props) {
  useEffect(() => {
    const element = document.querySelector(selector) as any

    setTimeout(() => {
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop : 0,
      })
    }, 100)
  }, [scrollTo])

  return <></>
}
