/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface InvalidateChangeCodesDto
 */
export interface InvalidateChangeCodesDto {
  /**
   * When the cancelling possessor change form has arrived (only for paper registration).
   * @type {Date}
   * @memberof InvalidateChangeCodesDto
   */
  arrivalDate: Date
}

/**
 * Check if a given object implements the InvalidateChangeCodesDto interface.
 */
export function instanceOfInvalidateChangeCodesDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value

  return isInstance
}

export function InvalidateChangeCodesDtoFromJSON(json: any): InvalidateChangeCodesDto {
  return InvalidateChangeCodesDtoFromJSONTyped(json, false)
}

export function InvalidateChangeCodesDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InvalidateChangeCodesDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    arrivalDate: new Date(json['arrivalDate']),
  }
}

export function InvalidateChangeCodesDtoToJSON(value?: InvalidateChangeCodesDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
  }
}
