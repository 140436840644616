import { AppUserDto } from '../../generated/openapi'
import { Roles } from '../../data/roles'

export const editorUser = {
  name: 'test-user',
  roles: [Roles.ServiceDesk],
} as AppUserDto
export const viewerUser = {
  name: 'test-user',
  roles: [Roles.WideViewer],
} as AppUserDto
export const adminUser = {
  name: 'test-user',
  roles: [Roles.Admin],
} as AppUserDto
export const shelterUser = {
  name: 'test-user',
  roles: [Roles.Shelter],
} as AppUserDto
