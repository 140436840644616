import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CodeApiKeys } from '../data/apiKeys'
import { countryCodes } from '../data/countries'
import { Code, Configuration, CountriesApi, Middleware } from '../generated/codes-app-api'
import { ErrorDto } from '../generated/openapi'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { CODES_API_BASE_URL } from '../setup'

function useCountries() {
  const api = new CountriesApi({
    basePath: CODES_API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
  } as Configuration)

  const fetchCountries = (): UseQueryResult<Code[], ErrorDto> => {
    const fetchCountriesQuery = useQuery<Code[], ErrorDto>({
      queryKey: [CodeApiKeys.COUNTRIES],
      queryFn: () => api.getCountries(),
    })

    return fetchCountriesQuery
  }

  const defaultCountries = () => countryCodes as Code[]

  /**
   * Forms label in the correct language.
   * @param code
   * @returns
   */
  const getLabel = ({ code, language }: { code?: Code; language: string }) => {
    if (!code?.labels) return ''
    return code.labels.find((label) => label.lang === language)?.label
  }

  const getCountry = ({
    code: countryCode,
    language,
  }: {
    code: string
    language: string
  }): { value?: string; error?: ErrorDto | null; isPending?: boolean } => {
    const { data: countryList, error: countryListError, isPending } = fetchCountries()

    if (isPending) {
      return { isPending }
    }

    if (!countryList || countryListError) {
      return { error: countryListError }
    }

    return {
      value: getLabel({
        code: countryList.find((code) => `${code.code}` === countryCode),
        language,
      }),
    }
  }

  return {
    getCountry,
    fetchCountries,
    defaultCountries,
  }
}
export default useCountries
