import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

import { AnimalDtoSexCodeEnum } from '../../generated/animare-management-api'
import { useTranslation } from 'react-i18next'
import InlineValidationError from './InlineValidationError'

type Props = {
  field: ControllerRenderProps<FieldValues, any>
  error: any
  errorContent: string
  errorId?: string
}

function SexSelect({ field, error, errorContent, errorId }: Props) {
  const { t } = useTranslation('common')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    field.onChange(value)
  }

  return (
    <Box>
      <Typography variant="h3" id="sex-title">
        {t('common.animal.sex')} <i aria-hidden={true}>*</i>
        <i className="visible-hidden">{t('registrationForm.field.required')}</i>
      </Typography>
      <Typography variant="body1"> {t('registrationForm.help.sex')} </Typography>
      <RadioGroup
        data-qa="sexCode"
        {...field}
        {...error}
        aria-labelledby={!error ? 'sex-title' : errorId}
        value={field.value}
        onChange={handleChange}
        sx={{ mt: 1, gap: { sm: 10, xs: 0 } }}
        row
      >
        {Object.values(AnimalDtoSexCodeEnum).map((sexCode, index) => {
          return (
            <FormControlLabel
              key={index}
              value={sexCode}
              control={<Radio />}
              label={t(`common.sex.${sexCode}`)}
            />
          )
        })}
      </RadioGroup>
      {!!error && <InlineValidationError content={errorContent} id={errorId} />}
    </Box>
  )
}

export default SexSelect
