/* tslint:disable */
/* eslint-disable */
/**
 * Animare Codes API
 * Provides codes and their labels in various languages used by Animare applications.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { CodeLabelsInner } from './CodeLabelsInner'
import {
  CodeLabelsInnerFromJSON,
  CodeLabelsInnerFromJSONTyped,
  CodeLabelsInnerToJSON,
} from './CodeLabelsInner'

/**
 *
 * @export
 * @interface Code
 */
export interface Code {
  /**
   * The code being described
   * @type {string}
   * @memberof Code
   */
  code: string
  /**
   * All known labels for the code in various languages
   * @type {Array<CodeLabelsInner>}
   * @memberof Code
   */
  labels?: Array<CodeLabelsInner>
}

/**
 * Check if a given object implements the Code interface.
 */
export function instanceOfCode(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'code' in value

  return isInstance
}

export function CodeFromJSON(json: any): Code {
  return CodeFromJSONTyped(json, false)
}

export function CodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Code {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: json['code'],
    labels: !exists(json, 'labels')
      ? undefined
      : (json['labels'] as Array<any>).map(CodeLabelsInnerFromJSON),
  }
}

export function CodeToJSON(value?: Code | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: value.code,
    labels:
      value.labels === undefined
        ? undefined
        : (value.labels as Array<any>).map(CodeLabelsInnerToJSON),
  }
}
