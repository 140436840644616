import {
  Divider,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AnimalImportingSearchDto,
  AnimalProtectionSearchDto,
  ProfessionalActivitySearchDto,
  PuppyMillingPossessorChangeSearchDto,
  PuppyMillingRegistrationsSearchDto,
} from '../../generated/animare-management-api'
import { useMonitoringMapper } from '../../hooks/useMapper'
import { useMonitoring } from '../../hooks/useMonitoring'
import { useMonitoringCache } from '../../hooks/useMonitoringCache'
import BackButton from '../BackButton'
import AnimalProtectionSearch from '../Monitoring/AnimalProtectionSearch'
import SpacerBox from '../SpacerBox'
import AnimalImportingSearch from './AnimalImportingSearch'
import FirstRegistrationsSearch from './FirstRegistrationsSearch'
import {
  AnimalImportingSearchFormValues,
  AnimalProtectionSearchFormValues,
  FirstRegistrationsFormValues,
  PossessorChangeSearchFormValues,
  ProfessionalActivitySearchFormValues,
  getAnimalImportingSearchSchema,
  getAnimalProtectionSearchSchema,
  getFirstRegistrationsSearchSchema,
  getPossessorChangeSearchSchema,
  getProfessionalActivitySearchSchema,
} from './MonitoringSearchSchema'
import PossessorChangeSearch from './PossessorChangeSearch'
import ProfessionalActivitySearch from './ProfessionalActivitySearch'
import SearchForm, { Props as SearchFormProps } from './SearchForm'

interface Props {
  searchType?: SearchTypes
}

export enum SearchTypes {
  AnimalProtection = 'animalProtection',
  ProfessionalActivity = 'professionalActivity',
  PossessorChange = 'puppyMillingPossessorChange',
  FirstRegistrations = 'puppyMillingFirstRegistrations',
  AnimalImporting = 'animalImporting',
}

export default function MonitoringSearchPage({ searchType }: Props) {
  const { t } = useTranslation('common')

  const monitoringHook = useMonitoring()
  const mapper = useMonitoringMapper()

  const animalImportSearch = {
    searchType: SearchTypes.AnimalImporting,
    mutationCallback: monitoringHook.searchForAnimalImporting,
    schema: getAnimalImportingSearchSchema(),
    mapper: mapper.mapToAnimalImportingSearchDto,
    blankValues: {
      birthCountryCode: '',
      allCountries: false,
      municipalityCode: '',
      registrationStartDate: undefined,
      registrationEndDate: undefined,
      birthStartDate: undefined,
      birthEndDate: undefined,
      possessorChangeStartDate: undefined,
      possessorChangeEndDate: undefined,
    },
    component: AnimalImportingSearch,
    canDownloadResults: true,
  } as SearchFormProps<AnimalImportingSearchDto, AnimalImportingSearchFormValues>

  const animalProtectionSearch = {
    searchType: SearchTypes.AnimalProtection,
    mutationCallback: monitoringHook.searchForAnimalProtection,
    schema: getAnimalProtectionSearchSchema(),
    mapper: mapper.mapToAnimalProtectionSearchDto,
    blankValues: {
      address: '',
      breedCode: '',
      firstName: '',
      lastName: '',
      municipalityCode: '',
      organizationName: '',
      postalCode: '',
    },
    component: AnimalProtectionSearch,
    canDownloadResults: false,
  } as SearchFormProps<AnimalProtectionSearchDto, AnimalProtectionSearchFormValues>

  const firstRegistrationsSearch = {
    searchType: SearchTypes.FirstRegistrations,
    mutationCallback: monitoringHook.searchForFirstRegistrations,
    schema: getFirstRegistrationsSearchSchema(),
    mapper: mapper.mapToPuppyMillingRegistrationsSearchDto,
    blankValues: {
      municipalityCode: '',
      endDate: '',
      startDate: '',
      firstRegistrationCount: undefined,
    },
    component: FirstRegistrationsSearch,
    canDownloadResults: true,
  } as SearchFormProps<PuppyMillingRegistrationsSearchDto, FirstRegistrationsFormValues>

  const possessorChangeSearch = {
    searchType: SearchTypes.PossessorChange,
    mutationCallback: monitoringHook.searchForPossessorChange,
    schema: getPossessorChangeSearchSchema(),
    mapper: mapper.mapToPuppyMillingPossessorChangeSearchDto,
    blankValues: { municipalityCode: '', minimumPossessorChangeCount: undefined },
    component: PossessorChangeSearch,
    canDownloadResults: true,
  } as SearchFormProps<PuppyMillingPossessorChangeSearchDto, PossessorChangeSearchFormValues>

  const professionalSearch = {
    searchType: SearchTypes.ProfessionalActivity,
    mutationCallback: monitoringHook.searchForProfessionalActivity,
    schema: getProfessionalActivitySearchSchema(),
    mapper: mapper.mapToProfessionalActivitySearchDto,
    blankValues: { minimumRegisteredAnimals: undefined, municipalityCode: '' },
    component: ProfessionalActivitySearch,
    canDownloadResults: true,
  } as SearchFormProps<ProfessionalActivitySearchDto, ProfessionalActivitySearchFormValues>

  const searchForms = [
    animalProtectionSearch,
    firstRegistrationsSearch,
    possessorChangeSearch,
    professionalSearch,
    animalImportSearch,
  ]

  const [selectedForm, setSelectedForm] = useState<SearchFormProps<any, any> | undefined>(
    searchForms.find((f) => f.searchType === searchType),
  )

  const changeSearchType = (event: SelectChangeEvent) => {
    setSelectedForm(searchForms.find((f) => f.searchType === event.target.value))
  }
  return (
    <>
      <BackButton
        onClick={() => {
          useMonitoringCache().clear()
        }}
      />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={4} variant="container">
          <SpacerBox component="section" gap={2} variant="container">
            <Typography variant="h1">{t('monitoring.title')}</Typography>

            <Divider sx={{ mb: 2 }} />

            <FormGroup sx={{ width: { xs: '100%', sm: '48.5%' } }}>
              <InputLabel id="monitoring-label">{t('monitoring.searchTypes.label')}</InputLabel>
              <Select
                data-qa={'monitoringSearchType'}
                value={selectedForm?.searchType ?? ''}
                onChange={changeSearchType}
                inputProps={{ 'aria-label': `${t('monitoring.searchTypes.label')}` }}
              >
                {searchForms.map((f) => (
                  <MenuItem
                    key={f.searchType}
                    value={f.searchType}
                    data-qa={`monitoringSearchType-option-${f.searchType}`}
                  >
                    {t(`monitoring.searchTypes.${f.searchType}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormGroup>
          </SpacerBox>
          {selectedForm && <SearchForm {...selectedForm} />}
        </SpacerBox>
      </Paper>
    </>
  )
}
