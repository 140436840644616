/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LimitedMicrochip
 */
export interface LimitedMicrochip {
  /**
   *
   * @type {string}
   * @memberof LimitedMicrochip
   */
  number?: string
  /**
   *
   * @type {Date}
   * @memberof LimitedMicrochip
   */
  date?: Date
}

/**
 * Check if a given object implements the LimitedMicrochip interface.
 */
export function instanceOfLimitedMicrochip(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function LimitedMicrochipFromJSON(json: any): LimitedMicrochip {
  return LimitedMicrochipFromJSONTyped(json, false)
}

export function LimitedMicrochipFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LimitedMicrochip {
  if (json === undefined || json === null) {
    return json
  }
  return {
    number: !exists(json, 'number') ? undefined : json['number'],
    date: !exists(json, 'date') ? undefined : new Date(json['date']),
  }
}

export function LimitedMicrochipToJSON(value?: LimitedMicrochip | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    number: value.number,
    date: value.date === undefined ? undefined : value.date.toISOString().substr(0, 10),
  }
}
