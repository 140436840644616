/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Is the animal dead or alive?
 * @export
 */
export const AnimalHealthType = {
  Alive: 'alive',
  Dead: 'dead',
} as const
export type AnimalHealthType = (typeof AnimalHealthType)[keyof typeof AnimalHealthType]

export function AnimalHealthTypeFromJSON(json: any): AnimalHealthType {
  return AnimalHealthTypeFromJSONTyped(json, false)
}

export function AnimalHealthTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalHealthType {
  return json as AnimalHealthType
}

export function AnimalHealthTypeToJSON(value?: AnimalHealthType | null): any {
  return value as any
}
