import { useEffect } from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Code, CodeLabelsInner } from '../../generated/codes-app-api'

import { useTranslation } from 'react-i18next'
import useCountries from '../../hooks/useCountries'
import { FormAutoComplete } from '../FormAutoComplete'
import InlineValidationError from './InlineValidationError'

type Props = {
  error: boolean
  ariaLabelledBy?: string
  field: ControllerRenderProps<FieldValues, any>
  errorContent: string
  errorId?: string
  excludeFinland?: boolean
  isPending?: boolean
  isDisabled?: boolean
}

function CountrySelect({
  error,
  ariaLabelledBy,
  field,
  errorContent,
  errorId,
  excludeFinland,
  isDisabled,
}: Props) {
  const { defaultCountries, fetchCountries } = useCountries()
  const { i18n, t } = useTranslation('common')

  interface CountryType {
    label: string
    code: Code
  }

  const toCountryType = (code: Code): CountryType => {
    return {
      label:
        code.labels?.find((label: CodeLabelsInner) => label.lang === i18n.language)?.label ??
        code.code,
      code: code,
    }
  }

  const {
    refetch: refetchCountries,
    data: allCountries,
    error: allCountriesError,
    isPending,
  } = fetchCountries()

  const defaultOptions = defaultCountries().map(toCountryType)

  const FINLAND_COUNTRY_CODE = '246'

  // Finland is excluded of Animal Import Search
  const defaultCountriesListing = excludeFinland
    ? defaultOptions.length - 2
    : defaultOptions.length - 1

  const getAllCountries = (): CountryType[] => {
    if (!allCountries || allCountriesError) {
      return []
    }

    const countryTypes = allCountries
      .map((code) => toCountryType(code))
      .sort((a, b) => a.label.localeCompare(b.label))

    if (countryTypes && countryTypes.length > 0) {
      // Map default codes (number, eg. 246 = Finland, in countries.ts file)
      const defaultCodes = defaultOptions.map(({ code }) => code.code)
      // Map default codes with new index, 0,1,2...
      const defaultCodesWithRewritedIndex = defaultOptions
        .map((countryType, index) => {
          return {
            ...countryType,
            index,
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
        .filter((countryType) =>
          excludeFinland ? countryType.code.code !== FINLAND_COUNTRY_CODE : true,
        ) // Exclude Finland in Animal Importing search

      // Filter default codes from all results and reindex all results
      // Index now starts with defaultCodes.length = 3,4,5....
      const reIndexedountryTypes = countryTypes
        .filter((countryType) => !defaultCodes.includes(countryType.code.code))
        .map((countryType, index) => {
          return {
            ...countryType,
            index: index + defaultCodes.length,
          }
        })

      // Combine resultset to single array
      return [...defaultCodesWithRewritedIndex, ...reIndexedountryTypes]
    }

    return defaultOptions
  }

  useEffect(() => {
    refetchCountries()
  }, [i18n.language])

  const dropdownOptions = getAllCountries().map((country) => ({
    label: country.label,
    value: `${country.code.code}`,
  }))

  return dropdownOptions && dropdownOptions.length > 0 ? (
    <>
      <FormAutoComplete
        options={dropdownOptions}
        loading={isPending}
        field={field}
        isRequired
        isDisabled={isDisabled}
        dataQAString={field.name}
        error={error && !field.value}
        ariaLabelledBy={ariaLabelledBy}
        inputTitle={t('common.animal.birthCountry')}
        dividerPosition={dropdownOptions[defaultCountriesListing].value}
      />
      {!!error && !field.value && <InlineValidationError content={errorContent} id={errorId} />}
    </>
  ) : (
    <></>
  )
}

export default CountrySelect
