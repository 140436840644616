import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { OTHER_BREED } from '../data/breeds'
import { LimitedAnimalResponseDto } from '../generated/animare-management-api/models'
import BreedSize from './BreedSize'
import Breed from './Codes/Breed'
import Color from './Color'
import Field from './Field'
import Sex from './Sex'

export interface Props {
  registration?: LimitedAnimalResponseDto
}

export const ShelterDog = ({ registration }: Props) => {
  const { t } = useTranslation('common')

  const name = registration?.name
  const colors = registration?.colours
  const breedCode = registration?.breedCode
  const isUnknownBreed = !breedCode || breedCode === OTHER_BREED
  const breedDescription = registration?.breedDescription

  return (
    <>
      <Grid container data-qa="dog-container" component="dl" spacing={4} sx={{ mb: 4 }}>
        <Field
          title={t('common.animal.microchipCode')}
          text={registration?.microchipNumber}
          dataQa="microchip-field"
        />
        {name ? (
          <Field title={t('common.animal.name')} text={name} dataQa="animal-name-field" />
        ) : (
          <></>
        )}
        <Field
          title={t('common.animal.breed')}
          text={<Breed code={breedCode} />}
          dataQa="breed-field"
        />
        {breedDescription ? (
          <Field
            title={t('common.animal.description')}
            text={breedDescription}
            dataQa="breed-description-field"
          />
        ) : (
          <></>
        )}
        {isUnknownBreed ? (
          <Field
            title={t('common.animal.size')}
            text={<BreedSize code={registration?.breedSize} />}
            dataQa="animal-size-field"
          />
        ) : (
          <></>
        )}
        <Field
          title={t('common.animal.sex')}
          text={<Sex code={registration?.sexCode} />}
          dataQa="sex-field"
        />
        <Field
          title={t('common.animal.color')}
          body={
            <>
              {colors?.map((c, key) => (
                <Typography
                  variant="body1"
                  component="dd"
                  key={key}
                  data-qa={`animal-color-${key}`}
                >
                  <Color code={c} />
                </Typography>
              ))}
            </>
          }
          dataQa="animal-color-field"
        />
      </Grid>
    </>
  )
}
