import { Box, Container } from '@mui/material'

type Props = {
  children: JSX.Element | JSX.Element[]
}

function Body({ children }: Props) {
  return (
    <Container component="main" sx={{ mb: 10, pr: { xs: 2, sm: 4 }, pl: { xs: 2, sm: 4 } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: { sm: 5, xs: 2 },
          mb: 5,
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

export default Body
