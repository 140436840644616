/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface AppUserDto
 */
export interface AppUserDto {
  /**
   *
   * @type {string}
   * @memberof AppUserDto
   */
  name?: string
  /**
   *
   * @type {Array<string>}
   * @memberof AppUserDto
   */
  roles?: Array<string>
}

/**
 * Check if a given object implements the AppUserDto interface.
 */
export function instanceOfAppUserDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function AppUserDtoFromJSON(json: any): AppUserDto {
  return AppUserDtoFromJSONTyped(json, false)
}

export function AppUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppUserDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    roles: !exists(json, 'roles') ? undefined : json['roles'],
  }
}

export function AppUserDtoToJSON(value?: AppUserDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    roles: value.roles,
  }
}
