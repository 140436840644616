import { Configuration, Middleware, querystring, UserRestControllerApi } from '../generated/openapi'
import { AppUserDto } from '../generated/openapi/models/AppUserDto'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'

export default function useUserApi(autoRedirectToLogout?: boolean) {
  const api = new UserRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware(autoRedirectToLogout ?? true)] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  const fetchCurrentUser = (): Promise<AppUserDto> => {
    return api.userInfo()
  }

  return {
    fetchCurrentUser,
  }
}
