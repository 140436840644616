import { Code } from '../generated/codes-app-api'

// Cache the most used countries
export const countryCodes = [
  {
    code: '246',
    labels: [
      {
        lang: 'fi',
        label: 'Suomi',
      },
      {
        lang: 'en',
        label: 'Finland',
      },
      {
        lang: 'sv',
        label: 'Finland',
      },
    ],
  },
  {
    code: '752',
    labels: [
      {
        lang: 'fi',
        label: 'Ruotsi',
      },
      {
        lang: 'en',
        label: 'Sweden',
      },
      {
        lang: 'sv',
        label: 'Sverige',
      },
    ],
  },
  {
    code: '578',
    labels: [
      {
        lang: 'fi',
        label: 'Norja',
      },
      {
        lang: 'en',
        label: 'Norway',
      },
      {
        lang: 'sv',
        label: 'Norge',
      },
    ],
  },
  {
    code: '233',
    labels: [
      {
        lang: 'fi',
        label: 'Viro',
      },
      {
        lang: 'en',
        label: 'Estonia',
      },
      {
        lang: 'sv',
        label: 'Estland',
      },
    ],
  },
  {
    code: '643',
    labels: [
      {
        lang: 'fi',
        label: 'Venäjä',
      },
      {
        lang: 'en',
        label: 'Russian Federation',
      },
      {
        lang: 'sv',
        label: 'Ryssland',
      },
    ],
  },
] as Code[]
