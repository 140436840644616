import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LimitedPossessor } from '../generated/animare-management-api'
import { Municipality } from './Codes/Municipality'
import Field from './Field'

interface ShelterPossessorProps {
  possessor: LimitedPossessor
}

export const ShelterPossessor = ({ possessor }: ShelterPossessorProps) => {
  const { t } = useTranslation('common')

  return (
    <Grid container data-qa="possessor-container" component="dl" spacing={4} sx={{ mb: 4 }}>
      <Field
        title={t('possessorDetails.field.name')}
        text={`${possessor.name}`}
        dataQa="person-identifier-name-field"
      />
      <Field
        title={t('possessorDetails.field.phoneNumber')}
        text={possessor.phone ? possessor.phone : t('common.not-known')}
        dataQa="phone-field"
      />
      <Field
        title={t('possessorDetails.field.email')}
        text={possessor.email ? possessor.email : t('common.not-known')}
        dataQa="email-field"
      />
      {possessor.municipalityCode && (
        <Field
          title={t('possessorDetails.field.municipality')}
          dataQa="municipality-field"
          text={<Municipality code={possessor.municipalityCode} />}
        ></Field>
      )}
    </Grid>
  )
}
