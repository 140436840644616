import { useTranslation } from 'react-i18next'
import { ErrorDto } from '../generated/animare-management-api/models/ErrorDto'

export enum ErrorCodes {
  General = 'E0000',
  DuplicateRegistration = 'E0010',
  RegistrationNotFound = 'E0020',
  InvalidChippingDate = 'E0030',
  UnknownCountry = 'E0040',
  UnknownBreed = 'E0041',
  BreedDetailsMissing = 'E0050',
  IllegalStatusUpdate = 'E0060',
  UnpaidRegistrationNotFound = 'E0090',
  PaymentNotFound = 'E0100',
  PaymentSignatureError = 'E0110',
  NotModifiable = 'E0200',
  AnimalNotFound = 'E0300',
  PossessorNotFound = 'E0400',
  DuplicateIdentifier = 'E0401',
  ChangeCodeForOwnAnimal = 'E0451',
  MicrochipNotFound = 'E0500',
  MethodArgumentNotValid = 'E1000',
  InvalidArgument = 'E1000',
  UnfinishedPayment = 'E1200',
  TooManyResults = 'E5500',
}

export enum SecretsApiErrorCodes {
  UnknownSecret = 'E0010',
  InvalidCipher = 'E0020',
  UnhashableSecret = 'E0030',
}

export enum ApiTypes {
  Animare = 'animare',
  Codes = 'codes',
  Management = 'management',
  Secrets = 'secrets',
}

export const getErrorText = (errorCode: ErrorDto, apiType: ApiTypes = ApiTypes.Animare): string => {
  const { t } = useTranslation('common')

  if (!errorCode.code) {
    return `${t('errors.general')}`
  }
  if (apiType === ApiTypes.Animare) {
    switch (errorCode.code) {
      case ErrorCodes.DuplicateRegistration: // This is clearly error that needs user actions
        return t('errors.duplicate-microchip-code')
      case ErrorCodes.DuplicateIdentifier:
        return t('errors.duplicate-identifier')
      case ErrorCodes.ChangeCodeForOwnAnimal:
        return t('errors.same-possessor-changeCode')
      case ErrorCodes.RegistrationNotFound:
        return t('errors.registration-not-found')
      case ErrorCodes.MicrochipNotFound:
        return t('errors.microchip-not-found')
      case ErrorCodes.TooManyResults:
        return t('errors.monitoring.tooManyResults')
    }
  }
  if (apiType === ApiTypes.Secrets) {
    switch (errorCode.code) {
      // these are hardcoded in Finnish for now
      case SecretsApiErrorCodes.InvalidCipher:
        return 'Salaisuuden purku epäonnistui'
      case SecretsApiErrorCodes.UnknownSecret:
        return 'Tuntematon salaisuustyyppi'
      case SecretsApiErrorCodes.UnhashableSecret:
        return 'Valitulle salaisuudelle ei voi laskea tiivistettä'
    }
  }
  // default case is last
  return `${t('errors.general')} (${errorCode.code as string}: ${errorCode.description})`
}

// Give an array of error codes and check if the error code exists in it
export const hasErrorCodes = (error: ErrorDto, errorCodes: ErrorCodes[]) =>
  errorCodes.find((errorCode) => errorCode === error.code)
