import { Grid, Typography } from '@mui/material'

export interface Props {
  title: string
  body?: any
  text?: any
  dataQa?: string
}

export default function Field({ title, body, text, dataQa }: Props) {
  return (
    <Grid item xs={12} md={4}>
      <Typography variant="body2" component="dt" data-qa={dataQa ? `${dataQa}-title` : ''}>
        {title}
      </Typography>
      {text ? (
        <Typography variant="body1" component="dd" data-qa={dataQa ? `${dataQa}-text` : ''}>
          {text}
        </Typography>
      ) : (
        <>{body}</>
      )}
    </Grid>
  )
}
