/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { EventDtoPossessor } from './EventDtoPossessor'
import {
  EventDtoPossessorFromJSON,
  EventDtoPossessorFromJSONTyped,
  EventDtoPossessorToJSON,
} from './EventDtoPossessor'

/**
 *
 * @export
 * @interface EventDto
 */
export interface EventDto {
  /**
   * The unique identifier of the event
   * @type {string}
   * @memberof EventDto
   */
  uid?: string
  /**
   * The time of the event.
   * @type {string}
   * @memberof EventDto
   */
  timestamp?: string
  /**
   * The event type.
   * @type {string}
   * @memberof EventDto
   */
  type?: string
  /**
   * The event type category.
   * @type {string}
   * @memberof EventDto
   */
  category?: string
  /**
   * If the event is something that has happened to the animal in real life (like microchipping, animal death,
   * possessor change...), the date when that physical action has happened.
   * @type {Date}
   * @memberof EventDto
   */
  animalPhysicalEventDate?: Date
  /**
   * If the event is related to a document/form, documentArrivalDate is added.
   * @type {Date}
   * @memberof EventDto
   */
  documentArrivalDate?: Date
  /**
   *
   * @type {EventDtoPossessor}
   * @memberof EventDto
   */
  possessor?: EventDtoPossessor
  /**
   * Extended details pertaining to an event.
   * @type {{ [key: string]: string; }}
   * @memberof EventDto
   */
  details?: { [key: string]: string }
}

/**
 * Check if a given object implements the EventDto interface.
 */
export function instanceOfEventDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function EventDtoFromJSON(json: any): EventDto {
  return EventDtoFromJSONTyped(json, false)
}

export function EventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    timestamp: !exists(json, 'timestamp') ? undefined : json['timestamp'],
    type: !exists(json, 'type') ? undefined : json['type'],
    category: !exists(json, 'category') ? undefined : json['category'],
    animalPhysicalEventDate: !exists(json, 'animalPhysicalEventDate')
      ? undefined
      : new Date(json['animalPhysicalEventDate']),
    documentArrivalDate: !exists(json, 'documentArrivalDate')
      ? undefined
      : new Date(json['documentArrivalDate']),
    possessor: !exists(json, 'possessor')
      ? undefined
      : EventDtoPossessorFromJSON(json['possessor']),
    details: !exists(json, 'details') ? undefined : json['details'],
  }
}

export function EventDtoToJSON(value?: EventDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    timestamp: value.timestamp,
    type: value.type,
    category: value.category,
    animalPhysicalEventDate:
      value.animalPhysicalEventDate === undefined
        ? undefined
        : value.animalPhysicalEventDate.toISOString().substr(0, 10),
    documentArrivalDate:
      value.documentArrivalDate === undefined
        ? undefined
        : value.documentArrivalDate.toISOString().substr(0, 10),
    possessor: EventDtoPossessorToJSON(value.possessor),
    details: value.details,
  }
}
