import {
  AnimalCountryDto,
  AnimalCountryType,
  AnimalDtoBreedSizeEnum,
  AnimalDtoColoursEnum,
  AnimalDtoSexCodeEnum,
  AnimalHealthType,
  AnimalLocationDto,
  AnimalLocationType,
  FullRegistrationDto,
  RegistrationDto,
} from '../../generated/animare-management-api'
import { ServiceDeskRegistrationFormValues } from '../../hooks/useRegistration'
import { organizationPossessorDto, possessorDto, possessorWithChangeCode } from './possessors'

export const fullRegistration = {
  uid: 'd681c37d-85cb-4d0f-979f-17b1546f2b7a',
  arrivalDate: new Date('2023-01-01'),
  startDate: new Date('2023-01-18'),
  animal: {
    uid: '82dd30ef-b659-4a7d-9eb2-f8506b1dfd15',
    name: 'Fueffe',
    kennelName: 'Fuellendorf von Wuff',
    birthDate: new Date('2022-12-01'),
    birthCountryCode: '246',
    breedDescription: 'Mukava koira on',
    breedCode: '2',
    sexCode: AnimalDtoSexCodeEnum.Female,
    colours: [AnimalDtoColoursEnum.Gray, AnimalDtoColoursEnum.Black],
    status: {
      country: { type: AnimalCountryType.Finland } as AnimalCountryDto,
      health: AnimalHealthType.Alive,
      location: {
        type: AnimalLocationType.Found,
      } as AnimalLocationDto,
    },
    microchips: [
      {
        uid: '8706676f-a673-4160-a35f-5127bc3dddd2',
        microchipNumber: '100003000092000',
        microchipDate: new Date('2023-05-15'),
        microchipSetterName: 'first last',
        microchipSetterAddress: 'street street whatever',
      },
      {
        uid: '8706676f-a673-4160-a35f-5127bc3dddd3',
        microchipNumber: '100003000092001',
        microchipDate: new Date('2023-03-14'),
        microchipSetterName: 'Mikko Mikrosiruttaja',
        microchipSetterAddress: 'Mikrosiruttajankatu 23',
      },
      {
        uid: '00000000-0000-0000-0000-000000000000',
        microchipNumber: '100003000092002',
        microchipDate: new Date('2022-12-01'),
        microchipSetterName: 'Mikko Mikrosiruttaja',
        microchipSetterAddress: 'Mikrosiruttajankatu 23',
      },
    ],
  },
  microchip: {
    uid: '8706676f-a673-4160-a35f-5127bc3dddd2',
    microchipNumber: '100003000092000',
    microchipDate: new Date('2022-12-01'),
    microchipSetterName: 'first last',
    microchipSetterAddress: 'street street whatever',
  },
  possessor: possessorDto,
} as FullRegistrationDto

export const fullOrganizationRegistration = {
  uid: 'd681c37d-85cb-4d0f-979f-17b1546f2b7b',
  arrivalDate: new Date('2023-01-01'),
  startDate: new Date('2023-01-18'),
  animal: {
    uid: '82dd30ef-b659-4a7d-9eb2-f8506b1dfd16',
    name: 'Pulivari',
    kennelName: 'Bolivar',
    birthDate: new Date('2022-12-01'),
    birthCountryCode: '246',
    breedDescription: 'Roteva mutta hellämielinen koiranköriläs',
    breedCode: '0',
    breedSize: AnimalDtoBreedSizeEnum.Large,
    sexCode: AnimalDtoSexCodeEnum.Male,
    colours: [AnimalDtoColoursEnum.Brown, AnimalDtoColoursEnum.White],
    status: {
      country: { type: AnimalCountryType.Finland } as AnimalCountryDto,
      health: AnimalHealthType.Alive,
      location: {
        type: AnimalLocationType.Found,
      } as AnimalLocationDto,
    },
    microchips: [
      {
        uid: '8706676f-a673-4160-a35f-5127bc3dddd5',
        microchipNumber: '100003000092005',
        microchipDate: new Date('2023-05-15'),
        microchipSetterName: 'Hessu Hopo',
        microchipSetterAddress: 'Mikrosiruttajankatu 11',
      },
      {
        uid: '8706676f-a673-4160-a35f-5127bc3dddd6',
        microchipNumber: '100003000092006',
        microchipDate: new Date('2023-03-14'),
        microchipSetterName: 'Mikki Hiiri',
        microchipSetterAddress: 'Mikrosiruttajankatu 22',
      },
      {
        uid: '8706676f-a673-4160-a35f-5127bc3dddd7',
        microchipNumber: '100003000092007',
        microchipDate: new Date('2022-12-01'),
        microchipSetterName: 'Roope Ankka',
        microchipSetterAddress: 'Mikrosiruttajankatu 2333',
      },
    ],
  },
  microchip: {
    uid: '8706676f-a673-4160-a35f-5127bc3dddd5',
    microchipNumber: '100003000092005',
    microchipDate: new Date('2023-05-15'),
    microchipSetterName: 'Hessu Hopo',
    microchipSetterAddress: 'Mikrosiruttajankatu 11',
  },
  possessor: organizationPossessorDto,
} as FullRegistrationDto

export const possessorChangeRegistration = {
  uid: 'k981c37d-85cb-4d0f-979f-17b1546f9x5y',
  arrivalDate: new Date('2023-01-01'),
  startDate: new Date('2023-01-31'),
  animal: {
    uid: '14dd30ef-b659-4a7d-9eb2-f8506b1dpk21',
    name: 'Vaihtokoodillinen',
    kennelName: 'Wakawanha Waihdokas',
    birthDate: new Date('2022-12-04'),
    birthCountryCode: '246',
    breedDescription: 'Tällä koiralla on vaihtokoodi',
    breedCode: '4',
    sexCode: AnimalDtoSexCodeEnum.Male,
    colours: [AnimalDtoColoursEnum.Red, AnimalDtoColoursEnum.Yellow],
    status: {
      country: { type: AnimalCountryType.Finland } as AnimalCountryDto,
      health: AnimalHealthType.Alive,
      location: {
        type: AnimalLocationType.Found,
      } as AnimalLocationDto,
    },
    microchips: [
      {
        uid: '7446676f-a673-4160-a35f-5127bc3drrr8',
        microchipNumber: '666666666666666',
        microchipDate: new Date('2022-12-31'),
        microchipSetterName: 'first last',
        microchipSetterAddress: 'street street whatever',
      },
    ],
  },
  microchip: {
    uid: '7446676f-a673-4160-a35f-5127bc3drrr8',
    microchipNumber: '666666666666666',
    microchipDate: new Date('2022-12-31'),
    microchipSetterName: 'first last',
    microchipSetterAddress: 'street street whatever',
  },
  possessor: possessorWithChangeCode,
  changeCode: {
    registrationUid: '4yty06e5-4176-4f5c-bd67-ec52360e1x30',
    animalUid: '14dd30ef-b659-4a7d-9eb2-f8506b1dpk21',
    code: '848484848484',
    creationTime: new Date('2023-06-06T13:53:59.137852Z'),
  },
} as FullRegistrationDto

export const endedRegistration = {
  uid: 'de0f3a3d-ca79-480e-98f6-0d472839613b',
  startDate: new Date('2023-01-22'),
  endDate: new Date('2023-03-11'),
  animal: {
    uid: '3243ce6d-70b4-4e51-9da0-450f9735c00c',
    name: 'Päättynyt',
    birthDate: new Date('2023-01-11'),
    birthCountryCode: '246',
    breedCode: '1',
    sexCode: AnimalDtoSexCodeEnum.Male,
    colours: [AnimalDtoColoursEnum.Red],
    status: {
      country: { type: AnimalCountryType.Finland } as AnimalCountryDto,
      health: AnimalHealthType.Alive,
      location: {
        type: AnimalLocationType.Found,
      } as AnimalLocationDto,
    },
    microchips: [
      {
        uid: '2ac3a78c-9aa5-4758-abd4-e218168fe7ff',
        microchipNumber: '100003000092010',
        microchipDate: new Date('2023-01-22'),
      },
    ],
  },
  microchip: {
    uid: '2ac3a78c-9aa5-4758-abd4-e218168fe7ff',
    microchipNumber: '100003000092010',
    microchipDate: new Date('2023-01-22'),
  },
  possessor: fullRegistration.possessor,
} as FullRegistrationDto

export const deadDog = {
  ...endedRegistration,
  uid: 'df3bd1ed-e49f-44a8-a25b-348d918e4b8d',
  endDate: undefined,
  animal: {
    ...endedRegistration.animal,
    name: 'Status Kuollut',
    status: {
      ...endedRegistration.animal.status,
      health: AnimalHealthType.Dead,
    },
    deathDate: new Date('2023-08-10'),
  },
} as FullRegistrationDto

export const statusAbroad = {
  ...deadDog,
  uid: '7be20a96-8b00-48a5-8b76-3468331bbbf2',
  animal: {
    ...deadDog.animal,
    name: 'Status Ulkomailla',
    status: {
      ...endedRegistration.animal.status,
      country: {
        type: AnimalCountryType.Abroad,
        date: new Date('2023-11-02'),
      },
    },
  },
} as FullRegistrationDto

export const statusMissing = {
  ...deadDog,
  uid: '0bbe52e3-8c17-4277-ae79-789787ef504a',
  animal: {
    ...deadDog.animal,
    name: 'Status Kadonnut',
    status: {
      ...endedRegistration.animal.status,
      location: {
        type: AnimalLocationType.Missing,
        date: new Date('2023-02-02'),
      },
    },
  },
} as FullRegistrationDto

export const transferredRegistration = {
  ...fullRegistration,
  uid: 'd923db4e-716c-4b7f-ae6d-8c8598a074cd',
  startDate: new Date('2023-10-20'),
  transferDate: new Date('2023-10-11'),
} as FullRegistrationDto

export const formValues = {
  arrivalDate: new Date(),
  microchipNumber: '000000000000001',
  name: 'Testdoggo',
  kennelName: 'The doggo',
  birthDate: new Date(),
  birthCountryCode: '246',
  breedCode: '124',
  breedSize: AnimalDtoBreedSizeEnum.Small,
  breedDescription: 'koiruli',
  sexCode: AnimalDtoSexCodeEnum.Female,
  colours: [AnimalDtoColoursEnum.Black],
  microchipDate: new Date(),
  microchipSetterName: 'Seppo Siruttaja',
  microchipSetterAddress: 'Kauppakatu 1',
} as ServiceDeskRegistrationFormValues

export const registrations = [
  possessorChangeRegistration,
  fullRegistration,
  fullOrganizationRegistration,
  endedRegistration,
  deadDog,
  statusAbroad,
  statusMissing,
  transferredRegistration,
] as FullRegistrationDto[]

export const toRegistrationDto = (r: FullRegistrationDto) => {
  return {
    animal: r.animal,
    arrivalDate: r.arrivalDate,
    endDate: r.endDate,
    microchip: r.microchip,
    startDate: r.startDate,
    uid: r.uid,
  } as RegistrationDto
}
