import * as yup from 'yup'

import { Box, Button, FormControl, FormGroup, TextField } from '@mui/material'
import { Message, useForm } from 'react-hook-form'
import { ErrorDto, PossessorResponseDto } from '../generated/animare-management-api'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePossessor } from '../hooks/usePossessor'
import { ErrorCodes } from '../utils/errorUtils'
import InlineValidationError from './Form/InlineValidationError'
import LoadingSpinner from './LoadingSpinner'

const IDENTIFIER_REGEX = /^\d{7}\-\d{1}$/

interface Props {
  onOrganizationFound: (response: PossessorResponseDto) => void
  onOrganizationNotFound: (response: string) => void
  onReset: () => void
}

export default function IdentifierOrganizationSearch({
  onOrganizationFound,
  onOrganizationNotFound,
  onReset,
}: Props) {
  const { t } = useTranslation('common')
  const { getOrganizationPossessor } = usePossessor()
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const queryClient = useQueryClient()

  const { mutate: getOrganizationPossessorMutation } = getOrganizationPossessor({
    onSuccess: (response) => {
      onOrganizationFound(response)
    },
    onError: (error: ErrorDto, organizationIdentifier: string) => {
      // If error is 404 from possessor search, it is ok
      if (error.code === ErrorCodes.PossessorNotFound) {
        onOrganizationNotFound(organizationIdentifier)
      } else {
        showErrorNotification()
        setApiError(error)
      }
    },
    onSettled: () => setLoading(false),
    queryClient,
  })

  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    wrongFormat: t('errors.validation.wrongFormat') as Message,
  }

  const schema = yup.object().shape({
    organizationIdentifier: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .matches(IDENTIFIER_REGEX, errorMessages.wrongFormat),
  })

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) })

  /**
   * Gets possessor and animal information.
   * @param event
   */
  const onSubmit = async (form: any) => {
    setApiError(undefined)
    setLoading(true)

    const { organizationIdentifier }: { organizationIdentifier: string } = form

    return getOrganizationPossessorMutation({
      organizationIdentifier,
      fetchPastRegistrations: true,
    })
  }

  /**
   * Reset organizationIdentifier and ui back to initial state
   */
  const handleReset = () => {
    setApiError(undefined)
    setLoading(false)
    setValue('organizationIdentifier', undefined)
    onReset()
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => handleReset()}
        data-qa="identifier-search-organization"
      >
        <FormGroup sx={{ display: 'flex', gap: 2 }}>
          <FormControl>
            <TextField
              {...register('organizationIdentifier')}
              id={'organizationIdentifier'}
              data-qa="organization-identifier-input"
              label={t('registrationSearchPage.searchSection.businessIdentifierLabel')}
              error={!!errors.organizationIdentifier}
              inputProps={{
                type: 'text',
                inputMode: 'text',
              }}
              autoComplete={'off'}
              sx={{ width: '40%' }}
            />
            {!!errors.organizationIdentifier && (
              <InlineValidationError content={errors.organizationIdentifier?.message} />
            )}
          </FormControl>
          <Box>
            <Button
              variant="contained"
              type="submit"
              sx={{ mr: 1 }}
              data-qa="organization-identifier-submit"
            >
              {t('registrationSearchPage.searchSection.button')}
            </Button>
            <Button variant="outlined" type="reset" data-qa="organization-identifier-reset">
              {t('registrationSearchPage.searchSection.reset')}
            </Button>
          </Box>
        </FormGroup>
      </Box>

      {loading && <LoadingSpinner />}

      {!loading && apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={!!apiError && showNotification === SnackBarTypes.ERROR}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
        />
      )}
    </>
  )
}
