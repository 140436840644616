import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

export default function LoadingSpinner() {
  return (
    <Box sx={{ display: 'flex' }} aria-hidden="true" data-qa="spinner">
      <CircularProgress />
    </Box>
  )
}
