import { ErrorOutlineSharp } from '@mui/icons-material'
import { Box, FormHelperText } from '@mui/material'

type Props = {
  content: any
  id?: string
  'data-qa'?: string
}

const style = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: 1,
  color: 'error.main',
}

export default function InlineValidationError({ content, id, 'data-qa': dataQa }: Props) {
  return (
    <Box sx={style} data-qa={dataQa}>
      <ErrorOutlineSharp />
      <FormHelperText id={id} sx={{ m: 0, ml: 1, color: 'error.main' }}>
        {content}
      </FormHelperText>
    </Box>
  )
}
