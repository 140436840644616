import { AccountCircleSharp, ArrowDropDownSharp } from '@mui/icons-material'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAuthContext } from '../../context/AuthProvider'
import { AppRoutes } from '../../routes'
import theme from '../../styles/theme'

function ProfileMenu() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')
  const { currentUser } = useAuthContext()
  const login = () => navigate(AppRoutes.Root)
  const logout = () => {
    navigate(AppRoutes.Logout)
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Box>
      {!currentUser && (
        <Button
          onClick={() => login()}
          id="profile-menu"
          aria-label={t('header.userProfileActionsAria') as string}
          endIcon={<ArrowDropDownSharp />}
          sx={{ textTransform: 'none', color: 'text.secondary' }}
        >
          {t('header.authLink')}
        </Button>
      )}
      {!!currentUser && (
        <div>
          <Button
            onClick={handleClick}
            id="profile-menu"
            aria-label={t('header.userProfileActionsAria') as string}
            startIcon={<AccountCircleSharp sx={{ fill: theme.palette.text.secondary }} />}
            endIcon={<ArrowDropDownSharp />}
            sx={{ textTransform: 'none', color: theme.palette.text.secondary }}
          >
            <Typography
              variant="body1"
              component="span"
              sx={{
                ml: -0.5,
                mr: -0.5,
                fontWeight: 700,
                display: { xs: 'none', sm: 'flex', color: theme.palette.text.secondary },
              }}
            >
              {currentUser?.name}
            </Typography>
          </Button>
          <Menu
            id="menu"
            elevation={2}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'profile-menu',
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose()
                logout()
              }}
            >
              {t('header.logout')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </Box>
  )
}

export default ProfileMenu
