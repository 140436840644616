import * as yup from 'yup'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
} from '@mui/material'
import { FieldValues, Message, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputArrivalDate, { arrivalDateSchema } from './Form/InputArrivalDate'
import { yupResolver } from '@hookform/resolvers/yup'

interface Props {
  open: boolean
  handleAction: (arrivalDate: Date) => void
  handleClose: () => void
}

export default function DogCancelDeathDialog({ open, handleClose, handleAction }: Props) {
  const { t } = useTranslation('common')
  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-qa="cancelDeathDialog-title">
        {t('dogView.confirmCancelDeath.title')}
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit((data: FieldValues) => handleAction(data.arrivalDate))}
        >
          <FormGroup
            sx={{
              display: 'grid',
              gap: 4,
              mt: 2,
              mb: 4,
              width: '100%',
            }}
          >
            <FormControl>
              <InputArrivalDate control={control} name={'arrivalDate'} errors={errors} />
            </FormControl>
          </FormGroup>
          <DialogContentText>{t('dogView.confirmCancelDeath.body')}</DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={handleClose} data-qa="cancelDeathDialog-secondary">
              {t('dogView.confirmCancelDeath.cancel')}
            </Button>
            <Button type="submit" disabled={isSubmitting} data-qa="cancelDeathDialog-primary">
              {t('dogView.confirmCancelDeath.ok')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
