import { FormControl, FormGroup, InputLabel, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import MunicipalitySelect from '../Form/MunicipalitySelect'
import { FirstRegistrationsFormValues } from './MonitoringSearchSchema'

import { getCurrentLocale } from '../../hooks/useDate'
import DatePicker from '../DatePicker'
import InlineValidationError from '../Form/InlineValidationError'
import { SearchProps } from './SearchForm'

export default function FirstRegistrationsSearch({
  control,
  errors,
  register,
}: SearchProps<FirstRegistrationsFormValues>) {
  const { t } = useTranslation('common')
  const MAX_DATE = new Date()

  return (
    <>
      <Typography variant="body1" data-qa="possessor-change-search-description">
        {t('monitoring.puppyMilling.firstRegistrations.searchDescription')}
      </Typography>
      <FormGroup sx={{ gap: 2, gridColumn: '1 / span 1' }}>
        <Typography variant="h2">{t('monitoring.professionalActivity.monitoringArea')}</Typography>
        <FormControl>
          <Controller
            name="municipalityCode"
            control={control}
            render={({ field }) => (
              <MunicipalitySelect
                field={field}
                error={!!errors.municipalityCode}
                errorContent={errors.municipalityCode?.message || ''}
              />
            )}
          />
        </FormControl>
      </FormGroup>

      <Typography variant="h2" sx={{ gridColumn: { xs: '100%', sm: '1 / span 2' } }}>
        {t('monitoring.puppyMilling.firstRegistrations.timeSpan')}
      </Typography>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '100%', sm: 'repeat(2, 1fr)' },
          gap: 2,
        }}
      >
        <FormControl>
          <DatePicker
            control={control}
            name={'startDate'}
            label={t('monitoring.puppyMilling.firstRegistrations.startDateLabel')}
            error={!!errors.startDate}
            locale={getCurrentLocale()}
            format={'dd.MM.yyyy'}
            ariaLabelledby={errors.startDate && 'inlineError-startDate'}
            maxDate={MAX_DATE}
            placeholder={t('registrationForm.field.date.placeHolder') as string}
            required={true}
          />
          {!!errors.startDate && (
            <InlineValidationError content={errors.startDate?.message} id="inlineError-startDate" />
          )}
        </FormControl>

        <FormControl>
          <DatePicker
            control={control}
            name={'endDate'}
            label={t('monitoring.puppyMilling.firstRegistrations.endDateLabel')}
            error={!!errors.endDate}
            locale={getCurrentLocale()}
            format={'dd.MM.yyyy'}
            ariaLabelledby={errors.endDate && 'inlineError-endDate'}
            maxDate={MAX_DATE}
            placeholder={t('registrationForm.field.date.placeHolder') as string}
            required={true}
          />
          {!!errors.endDate && (
            <InlineValidationError content={errors.endDate?.message} id="inlineError-startDate" />
          )}
        </FormControl>
      </FormGroup>

      <FormGroup sx={{ gap: 1, gridColumn: { xs: '100%', sm: '1 / span 1' } }}>
        <Typography variant="h2">{t('monitoring.professionalActivity.dogAmountTitle')}</Typography>
        <Typography variant="body1" mb={2}>
          {t('monitoring.puppyMilling.firstRegistrations.dogAmountBody')}
        </Typography>
        <FormControl>
          <InputLabel htmlFor={'minimumRegisteredAnimals'}>
            {t('monitoring.professionalActivity.dogAmountLabel')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('registrationForm.field.required')}</i>
          </InputLabel>
          <TextField
            {...register('firstRegistrationCount')}
            id="minimumRegisteredAnimals"
            error={!!errors.firstRegistrationCount}
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: 3,
            }}
            autoComplete={'off'}
            data-qa="minimumRegisteredAnimals"
            disabled={false}
          />
          {errors.firstRegistrationCount && (
            <InlineValidationError
              content={errors.firstRegistrationCount.message}
              data-qa="form-minimumRegisteredAnimals"
            />
          )}
        </FormControl>
      </FormGroup>
    </>
  )
}
