import { Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { AnimalHealthType, FullRegistrationDto } from '../generated/animare-management-api/models'
import BreedSize from './BreedSize'
import Breed from './Codes/Breed'
import Country from './Codes/Country'
import Color from './Color'
import DateFormatter from './DateFormatter'
import Field from './Field'
import Sex from './Sex'

export interface Props {
  registration?: FullRegistrationDto
  showStartDate: boolean
}

export default function Dog({ registration, showStartDate }: Props) {
  const { t } = useTranslation('common')

  const name = registration?.animal?.name
  const kennelName = registration?.animal?.kennelName
  const colors = registration?.animal?.colours
  const breedCode = registration?.animal?.breedCode
  const isUnknownBreed = !breedCode || breedCode === '0'
  const breedDescription = registration?.animal?.breedDescription
  const isDead = registration?.animal?.status?.health === AnimalHealthType.Dead

  return (
    <>
      <Grid container data-qa="dog-container" component="dl" spacing={4} sx={{ mb: 4 }}>
        {showStartDate && registration?.startDate && (
          <Grid item xs={12} md={12}>
            <Typography variant="body2" component="dt" data-qa="registration-start-date-title">
              {t('common.animal.startDate')}
            </Typography>
            <Typography variant="body1" component="dd" data-qa="registration-start-date-text">
              {<DateFormatter date={registration.startDate} />}
            </Typography>
          </Grid>
        )}
        <Field
          title={t('common.animal.microchipCode')}
          text={registration?.microchip?.microchipNumber}
          dataQa="microchip-field"
        />
        {name ? (
          <Field title={t('common.animal.name')} text={name} dataQa="animal-name-field" />
        ) : (
          <></>
        )}
        <Field
          title={t('common.animal.kennelName')}
          text={kennelName ? kennelName : t('common.not-known')}
          dataQa="kennel-name-field"
        />

        <Field
          title={t('common.animal.birthDate')}
          text={<DateFormatter date={registration?.animal?.birthDate} />}
          dataQa="birth-day-field"
        />
        <Field
          title={t('common.animal.birthCountry')}
          text={<Country code={registration?.animal?.birthCountryCode} />}
          dataQa="country-field"
        />
        <Field
          title={t('common.animal.breed')}
          text={<Breed code={breedCode} />}
          dataQa="breed-field"
        />
        {breedDescription ? (
          <Field
            title={t('common.animal.description')}
            text={breedDescription}
            dataQa="breed-description-field"
          />
        ) : (
          <></>
        )}
        {isUnknownBreed ? (
          <Field
            title={t('common.animal.size')}
            text={<BreedSize code={registration?.animal?.breedSize} />}
            dataQa="animal-size-field"
          />
        ) : (
          <></>
        )}
        <Field
          title={t('common.animal.sex')}
          text={<Sex code={registration?.animal?.sexCode} />}
          dataQa="sex-field"
        />
        <Field
          title={t('common.animal.color')}
          body={
            <>
              {colors?.map((c, key) => (
                <Typography
                  variant="body1"
                  component="dd"
                  key={key}
                  data-qa={`animal-color-${key}`}
                >
                  <Color code={c} />
                </Typography>
              ))}
            </>
          }
          dataQa="animal-color-field"
        />
        {isDead && registration?.animal?.deathDate && (
          <Field
            title={t('common.animal.deathDate')}
            text={<DateFormatter date={registration?.animal?.deathDate} />}
            dataQa="death-date-field"
          />
        )}
      </Grid>
    </>
  )
}
