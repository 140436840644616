/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalLocationType } from './AnimalLocationType'
import {
  AnimalLocationTypeFromJSON,
  AnimalLocationTypeFromJSONTyped,
  AnimalLocationTypeToJSON,
} from './AnimalLocationType'

/**
 * Provides information about animal location and date when information was last updated
 * @export
 * @interface AnimalLocationDto
 */
export interface AnimalLocationDto {
  /**
   *
   * @type {AnimalLocationType}
   * @memberof AnimalLocationDto
   */
  type: AnimalLocationType
  /**
   * Date when information was last changed.
   * @type {Date}
   * @memberof AnimalLocationDto
   */
  date?: Date
}

/**
 * Check if a given object implements the AnimalLocationDto interface.
 */
export function instanceOfAnimalLocationDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function AnimalLocationDtoFromJSON(json: any): AnimalLocationDto {
  return AnimalLocationDtoFromJSONTyped(json, false)
}

export function AnimalLocationDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalLocationDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: AnimalLocationTypeFromJSON(json['type']),
    date: !exists(json, 'date') ? undefined : new Date(json['date']),
  }
}

export function AnimalLocationDtoToJSON(value?: AnimalLocationDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: AnimalLocationTypeToJSON(value.type),
    date: value.date === undefined ? undefined : value.date.toISOString().substr(0, 10),
  }
}
