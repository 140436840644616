import { ErrorDto, OpenGateResponseDto } from '../../generated/openapi'
import { AppRoutes } from '../../routes'

export const initResponse = {
  ready: false,
  data: [
    {
      key: 'VETUMAISTUNTOID',
      value: 'lUykIRtXGHbj6yVlOJ',
    },
    {
      key: 'KIELI',
      value: 'fi',
    },
    {
      key: 'PALVELUID',
      value: 'LEMMIKKIREK',
    },
  ],
  questions: [
    {
      name: 'KAYTTAJA',
      label: 'Käyttäjätunnus/Användarnamn',
      hideAnswer: false,
      choose: [],
    },
    {
      name: 'SALASANA',
      label: 'Salasana/Lösenord',
      hideAnswer: true,
      choose: [],
    },
  ],
  externalLogin: [
    {
      url: `${AppRoutes.Virtu}?A_KAYTTAJA=mockuser%40ruokavirasto&A_ISTUNTOID=VQ3i2GP5t7itY4WKqD&A_PALVELUID=LEMMIKKIREK&A_KIELI=fi.FI&A_VIRTU=true`,
      language: 'fi',
      text: 'Virtu - kertakirjautumisratkaisu',
    },
    {
      url: `${AppRoutes.Virtu}?A_KAYTTAJA=mockuser%40ruokavirasto&A_ISTUNTOID=VQ3i2GP5t7itY4WKqD&A_PALVELUID=LEMMIKKIREK&A_KIELI=sv.FI&A_VIRTU=true`,
      language: 'sv',
      text: 'Virtu - engångsinloggning',
    },
    {
      url: `${AppRoutes.Virtu}?A_KAYTTAJA=mockuser%40ruokavirasto&A_ISTUNTOID=VQ3i2GP5t7itY4WKqD&A_PALVELUID=LEMMIKKIREK&A_KIELI=en.GB&A_VIRTU=true`,
      language: 'en',
      text: 'Virtu - Single Sign-On',
    },
  ],
} as OpenGateResponseDto

export const loginSuccessful = {
  ready: true,
} as OpenGateResponseDto

export const loginResponse = {
  ready: false,
  data: [
    {
      key: 'KAYTTAJA',
      value: 'mockuser@ruokavirasto',
    },
    {
      key: 'ISTUNTOID',
      value: 'T5MB9bvsykeUDadl6e',
    },
    {
      key: 'KIELI',
      value: 'fi.FI',
    },
    {
      key: 'PALVELUID',
      value: 'LEMMIKKIREK',
    },
  ],
  questions: [
    {
      name: 'AVAINLUKU',
      label: 'Anna avainluku 0022/Nyckeltal 0022',
      hideAnswer: true,
    },
  ],
} as OpenGateResponseDto

export const multipleChoice = {
  ready: false,
  data: loginResponse.data,
  questions: [
    {
      name: 'MONIVALINTA',
      label: 'Mitä näistä rakastat eniten?',
      choose: ['Koiria', 'Kissoja', 'Paviaaneja'],
    },
  ],
  externalLogin: [],
} as OpenGateResponseDto

export const loginWarning = {
  ready: false,
  data: [
    {
      key: 'VIRHESELITYS',
      value:
        'Avainlukulistasi luvut ovat loppumassa (5 jäljellä). Voit tilata uuden avainlukulistan Pääsynhallinnasta./Nyckeltalslistans tal tar snart slut (5 kvar). Beställ en ny lista från Åtkomstkontrollen.',
    },
    {
      key: 'KAYTTAJA',
      value: 'lemmikas@ruokavirasto.fi',
    },
    {
      key: 'ISTUNTOID',
      value: 'eBYR3ub2K6siRIUfjP',
    },
    {
      key: 'KIELI',
      value: 'fi.FI',
    },
    {
      key: 'PALVELUID',
      value: 'LEMMIKKIREK',
    },
  ],
  questions: [
    {
      name: 'AVAINLUKU',
      label: 'Anna avainluku 0246/Nyckeltal 0246',
      hideAnswer: true,
    },
  ],
} as OpenGateResponseDto

export const loginErrorResponse = {
  code: 'E0010',
  description: 'Väärä käyttäjätunnus tai salasana/Fel användarnamn eller lösenord',
} as ErrorDto
