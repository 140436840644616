/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Is the animal missing or does the possessor know its location?
 * @export
 */
export const AnimalLocationType = {
  Found: 'found',
  Missing: 'missing',
} as const
export type AnimalLocationType = (typeof AnimalLocationType)[keyof typeof AnimalLocationType]

export function AnimalLocationTypeFromJSON(json: any): AnimalLocationType {
  return AnimalLocationTypeFromJSONTyped(json, false)
}

export function AnimalLocationTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalLocationType {
  return json as AnimalLocationType
}

export function AnimalLocationTypeToJSON(value?: AnimalLocationType | null): any {
  return value as any
}
