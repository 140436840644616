import * as yup from 'yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAnimalStatus } from '../hooks/useAnimalStatus'
import { AnimalStatusDto } from '../generated/animare-management-api'
import { Controller, Message, useForm } from 'react-hook-form'
import DatePicker from './DatePicker'
import { getCurrentLocale, useDate } from '../hooks/useDate'
import { yupResolver } from '@hookform/resolvers/yup'
import InlineValidationError from './Form/InlineValidationError'
import InputArrivalDate, { arrivalDateSchema } from './Form/InputArrivalDate'

export interface dogCountryStatusFormValues {
  arrivalDate: Date
  countryStatusDate: Date
}

interface Props {
  status: AnimalStatusDto
  open: boolean
  handleAction: (value: dogCountryStatusFormValues) => void
  handleClose: () => void
  minDate: Date
}

export default function DogStatusCountryDialog({
  status,
  open,
  handleClose,
  handleAction,
  minDate,
}: Props) {
  const { t } = useTranslation('common')
  const { isDogAbroad } = useAnimalStatus()
  const MAX_DATE = new Date()
  const { toUTCDate } = useDate()

  // Currently same translations as registration at form
  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    date: {
      wrongFormat: t('register.inlineError.date.wrongFormat') as Message,
      max: t('register.inlineError.date.max') as Message,
      min: t('register.inlineError.date.minByStatusCountry') as Message,
    },
  }

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    countryStatusDate: yup
      .date()
      .typeError(errorMessages.date.wrongFormat)
      .required(errorMessages.date.wrongFormat)
      .nullable()
      .transform((value) => !!value && toUTCDate(value))
      .min(minDate, errorMessages.date.min)
      .max(MAX_DATE, errorMessages.date.max),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<dogCountryStatusFormValues>({ resolver: yupResolver(schema) })

  const onSubmit = (values: dogCountryStatusFormValues) => {
    handleAction(values)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle data-qa="dialog-title">
          {isDogAbroad(status)
            ? t('reportChange.dogReturned.confirm.titleH5')
            : t('reportChange.dogAbroad.confirmTemporary.titleH5')}
        </DialogTitle>
        <DialogContent>
          <FormGroup
            sx={{
              display: 'grid',
              gap: 4,
              mt: 2,
              mb: 4,
              width: '100%',
            }}
          >
            <FormControl>
              <InputArrivalDate control={control} errors={errors} />
            </FormControl>
            <FormControl>
              <Controller
                name="countryStatusDate"
                control={control}
                render={() => (
                  <DatePicker
                    control={control}
                    name="countryStatusDate"
                    label={
                      isDogAbroad(status)
                        ? t('reportChange.dogAbroad.dateToFinland.titleH5')
                        : t('reportChange.dogAbroad.dateToAbroad.titleH5')
                    }
                    error={!!errors.countryStatusDate}
                    locale={getCurrentLocale()}
                    format={'dd.MM.yyyy'}
                    placeholder={t('registrationForm.field.date.placeHolder') as string}
                    minDate={minDate}
                    maxDate={MAX_DATE}
                    required
                  />
                )}
              />
              {!!errors.countryStatusDate ? (
                <InlineValidationError
                  content={errors.countryStatusDate?.message}
                  id="countryStatusDate-error"
                />
              ) : (
                <FormHelperText>
                  {isDogAbroad(status)
                    ? t('reportChange.dogAbroad.dateToFinland.helperText')
                    : t('reportChange.dogAbroad.dateToAbroad.helperText')}
                </FormHelperText>
              )}
            </FormControl>
          </FormGroup>

          <DialogContentText data-qa="dialog-body">
            {isDogAbroad(status)
              ? t('reportChange.dogReturned.confirm.body')
              : t('reportChange.dogAbroad.confirmTemporary.body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 0 }}>
          <Button onClick={handleClose} data-qa="close">
            {t('common.cancel')}
          </Button>
          <Button type="submit" data-qa="action" disabled={isSubmitting}>
            {!isDogAbroad(status)
              ? t('reportChange.dogAbroadBtn')
              : t('reportChange.dogNotAbroadBtn')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
