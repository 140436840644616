/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalDto } from './AnimalDto'
import { AnimalDtoFromJSON, AnimalDtoFromJSONTyped, AnimalDtoToJSON } from './AnimalDto'
import type { MicrochipDto } from './MicrochipDto'
import { MicrochipDtoFromJSON, MicrochipDtoFromJSONTyped, MicrochipDtoToJSON } from './MicrochipDto'

/**
 * Information about the registration
 * @export
 * @interface RegistrationDto
 */
export interface RegistrationDto {
  /**
   *
   * @type {string}
   * @memberof RegistrationDto
   */
  uid?: string
  /**
   * When the registration form has arrived (only for paper registration).
   * @type {Date}
   * @memberof RegistrationDto
   */
  arrivalDate?: Date
  /**
   * Registration start date (when the registration has been saved in the system).
   * @type {Date}
   * @memberof RegistrationDto
   */
  startDate?: Date
  /**
   * End date of the registration, if the registration is no longer active (when the animal has been transferred).
   * @type {Date}
   * @memberof RegistrationDto
   */
  endDate?: Date
  /**
   * When the animal has physically changed possessors (if it is a transferred registration).
   * @type {Date}
   * @memberof RegistrationDto
   */
  transferDate?: Date
  /**
   *
   * @type {AnimalDto}
   * @memberof RegistrationDto
   */
  animal?: AnimalDto
  /**
   *
   * @type {MicrochipDto}
   * @memberof RegistrationDto
   */
  microchip?: MicrochipDto
}

/**
 * Check if a given object implements the RegistrationDto interface.
 */
export function instanceOfRegistrationDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function RegistrationDtoFromJSON(json: any): RegistrationDto {
  return RegistrationDtoFromJSONTyped(json, false)
}

export function RegistrationDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RegistrationDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    arrivalDate: !exists(json, 'arrivalDate') ? undefined : new Date(json['arrivalDate']),
    startDate: !exists(json, 'startDate') ? undefined : new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    transferDate: !exists(json, 'transferDate') ? undefined : new Date(json['transferDate']),
    animal: !exists(json, 'animal') ? undefined : AnimalDtoFromJSON(json['animal']),
    microchip: !exists(json, 'microchip') ? undefined : MicrochipDtoFromJSON(json['microchip']),
  }
}

export function RegistrationDtoToJSON(value?: RegistrationDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    arrivalDate:
      value.arrivalDate === undefined ? undefined : value.arrivalDate.toISOString().substr(0, 10),
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString().substr(0, 10),
    endDate: value.endDate === undefined ? undefined : value.endDate.toISOString().substr(0, 10),
    transferDate:
      value.transferDate === undefined ? undefined : value.transferDate.toISOString().substr(0, 10),
    animal: AnimalDtoToJSON(value.animal),
    microchip: MicrochipDtoToJSON(value.microchip),
  }
}
