/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalCountryType } from './AnimalCountryType'
import {
  AnimalCountryTypeFromJSON,
  AnimalCountryTypeFromJSONTyped,
  AnimalCountryTypeToJSON,
} from './AnimalCountryType'
import type { AnimalHealthType } from './AnimalHealthType'
import {
  AnimalHealthTypeFromJSON,
  AnimalHealthTypeFromJSONTyped,
  AnimalHealthTypeToJSON,
} from './AnimalHealthType'
import type { AnimalLocationType } from './AnimalLocationType'
import {
  AnimalLocationTypeFromJSON,
  AnimalLocationTypeFromJSONTyped,
  AnimalLocationTypeToJSON,
} from './AnimalLocationType'

/**
 * Includes new status of an animal to update, health, country or location
 * @export
 * @interface NewAnimalStatusDto
 */
export interface NewAnimalStatusDto {
  /**
   *
   * @type {AnimalHealthType}
   * @memberof NewAnimalStatusDto
   */
  health: AnimalHealthType
  /**
   *
   * @type {AnimalCountryType}
   * @memberof NewAnimalStatusDto
   */
  country: AnimalCountryType
  /**
   *
   * @type {AnimalLocationType}
   * @memberof NewAnimalStatusDto
   */
  location: AnimalLocationType
}

/**
 * Check if a given object implements the NewAnimalStatusDto interface.
 */
export function instanceOfNewAnimalStatusDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'health' in value
  isInstance = isInstance && 'country' in value
  isInstance = isInstance && 'location' in value

  return isInstance
}

export function NewAnimalStatusDtoFromJSON(json: any): NewAnimalStatusDto {
  return NewAnimalStatusDtoFromJSONTyped(json, false)
}

export function NewAnimalStatusDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NewAnimalStatusDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    health: AnimalHealthTypeFromJSON(json['health']),
    country: AnimalCountryTypeFromJSON(json['country']),
    location: AnimalLocationTypeFromJSON(json['location']),
  }
}

export function NewAnimalStatusDtoToJSON(value?: NewAnimalStatusDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    health: AnimalHealthTypeToJSON(value.health),
    country: AnimalCountryTypeToJSON(value.country),
    location: AnimalLocationTypeToJSON(value.location),
  }
}
