import {
  AnimalImportingSearchDto,
  AnimalProtectionSearchDto,
  Configuration,
  ErrorDto,
  Middleware,
  MonitoringRestControllerApi,
  MonitoringSearchResponseDto,
  ProfessionalActivitySearchDto,
  PuppyMillingPossessorChangeSearchDto,
  PuppyMillingRegistrationsSearchDto,
  querystring,
} from '../generated/animare-management-api'

import { QueryClient, useMutation, UseMutationResult } from '@tanstack/react-query'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'

export interface MutationProps {
  onSuccess: (response: MonitoringSearchResponseDto) => void
  onError: (error: ErrorDto) => void
  onSettled: () => void
  queryClient: QueryClient
}

export function useMonitoring() {
  const api = new MonitoringRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   * Fetches search results.
   * @param professionalActivitySearchDto
   * @returns
   */
  const searchForProfessionalActivity = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: MutationProps): UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    ProfessionalActivitySearchDto,
    unknown
  > => {
    const getProfessionalActivitySearchMutation = useMutation({
      mutationFn: ({ municipalityCode, minimumRegisteredAnimals }: ProfessionalActivitySearchDto) =>
        api.searchForProfessionalActivity({
          professionalActivitySearchDto: {
            municipalityCode,
            minimumRegisteredAnimals,
          },
        }),
      onSuccess: (response: MonitoringSearchResponseDto) => {
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
      },
      onSettled: onSettled,
    })

    return getProfessionalActivitySearchMutation
  }

  /**
   * Fetches search results for Possessor Changes.
   * @param professionalActivitySearchDto
   * @returns
   */
  const searchForPossessorChange = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: MutationProps): UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    PuppyMillingPossessorChangeSearchDto,
    unknown
  > => {
    const getPossessorChangeSearchMutation = useMutation({
      mutationFn: ({
        municipalityCode,
        minimumPossessorChangeCount,
      }: PuppyMillingPossessorChangeSearchDto) =>
        api.searchForPuppyMillingPossessorChanges({
          puppyMillingPossessorChangeSearchDto: {
            municipalityCode,
            minimumPossessorChangeCount,
          },
        }),
      onSuccess: (response: MonitoringSearchResponseDto) => {
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
      },
      onSettled: onSettled,
    })

    return getPossessorChangeSearchMutation
  }

  /**
   * Fetches search results for First Registrations.
   * @param puppyMillingRegistrationsSearchDto
   * @returns
   */
  const searchForFirstRegistrations = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: MutationProps): UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    PuppyMillingRegistrationsSearchDto,
    unknown
  > => {
    const getFirstRegistrationsSearchMutation = useMutation({
      mutationFn: ({
        municipalityCode,
        endDate,
        firstRegistrationCount,
        startDate,
      }: PuppyMillingRegistrationsSearchDto) =>
        api.searchForPuppyMillingRegistrations({
          puppyMillingRegistrationsSearchDto: {
            municipalityCode,
            endDate,
            firstRegistrationCount,
            startDate,
          },
        }),
      onSuccess: (response: MonitoringSearchResponseDto) => {
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
      },
      onSettled: onSettled,
    })

    return getFirstRegistrationsSearchMutation
  }

  /**
   * Fetches search results for Animal Importing.
   * @param animalImportingSearchDto
   * @returns
   */
  const searchForAnimalImporting = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: MutationProps): UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    AnimalImportingSearchDto,
    unknown
  > => {
    const getAnimalImportingSearchMutation = useMutation({
      mutationFn: ({
        birthCountryCode,
        allCountries,
        municipalityCode,
        registrationStartDate,
        registrationEndDate,
        birthStartDate,
        birthEndDate,
        possessorChangeStartDate,
        possessorChangeEndDate,
      }: AnimalImportingSearchDto) =>
        api.searchForAnimalImporting({
          animalImportingSearchDto: {
            birthCountryCode,
            allCountries,
            municipalityCode,
            registrationStartDate,
            registrationEndDate,
            birthStartDate,
            birthEndDate,
            possessorChangeStartDate,
            possessorChangeEndDate,
          },
        }),
      onSuccess: (response: MonitoringSearchResponseDto) => {
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
      },
      onSettled: onSettled,
    })

    return getAnimalImportingSearchMutation
  }

  /**
   * Fetches search results.
   * @param animalProtectionSearchDto
   * @returns
   */
  const searchForAnimalProtection = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: MutationProps): UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    AnimalProtectionSearchDto,
    unknown
  > => {
    const getAnimalProtectionSearchMutation = useMutation({
      mutationFn: ({
        municipalityCode,
        postalCode,
        address,
        firstName,
        lastName,
        organizationName,
        breedCode,
      }: AnimalProtectionSearchDto) =>
        api.searchForAnimalProtection({
          animalProtectionSearchDto: {
            municipalityCode,
            postalCode,
            address,
            firstName,
            lastName,
            organizationName,
            breedCode,
          },
        }),
      onSuccess: (response: MonitoringSearchResponseDto) => {
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
      },
      onSettled: onSettled,
    })

    return getAnimalProtectionSearchMutation
  }

  return {
    searchForProfessionalActivity,
    searchForAnimalProtection,
    searchForPossessorChange,
    searchForFirstRegistrations,
    searchForAnimalImporting,
  }
}
