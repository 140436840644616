import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { Controller } from 'react-hook-form'

import { Trans, useTranslation } from 'react-i18next'
import BreedSelect from '../Form/BreedSelect'
import InlineValidationError from '../Form/InlineValidationError'
import MunicipalitySelect from '../Form/MunicipalitySelect'
import {
  AnimalProtectionSearchFormValues,
  MAX_ADDRESS_LENGTH,
  MAX_NAME_LENGTH,
  MAX_ORG_NAME_LENGTH,
  MAX_POSTALCODE_LENGTH,
} from './MonitoringSearchSchema'
import { SearchProps } from './SearchForm'

export enum AnimalProtectionSearchValues {
  municipalityCode = 'municipalityCode',
  postalCode = 'postalCode',
  address = 'address',
  firstName = 'firstName',
  lastName = 'lastName',
  organizationName = 'organizationName',
  breedCode = 'breedCode',
}

export default function AnimalProtectionSearch({
  control,
  errors,
  register,
  watch,
}: SearchProps<AnimalProtectionSearchFormValues>) {
  const { t } = useTranslation('common')

  const isOrganizationNameDisabled = (): boolean => {
    const firstName = watch('firstName')
    const lastName = watch('lastName')

    return !!((firstName && firstName.length > 0) || (lastName && lastName.length > 0))
  }

  const isPersonPossessorNamesDisabled = (): boolean => {
    const organizationName = watch('organizationName')

    return !!(organizationName && organizationName.length > 0)
  }

  return (
    <>
      <Box sx={{ width: { sm: '100%', md: '60%' } }}>
        <Typography variant="h2">{t('monitoring.animalProtectionSearch.title')}</Typography>
        <Typography variant="body1">
          <Trans t={t} i18nKey="monitoring.animalProtectionSearch.requiredInformation">
            {t('monitoring.animalProtectionSearch.requiredInformation')}
          </Trans>
        </Typography>
      </Box>

      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
          },
          gap: '30px 10px',
        }}
      >
        <FormControl>
          <Controller
            name={AnimalProtectionSearchValues.municipalityCode}
            control={control}
            render={({ field }) => (
              <MunicipalitySelect
                field={field}
                error={!!errors[AnimalProtectionSearchValues.municipalityCode]}
                errorContent={(errors.municipalityCode?.message as string) || ''}
              />
            )}
          />
        </FormControl>

        <FormControl>
          <InputLabel htmlFor={'postalCode'}>
            {t('organizationPossessorForm.form.postalCode')}
          </InputLabel>
          <TextField
            {...register(AnimalProtectionSearchValues.postalCode)}
            id={AnimalProtectionSearchValues.postalCode}
            data-qa={AnimalProtectionSearchValues.postalCode}
            error={!!errors[AnimalProtectionSearchValues.postalCode]}
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: MAX_POSTALCODE_LENGTH,
            }}
          />

          {!!errors[AnimalProtectionSearchValues.postalCode] && (
            <InlineValidationError
              content={errors[AnimalProtectionSearchValues.postalCode].message}
              data-qa="form-postalCode"
            />
          )}
        </FormControl>

        <FormControl>
          <InputLabel htmlFor={AnimalProtectionSearchValues.address}>
            {t('monitoring.animalProtectionSearch.form.address')}
          </InputLabel>
          <TextField
            {...register(AnimalProtectionSearchValues.address)}
            id={AnimalProtectionSearchValues.address}
            data-qa={AnimalProtectionSearchValues.address}
            error={!!errors[AnimalProtectionSearchValues.address]}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_ADDRESS_LENGTH,
            }}
          />
          {!!errors[AnimalProtectionSearchValues.address] && (
            <InlineValidationError content={errors[AnimalProtectionSearchValues.address].message} />
          )}
        </FormControl>

        <FormControl>
          <InputLabel htmlFor={AnimalProtectionSearchValues.firstName}>
            {t('monitoring.animalProtectionSearch.form.firstName')}
          </InputLabel>
          <TextField
            {...register(AnimalProtectionSearchValues.firstName)}
            id={AnimalProtectionSearchValues.firstName}
            data-qa={AnimalProtectionSearchValues.firstName}
            error={!!errors[AnimalProtectionSearchValues.firstName]}
            disabled={isPersonPossessorNamesDisabled()}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_NAME_LENGTH,
            }}
          />
          {!!errors[AnimalProtectionSearchValues.firstName] && (
            <InlineValidationError
              content={errors[AnimalProtectionSearchValues.firstName].message}
            />
          )}
          {isPersonPossessorNamesDisabled() && (
            <FormHelperText sx={{ color: 'text.primary' }}>
              {t('monitoring.animalProtectionSearch.form.firstNameHelperText')}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl>
          <InputLabel htmlFor={AnimalProtectionSearchValues.lastName}>
            {t('monitoring.animalProtectionSearch.form.lastName')}
          </InputLabel>
          <TextField
            {...register(AnimalProtectionSearchValues.lastName)}
            id={AnimalProtectionSearchValues.lastName}
            data-qa={AnimalProtectionSearchValues.lastName}
            error={!!errors[AnimalProtectionSearchValues.lastName]}
            disabled={isPersonPossessorNamesDisabled()}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_NAME_LENGTH,
            }}
          />
          {!!errors[AnimalProtectionSearchValues.lastName] && (
            <InlineValidationError
              content={errors[AnimalProtectionSearchValues.lastName].message}
            />
          )}
        </FormControl>

        <FormControl>
          <InputLabel htmlFor={AnimalProtectionSearchValues.organizationName}>
            {t('monitoring.animalProtectionSearch.form.organizationName')}
          </InputLabel>
          <TextField
            {...register(AnimalProtectionSearchValues.organizationName)}
            id={AnimalProtectionSearchValues.organizationName}
            data-qa={AnimalProtectionSearchValues.organizationName}
            error={!!errors[AnimalProtectionSearchValues.organizationName]}
            disabled={isOrganizationNameDisabled()}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_ORG_NAME_LENGTH,
            }}
          />
          {isOrganizationNameDisabled() && (
            <FormHelperText sx={{ color: 'text.primary' }}>
              {t('monitoring.animalProtectionSearch.form.organizationNameHelperText')}
            </FormHelperText>
          )}
          {!!errors[AnimalProtectionSearchValues.organizationName] && (
            <InlineValidationError
              content={errors[AnimalProtectionSearchValues.organizationName].message}
            />
          )}
        </FormControl>

        <FormControl>
          <Controller
            name={AnimalProtectionSearchValues.breedCode}
            control={control}
            render={({ field }) => (
              <BreedSelect
                error={!!errors[field.name]}
                isRequired={false}
                ariaLabelledBy={
                  errors[AnimalProtectionSearchValues.breedCode]
                    ? 'inlineError-breedCode'
                    : field.name
                }
                field={field as any}
                errorContent={
                  (errors[AnimalProtectionSearchValues.breedCode]?.message as string) || ''
                }
                errorId="inlineError-breedCode"
              />
            )}
          />
        </FormControl>
      </FormGroup>
    </>
  )
}
