import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import { json2csv } from 'json-2-csv'
import { KeysList } from 'json-2-csv/lib/types'
import { useTranslation } from 'react-i18next'
import { MonitoringSearchResult } from '../../generated/animare-management-api'
import { Code } from '../../generated/codes-app-api'
import useMunicipalities from '../../hooks/useMunicipality'

export type Props = {
  searchResults: MonitoringSearchResult[]
}

export default function MonitoringSearchDownload({ searchResults }: Props) {
  const { t, i18n } = useTranslation('common')

  const { fetchMunicipalities } = useMunicipalities()

  const { data: municipalities, error, isPending } = fetchMunicipalities()

  if (isPending) {
    return null
  }

  // NOTE: see useMunicipality
  const municipalityName = (code: string | undefined) => {
    // if we fail to fetch municipalities, return the code by default
    if (error) {
      return code
    }
    if (!code) {
      return ''
    }
    const paddedMunicipality = `${code}`.padStart(3, '0')
    const result = (municipalities ?? []).find((m: Code) => `${m.code}` === paddedMunicipality)

    if (!result?.labels) {
      return code // return the code by default
    }
    return result.labels.find((label) => label.lang === i18n.language)?.label
  }

  const downloadCsv = () => {
    const data = searchResults.map((r) => {
      return {
        ...r,
        municipalityCode: municipalityName(r.municipalityCode),
      } as MonitoringSearchResult
    })

    const keys = [
      {
        field: 'firstName',
        title: t('monitoring.animalProtectionSearch.form.firstName'),
      },
      {
        field: 'lastName',
        title: t('monitoring.animalProtectionSearch.form.lastName'),
      },
      {
        field: 'organizationName',
        title: t('monitoring.animalProtectionSearch.form.organizationName'),
      },
      {
        field: 'address',
        title: t('monitoring.animalProtectionSearch.results.table.address'),
      },
      {
        field: 'postalCode',
        title: t('monitoring.animalProtectionSearch.results.table.postalCode'),
      },
      {
        field: 'municipalityCode',
        title: t('monitoring.animalProtectionSearch.results.table.municipality'),
      },
      {
        field: 'animalCount',
        title: t('monitoring.animalProtectionSearch.results.table.animalCount'),
      },
    ] as KeysList
    const resultsAsCsv = json2csv(data, {
      emptyFieldValue: '',
      keys,
      preventCsvInjection: true,
    })
    const url = URL.createObjectURL(new Blob([resultsAsCsv]))
    const timestamp = new Date().toISOString().substring(0, 19).replaceAll(':', '')
    const filename = `koirarekisteri-export-${timestamp}.csv`

    // We need to create a virtual link element and click it to download the blob
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link)

    // download proper
    link.click()

    // cleanup
    if (!!link.parentNode) {
      link.parentNode.removeChild(link)
    }

    URL.revokeObjectURL(url)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => downloadCsv()}
        startIcon={<Download sx={{ fill: 'primary.main' }} />}
        data-qa="monitoring-download"
      >
        {t('monitoring.download')}
      </Button>
    </>
  )
}
