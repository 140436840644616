import { useTranslation } from 'react-i18next'
import { FieldValues, FormProvider, Message, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { RegistrationFieldKeysEnum } from './Form/RegistrationForm'
import { ControlledTextField } from './Form/ControlledTextField'
import InputArrivalDate, { arrivalDateSchema } from './Form/InputArrivalDate'
import { DeleteMicrochipRequestDto } from '../generated/animare-management-api'

export type MicrochipDeleteFormValues = {
  arrivalDate: Date
  microchipNumber: string | null
}

interface Props {
  open: boolean
  handleAction: (arrivalDate: Date) => void
  handleClose: () => void
  microchipNumber: string
}

export default function MicrochipDeleteConfirmDialog({
  open,
  handleAction,
  handleClose,
  microchipNumber,
}: Props) {
  const { t } = useTranslation('common')

  const MAX_MICROCHIP_NUMBER_LENGTH = 15

  const errorMessages = {
    generic: t('registrationForm.error.ifEmpty.generic') as Message,
    microchip: {
      notEnough: t('registrationForm.error.microchipNumber.notEnough') as Message,
      wrongFormat: t('registrationForm.error.microchipNumber.wrongFormat') as Message,
      matchError: t('dogView.dialogDeleteMicrochipCode.matchError', { microchipNumber }) as Message,
    },
  }

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    microchipNumber: yup
      .string()
      .min(MAX_MICROCHIP_NUMBER_LENGTH, errorMessages.microchip.notEnough)
      .max(MAX_MICROCHIP_NUMBER_LENGTH)
      .oneOf([microchipNumber], errorMessages.microchip.matchError)
      .required(errorMessages.generic),
  })

  const methods = useForm<MicrochipDeleteFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      microchipNumber: '',
    },
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods

  return (
    <Dialog open={open} onClose={handleClose} data-qa="dialog-delete-microchip">
      <DialogTitle>{t('reportChange.dogDead.confirm.titleH5')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('dogView.dialogDeleteMicrochipCode.content', { microchipNumber })}
        </DialogContentText>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit((form: MicrochipDeleteFormValues) =>
              handleAction(form.arrivalDate),
            )}
          >
            <FormGroup
              sx={{
                display: 'grid',
                gap: 4,
                mt: 4,
                mb: 2,
                width: '100%',
              }}
            >
              <FormControl>
                <InputArrivalDate control={control} errors={errors} />
              </FormControl>
              <FormControl>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.MicrochipNumber}
                  error={errors?.[RegistrationFieldKeysEnum.MicrochipNumber]}
                  inputProps={{
                    type: 'text',
                    inputMode: 'numeric',
                    maxLength: MAX_MICROCHIP_NUMBER_LENGTH,
                  }}
                  title={'common.animal.microchipCode'}
                  required
                />
                {!errors[RegistrationFieldKeysEnum.MicrochipNumber] && (
                  <FormHelperText>{t('registrationForm.help.microchipCode')}</FormHelperText>
                )}
              </FormControl>
            </FormGroup>
            <DialogActions sx={{ pb: 0 }}>
              <Button onClick={handleClose}>{t('common.cancel')}</Button>
              <Button type="submit" disabled={isSubmitting}>
                {t('dogView.dialogDeleteMicrochipCode.confirm')}
              </Button>
            </DialogActions>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
