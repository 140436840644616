import { Add, Edit } from '@mui/icons-material'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowFor } from '../context/AuthProvider'
import { Roles } from '../data/roles'
import {
  ErrorDto,
  PossessorDto,
  PossessorDtoTypeEnum,
  RegistrationDto,
} from '../generated/animare-management-api'
import { usePossessor } from '../hooks/usePossessor'
import { AppRoutes } from '../routes'
import BackButton from './BackButton'
import Possessor from './Possessor'
import RegistrationsTable from './RegistrationsTable'
import { SnackBar, SnackBarTypes } from './SnackBar'
import SpacerBox from './SpacerBox'

interface Props {
  possessorUid: string
}
const PossessorPage = ({ possessorUid }: Props) => {
  const { t } = useTranslation('common')
  const { getPossessorResponseByUid } = usePossessor()
  const navigate = useNavigate()
  const [possessorData, setPossessorData] = useState<PossessorDto>()
  const [registrationsData, setRegistrationsData] = useState<RegistrationDto[]>()
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)

  const { data: possessorResponse, error: errorCode } = getPossessorResponseByUid({
    uid: possessorUid,
  })

  useEffect(() => {
    if (possessorResponse) {
      setPossessorData(possessorResponse.possessor)
      setRegistrationsData(possessorResponse.registrations)
    } else if (errorCode) {
      setApiError(errorCode)
    }
  }, [possessorResponse, errorCode])

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        {apiError && (
          <SnackBar
            open={!!apiError}
            type={SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              return setApiError(undefined)
            }}
          />
        )}
        <SpacerBox gap={3} variant="container">
          <Typography variant="h1" data-qa="possessorView-title">
            {possessorData?.type === PossessorDtoTypeEnum.Person
              ? `${possessorData?.firstName}` + `${' '}` + `${possessorData?.lastName}`
              : possessorData?.organizationName}
          </Typography>
          <Divider />
          {!!possessorData && (
            <>
              <Possessor possessor={possessorData as PossessorDto} />
              <ShowFor roles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    data-qa="edit-possessor"
                    onClick={() => navigate(`${AppRoutes.Possessor_Edit}`)}
                    startIcon={<Edit />}
                  >
                    {t('common.edit')}
                  </Button>
                  <Button
                    variant="outlined"
                    data-qa="add-animal"
                    onClick={() => navigate('registration')}
                    startIcon={<Add />}
                  >
                    {t('registrationSearchPage.animalsSection.button')}
                  </Button>
                </Box>
              </ShowFor>
            </>
          )}
          {!!registrationsData && (
            <RegistrationsTable registrations={registrationsData} headerSize="h2" />
          )}
        </SpacerBox>
      </Paper>
    </>
  )
}

export default PossessorPage
