import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '..'
import DogView from '../../components/DogView/DogView'

function DogInfoView() {
  const navigate = useNavigate()
  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }

  return <DogView uid={registrationUid} />
}

export default DogInfoView
