/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Criteria for animal protection suspicion search
 * @export
 * @interface AnimalProtectionSearchDto
 */
export interface AnimalProtectionSearchDto {
  /**
   * Municipality code.
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  municipalityCode: string
  /**
   * Street address.
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  address?: string
  /**
   * First name of a possessor.
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  firstName?: string
  /**
   * Last name of a possessor
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  lastName?: string
  /**
   * Organization name
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  organizationName?: string
  /**
   * Postal code.
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  postalCode?: string
  /**
   * Code of the animal breed
   * @type {string}
   * @memberof AnimalProtectionSearchDto
   */
  breedCode?: string
}

/**
 * Check if a given object implements the AnimalProtectionSearchDto interface.
 */
export function instanceOfAnimalProtectionSearchDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'municipalityCode' in value

  return isInstance
}

export function AnimalProtectionSearchDtoFromJSON(json: any): AnimalProtectionSearchDto {
  return AnimalProtectionSearchDtoFromJSONTyped(json, false)
}

export function AnimalProtectionSearchDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalProtectionSearchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    municipalityCode: json['municipalityCode'],
    address: !exists(json, 'address') ? undefined : json['address'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    postalCode: !exists(json, 'postalCode') ? undefined : json['postalCode'],
    breedCode: !exists(json, 'breedCode') ? undefined : json['breedCode'],
  }
}

export function AnimalProtectionSearchDtoToJSON(value?: AnimalProtectionSearchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    municipalityCode: value.municipalityCode,
    address: value.address,
    firstName: value.firstName,
    lastName: value.lastName,
    organizationName: value.organizationName,
    postalCode: value.postalCode,
    breedCode: value.breedCode,
  }
}
