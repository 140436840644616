import * as yup from 'yup'

import { Message } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { arrivalDateSchema } from './InputArrivalDate'

const IDENTIFIER_REGEX = /^\d{6}[\+\-A-Z]\d{3}[0-9A-Z]$/
// Allowed characters for text fields
const textRegex = /^[\u0020-\u007e|\u00c0-\u017F]+$/

export const MAX_NAME_LENGTH = 255
export const MAX_ADDRESS_LENGTH = 255
export const MAX_POSTALCODE_LENGTH = 5
export const MAX_PHONE_NUMBER_LENGTH = 20
export const MAX_EMAIL_LENGTH = 255

export const getPersonPossessorFormSchema = () => {
  const { t } = useTranslation('common')

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    postalCodeLength: t('errors.validation.lengthRequirement', { number: '5' }),
    generic: t('register.inlineError.ifEmpty.generic') as Message,
  }

  return yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    personIdentifier: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .matches(IDENTIFIER_REGEX, errorMessages.wrongFormat),
    firstName: yup
      .string()
      .min(0)
      .max(MAX_NAME_LENGTH)
      .matches(textRegex, errorMessages.wrongFormat),
    lastName: yup
      .string()
      .min(0)
      .max(MAX_NAME_LENGTH)
      .matches(textRegex, errorMessages.wrongFormat)
      .when('firstName', {
        // at least the last name is required if first name is not given
        is: (value: string) => value == null || !value.trim(),
        then: (schema: yup.StringSchema) => schema.required(errorMessages.required),
      }),
    address: yup
      .string()
      .notRequired()
      .transform((value: string) => (value === '' ? null : value))
      .min(0)
      .max(MAX_ADDRESS_LENGTH)
      .matches(textRegex, errorMessages.wrongFormat),
    postalCode: yup
      .string()
      .min(MAX_POSTALCODE_LENGTH, errorMessages.postalCodeLength)
      .max(MAX_POSTALCODE_LENGTH, errorMessages.postalCodeLength)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    municipalityCode: yup
      .string()
      .min(3)
      .max(5)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    phone: yup
      .string()
      .notRequired()
      .transform((value: string) => (value === '' ? null : value))
      .min(1)
      .max(MAX_PHONE_NUMBER_LENGTH)
      .matches(/^(\d|\+)?\d+$/, errorMessages.wrongFormat),
    email: yup
      .string()
      .email(errorMessages.wrongFormat)
      .notRequired()
      .transform((value: string) => (value === '' ? null : value))
      .min(3)
      .max(MAX_EMAIL_LENGTH)
      .matches(/^.+@.+$/, errorMessages.wrongFormat),
  })
}
