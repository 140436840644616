import { Code } from '../generated/codes-app-api'

export const OTHER_BREED = 'OTHER_BREED'

// Cache only "other" as it is not sent by the api
export const breedCodes = [
  {
    code: OTHER_BREED,
    labels: [
      {
        lang: 'fi',
        label: 'Jokin muu',
      },
      {
        lang: 'en',
        label: 'Other',
      },
      {
        lang: 'sv',
        label: 'Annan',
      },
    ],
  },
] as Code[]
