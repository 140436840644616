import { Box, Typography } from '@mui/material'
import { OrganizationFormValues, OrganizationPossessorForm } from './OrganizationPossessorForm'

import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  PossessorDto,
  PossessorDtoTypeEnum,
} from '../../generated/animare-management-api/models/PossessorDto'
import { ErrorDto } from '../../generated/openapi'
import { usePossessorMapper } from '../../hooks/useMapper'
import { usePossessor } from '../../hooks/usePossessor'

interface AddNewOrganizationProps {
  organizationIdentifier: string
}

export const AddNewOrganization = (props: AddNewOrganizationProps) => {
  const { t } = useTranslation('common')
  const { organizationIdentifier } = props
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const { createPossessor } = usePossessor()
  const { mapToModifyPossessorRequestDto } = usePossessorMapper()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: createPossessorMutation } = createPossessor({
    onSuccess: (data: PossessorDto) => {
      navigate(`possessor/${data.uid}/registration`)
    },
    onError: (error: ErrorDto) => {
      setApiError(error)

      console.error(error)
    },
    queryClient,
  })

  const onSubmit = (form: OrganizationFormValues) => {
    setApiError(undefined)
    const request = mapToModifyPossessorRequestDto(form, PossessorDtoTypeEnum.Organization)
    createPossessorMutation(request)
  }

  return (
    <Box>
      <Typography variant="h2">{t('possessorEdit.newPossessor')}</Typography>
      <OrganizationPossessorForm
        possessor={{ organizationIdentifier } as PossessorDto}
        onSubmit={onSubmit}
        errorCode={apiError}
      />
    </Box>
  )
}
