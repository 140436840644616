import { useTranslation } from 'react-i18next'

type Props = {
  date?: Date
}

export default function DateFormatter({ date }: Props) {
  const { t } = useTranslation('common')
  return <>{date ? date.toLocaleDateString('fi-FI', { timeZone: 'UTC' }) : t('common.not-known')}</>
}
