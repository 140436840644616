import { AnimalDtoColoursEnum } from '../generated/animare-management-api'
import {
  harmaaTurkki,
  keltainenTurkki,
  mustaTurkki,
  punainenTurkki,
  ruskeaTurkki,
  valkoinenTurkki,
} from '../styles/theme'

export const furColors = [
  {
    id: AnimalDtoColoursEnum.Brown,
    color: ruskeaTurkki,
  },
  {
    id: AnimalDtoColoursEnum.Black,
    color: mustaTurkki,
  },
  {
    id: AnimalDtoColoursEnum.White,
    color: valkoinenTurkki,
  },
  {
    id: AnimalDtoColoursEnum.Red,
    color: punainenTurkki,
  },
  {
    id: AnimalDtoColoursEnum.Gray,
    color: harmaaTurkki,
  },
  {
    id: AnimalDtoColoursEnum.Yellow,
    color: keltainenTurkki,
  },
]
