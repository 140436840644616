import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { FormEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '..'
import LoadingSpinner from '../../components/LoadingSpinner'
import { SnackBar, SnackBarTypes } from '../../components/SnackBar'
import SpacerBox from '../../components/SpacerBox'
import TitleWithUnderline from '../../components/TitleWithUnderline'
import { useAuthContext } from '../../context/AuthProvider'
import {
  ErrorDto,
  KeyValueDto,
  OpenGateRequestDto,
  OpenGateResponseDto,
} from '../../generated/openapi'
import { useAuthApi } from '../../hooks/useAuthApi'
import useUserApi from '../../hooks/useUserApi'
import { ApiTypes } from '../../utils/errorUtils'

const Login = () => {
  const { i18n, t } = useTranslation('common')

  const navigate = useNavigate()
  const { init, authenticate } = useAuthApi()
  const { fetchCurrentUser } = useUserApi()
  const { initializing, currentUser, setCurrentUser } = useAuthContext()

  const [isLoading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<ErrorDto | undefined>(undefined)
  const [warning, setWarning] = useState<string | undefined>(undefined)
  const [openGateResponse, setOpenGateResponse] = useState<OpenGateResponseDto | undefined>(
    undefined,
  )
  const [answers, setAnswers] = useState<KeyValueDto[]>([])

  useEffect(() => {
    if (!initializing && !!currentUser) {
      // if we hit this page with a logged in user, we should simply redirect them away
      navigate(AppRoutes.Dashboard)
    }
    if (!initializing && !currentUser) {
      // only init the form after we have checked that we don't have an user in the authentication context
      initializeLoginForm()
    }
  }, [initializing, currentUser])

  const initializeLoginForm = () => {
    init(i18n.language.toLowerCase())
      .then((response) => {
        setOpenGateResponse(response)
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  const updateAnswers = (event: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newAnswer = {
      key: (event.target as HTMLInputElement).name,
      value: (event.target as HTMLInputElement).value,
    }

    setAnswers([...answers.filter((kv) => kv.key != newAnswer.key), newAnswer])
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    setLoading(true)
    setOpenGateResponse(undefined)
    // request should contain data fields from the last response + answers to questions, which should be form data
    const request: OpenGateRequestDto = { data: openGateResponse?.data, answers: answers }
    authenticate(request)
      .then((response) => {
        setOpenGateResponse(response)
        checkResponseForWarnings(response ?? {})
        if (!!response.ready) {
          fetchCurrentUser().then((user) => {
            setCurrentUser(user)
            navigate(AppRoutes.Dashboard)
          })
        } else {
          // continue with new questions
          setLoading(false)
        }
        setAnswers([])
      })
      .catch((error) => {
        setError(error)
        console.error(error)
        setLoading(false)
      })
    event.preventDefault()
  }

  const checkResponseForWarnings = (response: OpenGateResponseDto) => {
    setWarning(response.data?.find((value) => value.key === 'VIRHESELITYS')?.value)
  }

  return (
    <Paper
      sx={{
        borderRadius: '8px',
        mx: { xs: 0, sm: '5%', md: '20%', lg: '28%' },
        px: { xs: 2, sm: 4 },
        py: { xs: 4, sm: 6 },
      }}
    >
      <SpacerBox sx={{ width: '80%', textAlign: 'left', margin: '0 auto' }} gap={2}>
        <Box component="section" mb={2}>
          <TitleWithUnderline>{t('frontPage.authSection.title')}</TitleWithUnderline>

          <Typography variant="body1">{t('frontPage.authSection.info')}</Typography>
        </Box>
        <Typography variant="h2">{t('loginPage.title')}</Typography>
        {error && (
          <Box>
            <SnackBar
              apiType={ApiTypes.Management}
              type={SnackBarTypes.ERROR}
              open={true}
              errorCode={error}
              innerText={error.description}
              handleClose={() => {
                location.reload()
              }}
            />
            <Button onClick={() => location.reload()}>{t('loginPage.title')}</Button>
          </Box>
        )}
        {warning && (
          <SnackBar
            type={SnackBarTypes.WARNING}
            open={true}
            innerText={warning}
            handleClose={() => {
              return
            }}
          />
        )}
        {isLoading && <LoadingSpinner />}
        {!error && !isLoading && (
          <Box component="section">
            <Box
              component="form"
              onSubmit={onSubmit}
              sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
            >
              {openGateResponse?.questions?.map((q, index) => {
                return (
                  <FormGroup key={index}>
                    <InputLabel htmlFor={q.name}>{q.label}</InputLabel>
                    <FormControl sx={{ maxWidth: '100%' }}>
                      {!!q.choose && q.choose.length > 0 && (
                        <RadioGroup data-qa={q.name} name={q.name} onChange={updateAnswers}>
                          {q.choose.map((choice, index) => {
                            return (
                              <FormControlLabel
                                data-qa={`${q.name}-${choice}`}
                                key={index}
                                value={choice}
                                control={<Radio />}
                                label={choice}
                                required
                              />
                            )
                          })}
                        </RadioGroup>
                      )}
                      {(!q.choose || q.choose.length == 0) && (
                        <TextField
                          data-qa={q.name}
                          className="login-form-field"
                          type={q.hideAnswer ? 'password' : 'text'}
                          name={q.name}
                          autoFocus={index == 0}
                          required
                          onChange={updateAnswers}
                        />
                      )}
                    </FormControl>
                  </FormGroup>
                )
              })}
              {openGateResponse?.questions && (
                <Button variant="contained" type="submit" data-qa="submit" sx={{ width: '100%' }}>
                  {t('loginPage.confirm')}
                </Button>
              )}
            </Box>
            {openGateResponse?.externalLogin && openGateResponse.externalLogin.length > 0 && (
              <Box>
                <Divider aria-label={t('common.or')} sx={{ mt: 3, mb: 3 }} data-qa="or-divider">
                  <Typography variant="body1"> {t('common.or')} </Typography>
                </Divider>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                  data-qa="external-login-providers"
                >
                  {openGateResponse?.externalLogin
                    ?.filter((provider) => provider.language === i18n.language)
                    .map((externalLoginProvider, index) => {
                      return (
                        <Button
                          key={index}
                          href={externalLoginProvider.url}
                          variant="contained"
                          sx={{ width: '100%' }}
                          data-qa={`external-login-provider-${index}`}
                        >
                          {externalLoginProvider.text}
                        </Button>
                      )
                    })}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </SpacerBox>
    </Paper>
  )
}
export default Login
