import { ExpandMoreSharp } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../context/AuthProvider'
import { EventDto } from '../../generated/animare-management-api'
import EventsTable from './EventsTable'

export interface EventProps {
  events: EventDto[]
  isEventAccordionOpen?: () => void
}

export default function EventLog({ events, isEventAccordionOpen }: EventProps) {
  const { t } = useTranslation('common')
  const { currentUser } = useAuthContext()

  return (
    <Accordion elevation={0} onChange={isEventAccordionOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreSharp data-qa="eventLogExpand" />}
        id="eventLog-summary"
      >
        <Typography variant="h2" data-qa="eventLogTitle">
          {t('dogView.events.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails id="eventLog-section">
        <EventsTable events={events} roles={currentUser?.roles ?? []} />
      </AccordionDetails>
    </Accordion>
  )
}
