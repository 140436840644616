/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LimitedPossessor
 */
export interface LimitedPossessor {
  /**
   *
   * @type {string}
   * @memberof LimitedPossessor
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LimitedPossessor
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof LimitedPossessor
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof LimitedPossessor
   */
  municipalityCode?: string
}

/**
 * Check if a given object implements the LimitedPossessor interface.
 */
export function instanceOfLimitedPossessor(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function LimitedPossessorFromJSON(json: any): LimitedPossessor {
  return LimitedPossessorFromJSONTyped(json, false)
}

export function LimitedPossessorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LimitedPossessor {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    email: !exists(json, 'email') ? undefined : json['email'],
    municipalityCode: !exists(json, 'municipalityCode') ? undefined : json['municipalityCode'],
  }
}

export function LimitedPossessorToJSON(value?: LimitedPossessor | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    phone: value.phone,
    email: value.email,
    municipalityCode: value.municipalityCode,
  }
}
