import { Code, CodeLabelsInner } from '../../generated/codes-app-api'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import useBreeds, { BreedType } from '../../hooks/useBreeds'
import { useEffect } from 'react'

import { FormAutoComplete } from '../FormAutoComplete'
import { FormHelperText } from '@mui/material'
import InlineValidationError from './InlineValidationError'
import { useTranslation } from 'react-i18next'

type Props = {
  field: ControllerRenderProps<FieldValues, any>
  error: boolean
  errorContent: string
  errorId?: string
  ariaLabelledBy?: string
  isRequired?: boolean
}

function BreedSelect({ error, errorContent, errorId, ariaLabelledBy, field, isRequired }: Props) {
  const { defaultBreeds, fetchBreeds } = useBreeds()
  const { i18n, t } = useTranslation('common')

  const toBreedType = (code: Code): BreedType => {
    // default label
    const label = code.labels?.find((label: CodeLabelsInner) => label.lang === i18n.language)?.label

    // if default label isn't found, fall back to fi code if it exists
    // if not, return code
    if (!label || !code.labels || code.labels.length === 0) {
      return {
        label: code.labels?.find((label) => label.lang === 'fi')?.label || code.code,
        code: code,
      }
    } else {
      // if all else is fine, return normal label
      return {
        label,
        code: code,
      }
    }
  }

  const {
    data: allBreeds,
    error: allBreedsError,
    refetch: refetchBreeds,
    isPending,
  } = fetchBreeds()

  const defaultOptions = defaultBreeds().map(toBreedType)

  const getAllBreeds = (): BreedType[] => {
    if (allBreedsError || !allBreeds) {
      return []
    }
    const breedTypes = allBreeds.map(toBreedType).sort((a, b) => a.label.localeCompare(b.label))

    const breeds = [...defaultOptions, ...breedTypes]
    return breeds
  }

  useEffect(() => {
    refetchBreeds()
  }, [i18n.language])

  const dropdownOptions = getAllBreeds().map((breed) => ({
    label: breed.label,
    value: `${breed.code.code}`,
  }))

  return dropdownOptions && dropdownOptions.length > 0 ? (
    <>
      <FormAutoComplete
        dataQAString={field.name}
        ariaLabelledBy={ariaLabelledBy}
        field={field}
        isRequired={isRequired}
        options={dropdownOptions}
        loading={isPending}
        error={error && !field.value}
        inputTitle={t('common.animal.breed')}
      />
      {!!error && !field.value ? (
        <InlineValidationError content={errorContent} id={errorId} />
      ) : (
        isRequired && <FormHelperText> {t('registrationForm.help.dog.breed')} </FormHelperText>
      )}
    </>
  ) : (
    <></>
  )
}

export default BreedSelect
