import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../..'
import RegistrationFormPage from '../../../../components/RegistrationFormPage'

const RegistrationFormView = () => {
  const navigate = useNavigate()
  const { possessorUid } = useParams()
  if (!possessorUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }

  return <RegistrationFormPage possessorUid={possessorUid} />
}

export default RegistrationFormView
