import CheckIcon from '@mui/icons-material/Check'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { furColors } from '../../data/FurColors'
import theme from '../../styles/theme'
import InlineValidationError from './InlineValidationError'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

type Props = {
  error: boolean
  field: ControllerRenderProps<FieldValues, any>
  errorContent: string
  errorId?: string
}

function ColorSelect({ error, errorContent, errorId, field }: Props) {
  const { t } = useTranslation('common')

  return (
    <Box sx={{ gap: 4 }}>
      <Box>
        <Typography variant="h3" id="color-title">
          {t('common.animal.color')} <i aria-hidden={true}>*</i>
          <i className="visible-hidden">{t('registrationForm.field.required')}</i>
        </Typography>
        <Typography variant="body1">{t('registrationForm.help.dog.color')}</Typography>
      </Box>
      <FormGroup sx={{ mt: 4 }}>
        <FormControl
          data-qa="colours"
          aria-labelledby={!error ? 'color-title' : errorId}
          id="colours"
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              md: 'repeat(6, 1fr)',
              sm: 'repeat(3, 1fr)',
              xs: 'repeat(2, 1fr)',
            },
            gap: { md: 0, sm: 2, xs: 3 },
            justifyContent: 'left',
          }}
        >
          {furColors.map((furColor) => {
            return (
              <FormControlLabel
                key={furColor.id}
                label={t(`common.color.${furColor.id}`)}
                labelPlacement="bottom"
                sx={{ gap: 1, textAlign: 'center' }}
                control={
                  <Checkbox
                    value={furColor.id}
                    checked={(field.value ?? []).includes(furColor.id)}
                    onChange={(event, checked) => {
                      const inputValue = event.target.value
                      const fieldValue = field.value ?? []
                      if (checked) {
                        field.onChange([...fieldValue, inputValue])
                      } else {
                        const newColours = fieldValue.filter((value: any) => value !== inputValue)
                        field.onChange(newColours)
                      }
                    }}
                    icon={
                      <CheckBoxOutlineBlankIcon
                        color="primary"
                        sx={{
                          p: 0,
                          backgroundColor: 'white',
                          borderRadius: '4px',
                          boxShadow: '0px 1px 5px 1px rgb(99, 99, 99, 0.2)',
                        }}
                      />
                    }
                    checkedIcon={
                      <CheckIcon
                        sx={{
                          outline: `2px solid ${theme.palette.primary.main}`,
                          borderRadius: '4px',
                          backgroundColor: 'white',
                          color: 'primary',
                          boxShadow: '0px 1px 5px 1px rgb(99, 99, 99, 0.2)',
                        }}
                      />
                    }
                    sx={{
                      p: 2,
                      backgroundColor: furColor.color,
                      borderRadius: 0,
                      outline: `1px solid ${theme.palette.grey[400]}`,
                      '&:hover': {
                        backgroundColor: furColor.color,
                        outline: `3px solid ${theme.palette.primary.main}`,
                        boxShadow: '0px 1px 8px 2px rgb(99, 99, 99, 0.2)',
                      },
                    }}
                  />
                }
              />
            )
          })}
        </FormControl>
        {!!error && <InlineValidationError content={errorContent} id={errorId} />}
      </FormGroup>
    </Box>
  )
}

export default ColorSelect
