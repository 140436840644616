import { Button, Divider, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ErrorDto } from '../generated/animare-secrets-api/models/ErrorDto'
import { useRegistration } from '../hooks/useRegistration'
import { AppRoutes } from '../routes'
import BackButton from './BackButton'
import Dog from './Dog'
import DogDates from './DogDates'
import DogMicrochipExtra from './DogMicrochipExtra'
import Possessor from './Possessor'
import { SnackBar, SnackBarTypes } from './SnackBar'
import SpacerBox from './SpacerBox'
import TitleWithUnderline from './TitleWithUnderline'

interface Props {
  registrationUid: string
}

const RegistrationConfirmationPage = ({ registrationUid }: Props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { getRegistration } = useRegistration()
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const { data: registrationData, error: registrationFetchError } = getRegistration({
    uid: registrationUid,
  })

  /**
   * Shows success notification on the top of the page
   */
  const showSuccessNotification = () => {
    setShowNotification(SnackBarTypes.SUCCESS)
  }

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  useEffect(() => {
    if (registrationFetchError) {
      setApiError(registrationFetchError)
      showErrorNotification()
    }
  }, [registrationFetchError])

  useEffect(() => {
    if (registrationData) {
      showSuccessNotification()
    }
  }, [registrationData])

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        {!!registrationData && (
          <SnackBar
            open={showNotification === SnackBarTypes.SUCCESS}
            type={SnackBarTypes.SUCCESS}
            innerText={`${t('common.save-successful')}`}
            handleClose={() => setShowNotification(undefined)}
          />
        )}
        {!!registrationFetchError && (
          <SnackBar
            open={showNotification === SnackBarTypes.ERROR}
            type={SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              setShowNotification(undefined)
              setApiError(undefined)
            }}
          />
        )}
        <SpacerBox gap={3} variant="container">
          <TitleWithUnderline>{t('registrationConfirmationPage.title')}</TitleWithUnderline>
          {registrationData?.possessor && (
            <SpacerBox component="section" gap={2}>
              <Typography variant="h2">{t('possessorChangeCompleted.possessorTitle')}</Typography>
              <Possessor possessor={registrationData?.possessor} />
              <Divider />
            </SpacerBox>
          )}
          {registrationData && (
            <SpacerBox gap={2} component="section">
              <Typography variant="h2" data-qa="animal-title">
                {t('possessorChangeCompleted.registrationTitle')}
              </Typography>
              <Dog registration={registrationData} showStartDate={false} />
              {registrationData?.microchip && (
                <>
                  <Typography variant="h3" sx={{ mb: 2 }} data-qa="microchip-extra">
                    {t('common.animal.microchipExtra')}
                  </Typography>
                  <DogMicrochipExtra microchip={registrationData?.microchip} />
                </>
              )}

              <Typography variant="h3" sx={{ mb: 2 }} data-qa="date-title">
                {t('common.animal.dateTitle')}
              </Typography>
              <DogDates registration={{ ...registrationData }} />
            </SpacerBox>
          )}
          <SpacerBox gap={2} orientation="horizontal">
            <Button variant="contained" type="button" onClick={() => navigate(AppRoutes.Dashboard)}>
              {t('registrationConfirmationPage.buttonSection.toFrontPage')}
            </Button>
            <Button
              variant="outlined"
              type="submit"
              // Disabled if possessor uid can't be found for some reason
              disabled={!registrationData?.possessor.uid}
              onClick={() =>
                navigate(`/search/possessor/${registrationData?.possessor.uid}/registration`)
              }
            >
              {t('registrationConfirmationPage.buttonSection.addNewDogToSamePossessor')}
            </Button>
            <Button variant="outlined" type="submit" onClick={() => navigate(AppRoutes.Search)}>
              {t('registrationConfirmationPage.buttonSection.addNewDogToNewPossessor')}
            </Button>
          </SpacerBox>
        </SpacerBox>
      </Paper>
    </>
  )
}

export default RegistrationConfirmationPage
