/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { MonitoringSearchResult } from './MonitoringSearchResult'
import {
  MonitoringSearchResultFromJSON,
  MonitoringSearchResultFromJSONTyped,
  MonitoringSearchResultToJSON,
} from './MonitoringSearchResult'

/**
 * Contains a list of possessors that fit the search criteria
 * @export
 * @interface MonitoringSearchResponseDto
 */
export interface MonitoringSearchResponseDto {
  /**
   *
   * @type {Array<MonitoringSearchResult>}
   * @memberof MonitoringSearchResponseDto
   */
  results?: Array<MonitoringSearchResult>
}

/**
 * Check if a given object implements the MonitoringSearchResponseDto interface.
 */
export function instanceOfMonitoringSearchResponseDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function MonitoringSearchResponseDtoFromJSON(json: any): MonitoringSearchResponseDto {
  return MonitoringSearchResponseDtoFromJSONTyped(json, false)
}

export function MonitoringSearchResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoringSearchResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    results: !exists(json, 'results')
      ? undefined
      : (json['results'] as Array<any>).map(MonitoringSearchResultFromJSON),
  }
}

export function MonitoringSearchResponseDtoToJSON(value?: MonitoringSearchResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    results:
      value.results === undefined
        ? undefined
        : (value.results as Array<any>).map(MonitoringSearchResultToJSON),
  }
}
