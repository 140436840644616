import { ExpandLessSharp, ExpandMoreSharp } from '@mui/icons-material'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Roles } from '../../data/roles'
import { EventDto, PossessorDtoTypeEnum } from '../../generated/animare-management-api'
import { DATE_FORMAT_FI, useDate } from '../../hooks/useDate'
import { useEvents } from '../../hooks/useEvents'
import i18n from '../../i18n'
import { SnackBar, SnackBarTypes } from '../SnackBar'
import SpacerBox from '../SpacerBox'
import EventTypeFilter, { ALL_EVENT_TYPES } from './EventTypeFilter'

export interface PossessorCellProps {
  event: EventDto
}

export const PossessorCell = ({ event }: PossessorCellProps) => {
  const { t } = useTranslation('common')
  const [showIdentifier, setShowIdentifier] = useState<boolean>(false)

  return (
    <TableCell data-qa="possessorCell">
      <div data-qa="possessorName">{event.possessor?.name ?? t('common.not-known')}</div>
      <div data-qa="possessorIdentifier">
        {!!event.possessor?.name && !event.possessor?.identifier && t('common.not-known')}
        {!showIdentifier && event.possessor?.type === PossessorDtoTypeEnum.Person && (
          <Button
            variant="text"
            onClick={() => setShowIdentifier(true)}
            sx={{ p: 0, fontWeight: 400, textAlign: 'left' }}
          >
            {t('possessorDetails.field.showIdentifierBtn')}
          </Button>
        )}
        {showIdentifier &&
          event.possessor?.type === PossessorDtoTypeEnum.Person &&
          event.possessor?.identifier}
        {event.possessor?.type === PossessorDtoTypeEnum.Organization && event.possessor?.identifier}
      </div>
    </TableCell>
  )
}

const expansionsAllowed = (roles: string[]) =>
  roles.includes(Roles.Admin) || roles.includes(Roles.ServiceDesk)

export interface EventTableRowProps {
  event: EventDto
  roles: string[]
  rowIndex: number
}

const EventTableRow = ({ event, roles, rowIndex }: EventTableRowProps) => {
  const { t } = useTranslation('common')
  const { stringToDateTime, formatDate } = useDate()
  const [open, setOpen] = useState<boolean>(false)
  const { getEventDetails } = useEvents()
  const [showErrorNotification, setShowErrorNotification] = useState<boolean>(false)

  const rowClass = rowIndex % 2 === 0 ? 'normal-table-row-even' : 'normal-table-row-odd'

  const {
    data: eventData,
    isPending,
    error: eventFetchError,
  } = getEventDetails({ uid: event.uid, enabled: !!event?.uid && open })

  useEffect(() => {
    if (!!eventFetchError) {
      setShowErrorNotification(true)
    }
  }, [eventFetchError])

  const { details } = eventData || {}

  const userIsAdmin = roles.includes(Roles.Admin)

  const isExpandable =
    [
      'POSSESSOR_CHANGE_CODE_CREATED',
      'POSSESSOR_CHANGE_CODE_INVALIDATED',
      'POSSESSOR_CHANGE_STARTED',
    ].includes(event.type ?? '') ||
    (event.category === 'PAYMENT' && userIsAdmin)

  return (
    <React.Fragment>
      <SnackBar
        type={SnackBarTypes.ERROR}
        handleClose={() => {
          setShowErrorNotification(false)
        }}
        errorCode={eventFetchError || undefined}
        open={showErrorNotification}
      />
      <TableRow className={rowClass} key={event.timestamp} data-qa="eventRow">
        {expansionsAllowed(roles) && (
          <TableCell sx={{ padding: 0 }}>
            {isExpandable && (
              <IconButton
                color="primary"
                data-qa="expandRow"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <ExpandLessSharp /> : <ExpandMoreSharp />}
              </IconButton>
            )}
          </TableCell>
        )}
        <PossessorCell event={event} />
        <TableCell data-qa="eventTypeCell">
          {t(`dogView.events.eventTypes.${event.type}`) ?? event.type}
        </TableCell>
        <TableCell data-qa="eventPhysicalDateCell">
          {!!event.animalPhysicalEventDate &&
            formatDate(event.animalPhysicalEventDate as Date, DATE_FORMAT_FI)}
        </TableCell>
        <TableCell data-qa="eventArrivalDate">
          {!!event.documentArrivalDate &&
            formatDate(event.documentArrivalDate as Date, DATE_FORMAT_FI)}
        </TableCell>
        <TableCell data-qa="eventTimestampCell">
          {stringToDateTime(event.timestamp as string)}
        </TableCell>
      </TableRow>
      {expansionsAllowed(roles) && isExpandable && (
        <TableRow className="expanded-table-row" sx={{ display: open ? 'table-row' : 'none' }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} data-qa="expandedRow">
            <Collapse in={open}>
              {isPending && <Skeleton data-qa="expandSkeleton" />}
              {details && (
                <Box sx={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <Table>
                    <TableBody>
                      {(Object.entries(details) ?? []).map(([key, value], index) => (
                        <TableRow className="expanded-table-child-row" key={index}>
                          <TableCell
                            component="th"
                            sx={{ padding: 0, borderBottom: 0, fontWeight: 'bold' }}
                          >
                            {key}
                          </TableCell>
                          <TableCell sx={{ padding: 0, borderBottom: 0 }}>{value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

export interface EventProps {
  events: EventDto[]
  roles: string[]
}

enum SortOrder {
  Descending = 'desc',
  Ascending = 'asc',
}

const EventTable = ({ events, roles }: EventProps) => {
  const { t } = useTranslation('common')
  const [eventTypeFilter, setEventTypeFilter] = useState<string>(ALL_EVENT_TYPES)
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Descending)

  const sortByDate = (array: EventDto[]): EventDto[] => {
    return array.sort((a, b) => {
      const aName = a.timestamp || ''
      const bName = b.timestamp || ''
      return sortOrder === SortOrder.Descending
        ? bName.localeCompare(aName, i18n.language)
        : aName.localeCompare(bName, i18n.language)
    })
  }

  const changeSortOrder = () => {
    sortOrder === SortOrder.Descending
      ? setSortOrder(SortOrder.Ascending)
      : setSortOrder(SortOrder.Descending)
  }

  // Filter events based on the selected event.type filter
  const filteredEvents =
    eventTypeFilter === ALL_EVENT_TYPES
      ? events
      : events.filter((event) => event.type === eventTypeFilter)

  return (
    <SpacerBox component="section" gap={4}>
      <Box>
        <EventTypeFilter
          events={events}
          filter={eventTypeFilter}
          onFilterChange={setEventTypeFilter}
        />
        <TableContainer>
          <Table size="small" data-qa="eventsTable">
            <TableHead>
              <TableRow data-qa="eventsTableHead">
                {expansionsAllowed(roles) && <TableCell />}
                <TableCell> {t('dogView.events.possessor')} </TableCell>
                <TableCell>{t('dogView.events.eventType')}</TableCell>
                <TableCell>{t('dogView.events.animalPhysicalDate')}</TableCell>
                <TableCell>{t('dogView.events.arrivalDate')}</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={
                      sortOrder === SortOrder.Descending
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    }
                    onClick={changeSortOrder}
                  >
                    {t('dogView.events.eventTimestamp')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortByDate(filteredEvents).map((event, index) => (
                <EventTableRow event={event} roles={roles} key={index} rowIndex={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </SpacerBox>
  )
}

export default EventTable
