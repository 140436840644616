/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { LimitedMicrochip } from './LimitedMicrochip'
import {
  LimitedMicrochipFromJSON,
  LimitedMicrochipFromJSONTyped,
  LimitedMicrochipToJSON,
} from './LimitedMicrochip'
import type { LimitedPossessor } from './LimitedPossessor'
import {
  LimitedPossessorFromJSON,
  LimitedPossessorFromJSONTyped,
  LimitedPossessorToJSON,
} from './LimitedPossessor'

/**
 * A limited subset of animal information.
 * @export
 * @interface LimitedAnimalResponseDto
 */
export interface LimitedAnimalResponseDto {
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  uid?: string
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  microchipNumber?: string
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  breedCode?: string
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  breedDescription?: string
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  sexCode?: LimitedAnimalResponseDtoSexCodeEnum
  /**
   *
   * @type {string}
   * @memberof LimitedAnimalResponseDto
   */
  breedSize?: LimitedAnimalResponseDtoBreedSizeEnum
  /**
   *
   * @type {Array<string>}
   * @memberof LimitedAnimalResponseDto
   */
  colours?: Array<LimitedAnimalResponseDtoColoursEnum>
  /**
   *
   * @type {Array<LimitedMicrochip>}
   * @memberof LimitedAnimalResponseDto
   */
  microchips?: Array<LimitedMicrochip>
  /**
   *
   * @type {LimitedPossessor}
   * @memberof LimitedAnimalResponseDto
   */
  possessor?: LimitedPossessor
}

/**
 * @export
 */
export const LimitedAnimalResponseDtoSexCodeEnum = {
  Male: 'male',
  Female: 'female',
  Unknown: 'unknown',
} as const
export type LimitedAnimalResponseDtoSexCodeEnum =
  (typeof LimitedAnimalResponseDtoSexCodeEnum)[keyof typeof LimitedAnimalResponseDtoSexCodeEnum]

/**
 * @export
 */
export const LimitedAnimalResponseDtoBreedSizeEnum = {
  XSmall: 'x-small',
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  XLarge: 'x-large',
  Unknown: 'unknown',
} as const
export type LimitedAnimalResponseDtoBreedSizeEnum =
  (typeof LimitedAnimalResponseDtoBreedSizeEnum)[keyof typeof LimitedAnimalResponseDtoBreedSizeEnum]

/**
 * @export
 */
export const LimitedAnimalResponseDtoColoursEnum = {
  Brown: 'brown',
  Black: 'black',
  White: 'white',
  Red: 'red',
  Gray: 'gray',
  Yellow: 'yellow',
} as const
export type LimitedAnimalResponseDtoColoursEnum =
  (typeof LimitedAnimalResponseDtoColoursEnum)[keyof typeof LimitedAnimalResponseDtoColoursEnum]

/**
 * Check if a given object implements the LimitedAnimalResponseDto interface.
 */
export function instanceOfLimitedAnimalResponseDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function LimitedAnimalResponseDtoFromJSON(json: any): LimitedAnimalResponseDto {
  return LimitedAnimalResponseDtoFromJSONTyped(json, false)
}

export function LimitedAnimalResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LimitedAnimalResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    name: !exists(json, 'name') ? undefined : json['name'],
    microchipNumber: !exists(json, 'microchipNumber') ? undefined : json['microchipNumber'],
    breedCode: !exists(json, 'breedCode') ? undefined : json['breedCode'],
    breedDescription: !exists(json, 'breedDescription') ? undefined : json['breedDescription'],
    sexCode: !exists(json, 'sexCode') ? undefined : json['sexCode'],
    breedSize: !exists(json, 'breedSize') ? undefined : json['breedSize'],
    colours: !exists(json, 'colours') ? undefined : json['colours'],
    microchips: !exists(json, 'microchips')
      ? undefined
      : (json['microchips'] as Array<any>).map(LimitedMicrochipFromJSON),
    possessor: !exists(json, 'possessor') ? undefined : LimitedPossessorFromJSON(json['possessor']),
  }
}

export function LimitedAnimalResponseDtoToJSON(value?: LimitedAnimalResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    name: value.name,
    microchipNumber: value.microchipNumber,
    breedCode: value.breedCode,
    breedDescription: value.breedDescription,
    sexCode: value.sexCode,
    breedSize: value.breedSize,
    colours: value.colours,
    microchips:
      value.microchips === undefined
        ? undefined
        : (value.microchips as Array<any>).map(LimitedMicrochipToJSON),
    possessor: LimitedPossessorToJSON(value.possessor),
  }
}
