import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, Divider, IconButton, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { enUS, fiFI, svSE } from '@mui/x-data-grid/locales'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  MonitoringSearchResult,
  MonitoringSearchResultPossessorTypeEnum,
} from '../../generated/animare-management-api'
import { Municipality } from '../Codes/Municipality'

export type Props = {
  searchResults: MonitoringSearchResult[]
}

export default function MonitoringSearchResults({ searchResults }: Props) {
  const { t, i18n } = useTranslation('common')
  const navigate = useNavigate()
  const locales = () => {
    if (i18n.language === 'fi') {
      return fiFI.components.MuiDataGrid.defaultProps.localeText
    } else if (i18n.language === 'en') {
      return enUS.components.MuiDataGrid.defaultProps.localeText
    } else if (i18n.language === 'sv') {
      return svSE.components.MuiDataGrid.defaultProps.localeText
    }
  }
  const columns: GridColDef<(typeof searchResults)[number]>[] = [
    {
      field: 'fullName',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.possessorName')}`,
      valueGetter: (params: any) => {
        return `${
          params.row.possessorType === MonitoringSearchResultPossessorTypeEnum.Person
            ? `${params.row.lastName}` + `${' '}` + `${params.row.firstName}`
            : `${params.row.organizationName}`
        }`
      },
      cellClassName: 'possessorNameCell',
      editable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'address',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.address')}`,
      cellClassName: 'addressCell',
      editable: false,
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'postalCode',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.postalCode')}`,
      cellClassName: 'postalCodeCell',
      editable: false,
      flex: 0.75,
      minWidth: 140,
    },
    {
      field: 'municipalityCode',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.municipality')}`,
      cellClassName: 'municipalityCodeCell',
      renderCell: (params) => <Municipality code={params.row.municipalityCode} />,
      editable: false,
      flex: 0.75,
      minWidth: 140,
    },
    {
      field: 'animalCount',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.animalCount')}`,
      cellClassName: 'animalCountCell',
      editable: false,
      flex: 0.5,
      minWidth: 140,
    },
    {
      field: 'possessorUid',
      headerName: `${t('monitoring.animalProtectionSearch.results.table.additionalInformation')}`,
      renderCell: (params: any) => (
        <IconButton
          color="primary"
          onClick={() => navigate(`possessor/${params.row.possessorUid}`)}
          aria-label={t('animalsTable.column.additionalInformationAria') as string}
          className="additionalInformationCell"
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
      flex: 0.5,
      minWidth: 140,
      disableColumnMenu: true,
    },
  ]

  return (
    <Box
      component="section"
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      data-qa="searchResults"
    >
      <Divider />
      <Typography variant="h3">{t('monitoring.animalProtectionSearch.results.title')}</Typography>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'fullName', sort: 'asc' }],
          },
        }}
        localeText={locales()}
        getRowId={(row: any) => row.possessorUid}
        rows={searchResults}
        columns={columns}
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnSelector
      />
    </Box>
  )
}
