import { OrganizationFormValues } from '../../components/Form/OrganizationPossessorForm'
import { PersonPossessorFormValues } from '../../components/Form/PersonPossessorForm'
import {
  OrganizationType,
  PossessorDto,
  PossessorDtoTypeEnum,
  PossessorResponseDto,
} from '../../generated/animare-management-api'

export const possessorDto = {
  uid: 'uid-person',
  type: PossessorDtoTypeEnum.Person,
  personIdentifier: '123456-9999',
  firstName: 'Michael',
  lastName: 'Jackson',
  address: 'Ingentinggatan 999',
  postalCode: '00000',
  municipalityCode: '700',
  phone: '+358000000',
  email: 'mjackson@example.com',
} as PossessorDto

export const possessorWithChangeCode = {
  uid: 'uid-person-change-code',
  type: PossessorDtoTypeEnum.Person,
  firstName: 'Valle',
  lastName: 'Vaihtaja',
  personIdentifier: '401200A9988',
  address: 'address',
  postalCode: '00000',
  municipalityCode: '399',
  phone: '000',
  email: 'fubar@example.com',
} as PossessorDto

export const organizationPossessorDto = {
  uid: 'uid-organization',
  type: PossessorDtoTypeEnum.Organization,
  organizationIdentifier: '1234567-8',
  organizationName: 'Testiyritys',
  address: 'address',
  postalCode: '00000',
  municipalityCode: '399',
  phone: '358000000',
  email: 'testi@yritys.com',
  organizationType: OrganizationType.Association,
  soleTraderIdentifier: undefined,
  soleTrader: false,
} as PossessorDto

export const companyOrganizationPossessorDto = {
  uid: 'uid-company',
  type: PossessorDtoTypeEnum.Organization,
  organizationIdentifier: '1234567-1',
  organizationName: 'Testikomppania',
  address: 'address',
  postalCode: '00000',
  municipalityCode: '399',
  phone: '358000000',
  email: 'testi@yritys.com',
  organizationType: OrganizationType.Company,
  soleTrader: false,
} as PossessorDto

export const soletraderOrganizationPossessorDto = {
  uid: 'uid-soletrader',
  type: PossessorDtoTypeEnum.Organization,
  organizationIdentifier: '1234567-9',
  organizationName: 'Testiyritys toiminimi',
  address: 'address',
  postalCode: '00000',
  municipalityCode: '399',
  phone: '358000000',
  email: 'testi@yritys.com',
  organizationType: OrganizationType.Company,
  soleTraderIdentifier: '123456-8888',
  soleTrader: true,
} as PossessorDto

export const errorPossessor = {
  uid: 'error',
  type: PossessorDtoTypeEnum.Person,
  personIdentifier: '000000-0000',
  firstName: 'Error',
  lastName: 'Terror',
  address: 'Errorway 500',
  postalCode: '00500',
  municipalityCode: '500',
  phone: '+358000000',
  email: 'error@example.com',
} as PossessorDto

export const badPossessor = {
  uid: 'bad',
  type: PossessorDtoTypeEnum.Person,
  personIdentifier: '000000-0001',
  firstName: 'Bad',
  lastName: 'Possessor',
  address: 'Badway 123',
  postalCode: '00001',
  municipalityCode: '123',
  phone: '+358000000',
  email: 'bad@example.com',
} as PossessorDto

export const mockPossessors = [
  possessorDto,
  possessorWithChangeCode,
  organizationPossessorDto,
  companyOrganizationPossessorDto,
  soletraderOrganizationPossessorDto,
  errorPossessor,
  badPossessor,
]

export const personPossessorForm = {
  arrivalDate: new Date(),
  address: 'katukatu 1',
  email: 'foo@example.com',
  firstName: 'Frist',
  lastName: 'Lazt',
  municipalityCode: '050',
  personIdentifier: '123456-111A',
  phone: '+000000',
  postalCode: '00009',
} as PersonPossessorFormValues

export const organizationPossessorForm = {
  arrivalDate: new Date(),
  address: 'katu 7',
  email: 'org@example.com',
  municipalityCode: '099',
  organizationIdentifier: '7654321-0',
  organizationName: 'The Best Organization Ever',
  organizationType: OrganizationType.Association,
  phone: '+0001',
  postalCode: '12121',
  soleTrader: false,
} as OrganizationFormValues

const personPossessorResponse = {
  possessor: {
    ...possessorDto,
  },
  registrations: [
    {
      uid: 'd681c37d-85cb-4d0f-979f-17b1546f2b7a',
      startDate: new Date('2023-01-01'),
      animal: {
        name: 'Fueffetön',
        birthDate: new Date('2023-01-01'),
        breedCode: '10',
      },
      microchip: {
        microchipNumber: '1234',
      },
    },
  ],
} as PossessorResponseDto

export const mockPossessorsWithOtherDataModel = [
  personPossessorResponse,
  {
    possessor: {
      ...organizationPossessorDto,
    },
    registrations: personPossessorResponse.registrations,
  },
] as PossessorResponseDto[]
