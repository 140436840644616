import { useTranslation } from 'react-i18next'
import useCountries from '../../hooks/useCountries'
import AbstractCode from './AbstractCode'

export interface Props {
  code?: string
}

export default function Country({ code }: Props) {
  const { t } = useTranslation('common')
  return (
    <AbstractCode
      code={code}
      generator={useCountries().getCountry}
      unknownResult={t('common.not-known')}
    />
  )
}
