/* tslint:disable */
/* eslint-disable */
/**
 * Animare Codes API
 * Provides codes and their labels in various languages used by Animare applications.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface CodeLabelsInner
 */
export interface CodeLabelsInner {
  /**
   * The code itself
   * @type {string}
   * @memberof CodeLabelsInner
   */
  lang?: string
  /**
   * The label of the code in the specified language.
   * @type {string}
   * @memberof CodeLabelsInner
   */
  label?: string
}

/**
 * Check if a given object implements the CodeLabelsInner interface.
 */
export function instanceOfCodeLabelsInner(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function CodeLabelsInnerFromJSON(json: any): CodeLabelsInner {
  return CodeLabelsInnerFromJSONTyped(json, false)
}

export function CodeLabelsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CodeLabelsInner {
  if (json === undefined || json === null) {
    return json
  }
  return {
    lang: !exists(json, 'lang') ? undefined : json['lang'],
    label: !exists(json, 'label') ? undefined : json['label'],
  }
}

export function CodeLabelsInnerToJSON(value?: CodeLabelsInner | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    lang: value.lang,
    label: value.label,
  }
}
