import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationFI from './locales/fi.json'
import translationSV from './locales/sv.json'

const resources = {
  fi: {
    common: translationFI,
  },
  sv: {
    common: translationSV,
  },
}

const resolveUserLanguage = () => {
  // check if we have saved the language to localstorage
  const lang = localStorage.getItem('lang')
  if (!!lang && Object.keys(resources).includes(lang)) {
    return lang
  }
  // use default finnish if nothing is selected
  return 'fi'
}

i18n.use(initReactI18next).init({
  resources,
  lng: resolveUserLanguage(),
  fallbackLng: 'fi',
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
})

export default i18n
