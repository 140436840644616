/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalDto } from './AnimalDto'
import { AnimalDtoFromJSON, AnimalDtoFromJSONTyped, AnimalDtoToJSON } from './AnimalDto'
import type { MicrochipDto } from './MicrochipDto'
import { MicrochipDtoFromJSON, MicrochipDtoFromJSONTyped, MicrochipDtoToJSON } from './MicrochipDto'
import type { PossessorChangeCodeDto } from './PossessorChangeCodeDto'
import {
  PossessorChangeCodeDtoFromJSON,
  PossessorChangeCodeDtoFromJSONTyped,
  PossessorChangeCodeDtoToJSON,
} from './PossessorChangeCodeDto'
import type { PossessorDto } from './PossessorDto'
import { PossessorDtoFromJSON, PossessorDtoFromJSONTyped, PossessorDtoToJSON } from './PossessorDto'

/**
 * Full registration information (animal, microchip, possessor)
 * @export
 * @interface FullRegistrationDto
 */
export interface FullRegistrationDto {
  /**
   *
   * @type {string}
   * @memberof FullRegistrationDto
   */
  uid?: string
  /**
   * When the registration form has arrived (only for paper registration).
   * @type {Date}
   * @memberof FullRegistrationDto
   */
  arrivalDate: Date
  /**
   * Registration start date (when the registration has been saved in the system).
   * @type {Date}
   * @memberof FullRegistrationDto
   */
  startDate?: Date
  /**
   * End date of the registration, if the registration is no longer active (when the animal has been transferred).
   * @type {Date}
   * @memberof FullRegistrationDto
   */
  endDate?: Date
  /**
   * When the animal has physically changed possessors (if it is a transferred registration).
   * @type {Date}
   * @memberof FullRegistrationDto
   */
  transferDate?: Date
  /**
   *
   * @type {AnimalDto}
   * @memberof FullRegistrationDto
   */
  animal: AnimalDto
  /**
   *
   * @type {MicrochipDto}
   * @memberof FullRegistrationDto
   */
  microchip: MicrochipDto
  /**
   *
   * @type {PossessorDto}
   * @memberof FullRegistrationDto
   */
  possessor: PossessorDto
  /**
   *
   * @type {PossessorChangeCodeDto}
   * @memberof FullRegistrationDto
   */
  changeCode?: PossessorChangeCodeDto
}

/**
 * Check if a given object implements the FullRegistrationDto interface.
 */
export function instanceOfFullRegistrationDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value
  isInstance = isInstance && 'animal' in value
  isInstance = isInstance && 'microchip' in value
  isInstance = isInstance && 'possessor' in value

  return isInstance
}

export function FullRegistrationDtoFromJSON(json: any): FullRegistrationDto {
  return FullRegistrationDtoFromJSONTyped(json, false)
}

export function FullRegistrationDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): FullRegistrationDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    arrivalDate: new Date(json['arrivalDate']),
    startDate: !exists(json, 'startDate') ? undefined : new Date(json['startDate']),
    endDate: !exists(json, 'endDate') ? undefined : new Date(json['endDate']),
    transferDate: !exists(json, 'transferDate') ? undefined : new Date(json['transferDate']),
    animal: AnimalDtoFromJSON(json['animal']),
    microchip: MicrochipDtoFromJSON(json['microchip']),
    possessor: PossessorDtoFromJSON(json['possessor']),
    changeCode: !exists(json, 'changeCode')
      ? undefined
      : PossessorChangeCodeDtoFromJSON(json['changeCode']),
  }
}

export function FullRegistrationDtoToJSON(value?: FullRegistrationDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
    startDate:
      value.startDate === undefined ? undefined : value.startDate.toISOString().substr(0, 10),
    endDate: value.endDate === undefined ? undefined : value.endDate.toISOString().substr(0, 10),
    transferDate:
      value.transferDate === undefined ? undefined : value.transferDate.toISOString().substr(0, 10),
    animal: AnimalDtoToJSON(value.animal),
    microchip: MicrochipDtoToJSON(value.microchip),
    possessor: PossessorDtoToJSON(value.possessor),
    changeCode: PossessorChangeCodeDtoToJSON(value.changeCode),
  }
}
