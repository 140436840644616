/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Information about microchip code change
 * @export
 * @interface MicrochipNumberChangeRequestDto
 */
export interface MicrochipNumberChangeRequestDto {
  /**
   * When the MicrochipNumberChangeRequest form has arrived (only for paper registration).
   * @type {Date}
   * @memberof MicrochipNumberChangeRequestDto
   */
  arrivalDate: Date
  /**
   * Current microchip number.
   * @type {string}
   * @memberof MicrochipNumberChangeRequestDto
   */
  microchipNumber?: string
  /**
   * New microchip number.
   * @type {string}
   * @memberof MicrochipNumberChangeRequestDto
   */
  newMicrochipNumber?: string
}

/**
 * Check if a given object implements the MicrochipNumberChangeRequestDto interface.
 */
export function instanceOfMicrochipNumberChangeRequestDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value

  return isInstance
}

export function MicrochipNumberChangeRequestDtoFromJSON(
  json: any,
): MicrochipNumberChangeRequestDto {
  return MicrochipNumberChangeRequestDtoFromJSONTyped(json, false)
}

export function MicrochipNumberChangeRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MicrochipNumberChangeRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    arrivalDate: new Date(json['arrivalDate']),
    microchipNumber: !exists(json, 'microchipNumber') ? undefined : json['microchipNumber'],
    newMicrochipNumber: !exists(json, 'newMicrochipNumber')
      ? undefined
      : json['newMicrochipNumber'],
  }
}

export function MicrochipNumberChangeRequestDtoToJSON(
  value?: MicrochipNumberChangeRequestDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
    microchipNumber: value.microchipNumber,
    newMicrochipNumber: value.newMicrochipNumber,
  }
}
