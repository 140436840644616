/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalCountryType } from './AnimalCountryType'
import {
  AnimalCountryTypeFromJSON,
  AnimalCountryTypeFromJSONTyped,
  AnimalCountryTypeToJSON,
} from './AnimalCountryType'

/**
 * Provides information about animal country and date when information was last updated
 * @export
 * @interface AnimalCountryDto
 */
export interface AnimalCountryDto {
  /**
   *
   * @type {AnimalCountryType}
   * @memberof AnimalCountryDto
   */
  type: AnimalCountryType
  /**
   * Date when information was last changed.
   * @type {Date}
   * @memberof AnimalCountryDto
   */
  date?: Date
}

/**
 * Check if a given object implements the AnimalCountryDto interface.
 */
export function instanceOfAnimalCountryDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value

  return isInstance
}

export function AnimalCountryDtoFromJSON(json: any): AnimalCountryDto {
  return AnimalCountryDtoFromJSONTyped(json, false)
}

export function AnimalCountryDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalCountryDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    type: AnimalCountryTypeFromJSON(json['type']),
    date: !exists(json, 'date') ? undefined : new Date(json['date']),
  }
}

export function AnimalCountryDtoToJSON(value?: AnimalCountryDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    type: AnimalCountryTypeToJSON(value.type),
    date: value.date === undefined ? undefined : value.date.toISOString().substr(0, 10),
  }
}
