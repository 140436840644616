import React from 'react'
import { Box, Menu, MenuItem, Button, Typography } from '@mui/material'
import { ArrowDropDownSharp } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

function LangMenu() {
  const { i18n } = useTranslation('common')

  const lang = [
    {
      name: 'fi',
      desc: 'FI – Suomeksi',
    },
    {
      name: 'sv',
      desc: 'SV – På Svenska',
    },
  ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (e: any) => {
    i18n.changeLanguage(e)
    setAnchorEl(null)
  }

  return (
    <Box>
      <Button onClick={handleClick} aria-label="Kielivalinta" endIcon={<ArrowDropDownSharp />}>
        <Typography
          variant="body1"
          component="span"
          sx={{ fontWeight: 700, mr: -0.5, color: 'text.secondary', textTransform: 'uppercase' }}
        >
          {i18n.language}
        </Typography>
      </Button>
      <Menu
        id="menu"
        elevation={2}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'language-menu',
        }}
      >
        {lang.map((lang) => {
          return (
            <MenuItem onClick={() => handleClose(lang.name)} key={lang.name} value={lang.name}>
              {lang.desc}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

export default LangMenu
