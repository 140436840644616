/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface QuestionDto
 */
export interface QuestionDto {
  /**
   * nimi
   * @type {string}
   * @memberof QuestionDto
   */
  name?: string
  /**
   * piilotettu
   * @type {boolean}
   * @memberof QuestionDto
   */
  hideAnswer?: boolean
  /**
   * selite
   * @type {string}
   * @memberof QuestionDto
   */
  label?: string
  /**
   * tarkenne
   * @type {string}
   * @memberof QuestionDto
   */
  description?: string
  /**
   * vaihtoehto
   * @type {Array<string>}
   * @memberof QuestionDto
   */
  choose?: Array<string>
}

/**
 * Check if a given object implements the QuestionDto interface.
 */
export function instanceOfQuestionDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function QuestionDtoFromJSON(json: any): QuestionDto {
  return QuestionDtoFromJSONTyped(json, false)
}

export function QuestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    hideAnswer: !exists(json, 'hideAnswer') ? undefined : json['hideAnswer'],
    label: !exists(json, 'label') ? undefined : json['label'],
    description: !exists(json, 'description') ? undefined : json['description'],
    choose: !exists(json, 'choose') ? undefined : json['choose'],
  }
}

export function QuestionDtoToJSON(value?: QuestionDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    hideAnswer: value.hideAnswer,
    label: value.label,
    description: value.description,
    choose: value.choose,
  }
}
