import { Box, Button } from '@mui/material'

import { Search } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../..'
import TitleWithUnderline from '../../../components/TitleWithUnderline'

export const ShelterHome = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  return (
    <Box>
      <TitleWithUnderline>{t('shelter.homePage.title')}</TitleWithUnderline>
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        type="submit"
        onClick={() => navigate(AppRoutes.Shelter)}
        aria-label={t('homePage.search.button') as string}
        startIcon={<Search />}
      >
        {t('homePage.search.button')}
      </Button>
    </Box>
  )
}
