/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { AnimalCountryDto } from './AnimalCountryDto'
import {
  AnimalCountryDtoFromJSON,
  AnimalCountryDtoFromJSONTyped,
  AnimalCountryDtoToJSON,
} from './AnimalCountryDto'
import type { AnimalHealthType } from './AnimalHealthType'
import {
  AnimalHealthTypeFromJSON,
  AnimalHealthTypeFromJSONTyped,
  AnimalHealthTypeToJSON,
} from './AnimalHealthType'
import type { AnimalLocationDto } from './AnimalLocationDto'
import {
  AnimalLocationDtoFromJSON,
  AnimalLocationDtoFromJSONTyped,
  AnimalLocationDtoToJSON,
} from './AnimalLocationDto'

/**
 * Provide information about if the animal is alive, in Finland or abroad, or missing.
 * @export
 * @interface AnimalStatusDto
 */
export interface AnimalStatusDto {
  /**
   *
   * @type {AnimalHealthType}
   * @memberof AnimalStatusDto
   */
  health: AnimalHealthType
  /**
   *
   * @type {AnimalCountryDto}
   * @memberof AnimalStatusDto
   */
  country: AnimalCountryDto
  /**
   *
   * @type {AnimalLocationDto}
   * @memberof AnimalStatusDto
   */
  location: AnimalLocationDto
}

/**
 * Check if a given object implements the AnimalStatusDto interface.
 */
export function instanceOfAnimalStatusDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'health' in value
  isInstance = isInstance && 'country' in value
  isInstance = isInstance && 'location' in value

  return isInstance
}

export function AnimalStatusDtoFromJSON(json: any): AnimalStatusDto {
  return AnimalStatusDtoFromJSONTyped(json, false)
}

export function AnimalStatusDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalStatusDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    health: AnimalHealthTypeFromJSON(json['health']),
    country: AnimalCountryDtoFromJSON(json['country']),
    location: AnimalLocationDtoFromJSON(json['location']),
  }
}

export function AnimalStatusDtoToJSON(value?: AnimalStatusDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    health: AnimalHealthTypeToJSON(value.health),
    country: AnimalCountryDtoToJSON(value.country),
    location: AnimalLocationDtoToJSON(value.location),
  }
}
