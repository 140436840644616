import { Box, Chip, Grid, ListItem, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DATETIME_FORMAT_FI, DATE_FORMAT_FI_SHORT, useDate } from '../../hooks/useDate'
import Breed from '../Codes/Breed'
import Country from '../Codes/Country'
import { Municipality } from '../Codes/Municipality'
import DateFormatter from '../DateFormatter'
import {
  AnimalImportingSearchFormValues,
  AnimalProtectionSearchFormValues,
  FirstRegistrationsFormValues,
  PossessorChangeSearchFormValues,
  ProfessionalActivitySearchFormValues,
} from './MonitoringSearchSchema'

export interface Props {
  searchParams: AnimalImportingSearchFormValues &
    AnimalProtectionSearchFormValues &
    ProfessionalActivitySearchFormValues &
    PossessorChangeSearchFormValues &
    FirstRegistrationsFormValues
  searchTime?: Date
  resultCount: number
  'data-qa'?: string
}

export const SearchParam = ({
  label,
  value,
  'data-qa': dataQa,
}: {
  label: string
  value: string | JSX.Element
  'data-qa': string
}) => {
  return (
    <ListItem sx={{ width: 'auto', listStyleType: 'none', p: 1, m: 0 }}>
      <Chip
        data-qa={dataQa}
        label={
          <Grid sx={{ display: 'flex', gap: 1 }} component="dl">
            <Typography
              variant="body1"
              component="dt"
              sx={{ fontWeight: 'bold', textTransform: 'none' }}
            >
              {label}:
            </Typography>
            <Typography variant="body1" component="dd" sx={{}}>
              {value}
            </Typography>
          </Grid>
        }
      />
    </ListItem>
  )
}

export const MonitoringSearchParams = ({
  searchParams,
  searchTime,
  resultCount,
  'data-qa': dataQa = 'searchParams',
}: Props) => {
  const { t } = useTranslation('common')
  const { formatDate } = useDate()

  return (
    <Box
      component="section"
      aria-label={t('monitoring.animalProtectionSearch.title')}
      sx={{ mt: 2, mb: 2 }}
      data-qa={dataQa}
    >
      <Typography data-qa="searchParams" variant="h3">
        {t('monitoring.animalProtectionSearch.results.params.title')}
      </Typography>
      <Box
        component="ul"
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          flexWrap: 'wrap',
          mb: 2,
        }}
        data-qa="searchParamsList"
      >
        {Object.keys(searchParams).length == 0 && (
          <SearchParam
            key="none"
            data-qa="none"
            label={t('monitoring.animalProtectionSearch.results.params.none')}
            value=""
          />
        )}
        {!!searchParams.allCountries && (
          <SearchParam
            key="allCountries"
            data-qa="allCountries"
            label={t('common.animal.birthCountry')}
            value={`${t('monitoring.animalImporting.allCountriesCheckbox')}`}
          />
        )}
        {!!searchParams.birthCountryCode && (
          <SearchParam
            key="birthCountryCode"
            data-qa="birthCountryCode"
            label={t('common.animal.birthCountry')}
            value={<Country code={searchParams.birthCountryCode} />}
          />
        )}
        {!!searchParams.birthStartDate && (
          <SearchParam
            key="birthStartDate"
            data-qa="birthStartDate"
            label={
              `${t('common.animal.birthDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.startDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.birthStartDate} />}
          />
        )}
        {!!searchParams.birthEndDate && (
          <SearchParam
            key="birthEndDate"
            data-qa="birthEndDate"
            label={
              `${t('common.animal.birthDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.endDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.birthEndDate} />}
          />
        )}
        {!!searchParams.registrationStartDate && (
          <SearchParam
            key="registrationStartDate"
            data-qa="registrationStartDate"
            label={
              `${t('monitoring.animalImporting.results.params.registrationDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.startDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.registrationStartDate} />}
          />
        )}
        {!!searchParams.registrationEndDate && (
          <SearchParam
            key="registrationEndDate"
            data-qa="registrationEndDate"
            label={
              `${t('monitoring.animalImporting.results.params.registrationDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.endDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.registrationEndDate} />}
          />
        )}
        {!!searchParams.possessorChangeStartDate && (
          <SearchParam
            key="possessorChangeStartDate"
            data-qa="possessorChangeStartDate"
            label={
              `${t('monitoring.animalImporting.results.params.possessorChangeDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.startDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.possessorChangeStartDate} />}
          />
        )}
        {!!searchParams.possessorChangeEndDate && (
          <SearchParam
            key="possessorChangeEndDate"
            data-qa="possessorChangeEndDate"
            label={
              `${t('monitoring.animalImporting.results.params.possessorChangeDate')}` +
              ' ' +
              `${t('monitoring.puppyMilling.firstRegistrations.endDateLabel').toLowerCase()}`
            }
            value={<DateFormatter date={searchParams.possessorChangeEndDate} />}
          />
        )}
        {!!searchParams.municipalityCode && (
          <SearchParam
            key="municipalityCode"
            data-qa="municipalityCode"
            label={t('monitoring.animalProtectionSearch.results.table.municipality')}
            value={<Municipality code={searchParams.municipalityCode} />}
          />
        )}
        {!!searchParams.minimumRegisteredAnimals && (
          <SearchParam
            key="minimumRegisteredAnimals"
            data-qa="minimumRegisteredAnimals"
            label={t('monitoring.professionalActivity.dogAmountLabel')}
            value={`${searchParams.minimumRegisteredAnimals}`}
          />
        )}
        {!!searchParams.postalCode && (
          <SearchParam
            key="postalCode"
            data-qa="postalCode"
            label={t('monitoring.animalProtectionSearch.results.table.postalCode')}
            value={`${searchParams.postalCode}`}
          />
        )}
        {!!searchParams.address && (
          <SearchParam
            key="address"
            data-qa="address"
            label={t('monitoring.animalProtectionSearch.form.address')}
            value={`${searchParams.address}`}
          />
        )}{' '}
        {!!searchParams.firstName && (
          <SearchParam
            key="firstName"
            data-qa="firstName"
            label={t('monitoring.animalProtectionSearch.form.firstName')}
            value={`${searchParams.firstName}`}
          />
        )}{' '}
        {!!searchParams.lastName && (
          <SearchParam
            key="lastName"
            data-qa="lastName"
            label={t('monitoring.animalProtectionSearch.form.lastName')}
            value={`${searchParams.lastName}`}
          />
        )}
        {!!searchParams.organizationName && (
          <SearchParam
            key="organizationName"
            data-qa="organizationName"
            label={t('monitoring.animalProtectionSearch.form.organizationName')}
            value={`${searchParams.organizationName}`}
          />
        )}
        {!!searchParams.breedCode && (
          <SearchParam
            key="breedCode"
            data-qa="breedCode"
            label={t('common.animal.breed')}
            value={<Breed code={searchParams.breedCode} />}
          />
        )}
        {!!searchParams.minimumPossessorChangeCount && (
          <SearchParam
            key="minimumPossessorChangeCount"
            data-qa="minimumPossessorChangeCount"
            label={t('monitoring.puppyMilling.possessorChange.amount')}
            value={`${searchParams.minimumPossessorChangeCount}`}
          />
        )}
        {!!searchParams.firstRegistrationCount && (
          <SearchParam
            key="firstRegistrationCount"
            data-qa="firstRegistrationCount"
            label={t('monitoring.professionalActivity.dogAmountLabel')}
            value={`${searchParams.firstRegistrationCount}`}
          />
        )}
        {!!searchParams.startDate && searchParams.startDate instanceof Date && (
          <SearchParam
            key="startDate"
            data-qa="startDate"
            label={t('monitoring.puppyMilling.firstRegistrations.startDateLabel')}
            value={formatDate(searchParams.startDate, DATE_FORMAT_FI_SHORT)}
          />
        )}
        {!!searchParams.endDate && searchParams.endDate instanceof Date && (
          <SearchParam
            key="endDate"
            data-qa="endDate"
            label={t('monitoring.puppyMilling.firstRegistrations.endDateLabel')}
            value={formatDate(searchParams.endDate, DATE_FORMAT_FI_SHORT)}
          />
        )}
      </Box>
      <Typography data-qa="resultCount">
        {t('monitoring.animalProtectionSearch.results.count', { count: resultCount })}
      </Typography>
      {searchTime && (
        <Typography data-qa="searchTime">
          {t('monitoring.animalProtectionSearch.results.time', {
            time: formatDate(searchTime, DATETIME_FORMAT_FI),
          })}
        </Typography>
      )}
    </Box>
  )
}
