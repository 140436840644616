import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  AnimalCountryType,
  AnimalHealthType,
  AnimalLocationType,
  AnimalStatusDto,
} from '../generated/animare-management-api'
import { DATE_FORMAT_FI_SHORT, useDate } from '../hooks/useDate'

interface Props {
  status: AnimalStatusDto
  text?: string
}

enum Colors {
  Default = 'info.main',
  Warn = 'warning.darker',
  Error = 'error.main',
}

export default function DogStatusInfo({ status, text }: Props) {
  const { t } = useTranslation('common')
  const { formatDate } = useDate()

  const styles = {
    color: Colors.Default,
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    width: 'fit-content',
    mb: 1,
  }

  const textStyles = {
    color: Colors.Default,
    textTransform: 'uppercase',
  }

  let statusText = text ?? ''

  if (status.location?.type === AnimalLocationType.Missing) {
    const statusDate =
      status.location.date && formatDate(status.location?.date, DATE_FORMAT_FI_SHORT)
    statusText = text ?? `${t('reportChange.dogMissingStatusText', { date: statusDate })}`
  }

  if (status.country?.type === AnimalCountryType.Abroad) {
    const statusDate = status.country.date && formatDate(status.country?.date, DATE_FORMAT_FI_SHORT)
    statusText =
      text ??
      t('reportChange.dogAbroadStatusText', {
        date: statusDate,
      })
  }

  // This overrides every other state
  if (status.health === AnimalHealthType.Dead) {
    statusText = text ?? t('reportChange.dogDeadStatusText')
  }

  if (statusText === '') {
    return <></>
  }

  return (
    <Box sx={styles} data-qa="dog-status-container">
      <InfoSharpIcon />
      <Typography variant="body2" sx={textStyles} data-qa="dog-status">
        {statusText}
      </Typography>
    </Box>
  )
}
