import { useTranslation } from 'react-i18next'
import { AnimalDtoColoursEnum } from '../generated/animare-management-api/models/AnimalDto'

export interface Props {
  code?: string
}
export default function Color({ code }: Props) {
  const { t } = useTranslation('common')
  const colors = Object.values(AnimalDtoColoursEnum)
  if (code === undefined || code === null || !colors.find((c) => code === c)) {
    return <>{t('common.color.unknown')}</>
  }
  return <>{t(`common.color.${code}`)}</>
}
