import { Box, Button, Container, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '.'

export default function NotFound() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  return (
    <Container>
      <Paper sx={{ p: { sm: 8, xs: 2 }, pb: { xs: 4 } }}>
        <Box component="section">
          <Typography variant="h1">{t('errors.404.page-not-found')}</Typography>
          <Button
            variant="contained"
            onClick={() => navigate(AppRoutes.Root)}
            sx={{ alignSelf: 'flex-start', mt: 2, mb: 1 }}
          >
            {t('nav.home')}
          </Button>
        </Box>
      </Paper>
    </Container>
  )
}
