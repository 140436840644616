import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { MonitoringSearchResult } from '../../generated/animare-management-api/models/MonitoringSearchResult'
import MonitoringSearchDownload from './MonitoringSearchDownload'
import { MonitoringSearchParams } from './MonitoringSearchParams'
import MonitoringSearchResults from './MonitoringSearchResults'

type Props = {
  searchParams?: any // NOTE: if you figure out the typing here, go for it
  searchResults: MonitoringSearchResult[] | undefined
  searchTime?: Date
  canDownloadResults: boolean
}

export default function MonitoringSearchResultsSection({
  searchResults,
  searchParams,
  searchTime,
  canDownloadResults,
}: Props) {
  const { t } = useTranslation('common')

  const hasSearchResults = !!searchResults && searchResults.length > 0

  return (
    <Box component="section" sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      {searchParams && (
        <MonitoringSearchParams
          searchParams={searchParams}
          resultCount={searchResults?.length ?? 0}
          searchTime={searchTime}
          data-qa="searchInfo"
        />
      )}
      {hasSearchResults && (
        <>
          <MonitoringSearchResults searchResults={searchResults} />
          {canDownloadResults && <MonitoringSearchDownload searchResults={searchResults} />}
        </>
      )}
      {!hasSearchResults && (
        <Box data-qa="no-search-results">
          <Typography variant="h3">{t('monitoring.noSearchResultsTitle')}</Typography>
          <Typography variant="body1">{t('monitoring.noSearchResults')}</Typography>
        </Box>
      )}
    </Box>
  )
}
