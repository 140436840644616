import * as yup from 'yup'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Controller, Message, useForm } from 'react-hook-form'
import { getCurrentLocale, useDate } from '../../hooks/useDate'
import { SnackBar, SnackBarTypes } from '../SnackBar'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { FullRegistrationDto } from '../../generated/animare-management-api'
import { ErrorDto } from '../../generated/openapi'
import { useRegistration } from '../../hooks/useRegistration'
import DatePicker from '../DatePicker'
import InlineValidationError from './InlineValidationError'
import InputArrivalDate, { arrivalDateSchema } from './InputArrivalDate'

interface Props {
  onCancel: () => void
  onSubmit: (data: any) => void
  registration: FullRegistrationDto
  errorCode?: ErrorDto
}

const MICROCHIP_SETTER_ADDRESS_MAX = 80
const MICROCHIP_SETTER_NAME_MAX = 80
const MICROCHIP_LENGTH_MIN = 15

type NewMicrochipFormErrorMessages = {
  generic: string
  date: { wrongFormat: Message; max: Message; min: Message }
  microchip: { notEnough: Message; wrongFormat: Message; maxAddress: Message }
  name: { wrongFormat: Message; maxLength: Message }
}

export default function NewMicrochipForm({ onCancel, onSubmit, registration, errorCode }: Props) {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()
  const [showNotification, setShowNotification] = useState<SnackBarTypes>()

  const { getMinMicrochipDate, getRegistrationMaxDate } = useRegistration()
  const MIN_DATE = getMinMicrochipDate(registration)
  const MAX_DATE = getRegistrationMaxDate()

  const errorMessages: NewMicrochipFormErrorMessages = {
    generic: t('errors.validation.required') as Message,
    date: {
      wrongFormat: t('errors.validation.wrongFormat') as Message,
      max: t('errors.validation.maxDate') as Message,
      min: t('errors.validation.afterBirth'),
    },
    microchip: {
      notEnough: t('errors.validation.lengthRequirement', {
        number: MICROCHIP_LENGTH_MIN,
      }) as Message,
      wrongFormat: t('errors.validation.wrongFormat') as Message,
      maxAddress: t('errors.validation.maxLength', {
        number: MICROCHIP_SETTER_ADDRESS_MAX,
      }) as Message,
    },
    name: {
      wrongFormat: t('errors.validation.wrongFormat') as Message,
      maxLength: t('errors.validation.maxLength', { number: MICROCHIP_SETTER_NAME_MAX }) as Message,
    },
  }

  // Allowed characters for microchipNumber
  const microchipNumberRegex = /^[0-9]+$/
  // Allowed characters for text fields
  const textRegex = /^[\u0020-\u007e|\u00c0-\u017F]+$/

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),

    microchipNumber: yup
      .string()
      .min(15, errorMessages.microchip.notEnough)
      .max(15)
      .matches(microchipNumberRegex, errorMessages.microchip.wrongFormat)
      .required(errorMessages.generic),

    microchipDate: yup
      .date()
      .typeError(errorMessages.date.wrongFormat)
      .required(errorMessages.generic)
      .transform((value) => toUTCDate(value))
      .min(MIN_DATE, errorMessages.date.min)
      .max(MAX_DATE, errorMessages.date.max),

    microchipSetterName: yup
      .string()
      .required(errorMessages.generic)
      .max(MICROCHIP_SETTER_NAME_MAX, errorMessages.name.maxLength)
      .matches(textRegex, {
        message: errorMessages.name.wrongFormat,
        excludeEmptyString: true,
      }),

    microchipSetterAddress: yup
      .string()
      .required(errorMessages.generic)
      .max(MICROCHIP_SETTER_ADDRESS_MAX, errorMessages.microchip.maxAddress)
      .matches(textRegex, {
        message: errorMessages.name.wrongFormat,
        excludeEmptyString: true,
      }),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(schema) })

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <SnackBar
        type={SnackBarTypes.ERROR}
        handleClose={() => {
          setShowNotification(undefined)
        }}
        errorCode={errorCode}
        open={showNotification === SnackBarTypes.ERROR}
      />
      <Typography variant="h2" sx={{ mb: 2 }}>
        {t('renewMicrochip.title')}
      </Typography>
      <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>
        {t('registrationForm.field.arrivalDate.title')}
      </Typography>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '100%' },
          gap: 4,
          mt: 2,
          width: '100%',
        }}
      >
        <FormControl>
          <InputArrivalDate control={control} errors={errors} />
        </FormControl>
      </FormGroup>

      <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>
        {t('renewMicrochip.newMicrochipTitle')}
      </Typography>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '100%' },
          gap: 4,
          mt: 2,
          width: '100%',
        }}
      >
        <FormControl>
          <InputLabel htmlFor="microchipNumber">
            {t('common.animal.microchipCode')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('register.requiredField')}</i>
          </InputLabel>
          <TextField
            {...register('microchipNumber')}
            id={'microchipNumber'}
            error={!!errors.microchipNumber}
            data-qa="microchipNumber"
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: 15,
            }}
          />
          {errors.microchipNumber && (
            <InlineValidationError content={errors.microchipNumber?.message} />
          )}
        </FormControl>

        <Box />

        <FormControl>
          <Controller
            name="microchipDate"
            control={control}
            render={() => (
              <DatePicker
                control={control}
                name={'microchipDate'}
                label={t('common.animal.microchipDate')}
                error={!!errors.microchipDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                minDate={MIN_DATE}
                maxDate={MAX_DATE}
                placeholder={t('common.dateFormat') as string}
                required={true}
              />
            )}
          />
          {errors.microchipDate && (
            <InlineValidationError
              content={errors.microchipDate?.message}
              data-qa="microchipDate-error"
            />
          )}
        </FormControl>

        <FormControl>
          <InputLabel htmlFor="microchipSetterName">
            {t('common.animal.microchipSetterName')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('register.requiredField')}</i>
          </InputLabel>
          <TextField
            {...register('microchipSetterName')}
            id={'microchipSetterName'}
            error={!!errors.microchipSetterName}
            data-qa="microchipSetterName"
            inputProps={{
              maxLength: MICROCHIP_SETTER_NAME_MAX,
            }}
          />
          {errors.microchipSetterName && (
            <InlineValidationError content={errors.microchipSetterName?.message} />
          )}
        </FormControl>

        <FormControl sx={{ gridColumn: { sm: '1 / span 2' } }}>
          <InputLabel htmlFor="microchipSetterAddress">
            {t('common.animal.microchipSetterAddress')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('register.requiredField')}</i>
          </InputLabel>
          <TextField
            {...register('microchipSetterAddress')}
            id={'microchipSetterAddress'}
            error={!!errors.microchipSetterAddress}
            data-qa="microchipSetterAddress"
            inputProps={{
              maxLength: MICROCHIP_SETTER_ADDRESS_MAX,
            }}
          />
          {errors.microchipSetterAddress && (
            <InlineValidationError content={errors.microchipSetterAddress?.message} />
          )}
        </FormControl>
      </FormGroup>
      <Box
        sx={{
          gridColumn: { sm: '1 / span 2', xs: '1' },
          display: 'flex',
          justifyContent: 'end',
          gap: 2,
          mt: 2,
        }}
      >
        <Button variant="outlined" onClick={onCancel} data-qa="cancel-change">
          {t('common.cancel')}
        </Button>
        <Button variant="contained" type="submit" disabled={isSubmitting} data-qa="submit-change">
          {t('common.save')}
        </Button>
      </Box>
    </Box>
  )
}
