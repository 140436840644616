import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { ErrorDto, NewMicrochipRequestDto } from '../generated/animare-management-api/models'

import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BackButton from '../components/BackButton'
import { useRegistration } from '../hooks/useRegistration'
import NewMicrochipForm from './Form/NewMicrochipForm'
import LoadingSpinner from './LoadingSpinner'
import { MicrochipHistory } from './MicrochipHistory'
import { SnackBar, SnackBarTypes } from './SnackBar'

export interface Params {
  registrationUid: string
}

export default function ManageMicrochips({ registrationUid }: Params) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  // Mutations and queries
  const { getRegistration, updateMicrochip } = useRegistration()
  const queryClient = useQueryClient()
  const { mutate: updateMicrochipMutation } = updateMicrochip({
    onSuccess: () => {
      setShowNotification(SnackBarTypes.SUCCESS)
      navigate('..')
    },
    onError: (error: ErrorDto) => {
      if (error.code) {
        setApiError(error)
        setShowNotification(SnackBarTypes.ERROR)
      }
      console.error(error)
    },
    queryClient,
  })

  const { isPending, data: animalData } = getRegistration({
    uid: registrationUid,
    enabled: !!registrationUid,
  })

  // Errors
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const onCancel = () => {
    navigate('..')
  }

  const onSubmit = async (form: NewMicrochipRequestDto) => {
    setApiError(undefined)
    updateMicrochipMutation({ uid: `${registrationUid}`, form: form })
  }

  if (isPending) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      <BackButton />
      <SnackBar
        open={showNotification === SnackBarTypes.SUCCESS}
        innerText={`${t('common.save-successful')}`}
        handleClose={() => setShowNotification(undefined)}
        type={SnackBarTypes.SUCCESS}
      />
      <SnackBar
        open={showNotification === SnackBarTypes.ERROR}
        type={SnackBarTypes.ERROR}
        errorCode={apiError}
        handleClose={() => {
          setShowNotification(undefined)
          return setApiError(undefined)
        }}
      />
      <Paper component="section" sx={{ p: { sm: 8, xs: 2 }, pb: { xs: 4 } }}>
        <Typography variant="h1" sx={{ mb: 2 }}>
          {t('dogView.manageMicrochipCodes')}
        </Typography>

        <Divider sx={{ mt: 4, mb: 4 }} />

        {animalData && (
          <NewMicrochipForm
            onCancel={onCancel}
            onSubmit={onSubmit}
            registration={animalData}
            errorCode={apiError}
          />
        )}

        <Divider sx={{ mt: 6, mb: 4 }} />

        <Typography variant="h2" sx={{ mb: 2 }}>
          {t('common.animal.name')}
        </Typography>
        <Typography>{animalData?.animal?.name}</Typography>

        <Divider sx={{ mt: 6, mb: 4 }} />

        <Typography variant="h2">{t('common.animal.microchipHistory')}</Typography>

        <Box sx={{ overflow: 'auto' }}>
          {animalData?.animal?.microchips && (
            <MicrochipHistory
              microchipData={animalData.animal?.microchips.map((microchip) => ({
                uid: microchip.uid,
                name: microchip?.microchipSetterName,
                date: microchip?.microchipDate,
                number: microchip?.microchipNumber,
                address: microchip?.microchipSetterAddress,
              }))}
              showDelete={true}
            />
          )}
        </Box>
      </Paper>
    </Container>
  )
}
