import {
  AuthenticateRequest,
  AuthRestControllerApi,
  Configuration,
  KeyValueDto,
  Middleware,
  OpenGateRequestDto,
  OpenGateResponseDto,
  querystring,
} from '../generated/openapi'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'

const AUTO_REDIRECT_TO_LOGOUT = false

export function useAuthApi() {
  const api = new AuthRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware(AUTO_REDIRECT_TO_LOGOUT)] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  const logoutUser = () => {
    return api.logout({ userId: 'empty' })
  }

  const virtuLogin = async (queryParams: URLSearchParams) => {
    // Redirect url from Virtu includes parameters with A_ prefix, we need to remove the prefix and send those to /authenticate endpoint
    const data: KeyValueDto[] = Array.from(queryParams.keys())
      .filter((key) => key.startsWith('A_'))
      .map((key: string) => {
        return {
          key: key.substring(2),
          value: queryParams.get(key),
        } as KeyValueDto
      })
    if (!data) {
      throw new Error('No acceptable parameters')
    }
    const request: AuthenticateRequest = {
      openGateRequestDto: {
        data,
        answers: [],
      } as OpenGateRequestDto,
    }

    return api.authenticate(request)
  }

  const init = (language: string): Promise<OpenGateResponseDto> => {
    // NOTE: language format should be 'fi', 'sv', 'en'
    return api.init({ language: language })
  }

  const authenticate = (request: OpenGateRequestDto): Promise<OpenGateResponseDto> => {
    return api.authenticate({ openGateRequestDto: request })
  }

  return {
    logoutUser,
    virtuLogin,
    init,
    authenticate,
  }
}
