import { ArrowForwardSharp, Close, ExpandMoreSharp } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimareApiKeys } from '../../data/apiKeys'
import { FullRegistrationDto, MicrochipDto } from '../../generated/animare-management-api'
import { useAnimalStatus } from '../../hooks/useAnimalStatus'
import { useRegistration } from '../../hooks/useRegistration'
import ConfirmDialog from '../ConfirmDialog'
import { MicrochipNumberChangeForm } from '../Form/MicrochipNumberChangeForm'
import DogCancelDeathDialog from '../DogCancelDeathDialog'
import DogCancelPossessorChangeDialog from '../DogCancelPossessorChangeDialog'

export interface Props {
  dogData: FullRegistrationDto
  onSuccess: (response?: FullRegistrationDto) => void
  onError: (error: any) => void
}

export default function CorrectInformation({ dogData, onSuccess, onError }: Props) {
  const [cancelPossessorChangeModalOpen, setCancelPossessorChangeModalOpen] =
    useState<boolean>(false)
  const [changeMicrochipNumberModalOpen, setChangeMicrochipNumberModalOpen] =
    useState<boolean>(false)
  const [cancelDeathModalOpen, setCancelDeathModalOpen] = useState<boolean>(false)

  const { t } = useTranslation('common')

  const { cancelPossessorChange } = useRegistration()
  const { setDogAlive } = useAnimalStatus()
  const queryClient = useQueryClient()

  const { mutate: cancelDogDeathMutation } = setDogAlive({
    onSuccess: () => {
      onSuccess()
    },
    onError: onError,
    onSettled: () => {
      setCancelDeathModalOpen(false)
    },
    queryClient,
  })

  const { mutate: cancelPossessorChangeMutation } = cancelPossessorChange({
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
      onSuccess()
      setCancelPossessorChangeModalOpen(false)
    },
    onError: onError,
    queryClient,
  })

  const doCancelPossessorChange = (arrivalDate: Date) => {
    if (!dogData.uid) {
      return
    }
    cancelPossessorChangeMutation({ uid: dogData.uid, arrivalDate: arrivalDate })
  }

  const doChangeMicrochipNumber = () => {
    setChangeMicrochipNumberModalOpen(false)
    onSuccess()
  }

  const doCancelDeath = (arrivalDate: Date) => {
    if (!dogData.uid) {
      return
    }
    cancelDogDeathMutation({ registrationUid: dogData.uid, arrivalDate: arrivalDate })
  }

  return (
    <>
      <DogCancelPossessorChangeDialog
        open={!!cancelPossessorChangeModalOpen}
        handleClose={() => setCancelPossessorChangeModalOpen(false)}
        handleAction={doCancelPossessorChange}
      />
      <Dialog
        onClose={() => setChangeMicrochipNumberModalOpen(false)}
        open={changeMicrochipNumberModalOpen}
        aria-labelledby="dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="dialog-title" data-qa="changeMicrochipNumberModalTitle">
          {t('dogView.dialogChangeMicrochipCode.title')}
          <IconButton
            aria-label={t('dogView.dialogChangeMicrochipCode.cancel') as string}
            aria-hidden={true}
            onClick={() => setChangeMicrochipNumberModalOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            data-qa="changeMicrochipNumberModalCross"
          >
            <Close color="primary" />
          </IconButton>
        </DialogTitle>
        <DialogContent data-qa="changeMicrochipNumberModalContent">
          <MicrochipNumberChangeForm
            microchip={dogData?.microchip as MicrochipDto}
            onSuccess={doChangeMicrochipNumber}
            onCancel={() => setChangeMicrochipNumberModalOpen(false)}
          />
        </DialogContent>
      </Dialog>
      <DogCancelDeathDialog
        open={!!cancelDeathModalOpen}
        handleClose={() => setCancelDeathModalOpen(false)}
        handleAction={doCancelDeath}
      />
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreSharp />} id="correctInformation-summary">
          <Typography variant="h2">{t('dogView.correctInformation')}</Typography>
        </AccordionSummary>
        <AccordionDetails id="correctInformation-details">
          <Box sx={{ my: 1 }}>
            <Button
              variant="text"
              onClick={() => setCancelPossessorChangeModalOpen(true)}
              endIcon={<ArrowForwardSharp />}
              data-qa="cancelPossessorChange"
              disabled={!dogData?.changeCode || dogData.animal.status?.health === 'dead'}
            >
              {t('dogView.cancelPossessorChange')}
            </Button>
          </Box>

          <Box sx={{ my: 1 }}>
            <Button
              variant="text"
              onClick={() => setChangeMicrochipNumberModalOpen(true)}
              endIcon={<ArrowForwardSharp />}
              disabled={!!dogData?.changeCode || dogData.animal.status?.health === 'dead'}
              data-qa="changeMicrochipCode"
            >
              {t('dogView.dialogChangeMicrochipCode.title')}
            </Button>
          </Box>

          <Box sx={{ my: 1 }}>
            <Button
              variant="text"
              onClick={() => setCancelDeathModalOpen(true)}
              endIcon={<ArrowForwardSharp />}
              disabled={dogData.animal.status?.health !== 'dead'}
              data-qa="cancelDeath"
            >
              {t('dogView.cancelDeath')}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
