/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Request for fetching a list of animals.
 * @export
 * @interface PossessorRequestDto
 */
export interface PossessorRequestDto {
  /**
   * What kind of possessor are we searching (person/organization).
   * @type {string}
   * @memberof PossessorRequestDto
   */
  possessorType: PossessorRequestDtoPossessorTypeEnum
  /**
   * The national identification number of the possessor if it is a person.
   * @type {string}
   * @memberof PossessorRequestDto
   */
  personIdentifier?: string
  /**
   * The business id of the possessor if it is an organization.
   * @type {string}
   * @memberof PossessorRequestDto
   */
  organizationIdentifier?: string
  /**
   * Should the result contain past registrations or only current ones (default).
   * @type {boolean}
   * @memberof PossessorRequestDto
   */
  fetchPastRegistrations?: boolean
}

/**
 * @export
 */
export const PossessorRequestDtoPossessorTypeEnum = {
  Person: 'person',
  Organization: 'organization',
} as const
export type PossessorRequestDtoPossessorTypeEnum =
  (typeof PossessorRequestDtoPossessorTypeEnum)[keyof typeof PossessorRequestDtoPossessorTypeEnum]

/**
 * Check if a given object implements the PossessorRequestDto interface.
 */
export function instanceOfPossessorRequestDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'possessorType' in value

  return isInstance
}

export function PossessorRequestDtoFromJSON(json: any): PossessorRequestDto {
  return PossessorRequestDtoFromJSONTyped(json, false)
}

export function PossessorRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PossessorRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    possessorType: json['possessorType'],
    personIdentifier: !exists(json, 'personIdentifier') ? undefined : json['personIdentifier'],
    organizationIdentifier: !exists(json, 'organizationIdentifier')
      ? undefined
      : json['organizationIdentifier'],
    fetchPastRegistrations: !exists(json, 'fetchPastRegistrations')
      ? undefined
      : json['fetchPastRegistrations'],
  }
}

export function PossessorRequestDtoToJSON(value?: PossessorRequestDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    possessorType: value.possessorType,
    personIdentifier: value.personIdentifier,
    organizationIdentifier: value.organizationIdentifier,
    fetchPastRegistrations: value.fetchPastRegistrations,
  }
}
