/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { NewAnimalStatusDto } from './NewAnimalStatusDto'
import {
  NewAnimalStatusDtoFromJSON,
  NewAnimalStatusDtoFromJSONTyped,
  NewAnimalStatusDtoToJSON,
} from './NewAnimalStatusDto'

/**
 * What status should be updated for the current registration's animal.
 * @export
 * @interface UpdateAnimalStatusDto
 */
export interface UpdateAnimalStatusDto {
  /**
   * When the statuschange form has arrived (only for paper registration).
   * @type {Date}
   * @memberof UpdateAnimalStatusDto
   */
  arrivalDate: Date
  /**
   * What status type should be changed?
   * @type {string}
   * @memberof UpdateAnimalStatusDto
   */
  statusType: UpdateAnimalStatusDtoStatusTypeEnum
  /**
   *
   * @type {NewAnimalStatusDto}
   * @memberof UpdateAnimalStatusDto
   */
  newStatus: NewAnimalStatusDto
  /**
   * Date of the status change. *Currently only date of death is supported*; only to be set if `statusType = health` and `newStatus = dead`.
   * @type {Date}
   * @memberof UpdateAnimalStatusDto
   */
  date: Date
}

/**
 * @export
 */
export const UpdateAnimalStatusDtoStatusTypeEnum = {
  Health: 'health',
  Country: 'country',
  Location: 'location',
} as const
export type UpdateAnimalStatusDtoStatusTypeEnum =
  (typeof UpdateAnimalStatusDtoStatusTypeEnum)[keyof typeof UpdateAnimalStatusDtoStatusTypeEnum]

/**
 * Check if a given object implements the UpdateAnimalStatusDto interface.
 */
export function instanceOfUpdateAnimalStatusDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value
  isInstance = isInstance && 'statusType' in value
  isInstance = isInstance && 'newStatus' in value
  isInstance = isInstance && 'date' in value

  return isInstance
}

export function UpdateAnimalStatusDtoFromJSON(json: any): UpdateAnimalStatusDto {
  return UpdateAnimalStatusDtoFromJSONTyped(json, false)
}

export function UpdateAnimalStatusDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): UpdateAnimalStatusDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    arrivalDate: new Date(json['arrivalDate']),
    statusType: json['statusType'],
    newStatus: NewAnimalStatusDtoFromJSON(json['newStatus']),
    date: new Date(json['date']),
  }
}

export function UpdateAnimalStatusDtoToJSON(value?: UpdateAnimalStatusDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
    statusType: value.statusType,
    newStatus: NewAnimalStatusDtoToJSON(value.newStatus),
    date: value.date.toISOString().substr(0, 10),
  }
}
