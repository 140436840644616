import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../..'
import ChangePossessor from '../../../../components/ChangePossessor'

const ChangePossessorView = () => {
  const navigate = useNavigate()
  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }
  return <ChangePossessor registrationUid={registrationUid} />
}

export default ChangePossessorView
