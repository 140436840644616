/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Search result object for monitoring
 * @export
 * @interface MonitoringSearchResult
 */
export interface MonitoringSearchResult {
  /**
   *
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  possessorUid?: string
  /**
   * First name of the possessor.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  firstName?: string
  /**
   * Last name of the possessor.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  lastName?: string
  /**
   * Name of the organization, if an Organization.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  organizationName?: string
  /**
   *
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  possessorType?: MonitoringSearchResultPossessorTypeEnum
  /**
   * Street address.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  address?: string
  /**
   * Postal code.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  postalCode?: string
  /**
   * Municipality code.
   * @type {string}
   * @memberof MonitoringSearchResult
   */
  municipalityCode?: string
  /**
   * Animal count.
   * @type {number}
   * @memberof MonitoringSearchResult
   */
  animalCount?: number
}

/**
 * @export
 */
export const MonitoringSearchResultPossessorTypeEnum = {
  Organization: 'organization',
  Person: 'person',
} as const
export type MonitoringSearchResultPossessorTypeEnum =
  (typeof MonitoringSearchResultPossessorTypeEnum)[keyof typeof MonitoringSearchResultPossessorTypeEnum]

/**
 * Check if a given object implements the MonitoringSearchResult interface.
 */
export function instanceOfMonitoringSearchResult(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function MonitoringSearchResultFromJSON(json: any): MonitoringSearchResult {
  return MonitoringSearchResultFromJSONTyped(json, false)
}

export function MonitoringSearchResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): MonitoringSearchResult {
  if (json === undefined || json === null) {
    return json
  }
  return {
    possessorUid: !exists(json, 'possessorUid') ? undefined : json['possessorUid'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    possessorType: !exists(json, 'possessorType') ? undefined : json['possessorType'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postalCode: !exists(json, 'postalCode') ? undefined : json['postalCode'],
    municipalityCode: !exists(json, 'municipalityCode') ? undefined : json['municipalityCode'],
    animalCount: !exists(json, 'animalCount') ? undefined : json['animalCount'],
  }
}

export function MonitoringSearchResultToJSON(value?: MonitoringSearchResult | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    possessorUid: value.possessorUid,
    firstName: value.firstName,
    lastName: value.lastName,
    organizationName: value.organizationName,
    possessorType: value.possessorType,
    address: value.address,
    postalCode: value.postalCode,
    municipalityCode: value.municipalityCode,
    animalCount: value.animalCount,
  }
}
