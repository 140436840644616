import { ArrowForwardSharp } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../..'
import SpacerBox from '../../../components/SpacerBox'
import TitleWithUnderline from '../../../components/TitleWithUnderline'
import { ShowFor, useAuthContext } from '../../../context/AuthProvider'
import { Roles } from '../../../data/roles'
import { ShelterHome } from '../shelter/ShelterHome'
import PermissionList from './PermissionList'

const DashboardHome = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { currentUser } = useAuthContext()

  return (
    <>
      <SpacerBox>
        <ShowFor roles={[Roles.Shelter]}>
          <ShelterHome />
        </ShowFor>

        <ShowFor
          roles={[
            Roles.Admin,
            Roles.ServiceDesk,
            Roles.NarrowViewer,
            Roles.WideViewer,
            Roles.Editor,
          ]}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ mb: 2 }}>
              <TitleWithUnderline>{t('homePage.title')}</TitleWithUnderline>
            </Box>
            <Button
              variant="contained"
              type="submit"
              onClick={() => navigate(AppRoutes.Search)}
              aria-label={t('homePage.search.button') as string}
              endIcon={<ArrowForwardSharp />}
              sx={{ minWidth: '192px' }}
              data-qa="search-button"
            >
              {t('homePage.search.button')}
            </Button>
            <ShowFor roles={[Roles.Admin]}>
              <Button
                data-qa="secrets-button"
                variant="outlined"
                type="submit"
                onClick={() => navigate(AppRoutes.SecretsView)}
                aria-label={`${t('admin.secrets.submit')}`}
                endIcon={<ArrowForwardSharp />}
                sx={{ mt: 2, minWidth: '192px' }}
              >
                {t('admin.secrets.submit')}
              </Button>
            </ShowFor>
            <ShowFor roles={[Roles.Admin, Roles.Editor, Roles.WideViewer]}>
              <Button
                data-qa="monitoring-button"
                variant="outlined"
                type="submit"
                onClick={() => navigate(AppRoutes.Monitoring_Search)}
                aria-label={`${t('monitoring.searchButton')}`}
                endIcon={<ArrowForwardSharp />}
                sx={{ mt: 2, minWidth: '192px' }}
              >
                {t('monitoring.searchButton')}
              </Button>
            </ShowFor>
          </Box>
        </ShowFor>
        <PermissionList roles={currentUser?.roles ?? []}></PermissionList>
      </SpacerBox>
    </>
  )
}

export default DashboardHome
