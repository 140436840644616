/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type {
  ErrorDto,
  ModifyPossessorRequestDto,
  PossessorByUidResponseDto,
  PossessorDto,
  PossessorRequestDto,
  PossessorResponseDto,
} from '../models'
import {
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  ModifyPossessorRequestDtoFromJSON,
  ModifyPossessorRequestDtoToJSON,
  PossessorByUidResponseDtoFromJSON,
  PossessorByUidResponseDtoToJSON,
  PossessorDtoFromJSON,
  PossessorDtoToJSON,
  PossessorRequestDtoFromJSON,
  PossessorRequestDtoToJSON,
  PossessorResponseDtoFromJSON,
  PossessorResponseDtoToJSON,
} from '../models'

export interface CreateOrModifyPossessorRequest {
  modifyPossessorRequestDto?: ModifyPossessorRequestDto
}

export interface GetPossessorRequest {
  possessorRequestDto?: PossessorRequestDto
}

export interface GetPossessorByUidRequest {
  uid: string
  fmt?: GetPossessorByUidFmtEnum
}

export interface GetPossessorNameByUidRequest {
  uid: string
}

/**
 *
 */
export class PossessorRestControllerApi extends runtime.BaseAPI {
  /**
   */
  async createOrModifyPossessorRaw(
    requestParameters: CreateOrModifyPossessorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PossessorDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/possessor`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ModifyPossessorRequestDtoToJSON(requestParameters.modifyPossessorRequestDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => PossessorDtoFromJSON(jsonValue))
  }

  /**
   */
  async createOrModifyPossessor(
    requestParameters: CreateOrModifyPossessorRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PossessorDto> {
    const response = await this.createOrModifyPossessorRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async getPossessorRaw(
    requestParameters: GetPossessorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PossessorResponseDto>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/possessor`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PossessorRequestDtoToJSON(requestParameters.possessorRequestDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PossessorResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   */
  async getPossessor(
    requestParameters: GetPossessorRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PossessorResponseDto> {
    const response = await this.getPossessorRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns PossessorDto with full details.
   */
  async getPossessorByUidRaw(
    requestParameters: GetPossessorByUidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PossessorByUidResponseDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling getPossessorByUid.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.fmt !== undefined) {
      queryParameters['fmt'] = requestParameters.fmt
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/possessor/{uid}`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PossessorByUidResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Returns PossessorDto with full details.
   */
  async getPossessorByUid(
    requestParameters: GetPossessorByUidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PossessorByUidResponseDto> {
    const response = await this.getPossessorByUidRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Returns PossessorDto only with name (firstname & lastname).
   */
  async getPossessorNameByUidRaw(
    requestParameters: GetPossessorNameByUidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PossessorDto>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling getPossessorNameByUid.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/possessor/{uid}/name`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => PossessorDtoFromJSON(jsonValue))
  }

  /**
   * Returns PossessorDto only with name (firstname & lastname).
   */
  async getPossessorNameByUid(
    requestParameters: GetPossessorNameByUidRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PossessorDto> {
    const response = await this.getPossessorNameByUidRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetPossessorByUidFmtEnum = {
  Form: 'form',
  Normal: 'normal',
} as const
export type GetPossessorByUidFmtEnum =
  (typeof GetPossessorByUidFmtEnum)[keyof typeof GetPossessorByUidFmtEnum]
