/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { ExternalLoginProvider } from './ExternalLoginProvider'
import {
  ExternalLoginProviderFromJSON,
  ExternalLoginProviderFromJSONTyped,
  ExternalLoginProviderToJSON,
} from './ExternalLoginProvider'
import type { KeyValueDto } from './KeyValueDto'
import { KeyValueDtoFromJSON, KeyValueDtoFromJSONTyped, KeyValueDtoToJSON } from './KeyValueDto'
import type { QuestionDto } from './QuestionDto'
import { QuestionDtoFromJSON, QuestionDtoFromJSONTyped, QuestionDtoToJSON } from './QuestionDto'

/**
 *
 * @export
 * @interface OpenGateResponseDto
 */
export interface OpenGateResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof OpenGateResponseDto
   */
  ready?: boolean
  /**
   *
   * @type {Array<KeyValueDto>}
   * @memberof OpenGateResponseDto
   */
  data?: Array<KeyValueDto>
  /**
   *
   * @type {Array<QuestionDto>}
   * @memberof OpenGateResponseDto
   */
  questions?: Array<QuestionDto>
  /**
   *
   * @type {Array<ExternalLoginProvider>}
   * @memberof OpenGateResponseDto
   */
  externalLogin?: Array<ExternalLoginProvider>
}

/**
 * Check if a given object implements the OpenGateResponseDto interface.
 */
export function instanceOfOpenGateResponseDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function OpenGateResponseDtoFromJSON(json: any): OpenGateResponseDto {
  return OpenGateResponseDtoFromJSONTyped(json, false)
}

export function OpenGateResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OpenGateResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    ready: !exists(json, 'ready') ? undefined : json['ready'],
    data: !exists(json, 'data') ? undefined : (json['data'] as Array<any>).map(KeyValueDtoFromJSON),
    questions: !exists(json, 'questions')
      ? undefined
      : (json['questions'] as Array<any>).map(QuestionDtoFromJSON),
    externalLogin: !exists(json, 'externalLogin')
      ? undefined
      : (json['externalLogin'] as Array<any>).map(ExternalLoginProviderFromJSON),
  }
}

export function OpenGateResponseDtoToJSON(value?: OpenGateResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    ready: value.ready,
    data: value.data === undefined ? undefined : (value.data as Array<any>).map(KeyValueDtoToJSON),
    questions:
      value.questions === undefined
        ? undefined
        : (value.questions as Array<any>).map(QuestionDtoToJSON),
    externalLogin:
      value.externalLogin === undefined
        ? undefined
        : (value.externalLogin as Array<any>).map(ExternalLoginProviderToJSON),
  }
}
