import { Box, Button, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OrganizationType, PossessorDto } from '../generated/animare-management-api'
import { usePossessor } from '../hooks/usePossessor'
import { Municipality } from './Codes/Municipality'
import Field from './Field'

interface Props {
  possessor: PossessorDto
  isShelterSearch?: boolean
}

export default function Possessor({ possessor, isShelterSearch }: Props) {
  const { t } = useTranslation('common')
  const { getOrganizationTypeName } = usePossessor()
  const [showPersonIdentifier, setShowPersonIdentifier] = useState(false)
  const [showOrganizationIdentifier, setShowOrganizationIdentifier] = useState(false)
  const [showSoleTraderIdentifier, setShowSoleTraderIdentifier] = useState(false)

  return (
    <Grid container data-qa="possessor-container" component="dl" spacing={4} sx={{ mb: 4 }}>
      {!isShelterSearch && possessor.personIdentifier && (
        <>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" component="dt" data-qa="identifier-title-typography">
              {t('possessorDetails.field.identifier')}
            </Typography>
            {!showPersonIdentifier && (
              <Box component="dd" m={0}>
                <Button variant="text" onClick={() => setShowPersonIdentifier(true)} sx={{ p: 0 }}>
                  {t('possessorDetails.field.showIdentifierBtn')}
                </Button>
              </Box>
            )}
            {showPersonIdentifier && possessor.personIdentifier && (
              <Typography variant="body1" component="dd" data-qa="identifier-value-typography">
                {possessor.personIdentifier}
              </Typography>
            )}
          </Grid>
          <Field
            title={t('possessorDetails.field.name')}
            text={possessor.firstName + ' ' + possessor.lastName}
            dataQa="person-identifier-name-field"
          />
        </>
      )}
      {possessor.organizationIdentifier && (
        <>
          <Grid item xs={12} md={12}>
            <Typography variant="body2" component="dt" data-qa="org-identifier-title-typography">
              {t('possessorDetails.field.businessIdentifier')}
            </Typography>
            {!showOrganizationIdentifier && (
              <Box component="dd" m={0}>
                <Button
                  variant="text"
                  onClick={() => setShowOrganizationIdentifier(true)}
                  sx={{ p: 0 }}
                  data-qa="show-org-identifier-button"
                >
                  {t('possessorDetails.field.showBusinessIdentifierBtn')}
                </Button>
              </Box>
            )}
            {showOrganizationIdentifier && possessor.organizationIdentifier && (
              <Typography variant="body1" component="dd" data-qa="org-identifier-value-typography">
                {possessor.organizationIdentifier}
              </Typography>
            )}
          </Grid>
          <Field
            title={t('possessorDetails.field.name')}
            text={possessor.organizationName}
            dataQa="org-name-field"
          />
          <Field
            title={t('possessorDetails.field.organizationType')}
            text={getOrganizationTypeName(possessor.organizationType)}
            dataQa="org-type-field"
          />
          {possessor.organizationType === OrganizationType.Company && possessor.soleTrader && (
            <>
              <Field
                title={t('common.organizationType.companyType')}
                text={t('common.organizationType.soletrader')}
                dataQa="org-company-type-field"
              />
              <Field
                title={t('organizationPossessorForm.form.soleTraderIdentifier')}
                text={
                  showSoleTraderIdentifier && possessor.soleTraderIdentifier
                    ? possessor.soleTraderIdentifier
                    : null
                }
                body={
                  !showSoleTraderIdentifier ? (
                    <Button
                      variant="text"
                      onClick={() => setShowSoleTraderIdentifier(true)}
                      sx={{ p: 0 }}
                      data-qa="show-soletrader-identifier-button"
                    >
                      {t('possessorDetails.field.showIdentifierBtn')}
                    </Button>
                  ) : null
                }
                dataQa="org-soletrader-identifier-field"
              />
            </>
          )}
        </>
      )}
      {isShelterSearch && possessor.personIdentifier && !possessor.organizationType && (
        <Field
          title={t('possessorDetails.field.name')}
          text={`${possessor.firstName} ${possessor.lastName}`}
          dataQa="person-identifier-name-field"
        />
      )}
      <Field
        title={t('possessorDetails.field.phoneNumber')}
        text={possessor.phone ? possessor.phone : t('common.not-known')}
        dataQa="phone-field"
      />
      <Field
        title={t('possessorDetails.field.email')}
        text={possessor.email ? possessor.email : t('common.not-known')}
        dataQa="email-field"
      />
      {!isShelterSearch && (
        <>
          <Field
            title={t('possessorDetails.field.address')}
            text={possessor.address ? possessor.address : t('common.not-known')}
            dataQa="address-field"
          />
          <Field
            title={t('possessorDetails.field.postalCode')}
            text={possessor.postalCode}
            dataQa="postal-code-field"
          />
        </>
      )}
      {possessor.municipalityCode && (
        <Field
          title={t('possessorDetails.field.municipality')}
          text={<Municipality code={possessor.municipalityCode} />}
          dataQa="municipality-field"
        />
      )}
    </Grid>
  )
}
