import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../'
import PossessorPage from '../../components/PossessorPage'

const PossessorView = () => {
  const navigate = useNavigate()
  const { possessorUid } = useParams()
  if (!possessorUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }

  return <PossessorPage possessorUid={possessorUid} />
}

export default PossessorView
