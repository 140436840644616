import { SearchTypes } from '../components/Monitoring/MonitoringSearchPage'
import {
  AnimalImportingSearchFormValues,
  AnimalProtectionSearchFormValues,
  FirstRegistrationsFormValues,
  PossessorChangeSearchFormValues,
  ProfessionalActivitySearchFormValues,
} from '../components/Monitoring/MonitoringSearchSchema'
import { MonitoringSearchResult } from '../generated/animare-management-api'

export interface MonitoringCacheContents {
  params?:
    | AnimalImportingSearchFormValues
    | AnimalProtectionSearchFormValues
    | ProfessionalActivitySearchFormValues
    | PossessorChangeSearchFormValues
    | FirstRegistrationsFormValues
  result?: MonitoringSearchResult[]
  searchTime?: Date
  searchType?: SearchTypes
}
export enum CacheKeys {
  Params = 'monitoringSearchParams',
  Result = 'monitoringSearchResult',
  SearchTime = 'monitoringSearchTime',
  SearchType = 'monitoringSearchType',
}

export const useMonitoringCache = () => {
  // JSON.stringify() creates ISO dates by defaults but cannot parse them back for whatever reason
  // https://stackoverflow.com/a/14509447
  // https://jsoneditoronline.org/indepth/parse/json-date-format/#deserialize-dates-based-on-data-contents
  const reviveDate = (key: string, value: string) => {
    // Matches strings like "2022-08-25T09:39:19.288Z"
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
    return typeof value === 'string' && isoDateRegex.test(value) ? new Date(value) : value
  }

  const get = (): MonitoringCacheContents => {
    const getParams = () => {
      const paramsFromStorage = sessionStorage.getItem(CacheKeys.Params)
      return paramsFromStorage ? JSON.parse(paramsFromStorage, reviveDate) : undefined
    }
    const getResult = () => {
      const resultFromStorage = sessionStorage.getItem(CacheKeys.Result)
      return resultFromStorage ? JSON.parse(resultFromStorage, reviveDate) : undefined
    }
    const getSearchTime = (): Date | undefined => {
      const timeFromStorage = sessionStorage.getItem(CacheKeys.SearchTime)
      return timeFromStorage ? new Date(timeFromStorage) : undefined
    }
    const getSearchType = (): SearchTypes | undefined => {
      const searchTypeFromStorage = sessionStorage.getItem(CacheKeys.SearchType)
      if (
        searchTypeFromStorage == null ||
        !(<any>Object).values(SearchTypes).includes(searchTypeFromStorage)
      ) {
        return undefined
      }
      return searchTypeFromStorage as SearchTypes
    }
    return {
      params: getParams(),
      result: getResult(),
      searchTime: getSearchTime(),
      searchType: getSearchType(),
    }
  }

  const set = ({
    params,
    result,
    searchTime,
    searchType,
  }: MonitoringCacheContents): MonitoringCacheContents => {
    params && sessionStorage.setItem(CacheKeys.Params, JSON.stringify(params))
    result && sessionStorage.setItem(CacheKeys.Result, JSON.stringify(result))
    searchTime && sessionStorage.setItem(CacheKeys.SearchTime, searchTime.toISOString())
    searchType && sessionStorage.setItem(CacheKeys.SearchType, searchType)
    return get()
  }

  const clear = (): MonitoringCacheContents => {
    sessionStorage.removeItem(CacheKeys.Params)
    sessionStorage.removeItem(CacheKeys.Result)
    sessionStorage.removeItem(CacheKeys.SearchTime)
    sessionStorage.removeItem(CacheKeys.SearchType)
    return get()
  }
  return { get, set, clear }
}
