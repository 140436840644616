import {
  Configuration,
  CreateOrModifyPossessorRequest,
  ErrorDto,
  GetPossessorByUidRequest,
  GetPossessorNameByUidRequest,
  Middleware,
  ModifyPossessorRequestDto,
  OrganizationType,
  PossessorByUidResponseDto,
  PossessorDto,
  PossessorDtoTypeEnum,
  PossessorResponseDto,
  PossessorRestControllerApi,
  querystring,
} from '../generated/animare-management-api'

import { QueryClient, UseMutationResult, useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AnimareApiKeys } from '../data/apiKeys'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'

export function usePossessor() {
  const { t } = useTranslation('common')

  const api = new PossessorRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   * Fetches possessor and past registrations (if needed).
   * @param possessorIdentifier
   * @param fetchPastRegistrations
   * @returns
   */
  const getPersonPossessor = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: {
    onSuccess: (response: PossessorResponseDto) => void
    onError: (error: ErrorDto) => void
    onSettled: () => void
    queryClient: QueryClient
  }): UseMutationResult<
    PossessorResponseDto,
    ErrorDto,
    { personIdentifier: string; fetchPastRegistrations: boolean },
    unknown
  > => {
    const getPersonPossessorMutation = useMutation({
      mutationFn: ({
        personIdentifier,
        fetchPastRegistrations,
      }: {
        personIdentifier: string
        fetchPastRegistrations: boolean
      }) =>
        api.getPossessor({
          possessorRequestDto: {
            possessorType: PossessorDtoTypeEnum.Person,
            personIdentifier,
            fetchPastRegistrations,
          },
        }),
      onSuccess: (response: PossessorResponseDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.POSSESSOR] })
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        onError(error)
        console.error(error)
      },
      onSettled: onSettled,
    })

    return getPersonPossessorMutation
  }

  const getOrganizationPossessor = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: {
    onSuccess: (response: PossessorResponseDto) => void
    onError: (error: ErrorDto, organizationIdentifier: string) => void
    onSettled: () => void
    queryClient: QueryClient
  }): UseMutationResult<
    PossessorResponseDto,
    ErrorDto,
    {
      organizationIdentifier: string
      fetchPastRegistrations: boolean
    },
    unknown
  > => {
    const getOrganizationPossessorMutation = useMutation({
      mutationFn: ({
        organizationIdentifier,
        fetchPastRegistrations,
      }: {
        organizationIdentifier: string
        fetchPastRegistrations: boolean
      }) =>
        api.getPossessor({
          possessorRequestDto: {
            possessorType: PossessorDtoTypeEnum.Organization,
            organizationIdentifier,
            fetchPastRegistrations,
          },
        }),
      onSuccess: (response) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.POSSESSOR] })
        onSuccess(response)
      },
      onError: (
        error: ErrorDto,
        queryVars: {
          organizationIdentifier: string
          fetchPastRegistrations: boolean
        },
      ) => {
        onError(error, queryVars.organizationIdentifier)

        console.error(error)
      },
      onSettled: onSettled,
    })

    return getOrganizationPossessorMutation
  }

  /**
   *
   * @param uid
   * @returns
   */
  const getPossessorName = (uid: string) => {
    return api.getPossessorNameByUid({ uid } as GetPossessorNameByUidRequest)
  }

  /**
   *
   * @param uid
   * @returns
   */
  const getPossessorByUid = ({
    uid,
    enabled = true,
  }: {
    uid: string
    fmt?: string
    enabled?: boolean
  }): { error: ErrorDto | null; data?: PossessorDto } => {
    const { data, error } = useQuery<PossessorDto, ErrorDto>({
      queryKey: [AnimareApiKeys.POSSESSOR, uid],
      queryFn: ({ queryKey }) =>
        api
          .getPossessorByUid({ uid: `${queryKey[1]}` } as GetPossessorByUidRequest)
          .then((result) => result as PossessorDto),
      enabled,
    })

    return { data, error }
  }

  /**
   *
   * @param uid
   * @returns
   */
  const getPossessorResponseByUid = ({
    uid,
    enabled = true,
  }: {
    uid: string
    fmt?: string
    enabled?: boolean
  }): { error: ErrorDto | null; data?: PossessorResponseDto } => {
    const { data, error } = useQuery<PossessorResponseDto, ErrorDto>({
      queryKey: [AnimareApiKeys.POSSESSOR, uid],
      queryFn: ({ queryKey }) =>
        api
          .getPossessorByUid({ uid: `${queryKey[1]}`, fmt: 'normal' } as GetPossessorByUidRequest)
          .then((result) => result as PossessorResponseDto),
      enabled,
    })

    return { data, error }
  }

  /**
   * Creates a new possessor.
   * @param possessorData
   * @returns
   */

  const createPossessor = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: PossessorDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<PossessorDto, ErrorDto, ModifyPossessorRequestDto, unknown> => {
    const createPossessorMutation = useMutation({
      mutationFn: (newPossessor: ModifyPossessorRequestDto) =>
        api.createOrModifyPossessor({
          modifyPossessorRequestDto: newPossessor,
        } as CreateOrModifyPossessorRequest),
      onSuccess: (res: PossessorDto) => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.POSSESSOR] })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })
    return createPossessorMutation
  }

  /**
   * Updates possessor details
   * @param possessorDto
   * @returns
   */

  const updatePossessor = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<PossessorDto, ErrorDto, ModifyPossessorRequestDto, unknown> => {
    const updatePossessorMutation = useMutation({
      mutationFn: (request: ModifyPossessorRequestDto) =>
        api.createOrModifyPossessor({
          modifyPossessorRequestDto: request,
        } as CreateOrModifyPossessorRequest),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.POSSESSOR] })
        onSuccess()
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return updatePossessorMutation
  }

  const getOrganizationTypeName = (organizationType: OrganizationType | undefined) => {
    if (organizationType === undefined) {
      return t('common.organizationType.unknown')
    }
    return t(`common.organizationType.${organizationType}`)
  }

  return {
    getPersonPossessor,
    getOrganizationPossessor,
    getPossessorByUid,
    getPossessorResponseByUid,
    getPossessorName,
    createPossessor,
    updatePossessor,
    getOrganizationTypeName,
  }
}
