import { FormControl, FormGroup, InputLabel, TextField, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'

import { useTranslation } from 'react-i18next'
import InlineValidationError from '../Form/InlineValidationError'
import MunicipalitySelect from '../Form/MunicipalitySelect'
import { ProfessionalActivitySearchFormValues } from './MonitoringSearchSchema'
import { SearchProps } from './SearchForm'

export default function ProfessionalActivitySearch({
  control,
  errors,
  register,
}: SearchProps<ProfessionalActivitySearchFormValues>) {
  const { t } = useTranslation('common')
  return (
    <>
      <FormGroup sx={{ gap: 2 }}>
        <Typography variant="h2">
          {' '}
          {t('monitoring.professionalActivity.monitoringArea')}{' '}
        </Typography>
        <FormControl>
          <Controller
            name="municipalityCode"
            control={control}
            render={({ field }) => (
              <MunicipalitySelect
                field={field}
                error={!!errors.municipalityCode}
                errorContent={errors.municipalityCode?.message || ''}
              />
            )}
          />
        </FormControl>
      </FormGroup>
      <FormGroup sx={{ gap: 1 }}>
        <Typography variant="h2">{t('monitoring.professionalActivity.dogAmountTitle')}</Typography>
        <Typography variant="body1" mb={2}>
          {t('monitoring.professionalActivity.dogAmountBody')}
        </Typography>
        <FormControl>
          <InputLabel htmlFor={'minimumRegisteredAnimals'}>
            {t('monitoring.professionalActivity.dogAmountLabel')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('registrationForm.field.required')}</i>
          </InputLabel>
          <TextField
            {...register('minimumRegisteredAnimals')}
            id="minimumRegisteredAnimals"
            error={!!errors.minimumRegisteredAnimals}
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: 3,
            }}
            autoComplete={'off'}
            data-qa="minimumRegisteredAnimals"
            disabled={false}
          />
          {errors.minimumRegisteredAnimals && (
            <InlineValidationError
              content={errors.minimumRegisteredAnimals.message}
              data-qa="form-minimumRegisteredAnimals"
            />
          )}
        </FormControl>
      </FormGroup>
    </>
  )
}
