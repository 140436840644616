import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'
import { Shadows } from '@mui/material/styles/shadows'
import { createBreakpoints } from '@mui/system'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'

// --------- Ruokavirasto colors ---------//
// Mustikka (blue), [Primary]
export const mustikka = '#004F71'
export const mustikkaLight = '#D0E6EF'
export const mustikkaDark = '#004462'
// Ruoho (green), [Secondary]
export const ruoho = '#C1D119'
export const ruohoLight = '#EAF292'
export const ruohoDark = '#9BA815'
// Hiili (dark grey), [Typography]
export const hiili = '#343841'
export const hiiliLight = '#F7F8FA'
// Harmaa tausta (light grey), [Background color for body]
export const taustaHarmaa = '#F7F8FA'
// Olki opaque (beige), [Header decor]
export const olki = 'rgba(206, 184, 136, 1)'
export const olkiLight = 'rgba(206, 184, 136, .3)'
// Vadelma (red), [Title decor]
export const vadelma = '#D0006F'
// Kuusi (green), [Success]
export const kuusi = '#26854A'
export const kuusiLight = '#E6FAED'
export const kuusiDark = '#0D5F2C'
// Siitepöly (yellow), [Warning]
export const siitepoly = '#F7CE3C'
export const siitepolyLight = '#FEFAE9'
export const siitepolyDarker = '#A15B00'
// Puolukka (red), [Error]
export const puolukka = '#D2132E'
export const puolukkaLight = '#FBE7EA'
export const puolukkaDark = '#A50E24'
// Vesi (blue), [Info]
export const vesi = '#2678B7'
export const vesiLight = '#E5F0F5'
export const vesiDark = '#135B92'
// Fur colors for selector
export const ruskeaTurkki = '#967038'
export const mustaTurkki = '#303030'
export const valkoinenTurkki = '#FFFEFA'
export const punainenTurkki = '#E06F4C'
export const harmaaTurkki = '#B9B5B2'
export const keltainenTurkki = '#FBE599'

// Add custom palette options
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      decor: React.CSSProperties['color']
      decorTitle: React.CSSProperties['color']
    }
  }
  interface Palette {
    decor: Palette['primary']
    decorTitle: Palette['primary']
  }
  interface PaletteOptions {
    decor: PaletteOptions['primary']
    decorTitle: PaletteOptions['primary']
  }
}

// --------- Ruokavirasto shadows ---------//
export const elevation0 = 'none'
export const elevation1 =
  '0px 3px 5px 0px rgba(0,0,0,0.02), 0px 0px 2px 0px rgba(0,0,0,0.05), 0px 1px 4px 0px rgba(0,0,0,0.08)'
export const elevation2 =
  '0px 4px 10px 0px rgba(0,0,0,0.03), 0px 0px 2px 0px rgba(0,0,0,0.06), 0px 2px 6px 0px rgba(0,0,0,0.12)'
export const elevation3 =
  '0px 1px 8px 0px rgba(0,0,0,0.08), 0px 3px 4px 0px rgba(0,0,0,0.1), 0px 1px 4px -1px rgba(0,0,0,0.1)'
export const elevation4 =
  '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)'
export const elevation5 =
  '0px 1px 7px 0px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2)'
export const elevation6 =
  '0px 3px 5px 0px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18)'
export const elevation7 =
  '0px 7px 30px 0px rgba(0, 0, 0, 0.15), 0px 22px 30px 0px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15)'
export const elevation8 =
  '0px 9px 46px 0px rgba(0, 0, 0, 0.12), 0px 24px 38px 0px rgba(0, 0, 0, 0.14), 0px 11px 15px 0px rgba(0, 0, 0, 0.2)'
const shadows = [
  elevation0,
  elevation1,
  elevation2,
  elevation3,
  elevation4,
  elevation5,
  elevation6,
  elevation7,
  elevation8,
  ...Array(16).fill(elevation0),
] as Shadows

// Breakpoints for theme
const breakpoints = createBreakpoints({})

const theme = createTheme({
  palette: {
    primary: {
      main: mustikka,
      light: mustikkaLight,
      dark: mustikkaDark,
    },
    secondary: {
      main: ruoho,
      light: ruohoLight,
      dark: ruohoDark,
    },
    decor: {
      main: olki,
      light: olkiLight,
    },
    decorTitle: {
      main: vadelma,
    },
    success: {
      main: kuusi,
      light: kuusiLight,
      dark: kuusiDark,
    },
    warning: {
      main: siitepoly,
      light: siitepolyLight,
      dark: siitepolyDarker,
    },
    error: {
      main: puolukka,
      light: puolukkaLight,
      dark: puolukkaDark,
    },
    info: {
      main: vesi,
      light: vesiLight,
      dark: vesiDark,
    },
    grey: {
      50: taustaHarmaa,
      100: '#F0F1F2',
      200: '#E6E7EB',
      300: '#D0D3D9',
      400: '#9AA0AD',
      500: '#6B7180',
      600: '#545966',
      700: '#3E424D',
      800: hiili,
      900: '#242830',
    },
    background: {
      default: taustaHarmaa,
    },
    text: {
      primary: hiili,
      secondary: hiiliLight,
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(', '),
    allVariants: {
      color: hiili,
      textTransform: 'none',
    },
    h1: {
      fontSize: '2.4rem',
      fontWeight: 700,
      lineHeight: 2,
    },
    h2: {
      fontSize: '1.8rem',
      fontWeight: 700,
      lineHeight: 1.5,
      [breakpoints.up('md')]: {
        lineHeight: 2,
      },
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: 1.5,
      [breakpoints.up('md')]: {
        lineHeight: 2,
      },
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 2,
      textTransform: 'uppercase',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  },
  shadows,
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 'none',
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: mustikka,
        },
        root: {
          padding: 0,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: '1rem',
          borderRadius: '8px',
        },
        outlinedError: {
          color: puolukkaDark,
          backgroundColor: puolukkaLight,
          borderColor: puolukkaDark,
          textTransform: 'none',
        },
        outlinedSuccess: {
          color: kuusiDark,
          backgroundColor: kuusiLight,
          borderColor: kuusiDark,
          textTransform: 'none',
        },
        outlinedInfo: {
          color: vesiDark,
          backgroundColor: vesiLight,
          borderColor: vesiDark,
          textTransform: 'none',
        },
        outlinedWarning: {
          color: siitepolyDarker,
          backgroundColor: siitepolyLight,
          borderColor: siitepolyDarker,
          textTransform: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: mustikka,
          color: hiiliLight,
          '& *': {
            // allVariants
            color: hiiliLight,
            borderColor: olkiLight,
          },
          border: 'none',
          borderBottom: '4px solid',
          borderColor: olki,
          padding: '0 3rem 0 3rem',
          [breakpoints.up('xl')]: {
            padding: '0 6rem 0 6rem',
          },
          [breakpoints.down('md')]: {
            padding: '0',
          },
          [breakpoints.down('sm')]: {
            padding: '0',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        noOptions: {
          color: grey[600],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          width: 'fit-content',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 0,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: grey[400],
          '&.Mui-checked': {
            color: mustikka,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          overflowWrap: 'break-word',
          [breakpoints.down('sm')]: {
            padding: 6,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: 20,
          [breakpoints.down('sm')]: {
            padding: 5,
            margin: 10,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 24,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: hiili,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.8rem',
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          color: hiili,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
        size: 'normal',
        sx: {
          fontSize: '1.3rem',
          fontWeight: 500,
          backgroundColor: 'white',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          lineHeight: '1.1',
          left: '-7px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontFamily: ['Roboto', 'sans-serif'].join(', '),
          color: hiili,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          display: 'list-item',
          listStyleType: 'disc',
          marginLeft: '2rem',
          paddingLeft: 0,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.Mui-disabled': {
            backgroundColor: grey[100],
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          border: `1px solid ${grey[300]}`,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: hiili,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          border: 'none',
          '& .MuiDataGrid-sortIcon': {
            color: `${mustikka} !important`,
          },
          '& .MuiDataGrid-menuIconButton': {
            color: `${mustikka}`,
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'white',
            fontWeight: 500,
          },
          '& .MuiDataGrid-row:nth-of-type(odd), .MuiDataGrid-row:nth-of-type(odd):hover': {
            backgroundColor: grey[50],
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-menuIcon': {
            width: 'auto',
            visibility: 'visible',
          },
          '& .MuiDataGrid-row.Mui-hovered': {
            backgroundColor: 'inherit',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '& .MuiTableSortLabel-icon': {
            color: `${mustikka} !important`,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '&:first-of-type > tr > th': {
            backgroundColor: 'white',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:nth-of-type(odd)': {
            backgroundColor: grey[50],
          },
          /* Values below are for tables with expandable child tables, e.g EventsTable */
          '&.normal-table-row-even': {
            backgroundColor: grey[50],
          },
          '&.normal-table-row-odd': {
            backgroundColor: 'white',
          },
          '&.normal-table-row-even + .expanded-table-row': {
            backgroundColor: grey[50],
            borderTop: '2px solid transparent' /* Override table row border inbetween */,
          },
          '&.normal-table-row-odd + .expanded-table-row': {
            backgroundColor: 'white',
            borderTop: '2px solid transparent' /* Override table row border inbetween */,
          },
          '&.expanded-table-child-row': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&:disabled': {
            backgroundColor: 'red',
          },
        },
      },
    },
  },
})

export default theme
