/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ExternalLoginProvider
 */
export interface ExternalLoginProvider {
  /**
   *
   * @type {string}
   * @memberof ExternalLoginProvider
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof ExternalLoginProvider
   */
  language?: ExternalLoginProviderLanguageEnum
  /**
   *
   * @type {string}
   * @memberof ExternalLoginProvider
   */
  text?: string
}

/**
 * @export
 */
export const ExternalLoginProviderLanguageEnum = {
  Fi: 'fi',
  Sv: 'sv',
  En: 'en',
} as const
export type ExternalLoginProviderLanguageEnum =
  (typeof ExternalLoginProviderLanguageEnum)[keyof typeof ExternalLoginProviderLanguageEnum]

/**
 * Check if a given object implements the ExternalLoginProvider interface.
 */
export function instanceOfExternalLoginProvider(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function ExternalLoginProviderFromJSON(json: any): ExternalLoginProvider {
  return ExternalLoginProviderFromJSONTyped(json, false)
}

export function ExternalLoginProviderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ExternalLoginProvider {
  if (json === undefined || json === null) {
    return json
  }
  return {
    url: !exists(json, 'url') ? undefined : json['url'],
    language: !exists(json, 'language') ? undefined : json['language'],
    text: !exists(json, 'text') ? undefined : json['text'],
  }
}

export function ExternalLoginProviderToJSON(value?: ExternalLoginProvider | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    url: value.url,
    language: value.language,
    text: value.text,
  }
}
