import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/globals.css'
import App from './App'
import { makeServer } from './mock/server'
import './i18n'

if (import.meta.env.DEV) {
  console.log(
    '%c 🐕 Development mode on, enjoy! 🐕',
    [
      'font-size: 18px',
      'font-family: monospace',
      'background: white',
      'display: inline-block',
      'color: black',
      'padding: 8px 19px',
      'border: 1px dashed;',
    ].join(';'),
  )

  console.log('Using env-variables: ', import.meta.env)
  // Mirage mock api for development
  if (import.meta.env.VITE_APP_MIRAGE_ENABLED === 'true') {
    console.log('<<< Using MirageJS Mock server as API >>>')
    makeServer()
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
