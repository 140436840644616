import {
  Box,
  Button,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { ServiceDeskRegistrationFormValues, useRegistration } from '../../hooks/useRegistration'
import { SnackBar, SnackBarTypes } from '../SnackBar'
import {
  MAX_ADDRESS_LENGTH,
  MAX_BREED_DESCRIPTION_LENGTH,
  MAX_MICROCHIP_NUMBER_LENGTH,
  MAX_NAME_LENGTH,
  getRegistrationFormSchema,
} from './registrationFormSchema'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { OTHER_BREED } from '../../data/breeds'
import { ErrorDto } from '../../generated/openapi'
import { getCurrentLocale } from '../../hooks/useDate'
import { ErrorCodes } from '../../utils/errorUtils'
import DatePicker from '../DatePicker'
import ScrollTo from '../ScrollTo'
import SpacerBox from '../SpacerBox'
import BreedSelect from './BreedSelect'
import ColorSelect from './ColorSelect'
import { ControlledTextField } from './ControlledTextField'
import CountrySelect from './CountrySelect'
import FormBreedSize from './FormBreedSize'
import InlineValidationError from './InlineValidationError'
import SexSelect from './SexSelect'
import InputArrivalDate from './InputArrivalDate'

// These keys are in the same order as they're shown on the form
export enum RegistrationFieldKeysEnum {
  ArrivalDate = 'arrivalDate',
  MicrochipNumber = 'microchipNumber',
  Name = 'name',
  KennelName = 'kennelName',
  BirthDate = 'birthDate',
  BirthCountryCode = 'birthCountryCode',
  BreedCode = 'breedCode',
  BreedSize = 'breedSize',
  BreedDescription = 'breedDescription',
  SexCode = 'sexCode',
  Colours = 'colours',
  MicrochipDate = 'microchipDate',
  MicrochipSetterName = 'microchipSetterName',
  MicrochipSetterAddress = 'microchipSetterAddress',
}

interface Props {
  defaultValues?: ServiceDeskRegistrationFormValues
  onSubmit: any
  submitButtonText: string
  errorCode?: ErrorDto
  isEdit?: boolean
}

function RegistrationForm({
  defaultValues,
  onSubmit,
  submitButtonText,
  errorCode,
  isEdit = false,
}: Props) {
  const { t } = useTranslation('common')

  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const { getRegistrationMinDate, getRegistrationMaxDate } = useRegistration()

  const MIN_DATE = getRegistrationMinDate()
  const MAX_DATE = getRegistrationMaxDate()

  const schema = getRegistrationFormSchema()

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const methods = useForm<ServiceDeskRegistrationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultValues,
      arrivalDate: undefined,
      isEdit,
    },
  })

  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = methods

  useEffect(() => {
    if (errorCode) {
      if (errorCode.code === ErrorCodes.DuplicateRegistration) {
        setError(
          'microchipNumber',
          { type: 'custom', message: t('errors.duplicate-microchip-code') as string },
          { shouldFocus: true },
        )
      } else {
        setApiError(errorCode)
        showErrorNotification()
        setScrollToElement('h1')
      }
    } else {
      setApiError(undefined)
      setShowNotification(undefined)
      setScrollToElement(undefined)
    }

    // Focus on fields that have errors. Because we have controlled components,
    // DOM selectors need to be used instead of relying on simple
    // fieldname paths.
    const firstError: string | undefined = Object.keys(errors)[0]
    if (firstError) {
      const elementByName = document.getElementsByName(firstError)[0]
      const elementById = document.getElementById(firstError)
      const elementToFocusOn = (elementByName ||
        // Check if the element selected by ID is input, if not,
        // pick the first input element below it
        (elementById?.tagName === ('INPUT' || 'TEXTAREA')
          ? elementById
          : elementById?.querySelector('input'))) as HTMLInputElement | null
      elementToFocusOn?.focus()
    }
  }, [errors, errorCode])

  const breedCode = watch(RegistrationFieldKeysEnum.BreedCode)

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        {apiError && (
          <SnackBar
            open={!!apiError && showNotification === SnackBarTypes.ERROR}
            type={SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              setShowNotification(undefined)
              return setApiError(undefined)
            }}
          />
        )}
        {scrollToElement && <ScrollTo selector={scrollToElement} />}
        <SpacerBox gap={3}>
          <SpacerBox component="section" gap={3}>
            <Divider sx={{ mt: 2 }} />
            <Box>
              <Typography variant="h2">{t('registrationForm.field.arrivalDate.title')}</Typography>
              <Typography>{t('registrationForm.requiredTitle')}</Typography>
            </Box>
            <FormGroup>
              <FormControl sx={{ maxWidth: { xs: '100%', sm: '300px' } }}>
                <InputArrivalDate control={control} name={'arrivalDate'} errors={errors} />
              </FormControl>
            </FormGroup>
          </SpacerBox>
          <SpacerBox component="section" gap={1}>
            <Typography variant="h2">{t('registrationForm.dogDetailsTitle')}</Typography>
            <FormGroup
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '100%' },
                gap: 4,
                mt: 2,
                width: '100%',
              }}
            >
              <FormControl sx={{ gridColumnStart: '1', gridColumnEnd: '2' }}>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.MicrochipNumber}
                  error={errors?.[RegistrationFieldKeysEnum.MicrochipNumber]}
                  disabled={isEdit}
                  inputProps={{
                    type: 'text',
                    inputMode: 'numeric',
                    maxLength: MAX_MICROCHIP_NUMBER_LENGTH,
                  }}
                  title={'common.animal.microchipCode'}
                  required
                />
                {!errors[RegistrationFieldKeysEnum.MicrochipNumber] && (
                  <FormHelperText>{t('registrationForm.help.microchipCode')}</FormHelperText>
                )}
              </FormControl>

              <FormControl sx={{ gridColumnStart: '1', gridColumnEnd: '2' }}>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.Name}
                  error={errors?.[RegistrationFieldKeysEnum.Name]}
                  inputProps={{ type: 'text', maxLength: MAX_NAME_LENGTH }}
                  title="common.animal.name"
                  required
                />
              </FormControl>

              <FormControl>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.KennelName}
                  error={errors?.[RegistrationFieldKeysEnum.KennelName]}
                  inputProps={{ type: 'text', maxLength: MAX_NAME_LENGTH }}
                  title="common.animal.kennelName"
                />
              </FormControl>

              <FormControl>
                <DatePicker
                  control={control}
                  name={RegistrationFieldKeysEnum.BirthDate}
                  label={t('common.animal.birthDate')}
                  error={!!errors[RegistrationFieldKeysEnum.BirthDate]}
                  locale={getCurrentLocale()}
                  format={'dd.MM.yyyy'}
                  ariaLabelledby={
                    errors[RegistrationFieldKeysEnum.BirthDate]
                      ? 'inlineError-birthDate'
                      : RegistrationFieldKeysEnum.BirthDate
                  }
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  placeholder={t('registrationForm.field.date.placeHolder') as string}
                  required={true}
                />
                {!!errors[RegistrationFieldKeysEnum.BirthDate] ? (
                  <InlineValidationError
                    content={errors.birthDate?.message}
                    id="inlineError-birthDate"
                  />
                ) : (
                  <FormHelperText>{t('registrationForm.help.birthDate')}</FormHelperText>
                )}
              </FormControl>

              <FormControl>
                <Controller
                  name={RegistrationFieldKeysEnum.BirthCountryCode}
                  render={({ field }) => (
                    <CountrySelect
                      error={!!errors[field.name]}
                      ariaLabelledBy={
                        errors[RegistrationFieldKeysEnum.BirthCountryCode]
                          ? 'inlineError-birthCountryCode'
                          : field.name
                      }
                      field={field}
                      errorContent={errors[field.name]?.message || ''}
                      errorId="inlineError-birthCountryCode"
                    />
                  )}
                />
              </FormControl>

              <FormControl>
                <Controller
                  name={RegistrationFieldKeysEnum.BreedCode}
                  render={({ field }) => (
                    <BreedSelect
                      error={!!errors[field.name]}
                      isRequired={true}
                      ariaLabelledBy={
                        errors[RegistrationFieldKeysEnum.BreedCode]
                          ? 'inlineError-breedCode'
                          : field.name
                      }
                      field={field}
                      errorContent={errors[field.name]?.message || ''}
                      errorId="inlineError-breedCode"
                    />
                  )}
                />
              </FormControl>

              {breedCode === OTHER_BREED && (
                <>
                  <FormControl>
                    <Controller
                      name={RegistrationFieldKeysEnum.BreedSize}
                      render={({ field }) => (
                        <FormBreedSize
                          ariaLabelledBy={
                            errors[RegistrationFieldKeysEnum.BreedSize]
                              ? 'inlineError-breedSize'
                              : field.name
                          }
                          dataQa={field.name}
                          error={!!errors.breedSize}
                          field={field}
                        />
                      )}
                    />
                    {!!errors[RegistrationFieldKeysEnum.BreedSize] ? (
                      <InlineValidationError
                        content={errors[RegistrationFieldKeysEnum.BreedSize].message}
                        id="inlineError-breedSize"
                      />
                    ) : (
                      <FormHelperText> {t('registrationForm.help.dog.size')} </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}

              <FormControl>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.BreedDescription}
                  error={errors?.[RegistrationFieldKeysEnum.BreedDescription]}
                  inputProps={{ maxLength: MAX_BREED_DESCRIPTION_LENGTH }}
                  title="common.animal.description"
                  multiline
                  rows={5}
                  required={breedCode === OTHER_BREED}
                />
              </FormControl>

              <FormControl sx={{ gridColumn: { sm: '1 / span 2', xs: '1' } }}>
                <Controller
                  name={RegistrationFieldKeysEnum.SexCode}
                  render={({ field }) => (
                    <SexSelect
                      field={field}
                      error={!!errors[field.name]}
                      errorContent={errors[RegistrationFieldKeysEnum.SexCode]?.message as string}
                      errorId="inlineError-sexCode"
                    />
                  )}
                />
              </FormControl>

              <FormControl sx={{ gridColumn: { sm: '1 / span 2', xs: '1' } }}>
                <Controller
                  name={RegistrationFieldKeysEnum.Colours}
                  render={({ field }) => (
                    <ColorSelect
                      field={field}
                      error={!!errors[field.name]}
                      errorContent={errors[RegistrationFieldKeysEnum.Colours]?.message as string}
                      errorId="inlineError-colours"
                    />
                  )}
                />
              </FormControl>

              <Divider sx={{ gridColumn: { sm: '1 / span 2', xs: '1' } }} />

              <Box sx={{ gridColumn: { sm: '1 / span 2', xs: '1' } }}>
                <Typography variant="h2">{t('common.animal.microchipExtra')}</Typography>
                <Typography variant="body1">
                  {t('registrationForm.help.microchipDetails')}
                </Typography>
              </Box>

              <FormControl sx={{ gridColumnStart: '1', gridColumnEnd: '2' }}>
                <DatePicker
                  control={control}
                  name={RegistrationFieldKeysEnum.MicrochipDate}
                  label={t('common.animal.microchipDate')}
                  error={!!errors[RegistrationFieldKeysEnum.MicrochipDate]}
                  ariaLabelledby={
                    errors[RegistrationFieldKeysEnum.MicrochipDate]
                      ? 'inlineError-microchipDate'
                      : RegistrationFieldKeysEnum.MicrochipDate
                  }
                  locale={getCurrentLocale()}
                  format={'dd.MM.yyyy'}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                  placeholder={t('registrationForm.field.date.placeHolder') as string}
                  required
                />
                {!!errors[RegistrationFieldKeysEnum.MicrochipDate] && (
                  <InlineValidationError
                    content={errors[RegistrationFieldKeysEnum.MicrochipDate]?.message as string}
                    id="inlineError-microchipDate"
                  />
                )}
              </FormControl>

              <FormControl>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.MicrochipSetterName}
                  error={errors?.[RegistrationFieldKeysEnum.MicrochipSetterName]}
                  inputProps={{ maxLength: MAX_NAME_LENGTH }}
                  title="common.animal.microchipSetterName"
                />
              </FormControl>

              <FormControl sx={{ gridColumn: { sm: '1 / span 2' } }}>
                <ControlledTextField
                  fieldName={RegistrationFieldKeysEnum.MicrochipSetterAddress}
                  error={errors?.[RegistrationFieldKeysEnum.MicrochipSetterAddress]}
                  inputProps={{ maxLength: MAX_ADDRESS_LENGTH }}
                  title="common.animal.microchipSetterAddress"
                />
              </FormControl>

              <Box
                sx={{
                  gridColumn: { sm: '1 / span 2', xs: '1' },
                  display: 'flex',
                  justifyContent: 'end',
                  gap: 2,
                }}
              >
                <Button variant="contained" type="submit" disabled={isSubmitting} data-qa="submit">
                  {submitButtonText}
                </Button>
              </Box>
            </FormGroup>
          </SpacerBox>
        </SpacerBox>
      </Box>
    </FormProvider>
  )
}

export default RegistrationForm
