import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MicrochipDto } from '../generated/animare-management-api/models'
import Field from './Field'
import DateFormatter from './DateFormatter'

export interface Props {
  microchip?: MicrochipDto
}

export default function DogMicrochipExtra({ microchip }: Props) {
  const { t } = useTranslation('common')

  const chipDate = microchip?.microchipDate
  const setterName = microchip?.microchipSetterName

  return (
    <>
      <Grid container data-qa="microchip-extra-container" component="dl" spacing={2} sx={{ mb: 4 }}>
        <Field
          title={t('common.animal.microchipDate')}
          text={chipDate ? <DateFormatter date={chipDate} /> : t('common.not-known')}
          dataQa="microchip-date-field"
        />
        <Field
          title={t('common.animal.microchipSetterName')}
          text={setterName ? setterName : t('common.not-known')}
          dataQa="microchip-setter-field"
        />
        <Field
          title={t('common.animal.microchipSetterAddress')}
          text={
            microchip?.microchipSetterAddress
              ? microchip?.microchipSetterAddress
              : t('common.not-known')
          }
          dataQa="microchip-setter-address-field"
        />
      </Grid>
    </>
  )
}
