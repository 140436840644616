import { ArrowForwardSharp, Info } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AnimareApiKeys } from '../../data/apiKeys'
import {
  AnimalCountryType,
  AnimalLocationType,
  AnimalStatusDto,
  ErrorDto,
  FullRegistrationDto,
} from '../../generated/animare-management-api'
import { useAnimalStatus } from '../../hooks/useAnimalStatus'
import { AppRoutes } from '../../routes'
import DogStatusCountryDialog, { dogCountryStatusFormValues } from '../DogStatusCountryDialog'
import DogStatusDeadDialog from '../DogStatusDeadDialog'
import DogStatusLocationDialog, { DogStatusLocationFormValues } from '../DogStatusLocationDialog'
export interface Props {
  dogData: FullRegistrationDto
  onSuccess: (response?: FullRegistrationDto) => void
  onError: (error: any) => void
}

export default function UpdateDetails({ dogData, onSuccess, onError }: Props) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const uid = dogData.uid
  if (!uid) {
    return null
  }
  const dogStatus = dogData.animal.status

  const MIN_DEATH_DATE = new Date('2023-01-01')
  const MAX_DEATH_DATE = new Date()

  const dogMinDeathDate =
    dogData.animal?.birthDate && MIN_DEATH_DATE < dogData.animal?.birthDate
      ? (dogData.animal?.birthDate as Date)
      : (MIN_DEATH_DATE as Date)
  const dogMaxDeathDate = MAX_DEATH_DATE

  const {
    getCountryMinDate,
    getLocationMinDate,
    isDogAbroad,
    isDogMissing,
    setDogMissing,
    isSetCountryEnabled,
    isSetLocationEnabled,
    setDogFound,
    setDogToFinland,
    setDogAbroad,
    setDogDead,
  } = useAnimalStatus()
  const [showDogAbroadDialog, setShowAbroadDialog] = useState<boolean>(false)
  const [showDogMissingDialog, setShowDogMissingDialog] = useState<boolean>(false)
  const [showDogDeadDialog, setShowDogDeadDialog] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutate: setDogAbroadMutation } = setDogAbroad({
    onSuccess: (dogStatus: AnimalStatusDto) => {
      setShowAbroadDialog(false)
      onSuccess({ ...dogData, animal: { ...dogData.animal, status: dogStatus } })
    },
    onError: (error: ErrorDto) => {
      console.error(error)
      if (error.code) {
        onError(error)
      }
      setShowAbroadDialog(false)
    },
    queryClient,
  })

  const { mutate: setDogToFinlandMutation } = setDogToFinland({
    onSuccess: (dogStatus: AnimalStatusDto) => {
      queryClient.invalidateQueries({
        queryKey: [AnimareApiKeys.REGISTRATION],
      })
      setShowAbroadDialog(false)
      onSuccess({ ...dogData, animal: { ...dogData.animal, status: dogStatus } })
    },
    onError: (error: ErrorDto) => {
      console.error(error)
      if (error.code) {
        onError(error)
      }
      setShowAbroadDialog(false)
    },
    queryClient,
  })

  const { mutate: setDogMissingMutation } = setDogMissing({
    onSuccess: (dogStatus: AnimalStatusDto) => {
      setShowDogMissingDialog(false)
      onSuccess({ ...dogData, animal: { ...dogData.animal, status: dogStatus } })
    },
    onError: (error: ErrorDto) => {
      setShowDogMissingDialog(false)
      onError(error)
    },
    queryClient,
  })

  const { mutate: setDogFoundMutation } = setDogFound({
    onSuccess: (dogStatus: AnimalStatusDto) => {
      setShowDogMissingDialog(false)
      onSuccess({ ...dogData, animal: { ...dogData.animal, status: dogStatus } })
    },
    onError: (error: ErrorDto) => {
      setShowDogMissingDialog(false)
      onError(error)
    },
    queryClient,
  })

  const { mutate: setDogDeadMutation } = setDogDead({
    onSuccess: (dogStatus: AnimalStatusDto) => {
      setShowDogDeadDialog(false)
      onSuccess({ ...dogData, animal: { ...dogData.animal, status: dogStatus } })
    },
    onError: (error: ErrorDto) => {
      setShowDogDeadDialog(false)
      onError(error)
    },
    queryClient,
  })

  /**
   * Changes dog country status:
   * - Currently Abroad to Finland
   * - Currently Finland to Abroad
   */
  const handleDogAbroadAction = async (values: dogCountryStatusFormValues) => {
    const { arrivalDate, countryStatusDate } = values
    switch (dogStatus?.country.type) {
      case AnimalCountryType.Abroad:
        setDogToFinlandMutation({
          registrationUid: uid,
          arrivalDate: arrivalDate,
          date: countryStatusDate,
        })
        break
      case AnimalCountryType.Finland:
        setDogAbroadMutation({
          registrationUid: uid,
          arrivalDate: arrivalDate,
          date: countryStatusDate,
        })
        break
    }
  }

  /**
   * Changes dog location status:
   * - Currently Found to Missing
   * - Currently Missing to Found
   */
  const handleDogLocationSubmit = async (form: DogStatusLocationFormValues) => {
    const { locationDate, arrivalDate } = form
    if (!locationDate || !arrivalDate) {
      return
    }
    switch (dogStatus?.location?.type) {
      case AnimalLocationType.Found:
        return setDogMissingMutation({ registrationUid: uid, date: locationDate, arrivalDate })
      case AnimalLocationType.Missing:
        return setDogFoundMutation({ registrationUid: uid, date: locationDate, arrivalDate })
    }
  }

  /**
   * Changes dog health status to dead
   * @param { deathDate }: form
   */
  const handleDogDeadAction = async ({
    arrivalDate,
    deathDate,
  }: {
    deathDate: Date
    arrivalDate: Date
  }) => {
    setDogDeadMutation({ registrationUid: uid, date: deathDate, arrivalDate })
  }

  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography variant="h2">{t('dogView.changeDetails')}</Typography>
      </Box>

      {!!dogData?.changeCode ? (
        <Typography
          variant="body1"
          color="info.main"
          sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 4 }}
        >
          <Info color="info" /> {t('dogView.noEditAllowed')}
        </Typography>
      ) : (
        <>
          <Box sx={{ lineHeight: { xs: 1.5 }, my: 2 }}>
            <Button
              variant="text"
              onClick={() => navigate(AppRoutes.ChangePossessor)}
              endIcon={<ArrowForwardSharp />}
              data-qa="change-possessor"
            >
              {t('dogView.startPossessorChange')}
            </Button>
          </Box>
          <Box sx={{ lineHeight: { xs: 1.5 }, my: 2 }}>
            <Button
              variant="text"
              onClick={() => navigate(AppRoutes.Registration_Edit)}
              endIcon={<ArrowForwardSharp />}
              data-qa="edit-registration"
            >
              {t('dogView.editRegistration')}
            </Button>
          </Box>
          {dogStatus && (
            <Box sx={{ lineHeight: { xs: 1.5 }, my: 2 }} id="dog-status-abroad">
              <Button
                data-qa="button-dog-status-country"
                variant="text"
                onClick={() => setShowAbroadDialog(true)}
                endIcon={<ArrowForwardSharp />}
                disabled={!isSetCountryEnabled(dogStatus.country, dogData.startDate)}
              >
                {isDogAbroad(dogStatus)
                  ? t('reportChange.dogNotAbroadBtn')
                  : t('reportChange.dogAbroadBtn')}
              </Button>
              <DogStatusCountryDialog
                status={dogStatus}
                open={showDogAbroadDialog}
                handleAction={handleDogAbroadAction}
                handleClose={() => setShowAbroadDialog(false)}
                minDate={getCountryMinDate(dogStatus.country, dogData.startDate)}
              />
            </Box>
          )}
          {dogStatus && (
            <Box sx={{ lineHeight: { xs: 1.5 }, my: 2 }} id="dog-status-missing">
              <Button
                data-qa="button-dog-status-location"
                variant="text"
                aria-label={
                  (isDogMissing(dogStatus)
                    ? t('reportChange.dogFoundButtonAria')
                    : t('reportChange.dogMissingBtnAria')) as string
                }
                onClick={() => setShowDogMissingDialog(true)}
                endIcon={<ArrowForwardSharp />}
                disabled={!isSetLocationEnabled(dogStatus.location, dogData.startDate)}
              >
                {isDogMissing(dogStatus)
                  ? t('reportChange.dogFoundButton')
                  : t('reportChange.dogMissingBtn')}
              </Button>
              <DogStatusLocationDialog
                status={dogStatus}
                open={showDogMissingDialog}
                minDate={getLocationMinDate(dogStatus.location, dogData.startDate)}
                handleClose={() => setShowDogMissingDialog(false)}
                onSubmit={handleDogLocationSubmit}
              />
            </Box>
          )}
          {dogStatus && (
            <Box id="dog-status-dead">
              <Button
                variant="text"
                aria-label={t('reportChange.dogDeadBtnAria') as string}
                onClick={() => setShowDogDeadDialog(true)}
                endIcon={<ArrowForwardSharp />}
              >
                {t('reportChange.dogDeadBtn')}
              </Button>
              <DogStatusDeadDialog
                open={showDogDeadDialog}
                handleClose={() => setShowDogDeadDialog(false)}
                handleAction={handleDogDeadAction}
                minDeathDate={dogMinDeathDate}
                maxDeathDate={dogMaxDeathDate}
              />
            </Box>
          )}
          <Box sx={{ lineHeight: { xs: 1.5 }, my: 2 }}>
            <Button
              variant="text"
              onClick={() => navigate(AppRoutes.Registration_Microchips)}
              endIcon={<ArrowForwardSharp />}
              data-qa="manage-microchips"
            >
              {t('dogView.manageMicrochipCodes')}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
