import {
  AnimalDto,
  EventDto,
  LimitedAnimalResponseDto,
  MicrochipDto,
} from '../../generated/animare-management-api'
import { possessorDto } from './possessors'

export const animalDto = {
  uid: 'af9dbb27-b91d-43ea-946f-e9ccacafdd3f',
  name: 'Murre',
  kennelName: 'Kennelname',
  birthDate: new Date('2022-10-06'),
  birthCountryCode: '010',
  breedCode: '1',
  breedDescription: 'a dog',
  breedSizeCode: 'x-large',
  sexCode: 'female',
  colours: ['black', 'brown'],
} as AnimalDto

export const microchipDto = {
  uid: 'fy2dbb27-b91d-43ea-946f-e9ccacafdd2y',
  microchipNumber: '827262524412345',
} as MicrochipDto

export const shelterAnimal = {
  uid: animalDto.uid,
  name: animalDto.name,
  microchipNumber: microchipDto.microchipNumber,
  breedCode: animalDto.breedCode,
  breedDescription: animalDto.breedDescription,
  sexCode: animalDto.sexCode,
  breedSize: animalDto.breedSize,
  colours: animalDto.colours,
  possessor: {
    name: `${possessorDto.lastName} ${possessorDto.firstName}`,
    phone: possessorDto.phone,
    email: possessorDto.email,
    municipalityCode: possessorDto.municipalityCode,
  },
  microchips: [
    {
      number: microchipDto.microchipNumber,
      date: new Date('2022-12-01'),
    },
  ],
} as LimitedAnimalResponseDto

export const events = [
  {
    timestamp: '2023-05-26T17:18:19.20939Z[Etc/UTC]',
    type: 'POSSESSOR_CHANGE_CODE_INVALIDATED',
    category: 'REGISTRATION',
    possessor: {
      uid: 'e42da5b4-9b12-4433-9c96-496a1c5d5756',
      type: 'person',
      name: 'last first',
      identifier: '010100A9999',
    },
  },
  {
    timestamp: '2023-05-26T17:16:22.11939Z[Etc/UTC]',
    type: 'POSSESSOR_CHANGE_STARTED',
    category: 'REGISTRATION',
    possessor: {
      uid: 'f63da5b4-9b12-4433-9c96-496a1c5d1198',
      type: 'organization',
      name: 'Testiyritys',
      identifier: '1234567-8',
    },
  },
  {
    timestamp: '2023-05-21T11:24:22.32939Z[Etc/UTC]',
    type: 'CHANGE_POSSESSOR',
    category: 'REGISTRATION',
    animalPhysicalEventDate: new Date('2023-05-21'),
    documentArrivalDate: new Date('2023-11-11'),
    possessor: {
      uid: 'f63da5b4-9b12-4433-9c96-496a1c5d1198',
      type: 'organization',
      name: 'Testiyritys',
      identifier: '1234567-8',
    },
  },
] as EventDto[]
