import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import DateFormatter from '../components/DateFormatter'
import { useTranslation } from 'react-i18next'
import { Roles } from '../data/roles'
import { useEffect, useState } from 'react'
import { ShowFor } from '../context/AuthProvider'
import MicrochipDeleteConfirmDialog from './MicrochipDeleteConfirmDialog'
import { useMicrochip } from '../hooks/useMicrochip'
import { ErrorDto } from '../generated/openapi'
import { SnackBar, SnackBarTypes } from './SnackBar'
import { AnimareApiKeys } from '../data/apiKeys'
import { DeleteMicrochipRequest } from '../generated/animare-management-api'
import { useQueryClient } from '@tanstack/react-query'

export interface MicrochipForDataTable {
  uid?: string
  number?: string
  date?: Date
  name?: string
  address?: string
}

export const MicrochipHistory = ({
  microchipData,
  role,
  showDelete = false,
}: {
  microchipData: MicrochipForDataTable[]
  role?: Roles
  showDelete?: boolean
}) => {
  const { t } = useTranslation('common')
  const { deleteMicrochip } = useMicrochip()

  const showSetterDetails = role !== Roles.Shelter

  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const [microchips, setMicrochips] = useState<MicrochipForDataTable[]>([])
  const [selectedMicrochip, setSelectedMicrochip] = useState<MicrochipForDataTable | undefined>(
    undefined,
  )
  const [showDeleteAction, setShowDeleteAction] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const { mutate: deleteMicrochipMutation } = deleteMicrochip({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.MICROCHIP] })
      setShowNotification(SnackBarTypes.SUCCESS)
      setMicrochips(microchips.filter((m) => m.uid !== selectedMicrochip?.uid))
      setSelectedMicrochip(undefined)
    },
    onError: (error: ErrorDto) => {
      if (error.code) {
        setApiError(error)
        setShowNotification(SnackBarTypes.ERROR)
      }
      console.error(error)
    },
    queryClient,
  })

  const handleDeleteMicrochip = (arrivalDate: Date) => {
    if (!selectedMicrochip?.uid) {
      return
    }

    deleteMicrochipMutation({
      uid: selectedMicrochip?.uid,
      arrivalDate: arrivalDate,
    })
  }

  const compareChipNumbers = (a: MicrochipForDataTable, b: MicrochipForDataTable): number => {
    if (!a.number && !b.number) {
      return 0
    } else if (!a.number) {
      return 1
    } else if (!b.number) {
      return -1
    } else {
      return a.number.localeCompare(b.number)
    }
  }

  const sortByDateDescending = (data: MicrochipForDataTable[]): MicrochipForDataTable[] => {
    return [...data].sort((a, b) => {
      if (a.date && b.date) {
        if (a.date.getTime() === b.date.getTime()) {
          return compareChipNumbers(a, b)
        }
        return b.date.getTime() - a.date.getTime()
      } else if (a.date) {
        return -1
      } else if (b.date) {
        return 1
      } else {
        return compareChipNumbers(a, b)
      }
    })
  }

  useEffect(() => {
    setMicrochips(sortByDateDescending(microchipData))
  }, [microchipData])

  useEffect(() => {
    setShowDeleteAction(showDelete && microchips.length > 1)
  }, [microchips])

  return (
    <Box>
      {apiError && (
        <SnackBar
          open={!!apiError && showNotification === SnackBarTypes.ERROR}
          type={SnackBarTypes.ERROR}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            return setApiError(undefined)
          }}
        />
      )}
      <SnackBar
        type={SnackBarTypes.SUCCESS}
        open={showNotification === SnackBarTypes.SUCCESS}
        handleClose={() => setShowNotification(undefined)}
        innerText={`${t('common.save-successful')}`}
      />
      <Box>
        <Table data-qa="microchip-history">
          <TableHead>
            <TableRow>
              <TableCell>{t('common.animal.microchipCode')}</TableCell>
              <TableCell>{t('common.animal.microchipDate')}</TableCell>
              {showSetterDetails && (
                <>
                  <TableCell>{t('common.animal.microchipSetterName')}</TableCell>
                  <TableCell>{t('common.animal.microchipSetterAddress')}</TableCell>
                </>
              )}
              {showDeleteAction && (
                <ShowFor roles={[Roles.Admin]}>
                  <TableCell>{t('renewMicrochip.deleteMicrochipColumnTitle')}</TableCell>
                </ShowFor>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {microchips.map((microchip, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{microchip?.number}</TableCell>
                  <TableCell>
                    <DateFormatter date={microchip?.date} />
                  </TableCell>
                  {showSetterDetails && (
                    <>
                      <TableCell>{microchip?.name || ''}</TableCell>
                      <TableCell>{microchip?.address || ''}</TableCell>
                    </>
                  )}
                  {showDeleteAction && microchips.length > 1 && (
                    <ShowFor roles={[Roles.Admin]}>
                      <TableCell data-qa="cell-delete-microchip">
                        <Button variant="text" onClick={() => setSelectedMicrochip(microchip)}>
                          {t('common.delete')}
                        </Button>
                      </TableCell>
                    </ShowFor>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {selectedMicrochip && (
          <MicrochipDeleteConfirmDialog
            open={!!selectedMicrochip}
            handleClose={() => setSelectedMicrochip(undefined)}
            handleAction={handleDeleteMicrochip}
            microchipNumber={selectedMicrochip.number as string}
          />
        )}
      </Box>
    </Box>
  )
}
