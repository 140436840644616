import { ErrorDto, Middleware, ResponseContext } from '../generated/animare-management-api'
import { AppRoutes } from '../routes'

const defaultError: ErrorDto = { code: 'E0000', description: 'Unknown error' }

export class ApiErrorMiddleware implements Middleware {
  readonly redirectToLogout: boolean = true

  constructor(autoRedirectToLogout?: boolean) {
    if (autoRedirectToLogout !== undefined) {
      this.redirectToLogout = autoRedirectToLogout
    }
  }

  public async post(context: ResponseContext): Promise<Response | void> {
    const { response } = context
    const isErrorDto = (object: any) => Object.keys(object ?? {}).includes('code')

    if (!response.ok) {
      console.error('Response status code:', response.status)
      if (this.redirectToLogout && (response.status === 401 || response.status === 403)) {
        document.location.href = AppRoutes.Logout
        return Promise.resolve()
      }
      try {
        const error = await response.json() // Get ErrorDto from error Response
        if (!isErrorDto(error)) {
          throw Error('Probably not an ErrorDto')
        }
        return Promise.reject(error)
      } catch (error) {
        console.error('Could not parse error body', error)
        throw defaultError
      }
    }

    return Promise.resolve(response) // Response ok
  }
}
