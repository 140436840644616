import { LocalizationProvider, enUS, fiFI, svSE } from '@mui/x-date-pickers'
import { Control, Controller, FieldValues, Path } from 'react-hook-form'

import { InputLabel, PopperProps } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from '../i18n'

// Define a generic TFieldValues extended from FieldValues to
// allow the usage of dynamic form field names
export type DatePickerProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>
  label: string
  ariaLabelledby?: string
  error: boolean
  control?: Control<TFieldValues>
  locale: Locale
  format: string
  required?: boolean
  maxDate?: Date
  minDate?: Date
  placeholder?: string
  disabled?: boolean
}

const DatePicker = <TFieldValues extends FieldValues>({
  name,
  label,
  ariaLabelledby,
  error,
  locale,
  format,
  required,
  maxDate,
  minDate,
  placeholder,
  control,
  disabled = false,
}: DatePickerProps<TFieldValues>) => {
  // Create a ref to anchor the datepicker popper to
  // A bit of a hack was needed to get it to stick to the input field during the first opening,
  // see https://github.com/mui/mui-x/issues/10824 for inspiration
  const [popperProps, setPopperProps] = useState<Partial<PopperProps>>({})
  const anchorElRef = useRef(null)
  useLayoutEffect(() => {
    setPopperProps({ anchorEl: anchorElRef.current })
  }, [])

  const { t } = useTranslation('common')
  const datepickerLocales = () => {
    if (i18n.language === 'fi') {
      return fiFI.components.MuiLocalizationProvider.defaultProps.localeText
    } else if (i18n.language === 'en') {
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
    } else if (i18n.language === 'sv') {
      return svSE.components.MuiLocalizationProvider.defaultProps.localeText
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale}
          localeText={datepickerLocales()}
        >
          <InputLabel htmlFor={name} id={`${name}-label`}>
            {label}
            {required ? (
              <>
                <i aria-hidden={true}>{' *'}</i>
                <i className="visible-hidden">{t('registrationForm.field.required')}</i>
              </>
            ) : null}
          </InputLabel>
          <MuiDatePicker
            onChange={field.onChange}
            ref={anchorElRef}
            value={field.value || null}
            disableFuture
            inputFormat={format}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            PopperProps={popperProps}
            renderInput={(params) => (
              <TextField
                {...params}
                data-qa={name}
                aria-labelledby={ariaLabelledby}
                id={name}
                error={error}
                inputProps={{
                  ...params.inputProps,
                  placeholder,
                  ref: field.ref,
                }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  )
}

export default DatePicker
