/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PossessorDto } from './PossessorDto'
import { PossessorDtoFromJSON, PossessorDtoFromJSONTyped, PossessorDtoToJSON } from './PossessorDto'

/**
 * A request to create or modify a possessor.
 * @export
 * @interface ModifyPossessorRequestDto
 */
export interface ModifyPossessorRequestDto {
  /**
   * When the request form has arrived (only for paper registration).
   * @type {Date}
   * @memberof ModifyPossessorRequestDto
   */
  arrivalDate: Date
  /**
   *
   * @type {PossessorDto}
   * @memberof ModifyPossessorRequestDto
   */
  possessor: PossessorDto
}

/**
 * Check if a given object implements the ModifyPossessorRequestDto interface.
 */
export function instanceOfModifyPossessorRequestDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value
  isInstance = isInstance && 'possessor' in value

  return isInstance
}

export function ModifyPossessorRequestDtoFromJSON(json: any): ModifyPossessorRequestDto {
  return ModifyPossessorRequestDtoFromJSONTyped(json, false)
}

export function ModifyPossessorRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ModifyPossessorRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    arrivalDate: new Date(json['arrivalDate']),
    possessor: PossessorDtoFromJSON(json['possessor']),
  }
}

export function ModifyPossessorRequestDtoToJSON(value?: ModifyPossessorRequestDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
    possessor: PossessorDtoToJSON(value.possessor),
  }
}
