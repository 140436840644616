import * as yup from 'yup'
import { Control, FieldErrors } from 'react-hook-form'
import DatePicker from '../DatePicker'
import { useTranslation } from 'react-i18next'
import { getCurrentLocale, useDate } from '../../hooks/useDate'
import InlineValidationError from './InlineValidationError'
import { Message } from 'yup'

interface Props {
  control: Control<any>
  name?: string
  disabled?: boolean
  errors: FieldErrors
}

const MIN_DATE = new Date('2023-01-01')
const MAX_DATE = new Date()

export const arrivalDateSchema = () => {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()

  return yup
    .date()
    .transform((curr: any, orig: any) => (orig === '' || isNaN(orig?.valueOf()) ? null : curr))
    .typeError(t('registrationForm.error.ifEmpty.generic') as Message)
    .required(t('registrationForm.error.ifEmpty.generic') as Message)
    .nullable()
    .transform((value) => !!value && toUTCDate(value))
    .min(MIN_DATE, t('registrationForm.error.date.wrongFormat') as Message)
    .max(MAX_DATE, t('registrationForm.error.date.max') as Message)
}

export default function InputArrivalDate({
  control,
  name = 'arrivalDate',
  disabled,
  errors,
}: Props) {
  const { t } = useTranslation('common')

  const error = !!errors[name as string]
  const errorContent = errors[name as string]?.message
  const ariaLabelledby = error ? `inlineError-${name}` : name

  return (
    <>
      <DatePicker
        control={control}
        name={name}
        label={t('registrationForm.field.arrivalDate.label')}
        disabled={disabled}
        format={'dd.MM.yyyy'}
        minDate={MIN_DATE}
        maxDate={MAX_DATE}
        placeholder={t('registrationForm.field.date.placeHolder') as string}
        error={!!errors.arrivalDate}
        ariaLabelledby={ariaLabelledby}
        locale={getCurrentLocale()}
        required={true}
      />
      {error && <InlineValidationError content={errorContent} id={`inlineError-${name}`} />}
    </>
  )
}
