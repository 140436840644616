import { Controller, FieldError } from 'react-hook-form'

import InlineValidationError from './InlineValidationError'
import { InputBaseProps } from '@mui/material/InputBase/InputBase'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import TextField from '@mui/material/TextField/TextField'
import { useTranslation } from 'react-i18next'

interface ControlledTextFieldProps {
  title: string
  fieldName: string
  error?: FieldError
  required?: boolean
  disabled?: boolean
  inputProps?: InputBaseProps['inputProps']
  multiline?: boolean
  rows?: number
}

export const ControlledTextField = (props: ControlledTextFieldProps) => {
  const { title, fieldName, error, required, disabled, inputProps, rows, multiline } = props
  const { t } = useTranslation('common')

  return (
    <>
      <InputLabel htmlFor={fieldName}>
        {t(title)}
        {required ? <i aria-hidden={true}>*</i> : ''}
        <i className="visible-hidden">{t('registrationForm.field.required')}</i>
      </InputLabel>
      <Controller
        name={fieldName}
        render={({ field }) => {
          const { name, value, onBlur, onChange, ref } = field
          return (
            <TextField
              id={name}
              data-qa={name}
              error={!!error}
              aria-labelledby={error ? `inlineError-${name}` : name}
              disabled={disabled}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              rows={rows}
              inputProps={{ ...inputProps, ref }}
              multiline={multiline}
            />
          )
        }}
      />
      {error ? (
        <InlineValidationError content={error?.message} id={`inlineError-${fieldName}`} />
      ) : null}
    </>
  )
}
