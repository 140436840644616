import { Server } from 'miragejs'
import { animalRoutes } from './api/animal'
import { authRoutes } from './api/auth'
import { codesRoutes } from './api/codes'
import { eventRoutes } from './api/event'
import { microchipRoutes } from './api/microchip'
import { monitoringRoutes } from './api/monitoring'
import { possessorRoutes } from './api/possessor'
import { registerRoutes } from './api/register'
import { secretsRoutes } from './api/secrets'
import { userRoutes } from './api/user'

const baseUrl = new URL(import.meta.env.VITE_APP_API_BASE_URL).origin

export function makeServer({ environment = 'development' } = {}) {
  const server = new Server({
    environment,

    routes() {
      this.urlPrefix = baseUrl as string
      console.log('initializing Mirage mock API', { urlPrefix: this.urlPrefix })
      animalRoutes(this)
      authRoutes(this)
      codesRoutes(this)
      eventRoutes(this)
      microchipRoutes(this)
      monitoringRoutes(this)
      possessorRoutes(this)
      registerRoutes(this)
      secretsRoutes(this)
      userRoutes(this)
    },
  })

  return server
}
