import * as yup from 'yup'

import { Box, Button, FormControl, FormGroup, TextField, Typography } from '@mui/material'
import { Message, useForm } from 'react-hook-form'
import { ErrorDto, FullRegistrationDto } from '../generated/animare-management-api'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRegistration } from '../hooks/useRegistration'
import { ErrorCodes, hasErrorCodes } from '../utils/errorUtils'
import InlineValidationError from './Form/InlineValidationError'
import LoadingSpinner from './LoadingSpinner'

const MICROCHIP_REGEX = /^[0-9]+$/
const MICROCHIP_LENGTH = 15

export interface MicrochipFormData {
  microchip?: string
}

export default function MicrochipSearch() {
  const { t } = useTranslation('common')
  const { getRegistrationUidByMicrochipNumber } = useRegistration()
  const navigate = useNavigate()
  const [noMicrochipResults, setNoMicrochipResults] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)

  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    min: t('errors.validation.lengthRequirement', { number: MICROCHIP_LENGTH }) as Message,
    wrongFormat: t('errors.validation.wrongFormat') as Message,
  }

  const schema = yup.object().shape({
    microchip: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .min(MICROCHIP_LENGTH, errorMessages.min)
      .max(MICROCHIP_LENGTH)
      .matches(MICROCHIP_REGEX, errorMessages.wrongFormat),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<MicrochipFormData>({ resolver: yupResolver(schema) })

  /**
   * Gets specific registration by microchip code.
   * @param event
   *
   *
   */
  const onSubmit = async (form: MicrochipFormData) => {
    const { microchip } = form
    if (!microchip) {
      return
    }
    setNoMicrochipResults(false)
    setApiError(undefined)
    setShowNotification(undefined)

    setLoading(true)

    return getRegistrationUidByMicrochipNumber(microchip)
      .then((response: FullRegistrationDto) => {
        navigate(`registration/${response?.uid}`)
      })
      .catch((error: ErrorDto) => {
        if (
          hasErrorCodes(error, [
            ErrorCodes.MicrochipNotFound,
            ErrorCodes.RegistrationNotFound,
            ErrorCodes.AnimalNotFound,
          ])
        ) {
          setNoMicrochipResults(true)
        } else {
          // Show error notification if there is an actual error,
          // not just a nonexistent microchip code
          showErrorNotification()
          setApiError(error)
        }

        console.error(error)
      })
      .finally(() => setLoading(false))
  }

  /**
   * Reset microchip field and ui back to initial state
   */
  const handleReset = () => {
    setNoMicrochipResults(false)
    setApiError(undefined)
    setShowNotification(undefined)
    setLoading(false)
    setValue('microchip', undefined)
  }

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => handleReset()}
        data-qa="microchip-search"
      >
        <FormGroup sx={{ display: 'flex', gap: 2 }}>
          <FormControl>
            <TextField
              {...register('microchip')}
              data-qa="microchip"
              id={'microchip'}
              label={t('registrationSearchPage.searchSection.microchipLabel')}
              error={!!errors.microchip}
              inputProps={{
                type: 'text',
                inputMode: 'numeric',
                maxLength: 15,
              }}
              autoComplete={'off'}
              sx={{ width: '40%' }}
            />
            {!!errors.microchip && <InlineValidationError content={errors.microchip?.message} />}
          </FormControl>
          <Box>
            <Button variant="contained" type="submit" sx={{ mr: 1 }} data-qa="microchip-submit">
              {t('registrationSearchPage.searchSection.button')}
            </Button>
            <Button variant="outlined" type="reset" data-qa="microchip-reset">
              {t('registrationSearchPage.searchSection.reset')}
            </Button>
          </Box>
        </FormGroup>
      </Box>

      {!loading && !!noMicrochipResults && (
        <Box data-qa="no-microchip-results">
          <Typography variant="h3">
            {t('registrationSearchPage.searchSection.noResultsTitle')}
          </Typography>
          <Typography variant="body1">
            {t('registrationSearchPage.searchSection.noMicrochipResultsInfo')}
          </Typography>
        </Box>
      )}

      {loading && <LoadingSpinner />}

      {!loading && apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={showNotification === SnackBarTypes.ERROR && !!apiError}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
        />
      )}
    </>
  )
}
