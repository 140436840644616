import * as yup from 'yup'

import { Box, Button, Divider, FormControl, FormGroup, Typography } from '@mui/material'
import { Controller, Message, useForm } from 'react-hook-form'
import {
  ErrorDto,
  FullRegistrationDto,
  PossessorChangeRequestDto,
  PossessorDto,
} from '../../generated/animare-management-api'
import { getCurrentLocale, useDate } from '../../hooks/useDate'
import { SnackBar, SnackBarTypes } from '../SnackBar'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRegistration } from '../../hooks/useRegistration'
import DatePicker from '../DatePicker'
import Possessor from '../Possessor'
import InlineValidationError from './InlineValidationError'
import InputArrivalDate from './InputArrivalDate'

export interface Props {
  possessor: PossessorDto
  registration: FullRegistrationDto
}

export default function PossessorChangeForm({ possessor, registration }: Props) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { changePossessor, getRegistrationMinDate, getRegistrationMaxDate } = useRegistration()
  const { toUTCDate } = useDate()
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const queryClient = useQueryClient()

  const MIN_DATE = getRegistrationMinDate()
  const MAX_DATE = getRegistrationMaxDate()

  const MIN_POSSESSOR_CHANGE_DATE = registration.animal.birthDate
  const MAX_POSSESSOR_CHANGE_DATE = registration.animal.deathDate || new Date()

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const errorMessages = {
    generic: t('registrationForm.error.ifEmpty.generic') as Message,
    date: {
      wrongFormat: t('registrationForm.error.date.wrongFormat') as Message,
      max: t('registrationForm.error.date.max') as Message,
    },
    transferDate: {
      wrongFormat: t('possessorChangeView.form.errors.invalidTransferDate') as Message,
    },
  }

  const schema = yup.object().shape({
    arrivalDate: yup
      .date()
      .typeError(errorMessages.generic)
      .required(errorMessages.generic)
      .nullable()
      .transform((value) => toUTCDate(value))
      .min(MIN_DATE, errorMessages.date.wrongFormat)
      .max(MAX_DATE, errorMessages.date.max),
    transferDate: yup
      .date()
      .typeError(errorMessages.generic)
      .required(errorMessages.generic)
      .nullable()
      .transform((value) => toUTCDate(value))
      .min(MIN_POSSESSOR_CHANGE_DATE, errorMessages.transferDate.wrongFormat)
      .max(MAX_POSSESSOR_CHANGE_DATE, errorMessages.transferDate.wrongFormat),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<PossessorChangeRequestDto>({ resolver: yupResolver(schema) })

  const { mutate: changePossessorMutation } = changePossessor({
    onSuccess: (result) => {
      navigate(
        `/search/possessor/${result.possessor.uid}/registration/${result.uid}/change-possessor-completed`,
      )
    },
    onError: (error: ErrorDto) => {
      if (error?.code) {
        showErrorNotification()
        setApiError(error)
      }
    },
    queryClient,
  })

  const onSubmit = async (form: PossessorChangeRequestDto) => {
    if (!registration.uid) {
      return
    }
    form.newPossessor = { ...possessor, uid: possessor?.uid }
    changePossessorMutation({ uid: registration.uid, form })
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} data-qa="possessor-change-form">
      {apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={!!apiError && showNotification === SnackBarTypes.ERROR}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            return setApiError(undefined)
          }}
        />
      )}
      <Typography variant="h2"> {t('possessorChangeView.form.title')} </Typography>
      <Box sx={{ mb: 4 }}>
        <Typography variant="body1">{t('registrationForm.requiredTitle')}</Typography>
      </Box>
      <Box sx={{ mb: 3 }}>
        <FormGroup sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 4 }}>
          <Box>
            <Typography variant="h3" id="arrivalDate-heading" sx={{ mb: 3 }}>
              {t('possessorChangeView.form.arrivalDate.header')}
            </Typography>
            <FormControl sx={{ width: '40%' }}>
              <InputArrivalDate control={control} errors={errors} />
            </FormControl>
          </Box>
          <Box>
            <Typography variant="h3" id="transferDate-heading" sx={{ mb: 3 }}>
              {t('possessorChangeView.form.transferDate.header')}
            </Typography>
            <FormControl sx={{ width: '40%' }}>
              <Controller
                name="transferDate"
                control={control}
                render={() => (
                  <DatePicker
                    required={true}
                    name={'transferDate'}
                    label={t('registrationForm.field.transferDate.title')}
                    format={'dd.MM.yyyy'}
                    minDate={MIN_POSSESSOR_CHANGE_DATE}
                    maxDate={MAX_POSSESSOR_CHANGE_DATE}
                    placeholder={t('registrationForm.field.date.placeHolder') as string}
                    error={!!errors.transferDate}
                    locale={getCurrentLocale()}
                    control={control}
                    ariaLabelledby="transferDate-heading"
                  />
                )}
              />
              <Typography variant="caption" mt={1}>
                {t('possessorChangeView.form.transferDate.instructions')}
              </Typography>
              {!!errors.transferDate && (
                <InlineValidationError content={errors.transferDate?.message} />
              )}
            </FormControl>
          </Box>
          <Button variant="contained" type="submit" sx={{ width: 'fit-content' }} data-qa="submit">
            {t('possessorChangeView.changePossessorBtn')}
          </Button>
        </FormGroup>
      </Box>
      <Divider sx={{ mt: 4, mb: 2 }} />
      <Box>
        <Typography variant="h2" sx={{ mb: 2 }}>
          {t('possessorDetails.title')}
        </Typography>
        <Possessor possessor={possessor} />
      </Box>
    </Box>
  )
}
