import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

import IdentifierOrganizationSearch from './identifierSearchOrganization'
import IdentifierSearch from './identifierSearch'
import MicrochipSearch from './MicrochipSearch'
import { PossessorDtoTypeEnum, PossessorResponseDto } from '../generated/animare-management-api'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export enum SearchMethods {
  Identifier = 'identifier-code',
  CompanyIdentifier = 'company-identifier',
  Microchip = 'microchip-code',
}

interface Props {
  hasIdentifierSearch?: boolean
  hasCompanyIdentifierSearch?: boolean
  hasMicrochipSearch?: boolean
  onPersonFound?: (response: PossessorResponseDto) => void
  onPersonFormReset?: () => void
  onOrganizationFound?: (response: PossessorResponseDto) => void
  onOrganizationFormReset?: () => void
  onIdentifierNotFound: (identifier: string, possessorType: PossessorDtoTypeEnum) => void
}

export default function Search({
  hasIdentifierSearch,
  hasCompanyIdentifierSearch,
  hasMicrochipSearch,
  onPersonFound,
  onPersonFormReset,
  onOrganizationFound,
  onOrganizationFormReset,
  onIdentifierNotFound,
}: Props) {
  const { t } = useTranslation('common')
  const [selectedSearchMethod, setSelectedSearchMethod] = useState<SearchMethods>(
    SearchMethods.Identifier,
  )

  /**
   *
   * @param event
   */
  const switchSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSearchMethod((event.target as HTMLInputElement).value as SearchMethods)
  }

  return (
    <>
      <RadioGroup value={selectedSearchMethod} onChange={switchSearch} row>
        {hasIdentifierSearch && (
          <FormControlLabel
            data-qa="identifier-rb-label"
            value={SearchMethods.Identifier}
            control={<Radio />}
            label={t('possessorChangeView.search.identifierRadioLabel')}
          />
        )}

        {hasCompanyIdentifierSearch && (
          <FormControlLabel
            data-qa="company-identifier-rb-label"
            value={SearchMethods.CompanyIdentifier}
            control={<Radio />}
            label={t('possessorChangeView.search.businessIdentifierRadioLabel')}
          />
        )}

        {hasMicrochipSearch && (
          <FormControlLabel
            data-qa="microchip-identifier-rb-label"
            value={SearchMethods.Microchip}
            control={<Radio />}
            label={t('registrationSearchPage.searchSection.microchipRadioLabel')}
          />
        )}
      </RadioGroup>

      {selectedSearchMethod === SearchMethods.Identifier &&
        onPersonFound &&
        onIdentifierNotFound &&
        onPersonFormReset && (
          <IdentifierSearch
            onPersonFound={onPersonFound}
            onPersonNotFound={(response) =>
              onIdentifierNotFound(response, PossessorDtoTypeEnum.Person)
            }
            onReset={onPersonFormReset}
          />
        )}
      {selectedSearchMethod === SearchMethods.CompanyIdentifier &&
        onOrganizationFound &&
        onIdentifierNotFound &&
        onOrganizationFormReset && (
          <IdentifierOrganizationSearch
            onOrganizationFound={onOrganizationFound}
            onOrganizationNotFound={(response) =>
              onIdentifierNotFound(response, PossessorDtoTypeEnum.Organization)
            }
            onReset={onOrganizationFormReset}
          />
        )}
      {selectedSearchMethod === SearchMethods.Microchip && <MicrochipSearch />}
    </>
  )
}
