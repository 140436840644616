import { useTranslation } from 'react-i18next'
import useMunicipalities from '../../hooks/useMunicipality'
import AbstractCode from './AbstractCode'

export const Municipality = ({ code }: { code?: string }) => {
  const { t } = useTranslation('common')
  return (
    <AbstractCode
      code={code}
      generator={useMunicipalities().getMunicipalityName}
      unknownResult={t('common.municipality.unknown')}
    />
  )
}
