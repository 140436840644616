import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { AppUserDto } from '../generated/openapi/models/AppUserDto'
import useUserApi from '../hooks/useUserApi'

import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../components/LoadingSpinner'
import { ErrorDto } from '../generated/openapi'
import { AppRoutes } from '../routes'

const AuthContext = createContext({
  currentUser: null,
  setCurrentUser: () => {
    // default to no-op function
  },
  error: null,
  initializing: true,
} as AuthContextProvider)

export function useAuthContext(): AuthContextProvider {
  const context = useContext(AuthContext)

  if (!context) throw new Error('useAuthContext must be used inside a `AuthProvider`')

  return context
}

interface AuthContextProvider {
  currentUser: AppUserDto | null
  setCurrentUser: Dispatch<SetStateAction<AppUserDto>>
  error: ErrorDto | null
  initializing: boolean
}

export function AuthProvider({ children }: { children: JSX.Element }) {
  const [currentUser, setCurrentUser] = useState<AppUserDto | null>(null)
  const [error, setError] = useState<ErrorDto | null>(null)
  const [initializing, setInitializing] = useState(true)
  const location = useLocation()

  // NOTE: we disable auto-redirection to logout on access errors in this instance of the client
  const { fetchCurrentUser } = useUserApi(false)

  useEffect(() => {
    fetchCurrentUser()
      .then((user: AppUserDto) => {
        setCurrentUser(user)
        setError(null)
      })
      .catch((error) => {
        setCurrentUser(null)
        setError(error)
      })
      .finally(() => {
        setInitializing(false)
      })
  }, [location])

  const currentAuthContext = {
    currentUser,
    setCurrentUser,
    error,
    initializing,
  } as AuthContextProvider

  return <AuthContext.Provider value={currentAuthContext}>{children}</AuthContext.Provider>
}

const isAllowed = (allowedRoles: string[], currentRoles: string[]): boolean => {
  if (allowedRoles.length == 0) {
    return true
  }
  return currentRoles.filter((role) => allowedRoles.includes(role)).length > 0
}

export interface Props {
  allowedRoles?: string[]
}

export function AuthGuard({ allowedRoles = [] }: Props): JSX.Element | null {
  const { currentUser, initializing }: AuthContextProvider = useAuthContext()
  const navigate = useNavigate()

  useEffect(() => {
    if (!initializing) {
      // auth is initialized and there is no user
      if (!currentUser) {
        // go to login if no user
        navigate(AppRoutes.Root)
      } else {
        // check if the current user is allowed to access this page
        if (!isAllowed(allowedRoles, currentUser.roles ?? [])) {
          navigate(AppRoutes.Dashboard)
        }
      }
    }
  }, [initializing, currentUser])

  /* show loading indicator while the auth provider is still initializing */
  if (initializing) {
    return <LoadingSpinner />
  }

  // if auth initialized with a valid user show protected page
  if (!initializing && currentUser) {
    return <Outlet />
  }

  /* otherwise don't return anything, will do a redirect from useEffect */
  return null
}

export interface ShowForProps {
  roles?: string[]
}

// NOTE: ShowFor works only within AuthGuarded pages
export function ShowFor({
  children,
  roles = [],
}: PropsWithChildren<ShowForProps>): JSX.Element | null {
  const { currentUser, initializing }: AuthContextProvider = useAuthContext()

  if (initializing || !currentUser) {
    return null
  }

  return isAllowed(roles, currentUser.roles ?? []) ? <>{children}</> : null
}
