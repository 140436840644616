import { useQuery } from '@tanstack/react-query'
import {
  AnimalRestControllerApi,
  Configuration,
  ErrorDto,
  EventDto,
  EventRestControllerApi,
  GetEventRequest,
  Middleware,
  querystring,
} from '../generated/animare-management-api'

import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'
import { AnimareApiKeys } from '../data/apiKeys'

export interface EventDetails {
  [key: string]: string
}

export function useEvents() {
  const api = new AnimalRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  const eventApi = new EventRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   * Fetches events of the given animal.
   * @param uid The unique identifier of the animal.
   * @param enabled If the query is enabled
   * @returns A list of events, error or if the query is still pending.
   */
  const getEvents = ({
    uid,
    enabled = true,
  }: {
    uid?: string
    enabled: boolean
  }): { error: ErrorDto | null; isPending: boolean; data?: EventDto[] } => {
    const {
      error,
      isPending,
      data = [],
    } = useQuery<Array<EventDto>, ErrorDto>({
      queryKey: [AnimareApiKeys.ANIMAL, uid],
      queryFn: ({ queryKey }) => api.getEventsForAnimal({ uid: `${queryKey[1]}` }),
      enabled,
    })

    return { error, data, isPending }
  }

  /**
   * Fetches detailed information about the given event.
   * @param uid The unique identifier of the event.
   * @param enabled When the query should be fired
   * @returns A detailed event.
   */
  const getEventDetails = ({
    uid,
    enabled,
  }: {
    uid?: string
    enabled?: boolean
  }): { isPending: boolean; error: ErrorDto | null; data?: EventDto } => {
    const { data, isPending, error } = useQuery<EventDto, ErrorDto>({
      queryKey: [AnimareApiKeys.EVENT, open, uid],
      queryFn: ({ queryKey }) => eventApi.getEvent({ uid: `${queryKey[2]}` } as GetEventRequest),
      enabled,
    })

    return { data, isPending, error }
  }

  return {
    getEvents,
    getEventDetails,
  }
}
