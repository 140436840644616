import { isBefore, isSameDay } from 'date-fns'
import {
  AnimalCountryDto,
  AnimalCountryType,
  AnimalHealthType,
  AnimalLocationDto,
  AnimalLocationType,
  AnimalStatusDto,
  Configuration,
  ErrorDto,
  Middleware,
  RegistrationRestControllerApi,
  SetRegistrationStatusRequest,
  UpdateAnimalStatusDto,
  UpdateAnimalStatusDtoStatusTypeEnum,
  querystring,
} from '../generated/animare-management-api'

import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'
import { useDate } from './useDate'
import { QueryClient, UseMutationResult, useMutation } from '@tanstack/react-query'
import { AnimareApiKeys } from '../data/apiKeys'

export function useAnimalStatus() {
  const { toUTCDate } = useDate()
  const api = new RegistrationRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   *
   * @param registrationUid
   * @param updateDto
   * @returns
   */
  const updateStatus = async (
    registrationUid: string,
    updateDto: UpdateAnimalStatusDto,
  ): Promise<AnimalStatusDto> => {
    const response = await api
      .setRegistrationStatus({
        uid: registrationUid,
        updateAnimalStatusDto: updateDto,
      } as SetRegistrationStatusRequest)
      .catch((error) => Promise.reject(error))
    return Promise.resolve({ ...response } as AnimalStatusDto)
  }

  /**
   *
   * @param onSuccess
   * @param onError
   * @returns
   */
  const setDogAbroad = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    { registrationUid: string; arrivalDate: Date; date: Date },
    unknown
  > => {
    const setDogAbroadMutation = useMutation({
      mutationFn: ({
        registrationUid,
        arrivalDate,
        date,
      }: {
        registrationUid: string
        arrivalDate: Date
        date: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Country,
          newStatus: {
            country: AnimalCountryType.Abroad,
          },
          arrivalDate: arrivalDate,
          date: date,
        } as UpdateAnimalStatusDto),
      onSuccess: (res) => {
        queryClient.invalidateQueries({
          queryKey: [AnimareApiKeys.REGISTRATION],
        })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        onError(error)
        console.error(error)
      },
    })

    return setDogAbroadMutation
  }

  /**
   *
   * @param registrationUid
   * @returns
   */
  const setDogToFinland = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    {
      registrationUid: string
      arrivalDate: Date
      date: Date
    },
    unknown
  > => {
    const setDogToFinlandMutation = useMutation({
      mutationFn: ({
        registrationUid,
        arrivalDate,
        date,
      }: {
        registrationUid: string
        arrivalDate: Date
        date: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Country,
          newStatus: {
            country: AnimalCountryType.Finland,
          },
          arrivalDate: arrivalDate,
          date: date,
        } as UpdateAnimalStatusDto),
      onSuccess: (res: AnimalStatusDto) => {
        queryClient.invalidateQueries({
          queryKey: [AnimareApiKeys.REGISTRATION],
        })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return setDogToFinlandMutation
  }

  /**
   *
   * @param onSuccess
   * @param onError
   * @returns
   */
  const setDogMissing = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    {
      registrationUid: string
      date: Date
      arrivalDate: Date
    },
    unknown
  > => {
    const setDogMissingMutation = useMutation({
      mutationFn: ({
        registrationUid,
        date,
        arrivalDate,
      }: {
        registrationUid: string
        date: Date
        arrivalDate: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Location,
          newStatus: {
            location: AnimalLocationType.Missing,
          },
          date: toUTCDate(date),
          arrivalDate: toUTCDate(arrivalDate),
        } as UpdateAnimalStatusDto),
      onSuccess: (res: AnimalStatusDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })
    return setDogMissingMutation
  }

  /**
   *
   * @param onSuccess
   * @param onError
   * @returns
   */
  const setDogFound = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    {
      registrationUid: string
      date: Date
      arrivalDate: Date
    },
    unknown
  > => {
    const setDogFoundMutation = useMutation({
      mutationFn: ({
        registrationUid,
        date,
        arrivalDate,
      }: {
        registrationUid: string
        date: Date
        arrivalDate: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Location,
          newStatus: { location: AnimalLocationType.Found },
          date: toUTCDate(date),
          arrivalDate: toUTCDate(arrivalDate),
        } as UpdateAnimalStatusDto),
      onSuccess: (res: AnimalStatusDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })
    return setDogFoundMutation
  }

  /**
   *
   * @param onSuccess
   * @param onError
   * @returns
   */
  const setDogDead = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    {
      registrationUid: string
      date: Date
      arrivalDate: Date
    },
    unknown
  > => {
    const setDogDeadMutation = useMutation({
      mutationFn: ({
        registrationUid,
        date,
        arrivalDate,
      }: {
        registrationUid: string
        date: Date
        arrivalDate: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Health,
          newStatus: { health: AnimalHealthType.Dead },
          date: toUTCDate(date),
          arrivalDate: toUTCDate(arrivalDate),
        } as UpdateAnimalStatusDto),
      onSuccess: (res: AnimalStatusDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return setDogDeadMutation
  }

  const setDogAlive = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: {
    onSuccess: (res: AnimalStatusDto) => void
    onError: (error: ErrorDto) => void
    onSettled: () => void
    queryClient: QueryClient
  }): UseMutationResult<
    AnimalStatusDto,
    ErrorDto,
    {
      registrationUid: string
      arrivalDate: Date
    },
    unknown
  > => {
    const setDogAliveMutation = useMutation({
      mutationFn: ({
        registrationUid,
        arrivalDate,
      }: {
        registrationUid: string
        arrivalDate: Date
      }) =>
        updateStatus(registrationUid, {
          statusType: UpdateAnimalStatusDtoStatusTypeEnum.Health,
          newStatus: { health: AnimalHealthType.Alive },
          // NOTE: this date is not actually used for anything, but the API needs it
          date: toUTCDate(new Date()),
          arrivalDate: arrivalDate,
        } as UpdateAnimalStatusDto),
      onSuccess: (res: AnimalStatusDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
      onSettled,
    })

    return setDogAliveMutation
  }

  /**
   *
   * @param animalStatusDto
   * @returns
   */
  const isDogAbroad = (animalStatusDto: AnimalStatusDto): boolean =>
    animalStatusDto.country.type === AnimalCountryType.Abroad

  /**
   *
   * @param animalStatusDto
   * @returns
   */
  const isDogMissing = (animalStatusDto: AnimalStatusDto): boolean =>
    animalStatusDto.location?.type === AnimalLocationType.Missing

  /**
   *
   * @param animalStatusDto
   * @returns
   */
  const isDogDead = (animalStatusDto: AnimalStatusDto): boolean =>
    animalStatusDto.health === AnimalHealthType.Dead

  /**
   * Returns minimum date of location status
   * If location.date has not been set, use registration start day
   * If location.date is set and last location is missing, use that day
   * If location.date is set and last location is found, use the next day
   * @param location animal current location
   * @param registrationStartDate when registration started, used if location status has not been set manually
   * @returns next possible date to set for location
   */
  const getLocationMinDate = (
    location: AnimalLocationDto,
    registrationStartDate: Date | undefined,
  ): Date => {
    if (!location.date) {
      return new Date(`${registrationStartDate}`)
    }
    const minDate = new Date(location.date)
    minDate.setHours(0, 0, 0, 0)

    // An animal can be set as missing again only on the next day
    if (location.type === AnimalLocationType.Found) {
      minDate.setDate(minDate.getDate() + 1)
    }

    return minDate
  }

  /**
   * If location min date is over current date, setting location is not possible
   * @param location animal current location
   * @param registrationStartDate when registration started, used if location status has not been set manually
   * @returns if setting location is enabled
   */
  const isSetLocationEnabled = (
    location: AnimalLocationDto,
    registrationStartDate: Date | undefined,
  ): boolean => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const allowedMinDate = getLocationMinDate(location, registrationStartDate)
    return isBefore(allowedMinDate, today) || isSameDay(allowedMinDate, today)
  }

  const getCountryMinDate = (
    country: AnimalCountryDto,
    registrationStartDate: Date | undefined,
  ) => {
    if (!country.date) {
      return new Date(`${registrationStartDate}`)
    }
    const minDate = new Date(country.date)
    minDate.setHours(0, 0, 0, 0)

    // An animal can be set as abroad again only on the next day
    if (country.type === AnimalCountryType.Finland) {
      minDate.setDate(minDate.getDate() + 1)
    }
    return minDate
  }

  const isSetCountryEnabled = (
    country: AnimalCountryDto,
    registrationStartDate: Date | undefined,
  ) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const allowedMinDate = getCountryMinDate(country, registrationStartDate)
    return isBefore(allowedMinDate, today) || isSameDay(allowedMinDate, today)
  }

  return {
    getCountryMinDate,
    getLocationMinDate,
    isDogAbroad,
    isDogMissing,
    isDogDead,
    isSetCountryEnabled,
    isSetLocationEnabled,
    setDogFound,
    setDogAbroad,
    setDogToFinland,
    setDogMissing,
    setDogDead,
    setDogAlive,
  }
}
