/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { PossessorDto } from './PossessorDto'
import { PossessorDtoFromJSON, PossessorDtoFromJSONTyped, PossessorDtoToJSON } from './PossessorDto'
import type { RegistrationDto } from './RegistrationDto'
import {
  RegistrationDtoFromJSON,
  RegistrationDtoFromJSONTyped,
  RegistrationDtoToJSON,
} from './RegistrationDto'

/**
 *
 * @export
 * @interface PossessorResponseDto
 */
export interface PossessorResponseDto {
  /**
   *
   * @type {PossessorDto}
   * @memberof PossessorResponseDto
   */
  possessor?: PossessorDto
  /**
   *
   * @type {Array<RegistrationDto>}
   * @memberof PossessorResponseDto
   */
  registrations?: Array<RegistrationDto>
}

/**
 * Check if a given object implements the PossessorResponseDto interface.
 */
export function instanceOfPossessorResponseDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PossessorResponseDtoFromJSON(json: any): PossessorResponseDto {
  return PossessorResponseDtoFromJSONTyped(json, false)
}

export function PossessorResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PossessorResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    possessor: !exists(json, 'possessor') ? undefined : PossessorDtoFromJSON(json['possessor']),
    registrations: !exists(json, 'registrations')
      ? undefined
      : (json['registrations'] as Array<any>).map(RegistrationDtoFromJSON),
  }
}

export function PossessorResponseDtoToJSON(value?: PossessorResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    possessor: PossessorDtoToJSON(value.possessor),
    registrations:
      value.registrations === undefined
        ? undefined
        : (value.registrations as Array<any>).map(RegistrationDtoToJSON),
  }
}
