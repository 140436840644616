import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../..'
import EditPossessor from '../../../components/EditPossessor'

export default function EditPossessorView() {
  const navigate = useNavigate()
  const { possessorUid } = useParams()
  if (!possessorUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }
  return <EditPossessor possessorUid={possessorUid} />
}
