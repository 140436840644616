/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { KeyValueDto } from './KeyValueDto'
import { KeyValueDtoFromJSON, KeyValueDtoFromJSONTyped, KeyValueDtoToJSON } from './KeyValueDto'

/**
 *
 * @export
 * @interface OpenGateRequestDto
 */
export interface OpenGateRequestDto {
  /**
   *
   * @type {Array<KeyValueDto>}
   * @memberof OpenGateRequestDto
   */
  data?: Array<KeyValueDto>
  /**
   *
   * @type {Array<KeyValueDto>}
   * @memberof OpenGateRequestDto
   */
  answers?: Array<KeyValueDto>
}

/**
 * Check if a given object implements the OpenGateRequestDto interface.
 */
export function instanceOfOpenGateRequestDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function OpenGateRequestDtoFromJSON(json: any): OpenGateRequestDto {
  return OpenGateRequestDtoFromJSONTyped(json, false)
}

export function OpenGateRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): OpenGateRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    data: !exists(json, 'data') ? undefined : (json['data'] as Array<any>).map(KeyValueDtoFromJSON),
    answers: !exists(json, 'answers')
      ? undefined
      : (json['answers'] as Array<any>).map(KeyValueDtoFromJSON),
  }
}

export function OpenGateRequestDtoToJSON(value?: OpenGateRequestDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    data: value.data === undefined ? undefined : (value.data as Array<any>).map(KeyValueDtoToJSON),
    answers:
      value.answers === undefined
        ? undefined
        : (value.answers as Array<any>).map(KeyValueDtoToJSON),
  }
}
