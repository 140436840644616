import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, FormControl, FormGroup, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { Message, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SnackBar, SnackBarTypes } from '../../../components/SnackBar'
import { LimitedAnimalResponseDto } from '../../../generated/animare-management-api/models/LimitedAnimalResponseDto'
import { ErrorDto } from '../../../generated/openapi'

import { useQueryClient } from '@tanstack/react-query'
import InlineValidationError from '../../../components/Form/InlineValidationError'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { MicrochipHistory } from '../../../components/MicrochipHistory'
import { MicrochipFormData } from '../../../components/MicrochipSearch'
import { ShelterDog } from '../../../components/ShelterDog'
import { ShelterPossessor } from '../../../components/ShelterPossessor'
import { Roles } from '../../../data/roles'
import { useAnimal } from '../../../hooks/useAnimal'
import { ErrorCodes, hasErrorCodes } from '../../../utils/errorUtils'

const MICROCHIP_REGEX = /^[0-9]+$/
const MICROCHIP_LENGTH = 15

export function ShelterSearch() {
  const { t } = useTranslation('common')
  const [noMicrochipResults, setNoMicrochipResults] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const { getLimitedRegistration } = useAnimal()
  const queryClient = useQueryClient()

  const { mutate: getLimitedRegistrationMutation } = getLimitedRegistration({
    onSuccess: (response: LimitedAnimalResponseDto) => {
      setDogData(response)
    },
    onError: (error: ErrorDto) => {
      if (
        hasErrorCodes(error, [
          ErrorCodes.MicrochipNotFound,
          ErrorCodes.RegistrationNotFound,
          ErrorCodes.AnimalNotFound,
        ])
      ) {
        setNoMicrochipResults(true)
      } else {
        // Show error notification if there is an actual error,
        // not just a nonexistent microchip code
        showErrorNotification()
        setApiError(error)
      }

      console.error(error)
    },
    onSettled: () => setLoading(false),
    queryClient,
  })

  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const [dogData, setDogData] = useState<LimitedAnimalResponseDto>()

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    min: t('errors.validation.lengthRequirement', { number: MICROCHIP_LENGTH }) as Message,
    wrongFormat: t('errors.validation.wrongFormat') as Message,
  }

  const schema = yup.object().shape({
    microchip: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .min(MICROCHIP_LENGTH, errorMessages.min)
      .max(MICROCHIP_LENGTH)
      .matches(MICROCHIP_REGEX, errorMessages.wrongFormat),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<MicrochipFormData>({ resolver: yupResolver(schema) })

  /**
   * Gets specific registration by microchip code.
   * @param event
   *
   *
   */
  const onSubmit = async (form: MicrochipFormData) => {
    const { microchip } = form
    if (!microchip) {
      console.error('No microchip found.')
      return
    }
    setDogData(undefined)
    setNoMicrochipResults(false)
    setApiError(undefined)
    setShowNotification(undefined)

    setLoading(true)

    return getLimitedRegistrationMutation(microchip)
  }

  /**
   * Reset microchip field and ui back to initial state
   */
  const handleReset = () => {
    setNoMicrochipResults(false)
    setApiError(undefined)
    setShowNotification(undefined)
    setLoading(false)
    setValue('microchip', undefined)
    setDogData(undefined)
  }

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} onReset={() => handleReset()}>
        <FormGroup sx={{ display: 'flex', gap: 2 }} data-qa="shelter-search">
          <FormControl>
            <TextField
              {...register('microchip')}
              data-qa="microchip"
              id={'microchip'}
              label={t('registrationSearchPage.searchSection.microchipLabel')}
              error={!!errors.microchip}
              inputProps={{
                type: 'text',
                inputMode: 'numeric',
                maxLength: 15,
              }}
              autoComplete={'off'}
              sx={{ width: '40%' }}
            />
            {!!errors.microchip && <InlineValidationError content={errors.microchip?.message} />}
          </FormControl>
          <Box>
            <Button variant="contained" type="submit" sx={{ mr: 1 }} data-qa="microchip-submit">
              {t('registrationSearchPage.searchSection.button')}
            </Button>
            <Button variant="outlined" type="reset" data-qa="microchip-reset">
              {t('registrationSearchPage.searchSection.reset')}
            </Button>
          </Box>
        </FormGroup>
      </Box>

      {!loading && dogData && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h2" sx={{ mb: 4 }} data-qa="dog-name">
            {dogData.name}
          </Typography>
          <Box sx={{ mb: 6 }}>
            <ShelterDog registration={dogData} />
          </Box>
          {dogData.microchips && (
            <Box sx={{ mb: 6 }}>
              <MicrochipHistory
                microchipData={dogData.microchips.map((microchip) => ({
                  date: microchip.date,
                  number: microchip.number,
                }))}
                role={Roles.Shelter}
              />
            </Box>
          )}
          <Box>
            <Typography variant="h2" sx={{ mb: 4 }}>
              {t('dogView.possessorInformation')}
            </Typography>
            {dogData?.possessor && <ShelterPossessor possessor={dogData.possessor} />}
          </Box>
        </Box>
      )}

      {!loading && !!noMicrochipResults && (
        <Box data-qa="no-microchip-results">
          <Typography variant="h2">
            {t('registrationSearchPage.searchSection.noResultsTitle')}
          </Typography>
          <Typography variant="body1">
            {t('registrationSearchPage.searchSection.noMicrochipResultsInfo')}
          </Typography>
        </Box>
      )}

      {loading && <LoadingSpinner />}

      {!loading && apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={showNotification === SnackBarTypes.ERROR && !!apiError}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
        />
      )}
    </>
  )
}
