import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RegistrationDto } from '../generated/animare-management-api'
import DateFormatter from './DateFormatter'
import Field from './Field'

export interface Props {
  registration: RegistrationDto
}
export default function DogDates({ registration }: Props) {
  const { t } = useTranslation('common')
  return (
    <Grid container data-qa="dog-dates-container" component="dl" spacing={2} sx={{ mb: 4 }}>
      {registration.startDate && (
        <Field
          title={t('common.animal.startDate')}
          text={<DateFormatter date={registration.startDate} />}
          dataQa="startDate"
        />
      )}
      {registration.transferDate && (
        <Field
          title={t('common.animal.transferDate')}
          text={<DateFormatter date={registration.transferDate} />}
          dataQa="transferDate"
        />
      )}
      {registration.arrivalDate && (
        <Field
          title={t('registrationForm.info.formArrival')}
          text={<DateFormatter date={registration.arrivalDate} />}
          dataQa="arrivalDate"
        />
      )}
    </Grid>
  )
}
