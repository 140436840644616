import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthProvider'
import { AppRoutes } from '../../routes'

export function LogoutTimer() {
  const LOGOUT_TIME_MS = 1000 * 60 * 30 // 30 minutes
  const navigate = useNavigate()
  const { currentUser }: { currentUser: any } = useAuthContext()
  const [logoutTimer, setLogoutTimer] = useState<null | NodeJS.Timeout>(null)
  useEffect(() => {
    if (!!logoutTimer) {
      clearTimeout(logoutTimer)
      setLogoutTimer(null)
    }
    if (!!currentUser) {
      setLogoutTimer(setTimeout(() => navigate(AppRoutes.Logout), LOGOUT_TIME_MS))
    }
  }, [currentUser])
  return <></>
}
