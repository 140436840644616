import * as yup from 'yup'

import { Message } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDate } from '../../hooks/useDate'
import { useRegistration } from '../../hooks/useRegistration'
import { textRegex } from '../Form/registrationFormSchema'

export const MAX_NAME_LENGTH = 80
export const MAX_ORG_NAME_LENGTH = 255
export const MAX_ADDRESS_LENGTH = 255
export const MAX_POSTALCODE_LENGTH = 5
export const MAX_PHONE_NUMBER_LENGTH = 20
export const MAX_EMAIL_LENGTH = 255

const { getRegistrationMinDate, getRegistrationMaxDate } = useRegistration()

export const MIN_DATE = getRegistrationMinDate()
export const MAX_DATE = getRegistrationMaxDate()

export interface AnimalProtectionSearchFormValues {
  address?: string
  breedCode?: string
  firstName?: string
  lastName?: string
  municipalityCode?: string
  organizationName?: string
  postalCode?: string
}

export const getAnimalProtectionSearchSchema = () => {
  const { t } = useTranslation('common')

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    postalCodeLength: t('errors.validation.lengthRequirement', { number: '5' }),
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    atLeastOne: t('errors.monitoring.municipalityCode-plus-one') as Message,
  }

  return yup
    .object()
    .shape({
      municipalityCode: yup
        .string()
        .min(3, errorMessages.required)
        .max(5)
        .matches(/^\d+$/, errorMessages.wrongFormat)
        .required(errorMessages.required),
      postalCode: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value))
        .min(5, errorMessages.postalCodeLength)
        .max(MAX_POSTALCODE_LENGTH, errorMessages.postalCodeLength)
        .matches(/^\d+$/, errorMessages.wrongFormat),
      address: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value))
        .min(1)
        .max(MAX_ADDRESS_LENGTH)
        .matches(textRegex, errorMessages.wrongFormat),
      firstName: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value))
        .min(0)
        .max(MAX_NAME_LENGTH)
        .matches(textRegex, errorMessages.wrongFormat),
      lastName: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value))
        .min(0)
        .max(MAX_NAME_LENGTH)
        .matches(textRegex, errorMessages.wrongFormat),
      organizationName: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value))
        .min(1)
        .max(MAX_ORG_NAME_LENGTH)
        .matches(textRegex, errorMessages.wrongFormat),
      breedCode: yup
        .string()
        .notRequired()
        .transform((value: string) => (value === '' ? undefined : value)),
    })
    .test(
      'at-least-one',
      'At least one of address, postalCode, firstName, lastName or organizationName must be provided',
      function (value) {
        const { address, postalCode, firstName, lastName, organizationName } = value
        if (!!address || !!postalCode || !!firstName || !!lastName || !!organizationName) {
          return true
        }
        return this.createError({
          path: 'postalCode',
          message: errorMessages.atLeastOne,
        })
      },
    )
}

export interface ProfessionalActivitySearchFormValues {
  minimumRegisteredAnimals?: number
  municipalityCode?: string
}

export const getProfessionalActivitySearchSchema = () => {
  const { t } = useTranslation('common')

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    minimumRegisteredAnimals: t('errors.monitoring.minimumRegisteredAnimals', { value: '7' }),
  }

  return yup.object().shape({
    municipalityCode: yup
      .string()
      .min(3, errorMessages.required)
      .max(5)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    minimumRegisteredAnimals: yup
      .number()
      .nullable()
      .transform((value: number, originalValue: string) => {
        return originalValue.trim() === '' ? null : value
      })
      .required(errorMessages.required)
      .typeError(errorMessages.wrongFormat)
      .min(7, errorMessages.minimumRegisteredAnimals)
      .max(999),
  })
}

export interface PossessorChangeSearchFormValues {
  minimumPossessorChangeCount?: number
  municipalityCode?: string
}

export const getPossessorChangeSearchSchema = () => {
  const { t } = useTranslation('common')

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    minimumPossessorChanges: t('errors.monitoring.minimumPossessorChanges', { value: '10' }),
  }

  return yup.object().shape({
    municipalityCode: yup
      .string()
      .min(3, errorMessages.required)
      .max(5)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    minimumPossessorChangeCount: yup
      .number()
      .nullable()
      .transform((value: number, originalValue: string) => {
        return originalValue.trim() === '' ? null : value
      })
      .required(errorMessages.required)
      .typeError(errorMessages.wrongFormat)
      .min(10, errorMessages.minimumPossessorChanges)
      .max(999),
  })
}

export interface FirstRegistrationsFormValues {
  endDate?: Date | string
  firstRegistrationCount?: number
  municipalityCode?: string
  startDate?: Date | string
}

export const getFirstRegistrationsSearchSchema = () => {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    minimumRegisteredAnimals: t('errors.monitoring.minimumRegisteredAnimals', { value: '10' }),
    max: t('registrationForm.error.date.max') as Message,
  }

  return yup.object().shape({
    municipalityCode: yup
      .string()
      .min(3, errorMessages.required)
      .max(5)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    endDate: yup
      .date()
      .nullable()
      .transform((curr: any, orig: any) => (orig === '' || isNaN(orig?.valueOf()) ? null : curr))
      .typeError(errorMessages.wrongFormat)
      .required(errorMessages.required)
      .transform((value) => !!value && toUTCDate(value))
      .min(MIN_DATE, errorMessages.wrongFormat)
      .max(MAX_DATE, errorMessages.max),
    startDate: yup
      .date()
      .nullable()
      .transform((curr: any, orig: any) => (orig === '' || isNaN(orig?.valueOf()) ? null : curr))
      .typeError(errorMessages.wrongFormat)
      .required(errorMessages.required)
      .transform((value) => !!value && toUTCDate(value))
      .min(MIN_DATE, errorMessages.wrongFormat)
      .max(MAX_DATE, errorMessages.max),
    firstRegistrationCount: yup
      .number()
      .nullable()
      .transform((value: number, originalValue: string) => {
        return originalValue.trim() === '' ? null : value
      })
      .required(errorMessages.required)
      .typeError(errorMessages.wrongFormat)
      .min(10, errorMessages.minimumRegisteredAnimals)
      .max(999),
  })
}

export interface AnimalImportingSearchFormValues {
  birthCountryCode?: string
  allCountries?: boolean
  municipalityCode?: string
  registrationStartDate?: Date
  registrationEndDate?: Date
  birthStartDate?: Date
  birthEndDate?: Date
  possessorChangeStartDate?: Date
  possessorChangeEndDate?: Date
}

export const getAnimalImportingSearchSchema = () => {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    max: t('registrationForm.error.date.max') as Message,
    dateMisMatch: t('errors.monitoring.dateMisMatch') as Message,
    atLeastOne: t('errors.monitoring.animalImportingInadequateSearch') as Message,
    endDateMissing: t('errors.monitoring.endDateMissing') as Message,
    startDateMissing: t('errors.monitoring.startDateMissing' as Message),
  }

  return yup
    .object()
    .shape({
      birthCountryCode: yup
        .string()
        .min(3, errorMessages.required)
        .max(5)
        .matches(/^\d+$/, errorMessages.wrongFormat)
        .when('allCountries', {
          is: (value: boolean) => value === true,
          then: (schema: yup.StringSchema) =>
            schema
              .trim()
              .transform((orig: any) => (orig === '' ? undefined : orig))
              .notRequired()
              .nullable(),
          otherwise: (schema: yup.StringSchema) => schema.required(errorMessages.required),
        }),
      allCountries: yup.boolean().transform((value: boolean) => (value === null ? false : value)),
      municipalityCode: yup
        .string()
        .notRequired()
        .transform((curr: any, orig: any) => (orig === '' || isNaN(orig?.valueOf()) ? null : curr))
        .matches(/^\d+$/, errorMessages.wrongFormat),
      birthStartDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
      birthEndDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
      registrationStartDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
      registrationEndDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
      possessorChangeStartDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
      possessorChangeEndDate: yup
        .date()
        .transform((curr: any, orig: any) =>
          orig === '' || isNaN(orig?.valueOf()) ? null : toUTCDate(curr),
        )
        .nullable()
        .notRequired()
        .typeError(errorMessages.wrongFormat)
        .min(MIN_DATE, errorMessages.wrongFormat)
        .max(MAX_DATE, errorMessages.max),
    })

    .test('date-validation', 'Date fields validation', function (value) {
      // Check for end dates without start dates
      if (!!value.birthEndDate && !value.birthStartDate) {
        return this.createError({
          path: 'birthEndDate',
          message: errorMessages.startDateMissing,
        })
      }

      if (!!value.registrationEndDate && !value.registrationStartDate) {
        return this.createError({
          path: 'registrationEndDate',
          message: errorMessages.startDateMissing,
        })
      }

      if (!!value.possessorChangeEndDate && !value.possessorChangeStartDate) {
        return this.createError({
          path: 'possessorChangeEndDate',
          message: errorMessages.startDateMissing,
        })
      }

      // Check for at least one valid date range
      const hasBirthDateRange = value.birthStartDate && value.birthEndDate
      const hasRegistrationDateRange = value.registrationStartDate && value.registrationEndDate
      const hasPossessorChangeDateRange =
        value.possessorChangeStartDate && value.possessorChangeEndDate

      if (!(hasBirthDateRange || hasRegistrationDateRange || hasPossessorChangeDateRange)) {
        return this.createError({
          path: 'birthStartDate',
          message: errorMessages.atLeastOne,
        })
      }

      return true
    })
}
