import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../..'
import ChangePossessorCompleted from '../../../../components/ChangePossessorCompleted'

const ChangePossessorCompletedView = () => {
  const navigate = useNavigate()

  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }
  return <ChangePossessorCompleted registrationUid={registrationUid} />
}

export default ChangePossessorCompletedView
