declare global {
  interface Window {
    _mtm: any
  }
}

const matomoUrl = import.meta.env.VITE_APP_MATOMO_URL

export const useMatomo = () => {
  if (matomoUrl !== undefined) {
    const _mtm = (window._mtm = window._mtm || [])
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
    const d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.async = true
    g.src = matomoUrl
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    s.parentNode!.insertBefore(g, s)
  }
}
