import { useNavigate, useParams } from 'react-router-dom'
import ManageMicrochips from '../../components/ManageMicrochips'
import { AppRoutes } from '../index'

export default function NewMicrochipView() {
  const navigate = useNavigate()

  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }

  return <ManageMicrochips registrationUid={registrationUid} />
}
