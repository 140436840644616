import { useTranslation } from 'react-i18next'
import { AnimalDtoBreedSizeEnum } from '../generated/animare-management-api/models/AnimalDto'

export interface Props {
  code?: string
}
export default function BreedSize({ code }: Props) {
  const { t } = useTranslation('common')
  const breedSizes = Object.values(AnimalDtoBreedSizeEnum)
  if (code === undefined || code === null || !breedSizes.find((s) => code === s)) {
    return <>{t('common.size.unknown')}</>
  }
  return <>{t(`common.size.${code}`)}</>
}
