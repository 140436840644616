/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface NewMicrochipRequestDto
 */
export interface NewMicrochipRequestDto {
  /**
   * When the newmicrochip form has arrived (only for paper registration).
   * @type {Date}
   * @memberof NewMicrochipRequestDto
   */
  arrivalDate: Date
  /**
   * The number of the microchip implanted in the animal.
   * @type {string}
   * @memberof NewMicrochipRequestDto
   */
  microchipNumber: string
  /**
   * Date of installation of the microchip.
   * @type {Date}
   * @memberof NewMicrochipRequestDto
   */
  microchipDate: Date
  /**
   * Setter of the microchip as reported by the possessor.
   * @type {string}
   * @memberof NewMicrochipRequestDto
   */
  microchipSetterName: string
  /**
   * Address of the microchip setter as reported by the possessor.
   * @type {string}
   * @memberof NewMicrochipRequestDto
   */
  microchipSetterAddress: string
}

/**
 * Check if a given object implements the NewMicrochipRequestDto interface.
 */
export function instanceOfNewMicrochipRequestDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'arrivalDate' in value
  isInstance = isInstance && 'microchipNumber' in value
  isInstance = isInstance && 'microchipDate' in value
  isInstance = isInstance && 'microchipSetterName' in value
  isInstance = isInstance && 'microchipSetterAddress' in value

  return isInstance
}

export function NewMicrochipRequestDtoFromJSON(json: any): NewMicrochipRequestDto {
  return NewMicrochipRequestDtoFromJSONTyped(json, false)
}

export function NewMicrochipRequestDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NewMicrochipRequestDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    arrivalDate: new Date(json['arrivalDate']),
    microchipNumber: json['microchipNumber'],
    microchipDate: new Date(json['microchipDate']),
    microchipSetterName: json['microchipSetterName'],
    microchipSetterAddress: json['microchipSetterAddress'],
  }
}

export function NewMicrochipRequestDtoToJSON(value?: NewMicrochipRequestDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    arrivalDate: value.arrivalDate.toISOString().substr(0, 10),
    microchipNumber: value.microchipNumber,
    microchipDate: value.microchipDate.toISOString().substr(0, 10),
    microchipSetterName: value.microchipSetterName,
    microchipSetterAddress: value.microchipSetterAddress,
  }
}
