import { Box, Grid, Divider } from '@mui/material'
import logo from '/ruokavirasto_logo.svg'
import { useTranslation } from 'react-i18next'

function Footer() {
  const { t } = useTranslation('common')

  return (
    <Box component="footer" sx={{ p: { xl: 9, lg: 6, sm: 3 } }}>
      <Divider
        aria-hidden="true"
        sx={{
          mb: { xl: 8, xs: 4 },
          ml: { xl: 6, lg: 3, sm: 0 },
          mr: { xl: 6, lg: 3, sm: 0 },
        }}
      />
      <Grid
        container
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Grid item xs={12} md={6} lg={3} sx={{ pl: { xs: 2, xl: 6 } }}>
          <Box
            component="img"
            src={logo}
            alt={t('footer.logoAlt')}
            sx={{ width: { sm: '420px', xs: '260px' }, height: { sm: '86px', xs: '53px' } }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
