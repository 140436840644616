import * as yup from 'yup'

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { Message, useForm } from 'react-hook-form'
import { SnackBar, SnackBarTypes } from '../SnackBar'

import { ErrorCodes } from '../../utils/errorUtils'
import { ErrorDto } from '../../generated/openapi'
import Field from '../Field'
import InlineValidationError from './InlineValidationError'
import { MicrochipDto } from '../../generated/animare-management-api'
import SpacerBox from '../SpacerBox'
import { useMicrochip } from '../../hooks/useMicrochip'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import InputArrivalDate, { arrivalDateSchema } from './InputArrivalDate'

export interface Props {
  microchip: MicrochipDto
  onSuccess: () => void
  onCancel: () => void
}

export type MicrochipNumberChangeFormValues = {
  arrivalDate: Date
  microchipNumber: string
}

const microchipNumberRegex = /^[0-9]+$/

export function MicrochipNumberChangeForm({ microchip, onSuccess, onCancel }: Props) {
  const { t } = useTranslation('common')
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const { changeMicrochipNumber } = useMicrochip()
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const queryClient = useQueryClient()

  const { mutate: changeMicrochipNumberMutation } = changeMicrochipNumber({
    onSuccess: () => {
      onSuccess()
    },
    onError: (error: ErrorDto) => {
      if (error.code === ErrorCodes.DuplicateRegistration) {
        setError(
          'microchipNumber',
          { type: 'custom', message: t('errors.duplicate-microchip-code') as string },
          { shouldFocus: true },
        )
      } else {
        console.error(error)
        setApiError(error)
        showErrorNotification()
      }

      console.error(error)
    },
    queryClient,
  })

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const errorMessages = {
    microchip: {
      notEnough: t('registrationForm.error.microchipNumber.notEnough') as Message,
      wrongFormat: t('registrationForm.error.microchipNumber.wrongFormat') as Message,
      duplicateCode: t('errors.duplicate-microchip-code') as Message,
    },
  }

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    microchipNumber: yup
      .string()
      .min(15, errorMessages.microchip.notEnough)
      .max(15)
      .matches(microchipNumberRegex, errorMessages.microchip.wrongFormat)
      .notOneOf([microchip.microchipNumber], errorMessages.microchip.duplicateCode)
      .required(),
  })

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<MicrochipNumberChangeFormValues>({ resolver: yupResolver(schema) })

  const onSubmit = async (form: MicrochipNumberChangeFormValues) => {
    setApiError(undefined)

    changeMicrochipNumberMutation({
      uid: microchip.uid as string,
      arrivalDate: form.arrivalDate,
      microchipNumber: microchip.microchipNumber,
      newMicrochipNumber: form.microchipNumber,
    })
  }

  return (
    <SpacerBox gap={3} data-qa="microchipnumber-change-form">
      <SpacerBox gap={2} data-qa="current-chipnumber">
        <Typography variant="body1">{t('dogView.dialogChangeMicrochipCode.helpText')}</Typography>
        <Divider />
        <Field
          title={t('dogView.dialogChangeMicrochipCode.currentMicrochipNumber')}
          text={microchip.microchipNumber}
        />
        {apiError && (
          <SnackBar
            type={SnackBarTypes.ERROR}
            open={!!apiError && showNotification === SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              setShowNotification(undefined)
              return setApiError(undefined)
            }}
          />
        )}
      </SpacerBox>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          sx={{
            display: 'grid',
            gap: 4,
            mt: 2,
            width: '100%',
          }}
        >
          <FormControl>
            <InputArrivalDate control={control} errors={errors} />
          </FormControl>
          <FormControl sx={{ gridColumnStart: '1', gridColumnEnd: '2' }}>
            <InputLabel htmlFor="microchip-code">
              {t('common.animal.microchipCode')} <i aria-hidden={true}>*</i>
              <i className="visible-hidden">{t('registrationForm.field.required')}</i>
            </InputLabel>
            <TextField
              {...register('microchipNumber')}
              id="microchip-code"
              data-qa="microchip-number"
              error={!!errors.microchipNumber}
              inputProps={{
                type: 'text',
                inputMode: 'numeric',
                maxLength: 15,
              }}
            />
            {!!errors.microchipNumber ? (
              <InlineValidationError content={errors.microchipNumber?.message} />
            ) : (
              <FormHelperText>{t('registrationForm.help.microchipCode')}</FormHelperText>
            )}
          </FormControl>
          <Grid container justifyContent="flex-end">
            <Button variant="text" disabled={isSubmitting} onClick={onCancel}>
              {t('dogView.dialogChangeMicrochipCode.cancel')}
            </Button>
            <Button variant="text" type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <Box aria-label={t('common.loading')}>
                  <CircularProgress size={10} /> {t('common.loading') + '...'}
                </Box>
              ) : (
                t('dogView.dialogChangeMicrochipCode.save')
              )}
            </Button>
          </Grid>
        </FormGroup>
      </Box>
    </SpacerBox>
  )
}
