/* tslint:disable */
/* eslint-disable */
/**
 * Animare Codes API
 * Provides codes and their labels in various languages used by Animare applications.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { Code } from '../models'
import { CodeFromJSON, CodeToJSON } from '../models'

export interface GetBreedRequest {
  code: string
}

export interface GetBreedsRequest {
  q?: string
  lang?: GetBreedsLangEnum
}

/**
 *
 */
export class BreedsApi extends runtime.BaseAPI {
  /**
   */
  async getBreedRaw(
    requestParameters: GetBreedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Code>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getBreed.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/breed/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => CodeFromJSON(jsonValue))
  }

  /**
   */
  async getBreed(
    requestParameters: GetBreedRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Code> {
    const response = await this.getBreedRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async getBreedsRaw(
    requestParameters: GetBreedsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Code>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/breeds`
          .replace(`{${'q'}}`, encodeURIComponent(String(requestParameters.q)))
          .replace(`{${'lang'}}`, encodeURIComponent(String(requestParameters.lang))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CodeFromJSON))
  }

  /**
   */
  async getBreeds(
    requestParameters: GetBreedsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Code>> {
    const response = await this.getBreedsRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetBreedsLangEnum = {
  En: 'en',
  Fi: 'fi',
  Sv: 'sv',
} as const
export type GetBreedsLangEnum = (typeof GetBreedsLangEnum)[keyof typeof GetBreedsLangEnum]
