import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface SpacerBoxProps extends BoxProps {
  gap?: number
  centercontent?: any
  variant?: string
  orientation?: string
}

const DEFAULT_GAP = 5
const ORIENTATION_HORIZONTAL = 'horizontal'

const SpacerBox = styled(Box)<SpacerBoxProps>(({ theme, gap, centercontent, orientation }) => ({
  display: 'flex',
  flexDirection: orientation === ORIENTATION_HORIZONTAL ? 'row' : 'column',
  gap: theme.spacing(gap || DEFAULT_GAP),
  marginLeft: centercontent ? 'auto' : 'initial',
  marginRight: centercontent ? 'auto' : 'initial',
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%',
  },
}))

export default SpacerBox
