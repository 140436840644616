/* tslint:disable */
/* eslint-disable */
/**
 * Animare Codes API
 * Provides codes and their labels in various languages used by Animare applications.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { Code } from '../models'
import { CodeFromJSON, CodeToJSON } from '../models'

export interface GetCountriesRequest {
  q?: string
  lang?: GetCountriesLangEnum
}

export interface GetCountryRequest {
  code: string
}

/**
 *
 */
export class CountriesApi extends runtime.BaseAPI {
  /**
   */
  async getCountriesRaw(
    requestParameters: GetCountriesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<Code>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/countries`
          .replace(`{${'q'}}`, encodeURIComponent(String(requestParameters.q)))
          .replace(`{${'lang'}}`, encodeURIComponent(String(requestParameters.lang))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CodeFromJSON))
  }

  /**
   */
  async getCountries(
    requestParameters: GetCountriesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<Code>> {
    const response = await this.getCountriesRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   */
  async getCountryRaw(
    requestParameters: GetCountryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Code>> {
    if (requestParameters.code === null || requestParameters.code === undefined) {
      throw new runtime.RequiredError(
        'code',
        'Required parameter requestParameters.code was null or undefined when calling getCountry.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/country/{code}`.replace(
          `{${'code'}}`,
          encodeURIComponent(String(requestParameters.code)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => CodeFromJSON(jsonValue))
  }

  /**
   */
  async getCountry(
    requestParameters: GetCountryRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Code> {
    const response = await this.getCountryRaw(requestParameters, initOverrides)
    return await response.value()
  }
}

/**
 * @export
 */
export const GetCountriesLangEnum = {
  En: 'en',
  Fi: 'fi',
  Sv: 'sv',
} as const
export type GetCountriesLangEnum = (typeof GetCountriesLangEnum)[keyof typeof GetCountriesLangEnum]
