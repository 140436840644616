import {
  MonitoringSearchResult,
  MonitoringSearchResultPossessorTypeEnum,
  PossessorDtoTypeEnum,
  PossessorResponseDto,
} from '../../generated/animare-management-api'
import {
  deadDog,
  fullOrganizationRegistration,
  fullRegistration,
  possessorChangeRegistration,
  statusAbroad,
  statusMissing,
} from './registrations'
import { organizationPossessorDto, possessorDto, possessorWithChangeCode } from './possessors'

export const animalProtectionSearchPossessor = [
  {
    possessorUid: 'uid-animal-protection-search-1',
    firstName: 'Aku',
    lastName: 'Ankka',
    organizationName: undefined,
    possessorType: MonitoringSearchResultPossessorTypeEnum.Person,
    address: 'Paratiisitie 13',
    postalCode: '33960',
    municipalityCode: '700',
    animalCount: 3,
  },
  {
    possessorUid: possessorDto.uid,
    firstName: possessorDto.firstName,
    lastName: possessorDto.lastName,
    organizationName: undefined,
    possessorType: MonitoringSearchResultPossessorTypeEnum.Person,
    address: possessorDto.address,
    postalCode: possessorDto.postalCode,
    municipalityCode: possessorDto.municipalityCode,
    animalCount: 3,
  },
  {
    possessorUid: 'uid-animal-protection-search-3',
    firstName: undefined,
    lastName: undefined,
    organizationName: 'ACME',
    possessorType: MonitoringSearchResultPossessorTypeEnum.Organization,
    address: 'Ankkalinnastreet 123',
    postalCode: '00300',
    municipalityCode: '700',
    animalCount: 20,
  },
  {
    possessorUid: organizationPossessorDto.uid,
    firstName: undefined,
    lastName: undefined,
    organizationName: organizationPossessorDto.organizationName,
    possessorType: MonitoringSearchResultPossessorTypeEnum.Organization,
    address: organizationPossessorDto.address,
    postalCode: organizationPossessorDto.postalCode,
    municipalityCode: organizationPossessorDto.municipalityCode,
    animalCount: 20,
  },
  {
    possessorUid: 'uid-animal-protection-search-4',
    firstName: undefined,
    lastName: undefined,
    organizationName: 'BACME',
    possessorType: MonitoringSearchResultPossessorTypeEnum.Organization,
    address: 'Ankkalinnastreet 124',
    postalCode: '00300',
    municipalityCode: '700',
    animalCount: 20,
  },
  {
    possessorUid: possessorWithChangeCode.uid,
    firstName: possessorWithChangeCode.firstName,
    lastName: possessorWithChangeCode.lastName,
    organizationName: undefined,
    possessorType: MonitoringSearchResultPossessorTypeEnum.Person,
    address: possessorWithChangeCode.address,
    postalCode: possessorWithChangeCode.postalCode,
    municipalityCode: possessorWithChangeCode.municipalityCode,
    animalCount: 4,
  },
  {
    possessorUid: 'uid-animal-protection-search-6',
    firstName: 'Kapteeni',
    lastName: 'Koukku',
    organizationName: undefined,
    possessorType: MonitoringSearchResultPossessorTypeEnum.Person,
    address: 'Rahasäiliö 1',
    postalCode: '00100',
    municipalityCode: '700',
    animalCount: 2,
  },
  {
    possessorUid: 'uid-animal-protection-search-7',
    firstName: undefined,
    lastName: undefined,
    organizationName: 'CACME',
    possessorType: MonitoringSearchResultPossessorTypeEnum.Organization,
    address: 'Ankkalinnastreet 125',
    postalCode: '00300',
    municipalityCode: '700',
    animalCount: 20,
  },
] as MonitoringSearchResult[]

export const monitoringPossessors = [
  {
    possessor: {
      uid: animalProtectionSearchPossessor[0].possessorUid,
      type: PossessorDtoTypeEnum.Person,
      personIdentifier: '123456-9999',
      organizationIdentifier: undefined,
      firstName: animalProtectionSearchPossessor[0].firstName,
      lastName: animalProtectionSearchPossessor[0].lastName,
      organizationName: animalProtectionSearchPossessor[0].organizationName,
      address: animalProtectionSearchPossessor[0].address,
      postalCode: animalProtectionSearchPossessor[0].postalCode,
      municipalityCode: animalProtectionSearchPossessor[0].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor1@example.com',
    },
    registrations: [
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
      {
        uid: possessorChangeRegistration.uid,
        animal: possessorChangeRegistration.animal,
        microchip: possessorChangeRegistration.microchip,
      },
    ],
  } as PossessorResponseDto,
  {
    possessor: {
      uid: animalProtectionSearchPossessor[1].possessorUid,
      type: PossessorDtoTypeEnum.Person,
      personIdentifier: '123456-9999',
      organizationIdentifier: undefined,
      firstName: animalProtectionSearchPossessor[1].firstName,
      lastName: animalProtectionSearchPossessor[1].lastName,
      organizationName: animalProtectionSearchPossessor[1].organizationName,
      address: animalProtectionSearchPossessor[1].address,
      postalCode: animalProtectionSearchPossessor[1].postalCode,
      municipalityCode: animalProtectionSearchPossessor[1].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor2@example.com',
    },
    registrations: [
      {
        uid: statusAbroad.uid,
        animal: statusAbroad.animal,
        microchip: statusAbroad.microchip,
      },
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
        endDate: new Date('2024-04-04'),
      },
    ],
  },
  {
    possessor: {
      uid: animalProtectionSearchPossessor[2].possessorUid,
      type: PossessorDtoTypeEnum.Organization,
      personIdentifier: undefined,
      organizationIdentifier: '1234567-8',
      firstName: animalProtectionSearchPossessor[2].firstName,
      lastName: animalProtectionSearchPossessor[2].lastName,
      organizationName: animalProtectionSearchPossessor[2].organizationName,
      address: animalProtectionSearchPossessor[2].address,
      postalCode: animalProtectionSearchPossessor[2].postalCode,
      municipalityCode: animalProtectionSearchPossessor[2].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor3@example.com',
    },
    registrations: [
      {
        uid: statusMissing.uid,
        animal: statusMissing.animal,
        microchip: statusMissing.microchip,
      },
    ],
  },
  {
    possessor: {
      uid: animalProtectionSearchPossessor[3].possessorUid,
      type: PossessorDtoTypeEnum.Organization,
      personIdentifier: undefined,
      organizationIdentifier: '1234568-9',
      firstName: animalProtectionSearchPossessor[3].firstName,
      lastName: animalProtectionSearchPossessor[3].lastName,
      organizationName: animalProtectionSearchPossessor[3].organizationName,
      address: animalProtectionSearchPossessor[3].address,
      postalCode: animalProtectionSearchPossessor[3].postalCode,
      municipalityCode: animalProtectionSearchPossessor[3].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor4@example.com',
    },
    registrations: [
      {
        uid: deadDog.uid,
        animal: deadDog.animal,
        microchip: deadDog.microchip,
      },
      {
        uid: fullOrganizationRegistration.uid,
        animal: fullOrganizationRegistration.animal,
        microchip: fullOrganizationRegistration.microchip,
      },
    ],
  },
  {
    possessor: {
      uid: animalProtectionSearchPossessor[4].possessorUid,
      type: PossessorDtoTypeEnum.Person,
      personIdentifier: '123456-1212',
      organizationIdentifier: undefined,
      firstName: animalProtectionSearchPossessor[4].firstName,
      lastName: animalProtectionSearchPossessor[4].lastName,
      organizationName: animalProtectionSearchPossessor[4].organizationName,
      address: animalProtectionSearchPossessor[4].address,
      postalCode: animalProtectionSearchPossessor[4].postalCode,
      municipalityCode: animalProtectionSearchPossessor[4].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor5@example.com',
    },
    registrations: [
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
    ],
  },
  {
    possessor: {
      uid: animalProtectionSearchPossessor[5].possessorUid,
      type: PossessorDtoTypeEnum.Person,
      personIdentifier: '654321-0000',
      organizationIdentifier: undefined,
      firstName: animalProtectionSearchPossessor[5].firstName,
      lastName: animalProtectionSearchPossessor[5].lastName,
      organizationName: animalProtectionSearchPossessor[5].organizationName,
      address: animalProtectionSearchPossessor[5].address,
      postalCode: animalProtectionSearchPossessor[5].postalCode,
      municipalityCode: animalProtectionSearchPossessor[5].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor6@example.com',
    },
    registrations: [
      {
        uid: deadDog.uid,
        animal: deadDog.animal,
        microchip: deadDog.microchip,
      },
      {
        uid: statusAbroad.uid,
        animal: statusAbroad.animal,
        microchip: statusAbroad.microchip,
      },
      {
        uid: possessorChangeRegistration.uid,
        animal: possessorChangeRegistration.animal,
        microchip: possessorChangeRegistration.microchip,
      },
    ],
  },
  {
    possessor: {
      uid: animalProtectionSearchPossessor[6].possessorUid,
      type: PossessorDtoTypeEnum.Organization,
      personIdentifier: undefined,
      organizationIdentifier: '87654321-0',
      firstName: animalProtectionSearchPossessor[6].firstName,
      lastName: animalProtectionSearchPossessor[6].lastName,
      organizationName: animalProtectionSearchPossessor[6].organizationName,
      address: animalProtectionSearchPossessor[6].address,
      postalCode: animalProtectionSearchPossessor[6].postalCode,
      municipalityCode: animalProtectionSearchPossessor[6].municipalityCode,
      phone: '+358000000',
      email: 'monitoring.possessor7@example.com',
    },
    registrations: [
      {
        uid: fullRegistration.uid,
        animal: fullRegistration.animal,
        microchip: fullRegistration.microchip,
      },
    ],
  },
]
