/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Criteria for animal importing search.
 * @export
 * @interface AnimalImportingSearchDto
 */
export interface AnimalImportingSearchDto {
  /**
   * Animal birth country code (ISO 3166-1 numeric)
   * @type {string}
   * @memberof AnimalImportingSearchDto
   */
  birthCountryCode?: string
  /**
   * Option to search animal imports from all countries
   * @type {boolean}
   * @memberof AnimalImportingSearchDto
   */
  allCountries?: boolean
  /**
   * Municipality code.
   * @type {string}
   * @memberof AnimalImportingSearchDto
   */
  municipalityCode?: string
  /**
   * Animal registration start date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  registrationStartDate?: Date
  /**
   * Animal registration end date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  registrationEndDate?: Date
  /**
   * Animal birth start date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  birthStartDate?: Date
  /**
   * Animal birth end date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  birthEndDate?: Date
  /**
   * Animal first possessor change start date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  possessorChangeStartDate?: Date
  /**
   * Animal first possessor change end date.
   * @type {Date}
   * @memberof AnimalImportingSearchDto
   */
  possessorChangeEndDate?: Date
}

/**
 * Check if a given object implements the AnimalImportingSearchDto interface.
 */
export function instanceOfAnimalImportingSearchDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function AnimalImportingSearchDtoFromJSON(json: any): AnimalImportingSearchDto {
  return AnimalImportingSearchDtoFromJSONTyped(json, false)
}

export function AnimalImportingSearchDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): AnimalImportingSearchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    birthCountryCode: !exists(json, 'birthCountryCode') ? undefined : json['birthCountryCode'],
    allCountries: !exists(json, 'allCountries') ? undefined : json['allCountries'],
    municipalityCode: !exists(json, 'municipalityCode') ? undefined : json['municipalityCode'],
    registrationStartDate: !exists(json, 'registrationStartDate')
      ? undefined
      : new Date(json['registrationStartDate']),
    registrationEndDate: !exists(json, 'registrationEndDate')
      ? undefined
      : new Date(json['registrationEndDate']),
    birthStartDate: !exists(json, 'birthStartDate') ? undefined : new Date(json['birthStartDate']),
    birthEndDate: !exists(json, 'birthEndDate') ? undefined : new Date(json['birthEndDate']),
    possessorChangeStartDate: !exists(json, 'possessorChangeStartDate')
      ? undefined
      : new Date(json['possessorChangeStartDate']),
    possessorChangeEndDate: !exists(json, 'possessorChangeEndDate')
      ? undefined
      : new Date(json['possessorChangeEndDate']),
  }
}

export function AnimalImportingSearchDtoToJSON(value?: AnimalImportingSearchDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    birthCountryCode: value.birthCountryCode,
    allCountries: value.allCountries,
    municipalityCode: value.municipalityCode,
    registrationStartDate:
      value.registrationStartDate === undefined
        ? undefined
        : value.registrationStartDate.toISOString().substr(0, 10),
    registrationEndDate:
      value.registrationEndDate === undefined
        ? undefined
        : value.registrationEndDate.toISOString().substr(0, 10),
    birthStartDate:
      value.birthStartDate === undefined
        ? undefined
        : value.birthStartDate.toISOString().substr(0, 10),
    birthEndDate:
      value.birthEndDate === undefined ? undefined : value.birthEndDate.toISOString().substr(0, 10),
    possessorChangeStartDate:
      value.possessorChangeStartDate === undefined
        ? undefined
        : value.possessorChangeStartDate.toISOString().substr(0, 10),
    possessorChangeEndDate:
      value.possessorChangeEndDate === undefined
        ? undefined
        : value.possessorChangeEndDate.toISOString().substr(0, 10),
  }
}
