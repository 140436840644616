import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../..'
import EditRegistration from '../../../../components/EditRegistration'

export default function EditRegistrationView() {
  const navigate = useNavigate()
  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }
  return <EditRegistration registrationUid={registrationUid} />
}
