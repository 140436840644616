import * as yup from 'yup'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
  FormHelperText,
} from '@mui/material'
import { Controller, FieldValues, Message, useForm } from 'react-hook-form'
import { getCurrentLocale, useDate } from '../hooks/useDate'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import DatePicker from './DatePicker'
import InlineValidationError from './Form/InlineValidationError'
import InputArrivalDate, { arrivalDateSchema } from './Form/InputArrivalDate'

interface Props {
  open: boolean
  handleAction: ({ deathDate, arrivalDate }: { deathDate: Date; arrivalDate: Date }) => void
  handleClose: () => void
  minDeathDate: Date
  maxDeathDate: Date
}

export default function DogStatusDeadDialog({
  open,
  handleClose,
  handleAction,
  minDeathDate,
  maxDeathDate,
}: Props) {
  const { t } = useTranslation('common')
  const { toUTCDate } = useDate()

  // Currently same translations as registration at form
  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    date: {
      wrongFormat: t('register.inlineError.date.wrongFormat') as Message,
      max: t('register.inlineError.date.max') as Message,
    },
  }

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    deathDate: yup
      .date()
      .typeError(errorMessages.generic)
      .required(errorMessages.generic)
      .nullable()
      .transform((value) => !!value && toUTCDate(value))
      .min(minDeathDate, errorMessages.date.wrongFormat)
      .max(maxDeathDate, errorMessages.date.max),
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { arrivalDate: undefined, deathDate: undefined },
  })

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('reportChange.dogDead.confirm.titleH5')}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          onSubmit={handleSubmit((data: FieldValues) => {
            handleAction({ deathDate: data.deathDate, arrivalDate: data.arrivalDate })
          })}
        >
          <FormGroup
            sx={{
              display: 'grid',
              gap: 4,
              mt: 2,
              mb: 4,
              width: '100%',
            }}
          >
            <FormControl>
              <InputArrivalDate control={control} name={'arrivalDate'} errors={errors} />
            </FormControl>
            <FormControl>
              <Controller
                name="deathDate"
                control={control}
                render={() => (
                  <DatePicker
                    control={control}
                    name={'deathDate'}
                    label={t('reportChange.dogDead.date.titleH5')}
                    error={!!errors.deathDate}
                    locale={getCurrentLocale()}
                    format={'dd.MM.yyyy'}
                    minDate={minDeathDate}
                    maxDate={maxDeathDate}
                    required={true}
                  />
                )}
              />
              {!!errors.deathDate ? (
                <InlineValidationError content={errors.deathDate?.message} />
              ) : (
                <FormHelperText>{t('reportChange.dogDead.date.helperText')}</FormHelperText>
              )}
            </FormControl>
          </FormGroup>
          <DialogContentText>{t('reportChange.dogDead.confirm.body')}</DialogContentText>
          <DialogActions sx={{ pb: 0 }}>
            <Button onClick={handleClose}>{t('common.cancel')}</Button>
            <Button type="submit" disabled={isSubmitting}>
              {t('reportChange.dogDeadBtn')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
