/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PossessorDto,
  instanceOfPossessorDto,
  PossessorDtoFromJSON,
  PossessorDtoFromJSONTyped,
  PossessorDtoToJSON,
} from './PossessorDto'
import {
  PossessorResponseDto,
  instanceOfPossessorResponseDto,
  PossessorResponseDtoFromJSON,
  PossessorResponseDtoFromJSONTyped,
  PossessorResponseDtoToJSON,
} from './PossessorResponseDto'

/**
 * @type PossessorByUidResponseDto
 *
 * @export
 */
export type PossessorByUidResponseDto = PossessorDto | PossessorResponseDto

export function PossessorByUidResponseDtoFromJSON(json: any): PossessorByUidResponseDto {
  return PossessorByUidResponseDtoFromJSONTyped(json, false)
}

export function PossessorByUidResponseDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PossessorByUidResponseDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    ...PossessorDtoFromJSONTyped(json, true),
    ...PossessorResponseDtoFromJSONTyped(json, true),
  }
}

export function PossessorByUidResponseDtoToJSON(value?: PossessorByUidResponseDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }

  if (instanceOfPossessorDto(value)) {
    return PossessorDtoToJSON(value as PossessorDto)
  }
  if (instanceOfPossessorResponseDto(value)) {
    return PossessorResponseDtoToJSON(value as PossessorResponseDto)
  }

  return {}
}
