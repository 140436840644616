/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management Backend API
 * Manages the users using OpenGate authentication.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorDto, OpenGateRequestDto, OpenGateResponseDto } from '../models'
import {
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  OpenGateRequestDtoFromJSON,
  OpenGateRequestDtoToJSON,
  OpenGateResponseDtoFromJSON,
  OpenGateResponseDtoToJSON,
} from '../models'

export interface AuthenticateRequest {
  openGateRequestDto: OpenGateRequestDto
}

export interface InitRequest {
  language: string
}

export interface LogoutRequest {
  userId: string
}

/**
 *
 */
export class AuthRestControllerApi extends runtime.BaseAPI {
  /**
   * Proceeds with the authentication flow. OpenGate might ask questions and the user needs to respond to them.  This endpoint will be polled multiple times to authenticate the user, as OpenGate can ask questions in multiple steps.  At the end, OpenGate will send a message that authentication has succeeded, and the access has been granted.
   */
  async authenticateRaw(
    requestParameters: AuthenticateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OpenGateResponseDto>> {
    if (
      requestParameters.openGateRequestDto === null ||
      requestParameters.openGateRequestDto === undefined
    ) {
      throw new runtime.RequiredError(
        'openGateRequestDto',
        'Required parameter requestParameters.openGateRequestDto was null or undefined when calling authenticate.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    const response = await this.request(
      {
        path: `/auth/authenticate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OpenGateRequestDtoToJSON(requestParameters.openGateRequestDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpenGateResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Proceeds with the authentication flow. OpenGate might ask questions and the user needs to respond to them.  This endpoint will be polled multiple times to authenticate the user, as OpenGate can ask questions in multiple steps.  At the end, OpenGate will send a message that authentication has succeeded, and the access has been granted.
   */
  async authenticate(
    requestParameters: AuthenticateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OpenGateResponseDto> {
    const response = await this.authenticateRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Initializes the authentication handshake.  OpenGate will return questions for user to answer before proceeding with authentication.
   */
  async initRaw(
    requestParameters: InitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<OpenGateResponseDto>> {
    if (requestParameters.language === null || requestParameters.language === undefined) {
      throw new runtime.RequiredError(
        'language',
        'Required parameter requestParameters.language was null or undefined when calling init.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.language !== undefined) {
      queryParameters['language'] = requestParameters.language
    }

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth/init`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OpenGateResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Initializes the authentication handshake.  OpenGate will return questions for user to answer before proceeding with authentication.
   */
  async init(
    requestParameters: InitRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<OpenGateResponseDto> {
    const response = await this.initRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Logs out the user and invalidates session from application and OpenGate.
   */
  async logoutRaw(
    requestParameters: LogoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling logout.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/auth/logout`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.VoidApiResponse(response)
  }

  /**
   * Logs out the user and invalidates session from application and OpenGate.
   */
  async logout(
    requestParameters: LogoutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.logoutRaw(requestParameters, initOverrides)
  }
}
