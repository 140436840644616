import { Box, Container, Divider, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import { SnackBar, SnackBarTypes } from '../../components/SnackBar'
import {
  AnimalStatusDto,
  ErrorDto,
  PossessorDto,
  PossessorDtoTypeEnum,
} from '../../generated/animare-management-api'
import { useAnimalStatus } from '../../hooks/useAnimalStatus'

import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import BackButton from '../../components/BackButton'
import Dog from '../../components/Dog'
import DogStatusInfo from '../../components/DogStatusInfo'
import CorrectInformation from '../../components/DogView/CorrectInformation'
import UpdateDetails from '../../components/DogView/UpdateDetails'
import Possessor from '../../components/Possessor'
import { ShowFor } from '../../context/AuthProvider'
import { AnimareApiKeys } from '../../data/apiKeys'
import { Roles } from '../../data/roles'
import { useEvents } from '../../hooks/useEvents'
import { useRegistration } from '../../hooks/useRegistration'
import DogMicrochipExtra from '../DogMicrochipExtra'
import LoadingSpinner from '../LoadingSpinner'
import { MicrochipHistory } from '../MicrochipHistory'
import EventLog from './EventLog'

export interface DogViewProps {
  uid: string
}

export default function DogView({ uid }: DogViewProps) {
  const { t } = useTranslation('common')
  const { getRegistration } = useRegistration()
  const { getEvents } = useEvents()
  const [isEventAccordionOpen, setIsEventAccordionOpen] = useState(false)
  const { isDogDead } = useAnimalStatus()

  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes>()

  const queryClient = useQueryClient()

  const {
    isPending: isDogDataLoading,
    error: dogDataError,
    data: dogData,
  } = getRegistration({ uid })

  /**
   * Shows success notification top of the page
   */
  const showSuccessNotification = () => {
    setShowNotification(SnackBarTypes.SUCCESS)
  }

  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const { error: eventDataError, data: eventData = [] } = getEvents({
    uid: dogData?.animal.uid,
    enabled: !!dogData?.animal.uid && isEventAccordionOpen,
  })

  if (isDogDataLoading) {
    return <LoadingSpinner />
  }

  if (dogDataError || eventDataError) {
    return <></>
  }

  if (!dogData) {
    return <></>
  }

  const onSuccess = () => {
    showSuccessNotification()
    queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION, uid] })
    queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.ANIMAL, dogData?.animal.uid] })
  }
  const onError = (error: any) => {
    setApiError(error)
    showErrorNotification()
    console.error(error)
  }

  const dogStatus = dogData.animal?.status ?? ({} as AnimalStatusDto)
  const microchips = dogData.animal.microchips
  return (
    <Container data-qa="dog-view">
      <BackButton />
      <Paper component="section" sx={{ p: { sm: 8, xs: 2 }, pb: { xs: 4 } }}>
        {dogStatus ? <DogStatusInfo status={dogStatus} /> : null}

        <SnackBar
          type={SnackBarTypes.SUCCESS}
          open={showNotification === SnackBarTypes.SUCCESS}
          handleClose={() => setShowNotification(undefined)}
          innerText={`${t('common.save-successful')}`}
        />
        <SnackBar
          type={SnackBarTypes.ERROR}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
          errorCode={apiError}
          open={showNotification === SnackBarTypes.ERROR}
        />

        {dogData?.changeCode && (
          <Box
            sx={{
              color: 'info.main',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              width: 'fit-content',
              mb: 1,
            }}
          >
            <InfoSharpIcon />
            <Typography variant="body2" sx={{ color: 'info.main', textTransform: 'uppercase' }}>
              {t('dogView.possessorChangeCodeExists')}
            </Typography>
          </Box>
        )}
        <Typography variant="h1" sx={{ mb: 2 }} data-qa="animal-name">
          {dogData?.animal?.name}
        </Typography>
        <Dog registration={dogData} showStartDate={true} />
        <Typography variant="h2" sx={{ mb: 2 }} data-qa="microchip-extra">
          {t('common.animal.microchipExtra')}
        </Typography>
        <DogMicrochipExtra microchip={dogData?.microchip} />
        {microchips && (
          <>
            <Typography variant="h2">{t('common.animal.microchipHistory')}</Typography>
            <MicrochipHistory
              microchipData={microchips.map((microchip) => ({
                number: microchip.microchipNumber,
                date: microchip.microchipDate,
                name: microchip.microchipSetterName,
                address: microchip.microchipSetterAddress,
              }))}
            />
          </>
        )}

        <Divider sx={{ mt: 6, mb: 4 }} />

        <Typography variant="h2" sx={{ mt: 2, mb: 2 }}>
          {t('dogView.possessorInformation')}
        </Typography>
        <Possessor
          possessor={dogData?.possessor ?? ({ type: PossessorDtoTypeEnum.Person } as PossessorDto)}
        />
        <ShowFor roles={[Roles.Editor, Roles.ServiceDesk, Roles.Admin]}>
          <Divider />
          {!dogData?.endDate && dogStatus && !isDogDead(dogStatus) && (
            <>
              <UpdateDetails dogData={dogData} onSuccess={onSuccess} onError={onError} />
            </>
          )}
          <Divider />
          <CorrectInformation dogData={dogData} onSuccess={onSuccess} onError={onError} />
        </ShowFor>
        <Divider />
        <EventLog
          events={eventData}
          isEventAccordionOpen={() =>
            isEventAccordionOpen ? setIsEventAccordionOpen(false) : setIsEventAccordionOpen(true)
          }
        />
      </Paper>
    </Container>
  )
}
