import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RegistrationDto } from '../generated/animare-management-api'
import { DATE_FORMAT_FI, useDate } from '../hooks/useDate'
import i18n from '../i18n'
import Breed from './Codes/Breed'
import RegistrationsDownload from './RegistrationsDownload'
import SpacerBox from './SpacerBox'

export interface Props {
  possessorUid?: string
  registrations: RegistrationDto[]
  headerSize?: 'h2' | 'h3'
}

const sortByName = (array: RegistrationDto[]): RegistrationDto[] => {
  return array.sort((a, b) => {
    const aName = a.animal?.name || ''
    const bName = b.animal?.name || ''
    return aName.localeCompare(bName, i18n.language)
  })
}

const RegistrationsTable = ({ possessorUid, registrations, headerSize = 'h3' }: Props) => {
  const { formatDate } = useDate()
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const registrationsWithoutEndDate = registrations.filter((registration) => !registration.endDate)
  const registrationsWithEndDate = registrations.filter((registration) => !!registration.endDate)

  return (
    <SpacerBox component="section" gap={4}>
      <Box>
        <Typography variant={headerSize} mb={1}>
          {t('registrationSearchPage.animalsSection.title')}
        </Typography>
        <TableContainer>
          <Table size="small" data-qa="registrations-table">
            <TableHead>
              <TableRow>
                <TableCell>{t('animalsTable.column.breedName')}</TableCell>
                <TableCell>{t('animalsTable.column.name')}</TableCell>
                <TableCell>{t('animalsTable.column.birthDate')}</TableCell>
                <TableCell>{t('animalsTable.column.chipCode')}</TableCell>
                <TableCell width={1}>{t('animalsTable.column.additionalInformation')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortByName(registrationsWithoutEndDate).map((registration) => (
                <TableRow key={registration.uid}>
                  <TableCell>
                    <Breed code={registration.animal?.breedCode} />
                  </TableCell>
                  <TableCell sx={{ wordBreak: 'break-word' }}>
                    {registration.animal?.name}
                  </TableCell>
                  <TableCell>
                    {formatDate(registration.animal?.birthDate as Date, DATE_FORMAT_FI)}
                  </TableCell>
                  <TableCell>{registration.microchip?.microchipNumber}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(
                          possessorUid
                            ? `possessor/${possessorUid}/registration/${registration.uid}`
                            : `registration/${registration.uid}`,
                        )
                      }
                      aria-label={t('animalsTable.column.additionalInformationAria') as string}
                      data-qa="registration-details-button"
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {registrationsWithoutEndDate && (
        <RegistrationsDownload registrations={registrationsWithoutEndDate} />
      )}
      {registrationsWithEndDate.length >= 1 && (
        <Box>
          <Typography variant="h3" mb={1}>
            {t('registrationSearchPage.animalsSection.pastRegistrationsTitle')}
          </Typography>
          <TableContainer>
            <Table size="small" data-qa="past-registrations-table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('animalsTable.column.breedName')}</TableCell>
                  <TableCell>{t('animalsTable.column.name')}</TableCell>
                  <TableCell>{t('animalsTable.column.birthDate')}</TableCell>
                  <TableCell>{t('animalsTable.column.chipCode')}</TableCell>
                  <TableCell>{t('animalsTable.column.registrationEndDate')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortByName(registrationsWithEndDate).map((registration) => (
                  <TableRow key={registration.uid}>
                    <TableCell>
                      <Breed code={registration.animal?.breedCode} />
                    </TableCell>
                    <TableCell sx={{ wordBreak: 'break-word' }}>
                      {registration.animal?.name}
                    </TableCell>
                    <TableCell>
                      {formatDate(registration.animal?.birthDate as Date, DATE_FORMAT_FI)}
                    </TableCell>
                    <TableCell>{registration.microchip?.microchipNumber}</TableCell>
                    <TableCell>
                      {formatDate(registration.endDate as Date, DATE_FORMAT_FI)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </SpacerBox>
  )
}

export default RegistrationsTable
