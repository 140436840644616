import { QueryClient, UseMutationResult, useMutation } from '@tanstack/react-query'
import {
  ChangeMicrochipNumberRequest,
  Configuration,
  DeleteMicrochipRequestDto,
  MicrochipDto,
  MicrochipNumberChangeRequestDto,
  MicrochipRestControllerApi,
  Middleware,
  querystring,
} from '../generated/animare-management-api'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'

import { API_BASE_URL } from '../setup'
import { ErrorDto } from '../generated/openapi'
import { AnimareApiKeys } from '../data/apiKeys'

export type ChangeMicrochipNumberMutationParams = {
  uid: string
  arrivalDate: Date
  microchipNumber: string
  newMicrochipNumber: string
}

export function useMicrochip() {
  const api = new MicrochipRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   * Update microchip number - does not add new microchip (this is different function)
   *
   * @param uid microchip uid
   * @param arrivalDate microchip change arrival date
   * @param microchipNumber incorrect microchip number
   * @param newMicrochipNumber correct microchip number
   * @returns correct microchip details
   */
  const changeMicrochipNumber = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    MicrochipDto,
    ErrorDto,
    {
      uid: string
      arrivalDate: Date
      microchipNumber: string
      newMicrochipNumber: string
    },
    unknown
  > => {
    const changeMicrochipNumberMutation = useMutation({
      mutationFn: ({
        uid,
        arrivalDate,
        microchipNumber,
        newMicrochipNumber,
      }: ChangeMicrochipNumberMutationParams) =>
        api.changeMicrochipNumber({
          uid,
          microchipNumberChangeRequestDto: {
            arrivalDate,
            microchipNumber,
            newMicrochipNumber,
          } as MicrochipNumberChangeRequestDto,
        } as ChangeMicrochipNumberRequest),
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess()
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return changeMicrochipNumberMutation
  }

  /**
   * Admin: Delete microchip from animal
   * @param arrivalDate microchip deletion arrival date
   * @param uid microchip uid
   */
  const deleteMicrochip = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<void, ErrorDto, { uid: string; arrivalDate: Date }, unknown> => {
    const deleteMicrochipMutation = useMutation({
      mutationFn: ({ uid, arrivalDate }: { uid: string; arrivalDate: Date }) =>
        api.deleteMicrochip({
          uid: uid,
          deleteMicrochipRequestDto: {
            arrivalDate: arrivalDate,
          } as DeleteMicrochipRequestDto,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.MICROCHIP] })
        onSuccess()
      },
      onError: onError,
    })

    return deleteMicrochipMutation
  }

  return {
    changeMicrochipNumber,
    deleteMicrochip,
  }
}
