import { Box, Step, StepButton, StepLabel, Stepper } from '@mui/material'

import React from 'react'
import theme from '../styles/theme'
import { useTranslation } from 'react-i18next'

const MockSeparatorLine = (props: { style?: React.CSSProperties }) => (
  <Box
    sx={{
      left: '0',
      right: '0',
      position: 'absolute',
      top: '12px',
      ...props.style,
    }}
  >
    <span
      style={{
        borderColor: '#e6e7eb',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        display: 'block',
      }}
    ></span>
  </Box>
)

type FormViewStepperProps = {
  activeStepIndex: number
  setActiveStep?: (index: number) => void
  steps: { label: string; index: number }[]
  nonLinear?: boolean
}

export const FormViewStepper = (props: FormViewStepperProps) => {
  const { activeStepIndex, setActiveStep, steps, nonLinear } = props

  const { t } = useTranslation('common')
  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step)
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <MockSeparatorLine />
      <Stepper
        data-qa="form-stepper"
        connector={
          <div
            style={{
              display: 'none',
            }}
          ></div>
        }
        nonLinear={nonLinear}
        activeStep={activeStepIndex}
        alternativeLabel
        sx={{
          position: 'relative',
          '.Mui-completed': {
            '.MuiStepLabel-label': {
              fontWeight: '400',
            },
          },
          '.inactive-step': {
            '.MuiStepIcon-root': {
              circle: {
                fill: theme.palette.text.secondary,
                stroke: theme.palette.grey[500],
                r: '11',
              },
              '.MuiStepIcon-text': {
                fill: theme.palette.grey[500],
              },
            },
            '.MuiStepLabel-label': {
              fontWeight: '400',
              color: theme.palette.grey[500],
            },
          },
          '.MuiStepLabel-label': {
            textTransform: 'initial',
            fontWeight: '700',
          },
        }}
      >
        {steps.map((step) => (
          <Step
            key={step.label}
            completed={step.index < activeStepIndex}
            className={
              activeStepIndex === step.index || step.index < activeStepIndex
                ? 'active-step'
                : 'inactive-step'
            }
          >
            {nonLinear ? (
              <StepButton color="inherit" onClick={handleStep(step.index)}>
                {t(`${step.label}`)}
              </StepButton>
            ) : (
              <StepLabel> {t(`${step.label}`)}</StepLabel>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
