import * as yup from 'yup'

import { Message } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OrganizationType } from '../../generated/animare-management-api'
import { arrivalDateSchema } from './InputArrivalDate'
import { textRegex } from './registrationFormSchema'

const SOLETRADER_IDENTIFIER_REGEX = /^\d{6}[\+\-A-Z]\d{3}[0-9A-Z]$/
const ORGANIZATION_IDENTIFIER_REGEX = /^\d{7}[\+\-A-Z]\d{0}[0-9A-Z]$/

export const MAX_ORG_NAME_LENGTH = 255
export const MAX_ADDRESS_LENGTH = 255
export const MAX_POSTALCODE_LENGTH = 5
export const MAX_PHONE_NUMBER_LENGTH = 20
export const MAX_EMAIL_LENGTH = 255

export const getOrganizationPossessorFormSchema = () => {
  const { t } = useTranslation('common')

  const errorMessages = {
    wrongFormat: t('errors.validation.wrongFormat') as Message,
    required: t('errors.validation.required') as Message,
    postalCodeLength: t('errors.validation.lengthRequirement', { number: '5' }),
    generic: t('register.inlineError.ifEmpty.generic') as Message,
  }

  return yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    organizationIdentifier: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .matches(ORGANIZATION_IDENTIFIER_REGEX, errorMessages.wrongFormat),
    organizationName: yup
      .string()
      .required(errorMessages.required)
      .transform((value: string) => (value === '' ? null : value))
      .min(1)
      .max(MAX_ORG_NAME_LENGTH)
      .matches(textRegex, errorMessages.wrongFormat),
    address: yup
      .string()
      .notRequired()
      .transform((value: string) => (value === '' ? null : value))
      .min(1)
      .max(MAX_ADDRESS_LENGTH)
      .matches(textRegex, errorMessages.wrongFormat),
    postalCode: yup
      .string()
      .required(errorMessages.required)
      .transform((value: string) => (value === '' ? null : value))
      .min(5, errorMessages.postalCodeLength)
      .max(MAX_POSTALCODE_LENGTH, errorMessages.postalCodeLength)
      .matches(/^\d+$/, errorMessages.wrongFormat),
    municipalityCode: yup
      .string()
      .min(3)
      .max(5)
      .matches(/^\d+$/, errorMessages.wrongFormat)
      .required(errorMessages.required),
    phone: yup
      .string()
      .notRequired()
      .transform((value: string) => (value === '' ? null : value))
      .min(1)
      .max(MAX_PHONE_NUMBER_LENGTH)
      .matches(/^(\d|\+)?\d+$/, errorMessages.wrongFormat),
    email: yup
      .string()
      .notRequired()
      .email(errorMessages.wrongFormat)
      .transform((value: string) => (value === '' ? null : value))
      .min(3)
      .max(MAX_EMAIL_LENGTH)
      .matches(/^.+@.+$/, errorMessages.wrongFormat),
    soleTrader: yup.boolean().transform((value: boolean) => (value === null ? false : value)),
    soleTraderIdentifier: yup.string().when(['organizationType', 'soleTrader'], {
      is: (type: OrganizationType, isSoleTrader: boolean) => {
        return type === OrganizationType.Company && isSoleTrader
      },
      then: (schema: yup.StringSchema) =>
        schema.required(errorMessages.required).matches(SOLETRADER_IDENTIFIER_REGEX, {
          message: errorMessages.wrongFormat,
        }),
      otherwise: (schema: yup.StringSchema) => schema.notRequired().nullable(),
    }),
    organizationType: yup.string().required(errorMessages.required),
  })
}
