/* tslint:disable */
/* eslint-disable */
/**
 * Animare Secrets API
 * This API allows to decrypt secrets and hash strings for admin purposes.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface HashingDto
 */
export interface HashingDto {
  /**
   * Code of the type of secret that should be used to hash the plaintext.
   * @type {string}
   * @memberof HashingDto
   */
  secretTypeCode?: string
  /**
   * Plaintext after decryption.
   * @type {string}
   * @memberof HashingDto
   */
  plaintext?: string
  /**
   * Result after hashing.
   * @type {string}
   * @memberof HashingDto
   */
  hash?: string
}

/**
 * Check if a given object implements the HashingDto interface.
 */
export function instanceOfHashingDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function HashingDtoFromJSON(json: any): HashingDto {
  return HashingDtoFromJSONTyped(json, false)
}

export function HashingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HashingDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    secretTypeCode: !exists(json, 'secretTypeCode') ? undefined : json['secretTypeCode'],
    plaintext: !exists(json, 'plaintext') ? undefined : json['plaintext'],
    hash: !exists(json, 'hash') ? undefined : json['hash'],
  }
}

export function HashingDtoToJSON(value?: HashingDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    secretTypeCode: value.secretTypeCode,
    plaintext: value.plaintext,
    hash: value.hash,
  }
}
