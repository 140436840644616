/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Information about the created possessor change code.
 * @export
 * @interface PossessorChangeCodeDto
 */
export interface PossessorChangeCodeDto {
  /**
   * The registration that will be ended after this code is used.
   * @type {string}
   * @memberof PossessorChangeCodeDto
   */
  registrationUid?: string
  /**
   * The animal that will be transferred after this code is used.
   * @type {string}
   * @memberof PossessorChangeCodeDto
   */
  animalUid?: string
  /**
   * The change code.
   * @type {string}
   * @memberof PossessorChangeCodeDto
   */
  code?: string
  /**
   * Creation time of the change code.
   * @type {Date}
   * @memberof PossessorChangeCodeDto
   */
  creationTime?: Date
}

/**
 * Check if a given object implements the PossessorChangeCodeDto interface.
 */
export function instanceOfPossessorChangeCodeDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function PossessorChangeCodeDtoFromJSON(json: any): PossessorChangeCodeDto {
  return PossessorChangeCodeDtoFromJSONTyped(json, false)
}

export function PossessorChangeCodeDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PossessorChangeCodeDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    registrationUid: !exists(json, 'registrationUid') ? undefined : json['registrationUid'],
    animalUid: !exists(json, 'animalUid') ? undefined : json['animalUid'],
    code: !exists(json, 'code') ? undefined : json['code'],
    creationTime: !exists(json, 'creationTime') ? undefined : new Date(json['creationTime']),
  }
}

export function PossessorChangeCodeDtoToJSON(value?: PossessorChangeCodeDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    registrationUid: value.registrationUid,
    animalUid: value.animalUid,
    code: value.code,
    creationTime: value.creationTime === undefined ? undefined : value.creationTime.toISOString(),
  }
}
