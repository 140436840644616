/* tslint:disable */
/* eslint-disable */
/**
 * Animare Secrets API
 * This API allows to decrypt secrets and hash strings for admin purposes.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { DecryptionDto, ErrorDto, HashingDto, SecretDto } from '../models'
import {
  DecryptionDtoFromJSON,
  DecryptionDtoToJSON,
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  HashingDtoFromJSON,
  HashingDtoToJSON,
  SecretDtoFromJSON,
  SecretDtoToJSON,
} from '../models'

export interface DecryptRequest {
  decryptionDto: DecryptionDto
}

export interface HashRequest {
  hashingDto: HashingDto
}

/**
 *
 */
export class SecretsRestControllerApi extends runtime.BaseAPI {
  /**
   * Decrypt a ciphertext.
   */
  async decryptRaw(
    requestParameters: DecryptRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<DecryptionDto>> {
    if (requestParameters.decryptionDto === null || requestParameters.decryptionDto === undefined) {
      throw new runtime.RequiredError(
        'decryptionDto',
        'Required parameter requestParameters.decryptionDto was null or undefined when calling decrypt.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/decrypt`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DecryptionDtoToJSON(requestParameters.decryptionDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => DecryptionDtoFromJSON(jsonValue))
  }

  /**
   * Decrypt a ciphertext.
   */
  async decrypt(
    requestParameters: DecryptRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DecryptionDto> {
    const response = await this.decryptRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get a list of known secrets.
   */
  async getSecretsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<SecretDto>>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/secrets`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SecretDtoFromJSON))
  }

  /**
   * Get a list of known secrets.
   */
  async getSecrets(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<SecretDto>> {
    const response = await this.getSecretsRaw(initOverrides)
    return await response.value()
  }

  /**
   * Hash a plaintext.
   */
  async hashRaw(
    requestParameters: HashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<HashingDto>> {
    if (requestParameters.hashingDto === null || requestParameters.hashingDto === undefined) {
      throw new runtime.RequiredError(
        'hashingDto',
        'Required parameter requestParameters.hashingDto was null or undefined when calling hash.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/hash`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: HashingDtoToJSON(requestParameters.hashingDto),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => HashingDtoFromJSON(jsonValue))
  }

  /**
   * Hash a plaintext.
   */
  async hash(
    requestParameters: HashRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<HashingDto> {
    const response = await this.hashRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
