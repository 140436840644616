import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { FormGroup, InputLabel, MenuItem, Select } from '@mui/material'

import { AnimalDtoBreedSizeEnum } from '../../generated/animare-management-api'
import { useTranslation } from 'react-i18next'

interface Props {
  error: boolean
  dataQa: string
  field: ControllerRenderProps<FieldValues, any>
  ariaLabelledBy?: string
}

export default function FormBreedSize({ error, dataQa, field, ariaLabelledBy }: Props) {
  const { t } = useTranslation('common')
  const { onChange, value, ref, name } = field

  const values = Object.values(AnimalDtoBreedSizeEnum).filter(
    (v: AnimalDtoBreedSizeEnum) => v !== AnimalDtoBreedSizeEnum.Unknown,
  )

  return (
    <>
      <FormGroup id={name}>
        <InputLabel id="dog-size-label">
          {t('common.animal.size')} <i aria-hidden={true}>*</i>
          <i className="visible-hidden">{t('register.requiredField')}</i>
        </InputLabel>
        <Select
          labelId="dog-size-label"
          aria-labelledby={ariaLabelledBy}
          data-qa={dataQa}
          value={value || ''}
          label={t('common.animal.size')}
          error={error}
          fullWidth
          onChange={onChange}
          inputProps={ref}
        >
          {values.map((size, index) => {
            return (
              <MenuItem key={index} value={size}>
                {t(`common.size.${size}`)}
              </MenuItem>
            )
          })}
        </Select>
      </FormGroup>
    </>
  )
}
