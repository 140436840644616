import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
  MAX_ADDRESS_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_ORG_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_POSTALCODE_LENGTH,
  getOrganizationPossessorFormSchema,
} from './OrganizationPossessorFormSchema'

import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { ShowFor } from '../../context/AuthProvider'
import { Roles } from '../../data/roles'
import { PossessorDto } from '../../generated/animare-management-api'
import { OrganizationType } from '../../generated/animare-management-api/models/OrganizationType'
import { ErrorDto } from '../../generated/openapi/models/ErrorDto'
import { ErrorCodes } from '../../utils/errorUtils'
import SpacerBox from '../SpacerBox'
import InlineValidationError from './InlineValidationError'
import InputArrivalDate from './InputArrivalDate'
import MunicipalitySelect from './MunicipalitySelect'
import { OrganizationTypeSelect } from './OrganizationTypeSelect'

export interface OrganizationFormValues {
  arrivalDate: Date | null
  organizationIdentifier: string | null
  organizationName: string | null
  address: string | null
  postalCode: string | null
  municipalityCode: string | null
  phone: string | null
  email: string | null
  organizationType: OrganizationType | null
  soleTraderIdentifier: string | null
  soleTrader: boolean | null
}

export enum OrganizationFormNames {
  arrivalDate = 'arrivalDate',
  organizationIdentifier = 'organizationIdentifier',
  organizationName = 'organizationName',
  organizationType = 'organizationType',
  address = 'address',
  postalCode = 'postalCode',
  municipalityCode = 'municipalityCode',
  phone = 'phone',
  email = 'email',
  soleTraderIdentifier = 'soleTraderIdentifier',
  soleTrader = 'soleTrader',
}

interface Props {
  possessor?: PossessorDto
  onSubmit: (form: OrganizationFormValues) => void
  errorCode?: ErrorDto
  isEdit?: boolean
  headerSize?: 'h2' | 'h3'
}

export const OrganizationPossessorForm = ({
  possessor,
  onSubmit,
  errorCode,
  isEdit = false,
  headerSize = 'h3',
}: Props) => {
  const [identifierFieldDisabled, setIdentifierFieldDisabled] = useState<boolean>(true)

  const { t } = useTranslation('common')

  const methods = useForm<OrganizationFormValues>({
    resolver: yupResolver(getOrganizationPossessorFormSchema()),
    defaultValues: {
      ...possessor,
      arrivalDate: null,
    },
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = methods

  useEffect(() => {
    if (errorCode) {
      if (errorCode.code === ErrorCodes.DuplicateIdentifier) {
        setError(
          'organizationIdentifier',
          { type: 'custom', message: t('errors.duplicate-organization-identifier') as string },
          { shouldFocus: true },
        )
      }
    }
  }, [errors, errorCode])

  useEffect(() => {
    if (isSubmitting && !identifierFieldDisabled) {
      setIdentifierFieldDisabled(true)
    }
  }, [isSubmitting, !identifierFieldDisabled])

  const organizationType = watch(OrganizationFormNames.organizationType)
  const isSoleTrader = watch(OrganizationFormNames.soleTrader)

  const showSoleTraderOption = organizationType === OrganizationType.Company

  useEffect(() => {
    if (organizationType !== OrganizationType.Company) {
      setValue(OrganizationFormNames.soleTraderIdentifier, null)
      setValue(OrganizationFormNames.soleTrader, false)
    }
  }, [organizationType])

  const handleSoleTraderChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const soleTrader = value === 'true'
    setValue(OrganizationFormNames.soleTrader, soleTrader)
    if (!soleTrader) {
      setValue(OrganizationFormNames.soleTraderIdentifier, null)
    }
  }

  return (
    <Box data-qa="possessor-form">
      <Box>
        <FormProvider {...methods}>
          <SpacerBox
            component="form"
            gap={3}
            onSubmit={handleSubmit(onSubmit)}
            data-qa="organization-possessor-form"
          >
            <Box>
              <Typography variant="body1" mb={2}>
                {t('organizationPossessorForm.form.requiredTitle')}
              </Typography>
              <Typography variant={headerSize}>
                {t('registrationSearchPage.arrivalDate')}
              </Typography>
              <FormControl sx={{ maxWidth: { xs: '100%', sm: '300px' }, mt: 2 }}>
                <InputArrivalDate control={control} errors={errors} />
              </FormControl>
            </Box>

            <Box>
              <Typography variant={headerSize} data-qa="possessorDetailsTitle" mb={2}>
                {t(isEdit ? 'possessorDetails.title' : 'organizationPossessorForm.title')}
              </Typography>
              {possessor?.uid ? (
                <ShowFor roles={[Roles.Admin]}>
                  <Box>
                    <FormControl>
                      <InputLabel htmlFor="organizationIdentifier">
                        {t('organizationPossessorForm.form.organizationIdentifier')}
                        <i aria-hidden={true}>*</i>
                        <i className="visible-hidden">{t('registrationForm.field.required')}</i>
                      </InputLabel>
                      <TextField
                        {...register('organizationIdentifier')}
                        id="organizationIdentifier"
                        error={!!errors.organizationIdentifier}
                        inputProps={{
                          type: 'text',
                          inputMode: 'text',
                          maxLength: 9,
                        }}
                        autoComplete={'off'}
                        data-qa="organizationIdentifier"
                        disabled={identifierFieldDisabled}
                      />
                      {errors.organizationIdentifier && (
                        <InlineValidationError content={errors.organizationIdentifier.message} />
                      )}
                    </FormControl>
                    <Button
                      variant="text"
                      onClick={() =>
                        identifierFieldDisabled
                          ? setIdentifierFieldDisabled(false)
                          : setIdentifierFieldDisabled(true)
                      }
                      disabled={identifierFieldDisabled ? false : true}
                      sx={{ p: 2 }}
                      data-qa="modifyIdentifierButton"
                    >
                      {t('organizationPossessorForm.form.identifierEdit')}
                    </Button>
                  </Box>
                </ShowFor>
              ) : null}
              <ShowFor roles={[Roles.Editor, Roles.ServiceDesk]}>
                {possessor && (
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body1"
                      sx={{ textTransform: 'uppercase' }}
                      data-qa="organizationIdentifier"
                    >
                      {`${t('organizationPossessorForm.form.organizationIdentifier')}: ${
                        possessor?.organizationIdentifier
                      }`}
                    </Typography>
                  </Box>
                )}
              </ShowFor>
              <ShowFor roles={[Roles.Admin]}>
                {possessor && !possessor.uid && (
                  <Typography variant="body1" data-qa="organizationIdentifier">
                    {`${t('organizationPossessorForm.form.organizationIdentifier')}: ${
                      possessor.organizationIdentifier
                    }`}
                  </Typography>
                )}
              </ShowFor>
            </Box>
            <FormGroup
              sx={{
                display: 'grid',
                gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '100%' },
                gap: 4,
                mt: 2,
                width: '100%',
              }}
            >
              <FormControl>
                <InputLabel htmlFor={OrganizationFormNames.organizationName}>
                  {t('organizationPossessorForm.form.organizationName')} <i aria-hidden={true}>*</i>
                  <i className="visible-hidden">{t('register.requiredField')}</i>
                </InputLabel>
                <TextField
                  {...register(OrganizationFormNames.organizationName)}
                  id={OrganizationFormNames.organizationName}
                  data-qa={OrganizationFormNames.organizationName}
                  error={!!errors[OrganizationFormNames.organizationName]}
                  inputProps={{
                    type: 'text',
                    inputMode: 'text',
                    maxLength: MAX_ORG_NAME_LENGTH,
                  }}
                />
                {!!errors[OrganizationFormNames.organizationName] && (
                  <InlineValidationError
                    content={errors[OrganizationFormNames.organizationName].message}
                  />
                )}
              </FormControl>
              <FormControl>
                <Controller
                  name={OrganizationFormNames.organizationType}
                  control={control}
                  render={({ field }) => (
                    <OrganizationTypeSelect
                      field={field}
                      error={!!errors[OrganizationFormNames.organizationType]}
                    />
                  )}
                />
                {!!errors[OrganizationFormNames.organizationType] && (
                  <InlineValidationError
                    content={errors[OrganizationFormNames.organizationType].message}
                  />
                )}
              </FormControl>
              {showSoleTraderOption && (
                <FormControl
                  sx={{ gridColumn: !isSoleTrader ? { sm: '1 / span 2', xs: '1' } : 'inherit' }}
                >
                  <Controller
                    name={OrganizationFormNames.soleTrader}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        data-qa={OrganizationFormNames.soleTrader}
                        {...register(OrganizationFormNames.soleTrader)}
                        value={field.value}
                        onChange={handleSoleTraderChange}
                      >
                        <FormControlLabel
                          data-qa="soletrader-rb-label"
                          value={true}
                          control={<Radio />}
                          label={t('common.organizationType.soletrader')}
                        />
                        <FormControlLabel
                          data-qa="company-other-rb-label"
                          value={false}
                          control={<Radio />}
                          label={t('common.organizationType.other')}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              )}
              {isSoleTrader && (
                <FormControl>
                  <InputLabel htmlFor={OrganizationFormNames.soleTraderIdentifier}>
                    {t('organizationPossessorForm.form.soleTraderIdentifier')}{' '}
                    <i aria-hidden={true}>*</i>
                    <i className="visible-hidden">{t('register.requiredField')}</i>
                  </InputLabel>
                  <TextField
                    {...register(OrganizationFormNames.soleTraderIdentifier)}
                    id={OrganizationFormNames.soleTraderIdentifier}
                    data-qa={OrganizationFormNames.soleTraderIdentifier}
                    error={!!errors[OrganizationFormNames.soleTraderIdentifier]}
                    inputProps={{
                      type: 'text',
                      inputMode: 'text',
                    }}
                    autoComplete={'off'}
                  />
                  {!!errors[OrganizationFormNames.soleTraderIdentifier] && (
                    <InlineValidationError
                      content={errors[OrganizationFormNames.soleTraderIdentifier].message}
                    />
                  )}
                </FormControl>
              )}
              <FormControl>
                <InputLabel htmlFor={OrganizationFormNames.address}>
                  {t('organizationPossessorForm.form.address')}
                </InputLabel>
                <TextField
                  {...register(OrganizationFormNames.address)}
                  id={OrganizationFormNames.address}
                  data-qa={OrganizationFormNames.address}
                  error={!!errors[OrganizationFormNames.address]}
                  inputProps={{
                    type: 'text',
                    inputMode: 'text',
                    maxLength: MAX_ADDRESS_LENGTH,
                  }}
                />
                {!!errors[OrganizationFormNames.address] && (
                  <InlineValidationError content={errors[OrganizationFormNames.address].message} />
                )}
              </FormControl>
              <FormControl>
                <InputLabel htmlFor={OrganizationFormNames.postalCode}>
                  {t('organizationPossessorForm.form.postalCode')} <i aria-hidden={true}>*</i>
                  <i className="visible-hidden">{t('register.requiredField')}</i>
                </InputLabel>
                <TextField
                  {...register(OrganizationFormNames.postalCode)}
                  id={OrganizationFormNames.postalCode}
                  data-qa={OrganizationFormNames.postalCode}
                  error={!!errors[OrganizationFormNames.postalCode]}
                  inputProps={{
                    type: 'text',
                    inputMode: 'numeric',
                    maxLength: MAX_POSTALCODE_LENGTH,
                  }}
                />

                {!!errors[OrganizationFormNames.postalCode] && (
                  <InlineValidationError
                    content={errors[OrganizationFormNames.postalCode].message}
                  />
                )}
              </FormControl>
              <FormControl>
                <Controller
                  name={OrganizationFormNames.municipalityCode}
                  control={control}
                  render={({ field }) => (
                    <MunicipalitySelect
                      field={field}
                      error={!!errors[OrganizationFormNames.municipalityCode]}
                      errorContent={errors.municipalityCode?.message || ''}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <InputLabel htmlFor={OrganizationFormNames.phone}>
                  {t('organizationPossessorForm.form.phone')}
                </InputLabel>
                <TextField
                  {...register(OrganizationFormNames.phone)}
                  id={OrganizationFormNames.phone}
                  error={!!errors.phone}
                  data-qa={OrganizationFormNames.phone}
                  inputProps={{
                    type: 'text',
                    inputMode: 'numeric',
                    maxLength: MAX_PHONE_NUMBER_LENGTH,
                  }}
                  autoComplete={'off'}
                />
                {!!errors[OrganizationFormNames.phone] && (
                  <InlineValidationError content={errors[OrganizationFormNames.phone].message} />
                )}
              </FormControl>
              <FormControl>
                <InputLabel htmlFor={OrganizationFormNames.email}>
                  {t('organizationPossessorForm.form.email')}
                </InputLabel>
                <TextField
                  {...register(OrganizationFormNames.email)}
                  id={OrganizationFormNames.email}
                  data-qa={OrganizationFormNames.email}
                  error={!!errors[OrganizationFormNames.email]}
                  inputProps={{
                    type: 'text',
                    inputMode: 'text',
                    maxLength: MAX_EMAIL_LENGTH,
                  }}
                  autoComplete={'off'}
                />
                {!!errors[OrganizationFormNames.email] && (
                  <InlineValidationError content={errors[OrganizationFormNames.email].message} />
                )}
              </FormControl>
            </FormGroup>

            <Box>
              <Button
                variant="contained"
                type="submit"
                sx={{ display: 'block', marginLeft: 'auto' }}
                disabled={isSubmitting}
                data-qa="submitForm"
              >
                {isSubmitting ? (
                  <Box aria-label={t('common.loading')}>
                    <CircularProgress size={10} /> {t('common.loading') + '...'}
                  </Box>
                ) : (
                  t('common.save')
                )}
              </Button>
            </Box>
          </SpacerBox>
        </FormProvider>
      </Box>
    </Box>
  )
}
