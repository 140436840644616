import * as yup from 'yup'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormGroup,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAnimalStatus } from '../hooks/useAnimalStatus'
import { Controller, DefaultValues, FormProvider, Message, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getCurrentLocale, useDate } from '../hooks/useDate'
import InlineValidationError from './Form/InlineValidationError'
import DatePicker from './DatePicker'
import { AnimalLocationType, AnimalStatusDto } from '../generated/animare-management-api'
import InputArrivalDate, { arrivalDateSchema } from './Form/InputArrivalDate'

interface Props {
  status: AnimalStatusDto
  minDate: Date
  open: boolean
  onSubmit: (form: DogStatusLocationFormValues) => void
  handleClose: any
}

export type DogStatusLocationFormValues = {
  arrivalDate: Date
  locationDate: Date | null
}

export default function DogStatusLocationDialog({
  status,
  minDate,
  open,
  handleClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation('common')
  const { isDogMissing } = useAnimalStatus()
  const { toUTCDate } = useDate()

  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    date: {
      wrongFormat: t('register.inlineError.date.wrongFormat') as Message,
      min: t('register.inlineError.date.min') as Message,
      minByStatus:
        status.location.type === AnimalLocationType.Found
          ? t('register.inlineError.date.minByStatusMissing')
          : t('register.inlineError.date.minByStatusFound'),
      max: t('register.inlineError.date.max') as Message,
    },
  }

  const MAX_DATE = new Date()

  const schema = yup.object().shape({
    arrivalDate: arrivalDateSchema(),
    locationDate: yup
      .date()
      .typeError(errorMessages.date.wrongFormat)
      .required(errorMessages.date.wrongFormat)
      .transform((value) => !!value && toUTCDate(value))
      .min(minDate, status.location.date ? errorMessages.date.minByStatus : errorMessages.date.min)
      .max(MAX_DATE, errorMessages.date.max),
  })

  const methods = useForm<DogStatusLocationFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      locationDate: null,
    } as DefaultValues<DogStatusLocationFormValues>,
  })

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-qa="title-dog-location">
        {!isDogMissing(status)
          ? t('reportChange.dogMissing.confirm.titleH5')
          : t('reportChange.dogFound.confirm.titleH5')}
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <FormGroup
              sx={{
                display: 'grid',
                gap: 4,
                mt: 2,
                mb: 4,
                width: '100%',
              }}
            >
              <FormControl>
                <InputArrivalDate control={control} errors={errors}></InputArrivalDate>
              </FormControl>
              <FormControl>
                <Controller
                  name="locationDate"
                  control={control}
                  render={() => (
                    <DatePicker
                      control={control}
                      name={'locationDate'}
                      label={
                        !isDogMissing(status)
                          ? t('reportChange.dogMissing.date.titleH5')
                          : t('reportChange.dogFound.date.titleH5')
                      }
                      error={!!errors.locationDate}
                      locale={getCurrentLocale()}
                      format={'dd.MM.yyyy'}
                      minDate={minDate}
                      maxDate={MAX_DATE}
                      required
                    />
                  )}
                />
                {!!errors.locationDate ? (
                  <InlineValidationError
                    content={errors.locationDate?.message}
                    id="locationDate-error"
                  />
                ) : null}
              </FormControl>
            </FormGroup>
            <DialogContentText data-qa="content-dog-location">
              {!isDogMissing(status)
                ? t('reportChange.dogMissing.confirm.body')
                : t('reportChange.dogFound.confirm.body')}
            </DialogContentText>
            <DialogActions sx={{ pb: 0 }} data-qa="actions-dog-location">
              <Button onClick={handleClose}>{t('common.cancel')}</Button>
              <Button type="submit" disabled={isSubmitting}>
                {!isDogMissing(status)
                  ? t('reportChange.dogMissingBtn')
                  : t('reportChange.dogFoundButton')}
              </Button>
            </DialogActions>
          </Box>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}
