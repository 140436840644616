import { useTranslation } from 'react-i18next'
import { AnimalDtoSexCodeEnum } from '../generated/animare-management-api/models/AnimalDto'

export interface Props {
  code?: string
}
export default function Sex({ code }: Props) {
  const { t } = useTranslation('common')
  const sexes = Object.values(AnimalDtoSexCodeEnum)
  if (code === undefined || code === null || !sexes.find((s) => code === s)) {
    return <>{t('common.sex.unknown')}</>
  }
  return <>{t(`common.sex.${code}`)}</>
}
