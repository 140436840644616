import { Divider, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ErrorDto, PossessorDto } from '../generated/animare-management-api'
import { ServiceDeskRegistrationFormValues, useRegistration } from '../hooks/useRegistration'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useRegistrationMapper } from '../hooks/useMapper'
import BackButton from './BackButton'
import RegistrationForm from './Form/RegistrationForm'
import ScrollTo from './ScrollTo'
import SpacerBox from './SpacerBox'

interface Props {
  registrationUid: string
}
export default function EditRegistration({ registrationUid }: Props) {
  const { mapToServiceDeskRegistrationFormValues } = useRegistrationMapper()

  const queryClient = useQueryClient()

  const { t } = useTranslation('common')

  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const { editRegistration, getRegistration } = useRegistration()
  const { mutate: editRegistrationMutation } = editRegistration({
    onSuccess: () => {
      showSuccessNotification()
      setScrollToElement('h1')
    },
    onError: (error: ErrorDto) => {
      setApiError(error)
      showErrorNotification()
    },
    queryClient,
  })

  /**
   * Shows success notification on the top of the page
   */
  const showSuccessNotification = () => {
    setShowNotification(SnackBarTypes.SUCCESS)
  }

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const { data: registrationData, error: registrationFetchError } = getRegistration({
    uid: registrationUid,
  })

  useEffect(() => {
    if (!!registrationFetchError) {
      setApiError(registrationFetchError)
      showErrorNotification()
    }
  }, [registrationFetchError])

  const onSubmit = async (form: ServiceDeskRegistrationFormValues) => {
    if (!registrationUid) {
      return
    }
    setShowNotification(undefined)
    setScrollToElement(undefined)
    return editRegistrationMutation({
      form: form,
      possessor: registrationData?.possessor ?? ({} as PossessorDto),
      uid: registrationUid,
    })
  }

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }} data-qa="edit-registration">
        <SpacerBox gap={3} centercontent={'true'}>
          <Typography variant="h1">{t('registrationFormPage.edit-title')}</Typography>
          <SnackBar
            open={showNotification === SnackBarTypes.SUCCESS}
            type={SnackBarTypes.SUCCESS}
            innerText={`${t('common.save-successful')}`}
            handleClose={() => setShowNotification(undefined)}
          />
          <SnackBar
            open={!!apiError && showNotification === SnackBarTypes.ERROR}
            type={SnackBarTypes.ERROR}
            errorCode={apiError}
            handleClose={() => {
              setShowNotification(undefined)
              return setApiError(undefined)
            }}
          />
          {scrollToElement && <ScrollTo selector={scrollToElement} />}
          <Divider />
          {!!registrationData?.possessor && (
            <>
              <SpacerBox gap={1}>
                <Typography variant="h2">{t('possessorDetails.name')}</Typography>
                <Typography variant="body1">
                  {registrationData?.possessor?.firstName} {registrationData?.possessor?.lastName}
                </Typography>
              </SpacerBox>
            </>
          )}
          {!!registrationData && (
            <RegistrationForm
              defaultValues={mapToServiceDeskRegistrationFormValues(registrationData)}
              submitButtonText={t('common.save')}
              onSubmit={onSubmit}
              errorCode={apiError}
              isEdit={true}
            />
          )}
        </SpacerBox>
      </Paper>
    </>
  )
}
