import { isBefore } from 'date-fns'
import {
  AnimalDtoBreedSizeEnum,
  AnimalDtoColoursEnum,
  AnimalDtoSexCodeEnum,
  Configuration,
  ErrorDto,
  FullRegistrationDto,
  GetRegistrationUidByMicrochipNumberRequest,
  InvalidateChangeCodesDto,
  MicrochipDto,
  Middleware,
  NewMicrochipRequestDto,
  PossessorChangeRequestDto,
  PossessorDto,
  RegistrationRestControllerApi,
  querystring,
} from '../generated/animare-management-api'

import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'
import { useDate } from './useDate'
import { useRegistrationMapper } from './useMapper'
import { QueryClient, UseMutationResult, useMutation, useQuery } from '@tanstack/react-query'
import { AnimareApiKeys } from '../data/apiKeys'

const REGISTRATION_MIN_DATE = new Date('1990-01-01')
const REGISTRATION_LEGAL_DATE = new Date('2023-01-01')

export type AnimalSizeEnum = AnimalDtoBreedSizeEnum
export type AnimalSexEnum = AnimalDtoSexCodeEnum
export type AnimalColourEnum = AnimalDtoColoursEnum

export type RegistrationFormValues = {
  microchipNumber: string | null
  name: string | null
  kennelName: string | null
  birthDate: Date | null
  birthCountryCode: string | null
  breedCode: string | null
  breedSize: AnimalSizeEnum | null
  breedDescription: string | null
  sexCode: AnimalSexEnum | null
  colours: Array<AnimalColourEnum> | null
  microchipDate: Date | null
  microchipSetterName: string | null
  microchipSetterAddress: string | null
}

export type ServiceDeskRegistrationFormValues = RegistrationFormValues & {
  arrivalDate: Date | null
  isEdit?: boolean
}

export function useRegistration() {
  const { toUTCDate } = useDate()
  const { mapToFullRegistrationDto } = useRegistrationMapper()
  const api = new RegistrationRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  const sendRegistrationForm = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: FullRegistrationDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    FullRegistrationDto,
    ErrorDto,
    {
      form: ServiceDeskRegistrationFormValues
      possessor: PossessorDto
    },
    unknown
  > => {
    const sendRegistrationFormMutation = useMutation({
      mutationFn: ({
        form,
        possessor,
      }: {
        form: ServiceDeskRegistrationFormValues
        possessor: PossessorDto
      }) =>
        api.createRegistration({ fullRegistrationDto: mapToFullRegistrationDto(form, possessor) }),
      onSuccess: (res: FullRegistrationDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })

        onSuccess(res)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return sendRegistrationFormMutation
  }

  const editRegistration = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    FullRegistrationDto,
    ErrorDto,
    {
      form: ServiceDeskRegistrationFormValues
      possessor: PossessorDto
      uid: string
    },
    unknown
  > => {
    const editRegistrationMutation = useMutation({
      mutationFn: ({
        form,
        possessor,
        uid,
      }: {
        form: ServiceDeskRegistrationFormValues
        possessor: PossessorDto
        uid: string
      }) =>
        api.changeRegistration({
          fullRegistrationDto: mapToFullRegistrationDto(form, possessor),
          uid,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess()
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return editRegistrationMutation
  }

  const getRegistration = ({
    uid,
    enabled = true,
  }: {
    uid: string
    enabled?: boolean
  }): { isPending: boolean; error: ErrorDto | null; data?: FullRegistrationDto } => {
    const { isPending, error, data } = useQuery<FullRegistrationDto, ErrorDto>({
      queryKey: [AnimareApiKeys.REGISTRATION, uid],
      queryFn: ({ queryKey }): Promise<FullRegistrationDto> =>
        api.getRegistration({ uid: `${queryKey[1]}` }),
      enabled,
    })

    return { isPending, error, data }
  }

  const getRegistrationUidByMicrochipNumber = (
    microchipNumber: string,
  ): Promise<FullRegistrationDto> => {
    return api.getRegistrationUidByMicrochipNumber({
      microchipNumber,
    } as GetRegistrationUidByMicrochipNumberRequest)
  }

  const changePossessor = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: (res: FullRegistrationDto) => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    FullRegistrationDto,
    ErrorDto,
    { uid: string; form: PossessorChangeRequestDto },
    unknown
  > => {
    const changePossessorMutation = useMutation({
      mutationFn: ({ uid, form }: { uid: string; form: PossessorChangeRequestDto }) =>
        api.changePossessor({
          uid: uid,
          possessorChangeRequestDto: {
            arrivalDate: form?.arrivalDate as Date,
            transferDate: form?.transferDate as Date,
            newPossessor: {
              uid: form?.newPossessor?.uid,
              type: form?.newPossessor?.type,
              firstName: form?.newPossessor?.firstName,
              lastName: form?.newPossessor?.lastName,
              personIdentifier: form?.newPossessor?.personIdentifier,
              address: form?.newPossessor?.address,
              postalCode: form?.newPossessor?.postalCode,
              municipalityCode: form?.newPossessor?.municipalityCode,
              phone: form?.newPossessor?.phone,
              email: form?.newPossessor?.email,
              organizationName: form?.newPossessor?.organizationName,
              organizationType: form?.newPossessor?.organizationType,
              organizationIdentifier: form?.newPossessor?.organizationIdentifier,
            } as PossessorDto,
          } as PossessorChangeRequestDto,
        }),
      onSuccess: (result) => {
        queryClient.invalidateQueries({
          queryKey: [AnimareApiKeys.REGISTRATION, AnimareApiKeys.POSSESSOR],
        })
        onSuccess(result)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return changePossessorMutation
  }

  const cancelPossessorChange = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    void,
    ErrorDto,
    {
      uid: string
      arrivalDate: Date
    },
    unknown
  > => {
    const cancelPossessorChangeMutation = useMutation({
      mutationFn: ({ uid, arrivalDate }: { uid: string; arrivalDate: Date }) =>
        api.invalidateChangeCodes({
          uid: uid,
          invalidateChangeCodesDto: {
            arrivalDate: arrivalDate,
          } as InvalidateChangeCodesDto,
        }),
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess()
      },
      onError: onError,
    })

    return cancelPossessorChangeMutation
  }

  /**
   *
   * @returns
   */
  const getRegistrationLegalDate = (): Date => toUTCDate(REGISTRATION_LEGAL_DATE)

  /**
   *
   * @returns
   */
  const getRegistrationMaxDate = (): Date => toUTCDate(new Date())

  /**
   *
   * @returns
   */
  const getRegistrationMinDate = (): Date => toUTCDate(REGISTRATION_MIN_DATE)

  const getMinMicrochipDate = (registration: FullRegistrationDto): Date => {
    const date = toUTCDate(registration.animal?.birthDate ?? REGISTRATION_MIN_DATE)
    if (isBefore(date, REGISTRATION_MIN_DATE)) {
      return REGISTRATION_MIN_DATE
    }
    return date
  }

  /**
   * Add new microchip code
   *
   */
  const updateMicrochip = ({
    onSuccess,
    onError,
    queryClient,
  }: {
    onSuccess: () => void
    onError: (error: ErrorDto) => void
    queryClient: QueryClient
  }): UseMutationResult<
    MicrochipDto,
    ErrorDto,
    {
      uid: string
      form: NewMicrochipRequestDto
    },
    unknown
  > => {
    const updateMicrochipMutation = useMutation({
      mutationFn: ({ uid, form }: { uid: string; form: NewMicrochipRequestDto }) =>
        api.newMicrochip({
          uid: uid,
          newMicrochipRequestDto: form,
        }),
      onSuccess: () => {
        // Invalidate and refetch
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess()
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
    })

    return updateMicrochipMutation
  }

  return {
    cancelPossessorChange,
    changePossessor,
    editRegistration,
    getMinMicrochipDate,
    getRegistration,
    getRegistrationUidByMicrochipNumber,
    getRegistrationLegalDate,
    getRegistrationMinDate,
    getRegistrationMaxDate,
    sendRegistrationForm,
    updateMicrochip,
  }
}
