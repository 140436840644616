import { Box, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PossessorDto, PossessorDtoTypeEnum } from '../../generated/animare-management-api'
import { ErrorDto } from '../../generated/openapi'
import { usePossessorMapper } from '../../hooks/useMapper'
import { usePossessor } from '../../hooks/usePossessor'
import PersonPossessorForm, { PersonPossessorFormValues } from './PersonPossessorForm'

interface Props {
  personIdentifier: string
}

export const AddNewPerson = ({ personIdentifier }: Props) => {
  const { t } = useTranslation('common')
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const { createPossessor } = usePossessor()
  const { mapToModifyPossessorRequestDto } = usePossessorMapper()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const { mutate: createPossessorMutation } = createPossessor({
    onSuccess: (data: PossessorDto) => {
      navigate(`possessor/${data.uid}/registration`)
    },
    onError: (error: ErrorDto) => {
      setApiError(error)
      console.error(error)
    },
    queryClient,
  })

  /**
   * Saves information of a new possessor.
   * @param form Form details
   */
  const saveNewPossessor = async (form: PersonPossessorFormValues) => {
    setApiError(undefined)
    const request = mapToModifyPossessorRequestDto(form, PossessorDtoTypeEnum.Person)
    return createPossessorMutation(request)
  }

  return (
    <Box>
      <Typography variant="h2">{t('possessorEdit.newPossessor')}</Typography>
      <PersonPossessorForm
        possessor={{ personIdentifier } as PossessorDto}
        onSubmit={saveNewPossessor}
        errorCode={apiError}
      />
    </Box>
  )
}
