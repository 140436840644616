import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import MunicipalitySelect from '../Form/MunicipalitySelect'
import { AnimalImportingSearchFormValues } from './MonitoringSearchSchema'

import { getCurrentLocale } from '../../hooks/useDate'
import DatePicker from '../DatePicker'
import CountrySelect from '../Form/CountrySelect'
import InlineValidationError from '../Form/InlineValidationError'
import { SearchProps } from './SearchForm'

export enum AnimalImportingSearchValues {
  birthCountryCode = 'birthCountryCode',
  allCountries = 'allCountries',
  municipalityCode = 'municipalityCode',
  registrationStartDate = 'registrationStartDate',
  registrationEndDate = 'registrationEndDate',
  birthStartDate = 'birthStartDate',
  birthEndDate = 'birthEndDate',
  possessorChangeStartDate = 'possessorChangeStartDate',
  possessorChangeEndDate = 'possessorChangeEndDate',
}

export default function AnimalImportingSearch({
  control,
  errors,
  watch,
  register,
}: SearchProps<AnimalImportingSearchFormValues>) {
  const { t } = useTranslation('common')

  const MAX_DATE = new Date()

  const allCountriesWatch = watch(AnimalImportingSearchValues.allCountries)

  return (
    <>
      <Box>
        <Typography variant="h2">{t('monitoring.animalImporting.title')}</Typography>
        <Typography variant="body1">
          <Trans t={t} i18nKey="monitoring.animalImporting.requiredInformation">
            {t('monitoring.animalImporting.requiredInformation')}
          </Trans>
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl>
          <Controller
            control={control}
            name={AnimalImportingSearchValues.birthCountryCode}
            render={({ field }) => (
              <CountrySelect
                error={!!errors[field.name]}
                ariaLabelledBy={
                  errors['birthCountryCode'] ? 'inlineError-birthCountryCode' : field.name
                }
                field={field}
                errorContent={errors[field.name]?.message || ''}
                errorId="inlineError-birthCountryCode"
                excludeFinland
                isDisabled={!!allCountriesWatch}
              />
            )}
          />
        </FormControl>

        <Controller
          control={control}
          name={AnimalImportingSearchValues.allCountries}
          render={({ field }) => (
            <FormControlLabel
              label={t('monitoring.animalImporting.allCountriesCheckbox')}
              control={
                <Checkbox
                  {...field}
                  color="primary"
                  data-qa={AnimalImportingSearchValues.allCountries}
                  checked={!!field.value}
                />
              }
            />
          )}
        />
      </Box>

      <FormGroup sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" component="h3" sx={{ fontWeight: 500 }}>
            {t('monitoring.animalImporting.birthDateTitle')}
          </Typography>

          <Box
            sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
          >
            <FormControl>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.birthStartDate}
                label={t('monitoring.puppyMilling.firstRegistrations.startDateLabel')}
                error={!!errors.birthStartDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={errors.birthStartDate && 'inlineError-birthStartDate'}
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.birthStartDate && (
                <InlineValidationError
                  content={errors.birthStartDate?.message}
                  id="inlineError-birthStartDate"
                />
              )}
            </FormControl>

            <FormControl>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.birthEndDate}
                label={t('monitoring.puppyMilling.firstRegistrations.endDateLabel')}
                error={!!errors.birthEndDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={errors.birthEndDate && 'inlineError-birthEndDate'}
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.birthEndDate && (
                <InlineValidationError
                  content={errors.birthEndDate?.message}
                  id="inlineError-birthEndDate"
                />
              )}
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" component="h3" sx={{ fontWeight: 500 }}>
            {t('monitoring.animalImporting.registrationDateTitle')}
          </Typography>

          <Box
            sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
          >
            <FormControl>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.registrationStartDate}
                label={t('monitoring.puppyMilling.firstRegistrations.startDateLabel')}
                error={!!errors.registrationStartDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={errors.registrationStartDate && 'inlineError-registrationStartDate'}
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.registrationStartDate && (
                <InlineValidationError
                  content={errors.registrationStartDate?.message}
                  id="inlineError-registrationStartDate"
                />
              )}
            </FormControl>

            <FormControl>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.registrationEndDate}
                label={t('monitoring.puppyMilling.firstRegistrations.endDateLabel')}
                error={!!errors.registrationEndDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={errors.registrationEndDate && 'inlineError-registrationEndDate'}
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.registrationEndDate && (
                <InlineValidationError
                  content={errors.registrationEndDate?.message}
                  id="inlineError-registrationEndDate"
                />
              )}
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="body1" component="h3" sx={{ fontWeight: 500 }}>
            {t('monitoring.animalImporting.possessorChangeDateTitle')}
          </Typography>

          <Box
            sx={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}
          >
            <FormControl sx={{ mb: 2 }}>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.possessorChangeStartDate}
                label={t('monitoring.puppyMilling.firstRegistrations.startDateLabel')}
                error={!!errors.possessorChangeStartDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={
                  errors.possessorChangeStartDate && 'inlineError-possessorChangeStartDate'
                }
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.possessorChangeStartDate && (
                <InlineValidationError
                  content={errors.possessorChangeStartDate?.message}
                  id="inlineError-possessorChangeStartDate"
                />
              )}
            </FormControl>

            <FormControl>
              <DatePicker
                control={control}
                name={AnimalImportingSearchValues.possessorChangeEndDate}
                label={t('monitoring.puppyMilling.firstRegistrations.endDateLabel')}
                error={!!errors.possessorChangeEndDate}
                locale={getCurrentLocale()}
                format={'dd.MM.yyyy'}
                ariaLabelledby={
                  errors.possessorChangeEndDate && 'inlineError-possessorChangeEndDate'
                }
                maxDate={MAX_DATE}
                placeholder={t('registrationForm.field.date.placeHolder') as string}
                required
              />
              {!!errors.possessorChangeEndDate && (
                <InlineValidationError
                  content={errors.possessorChangeEndDate?.message}
                  id="inlineError-possessorChangeEndDate"
                />
              )}
            </FormControl>
          </Box>
        </Box>
      </FormGroup>

      <FormControl>
        <Controller
          name={AnimalImportingSearchValues.municipalityCode}
          control={control}
          render={({ field }) => (
            <MunicipalitySelect
              field={field}
              error={!!errors.municipalityCode}
              errorContent={errors.municipalityCode?.message || ''}
              isRequired={false}
            />
          )}
        />
      </FormControl>
    </>
  )
}
