import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { CodeApiKeys } from '../data/apiKeys'
import { municipalityCodes } from '../data/municipalities'
import { Code, Configuration, Middleware, MunicipalitiesApi } from '../generated/codes-app-api'
import { ErrorDto } from '../generated/openapi'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { CODES_API_BASE_URL } from '../setup'

export interface MunicipalityType {
  label: string
  code: Code
}

function useMunicipalities() {
  const api = new MunicipalitiesApi({
    basePath: CODES_API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
  } as Configuration)

  const fetchMunicipalities = (): UseQueryResult<Code[], ErrorDto> => {
    const getMunicipalityNameQuery = useQuery<Code[], ErrorDto>({
      queryKey: [CodeApiKeys.MUNICIPALITIES],
      queryFn: () => api.getMunicipalities(),
    })
    return getMunicipalityNameQuery
  }

  /**
   * Forms label in the correct language.
   * @param code
   * @returns
   */
  const getLabel = ({ code, language }: { code?: Code; language: string }) => {
    if (!code?.labels) return ''
    return code.labels.find((label) => label.lang === language)?.label
  }

  /**
   *
   * @param municipalityCode
   * @returns
   */
  const getMunicipalityName = ({
    code: municipalityCode,
    language,
  }: {
    code?: string
    language: string
  }): { value?: string; error?: ErrorDto | null; isPending?: boolean } => {
    const {
      data: municipalityList,
      error: municipalityListError,
      isPending,
    } = fetchMunicipalities()

    if (isPending) {
      return { isPending }
    }

    if (!municipalityList || municipalityListError) {
      return { error: municipalityListError }
    }

    const paddedMunicipality = `${municipalityCode}`.padStart(3, '0')

    return {
      value: getLabel({
        code: municipalityList.find((code) => `${code.code}` === paddedMunicipality),
        language,
      }),
    }
  }

  const defaultMunicipalities = () => municipalityCodes as Code[]

  return {
    fetchMunicipalities,
    defaultMunicipalities,
    getMunicipalityName,
  }
}
export default useMunicipalities
