/* tslint:disable */
/* eslint-disable */
/**
 * Animare Secrets API
 * This API allows to decrypt secrets and hash strings for admin purposes.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * A type of secret that can be used in the application.
 * @export
 * @interface SecretDto
 */
export interface SecretDto {
  /**
   * Secret type code
   * @type {string}
   * @memberof SecretDto
   */
  code?: string
  /**
   * Secret name/description
   * @type {string}
   * @memberof SecretDto
   */
  name?: string
  /**
   * Whether the secret provides hashing functionality
   * @type {boolean}
   * @memberof SecretDto
   */
  hashable?: boolean
}

/**
 * Check if a given object implements the SecretDto interface.
 */
export function instanceOfSecretDto(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function SecretDtoFromJSON(json: any): SecretDto {
  return SecretDtoFromJSONTyped(json, false)
}

export function SecretDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    code: !exists(json, 'code') ? undefined : json['code'],
    name: !exists(json, 'name') ? undefined : json['name'],
    hashable: !exists(json, 'hashable') ? undefined : json['hashable'],
  }
}

export function SecretDtoToJSON(value?: SecretDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    code: value.code,
    name: value.name,
    hashable: value.hashable,
  }
}
