import { Box, Divider, FormGroup, InputLabel, TextField } from '@mui/material'
import { HTMLAttributes, SyntheticEvent, useState } from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'

import Autocomplete from '@mui/material/Autocomplete/Autocomplete'
import { useTranslation } from 'react-i18next'

export type AutoSelectOptionType = { label: string; value: string }

type FormAutoCompleteProps = {
  options: AutoSelectOptionType[]
  loading: boolean
  error: boolean
  ariaLabelledBy?: string
  isRequired?: boolean
  inputTitle: string
  placeholderText?: string
  dataQAString: string
  dividerPosition?: string
  field: ControllerRenderProps<FieldValues, any>
  isDisabled?: boolean
}

export const FormAutoComplete = (props: FormAutoCompleteProps) => {
  // Set the written input in the text field, not the selected value
  const [inputValue, setInputValue] = useState<string>('')

  const { t } = useTranslation('common')

  const {
    options,
    loading,
    error,
    ariaLabelledBy,
    isRequired,
    inputTitle,
    placeholderText,
    dataQAString,
    dividerPosition,
    field,
    isDisabled,
  } = props

  return field ? (
    <Autocomplete
      data-qa={dataQAString}
      aria-labelledby={ariaLabelledBy}
      id={field.name}
      loading={loading}
      disabled={loading || isDisabled}
      fullWidth
      autoHighlight
      noOptionsText={t('registrationForm.error.unknownSelection')}
      // Give a default value to keep the component controlled, no undefined
      // accepted so either null or a realistic value
      value={options.find((option) => option.value === field.value) || null}
      // Filter out options that don't fit the given input value
      options={options.filter((b) =>
        b.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
      )}
      // Always return true as a workaround to MUI's Autocomplete bug logging
      // "warning" until it's fixed. It tries to constantly find the already
      // previously selected value from the new filtered list when writing in
      // a new value label
      // https://github.com/mui/material-ui/issues/29727
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isOptionEqualToValue={(_) => {
        return true
      }}
      inputValue={inputValue}
      onChange={(event: SyntheticEvent<Element, Event>, newValue: AutoSelectOptionType | null) => {
        if (newValue?.value) {
          field.onChange(`${newValue.value}`)
        } else {
          field.onChange(null)
        }
      }}
      onInputChange={(_: SyntheticEvent<Element, Event>, newInputValue: string) => {
        setInputValue(newInputValue)
        if (!!loading) return
      }}
      renderOption={(props, option: AutoSelectOptionType) => {
        // need to pick key out or else react whines that it is spread incorrectly into jsx
        // key comes from somewhere (I have no idea where), but should not
        // https://github.com/mui/material-ui/issues/39833#issuecomment-2138406917
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
        const { key, ...rest } = props as HTMLAttributes<HTMLLIElement> & { key: any }
        return (
          <Box key={option.value}>
            <Box component="li" {...rest} value={option.value}>
              {option.label}
            </Box>
            {dividerPosition && option.value === dividerPosition && <Divider />}
          </Box>
        )
      }}
      renderInput={(params) => {
        return (
          <FormGroup>
            <InputLabel htmlFor={field.name}>
              {inputTitle} {isRequired && <i aria-hidden={true}>*</i>}
              <i className="visible-hidden">{t('registrationForm.field.required')}</i>
            </InputLabel>
            <TextField
              error={error}
              InputProps={{ ...params.InputProps }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
                placeholder: placeholderText,
              }}
              InputLabelProps={{
                size: 'normal',
              }}
              inputRef={field.ref}
              disabled={isDisabled}
            />
          </FormGroup>
        )
      }}
    />
  ) : (
    <></>
  )
}
