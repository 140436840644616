import { Code, CodeLabelsInner } from '../../generated/codes-app-api'
import useMunicipalities, { MunicipalityType } from '../../hooks/useMunicipality'

import { ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormAutoComplete } from '../FormAutoComplete'
import InlineValidationError from './InlineValidationError'

type Props = {
  error?: any
  errorContent: string
  field: ControllerRenderProps<any, any>
  isRequired?: boolean
}

function MunicipalitySelect({ error, field, errorContent, isRequired = true }: Props) {
  const { defaultMunicipalities, fetchMunicipalities } = useMunicipalities()
  const { i18n, t } = useTranslation('common')

  const toMunicipalityType = (code: Code): MunicipalityType => {
    return {
      label:
        code.labels?.find((label: CodeLabelsInner) => label.lang === i18n.language)?.label ??
        code.code,
      code: code,
    }
  }

  const {
    isPending,
    data: allMunicipalities,
    error: allMunicipalitiesError,
  } = fetchMunicipalities()

  const defaultOptions = defaultMunicipalities().map(toMunicipalityType)

  const getAllMunicipalities = (): MunicipalityType[] => {
    const getMunicipalityTypes = (): MunicipalityType[] => {
      if (!allMunicipalities || allMunicipalitiesError) {
        return []
      }
      return allMunicipalities
        .map(toMunicipalityType)
        .sort((a, b) => a.label.localeCompare(b.label))
    }
    const municipalities = [...getMunicipalityTypes(), ...defaultOptions]
    return municipalities
  }

  const dropdownOptions = getAllMunicipalities().map((municipality) => ({
    label: municipality.label,
    value: `${municipality.code.code}`,
  }))

  return dropdownOptions && dropdownOptions?.length > 0 ? (
    <>
      <FormAutoComplete
        options={dropdownOptions}
        loading={isPending}
        isRequired={isRequired}
        dataQAString={field.name}
        field={field}
        error={error && !field.value}
        inputTitle={t('possessorDetails.field.municipality')}
      />
      {!!error && !field.value && <InlineValidationError content={errorContent} />}
    </>
  ) : (
    <></>
  )
}

export default MunicipalitySelect
