import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button } from '@mui/material'
import { QueryClient, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Schema } from 'yup'
import {
  MonitoringSearchResponseDto,
  MonitoringSearchResult,
} from '../../generated/animare-management-api'
import { ErrorDto } from '../../generated/openapi'
import { MutationProps } from '../../hooks/useMonitoring'
import { useMonitoringCache } from '../../hooks/useMonitoringCache'
import LoadingSpinner from '../LoadingSpinner'
import ScrollTo from '../ScrollTo'
import { SnackBar, SnackBarTypes } from '../SnackBar'
import { SearchTypes } from './MonitoringSearchPage'
import MonitoringSearchResultsSection from './MonitoringSearchResultsSection'

export interface Props<DTO extends object, T extends FieldValues> {
  searchType: SearchTypes
  mutationCallback: ({}: MutationProps) => UseMutationResult<
    MonitoringSearchResponseDto,
    ErrorDto,
    T,
    QueryClient
  >
  schema: Schema<T>
  mapper: (form: T) => DTO
  blankValues: T
  component: React.ElementType<SearchProps<T>>
  canDownloadResults: boolean
}

export interface SearchProps<T extends FieldValues> {
  control: Control<T, any>
  errors: FieldErrors<T>
  register: UseFormRegister<T>
  watch: UseFormWatch<T>
}

export default function SearchForm({
  searchType,
  mutationCallback,
  schema,
  mapper,
  blankValues,
  component,
  canDownloadResults,
}: Props<object, FieldValues>) {
  const { t } = useTranslation('common')
  const [loading, setLoading] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useState<object | undefined>(undefined)
  const [searchTime, setSearchTime] = useState<Date | undefined>(undefined)
  const [searchResults, setSearchResults] = useState<MonitoringSearchResult[]>()
  const [scrollToElement, setScrollToElement] = useState<string | undefined>(undefined)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const [showResults, setShowResults] = useState<boolean>(false)

  const cache = useMonitoringCache()

  useEffect(() => {
    // load search query and results from cache if they exist
    const results = cache.get().result
    results && setSearchParams(cache.get().params)
    results && setSearchTime(cache.get().searchTime)
    results && setSearchResults(results)
    results && setShowResults(true)
  }, [])

  useEffect(() => {
    // if search type changes and we have done a search before, reset forms
    if (showResults) {
      handleReset()
    }
  }, [searchType])

  const { mutate } = mutationCallback({
    onSuccess: (response: MonitoringSearchResponseDto) => {
      setSearchResults(response.results)
      setScrollToElement('h3')
      cache.set({ result: response.results })
      setShowResults(true)
    },
    onError: (error: ErrorDto) => {
      setApiError(error)
      showErrorNotification()
      cache.clear()
      setShowResults(false)
    },
    onSettled: () => {
      setLoading(false)
    },
    queryClient,
  })

  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: cache.get().params,
  })

  /**
   * Gets search results based on filled fields
   * @param form
   */
  const onSubmit = (form: FieldValues) => {
    setShowResults(false)
    setSearchResults(undefined)
    setLoading(true)
    setApiError(undefined)
    setShowNotification(undefined)
    setSearchParams(form)
    const now = new Date()
    setSearchTime(now)
    cache.set({ params: form, searchType: searchType, searchTime: now })
    return mutate(mapper(form))
  }

  /**
   * Reset search form back to initial state
   */
  const handleReset = () => {
    cache.clear()
    setLoading(false)
    setApiError(undefined)
    setShowNotification(undefined)
    setSearchParams(undefined)
    setSearchTime(undefined)
    reset(blankValues)
    setSearchResults(undefined)
    setShowResults(false)
  }

  const SearchForm = component

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => handleReset()}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          width: '100%',
        }}
      >
        {<SearchForm control={control} errors={errors} register={register} watch={watch} />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
          }}
        >
          <Button variant="contained" type="submit" data-qa="search-form-submit">
            {t('registrationSearchPage.searchSection.button')}
          </Button>
          <Button variant="outlined" type="reset" data-qa="search-form-reset">
            {t('registrationSearchPage.searchSection.reset')}
          </Button>
        </Box>
      </Box>
      {showResults && (
        <MonitoringSearchResultsSection
          searchResults={searchResults}
          searchParams={searchParams}
          searchTime={searchTime}
          canDownloadResults={canDownloadResults}
        />
      )}
      {loading && <LoadingSpinner />}

      {scrollToElement && <ScrollTo selector={scrollToElement} />}

      {!loading && apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={showNotification === SnackBarTypes.ERROR && !!apiError}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
        />
      )}
    </>
  )
}
