import { useQuery } from '@tanstack/react-query'
import { AnimareSecretsApiKeys } from '../data/apiKeys'
import {
  Configuration,
  DecryptRequest,
  DecryptionDto,
  ErrorDto,
  HashRequest,
  HashingDto,
  Middleware,
  SecretDto,
  SecretsRestControllerApi,
  querystring,
} from '../generated/animare-secrets-api'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { SECRETS_API_BASE_URL } from '../setup'

export default function useSecretsApi() {
  const api = new SecretsRestControllerApi({
    basePath: SECRETS_API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  const fetchSecrets = (): { error: ErrorDto | null; isPending: boolean; data?: SecretDto[] } => {
    const { data, error, isPending } = useQuery<SecretDto[], ErrorDto>({
      queryKey: [AnimareSecretsApiKeys.SECRETS],
      queryFn: () => api.getSecrets(),
    })

    return { data, error, isPending }
  }

  const decryptSecret = (request: DecryptionDto): Promise<DecryptionDto> => {
    return api.decrypt({ decryptionDto: request } as DecryptRequest)
  }

  const hashPlaintext = (request: HashingDto): Promise<HashingDto> => {
    return api.hash({ hashingDto: request } as HashRequest)
  }

  return {
    decryptSecret,
    fetchSecrets,
    hashPlaintext,
  }
}
