import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { SnackBar, SnackBarTypes } from '../../../components/SnackBar'
import {
  DecryptionDto,
  ErrorDto,
  HashingDto,
  SecretDto,
} from '../../../generated/animare-secrets-api'

import { useTranslation } from 'react-i18next'
import BackButton from '../../../components/BackButton'
import SpacerBox from '../../../components/SpacerBox'
import useSecretsApi from '../../../hooks/useSecrets'
import { ApiTypes } from '../../../utils/errorUtils'

export enum SecretsFormTypes {
  Secret = 0,
  Hash = 1,
}

export const SecretsView = () => {
  const { t } = useTranslation('common')
  const { decryptSecret, fetchSecrets, hashPlaintext } = useSecretsApi()
  const [result, setResult] = useState<string>('')
  const [resultReceived, setResultReceived] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [formType, setFormType] = useState<SecretsFormTypes>(SecretsFormTypes.Secret)

  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const { data: secrets, error: secretsFetchError } = fetchSecrets()

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  useEffect(() => {
    if (!!secretsFetchError) {
      setApiError(secretsFetchError)
    }
  }, [secretsFetchError])

  const changeFormType = (event: FormEvent<HTMLElement>) => {
    handleReset(event)
    if (formType === SecretsFormTypes.Secret) {
      setFormType(SecretsFormTypes.Hash)
    } else {
      setFormType(SecretsFormTypes.Secret)
    }
  }

  const [selectedSecret, setSelectedSecret] = useState<string>('')
  const [ciphertext, setCiphertext] = useState<string>('')

  const handleSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()
    setResult('')
    setResultReceived(false)
    setApiError(undefined)
    setShowNotification(undefined)
    if (!selectedSecret || !ciphertext) {
      return
    }
    const decrypt = async (): Promise<DecryptionDto> => {
      const request = {
        secretTypeCode: selectedSecret,
        ciphertext: ciphertext,
      } as DecryptionDto
      return decryptSecret(request)
    }
    decrypt()
      .then((result: DecryptionDto) => setResult(result.plaintext ?? ''))
      .then(() => setResultReceived(true))
      .catch((error) => {
        if (error as ErrorDto) {
          setApiError(error)
          showErrorNotification()
          setResultReceived(false)
        }
        console.error(error)
      })
  }

  const handleReset = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()
    setSelectedSecret('')
    setCiphertext('')
    setResult('')
    setResultReceived(false)
    setPlaintext('')
    setApiError(undefined)
  }

  const secretsForm = () =>
    formType === SecretsFormTypes.Secret && (
      <SpacerBox gap={3} variant="container">
        <Box component="section" sx={{ mb: 2 }}>
          <Typography variant="h1">{t('admin.secrets.title')}</Typography>
        </Box>
        <SpacerBox
          gap={3}
          sx={{ mb: 2 }}
          component="form"
          onSubmit={handleSubmit}
          onReset={handleReset}
        >
          {apiError && (
            <SnackBar
              apiType={ApiTypes.Secrets}
              type={SnackBarTypes.ERROR}
              open={!!apiError && showNotification === SnackBarTypes.ERROR}
              errorCode={apiError}
              handleClose={() => {
                setShowNotification(undefined)
                return setApiError(undefined)
              }}
            />
          )}

          <FormGroup
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '100%' },
              gap: 4,
              mt: 2,
              width: '100%',
            }}
          >
            <FormControl>
              <InputLabel>{t('admin.secrets.secretType')}</InputLabel>
              <Select
                id="secretType"
                onChange={handleChange}
                value={selectedSecret}
                required={true}
                inputProps={{ 'aria-label': `${t('admin.secrets.secretType')}` }}
              >
                {secrets &&
                  secrets.map((value: SecretDto, index: number) => (
                    <MenuItem key={index} value={value.code} data-qa={`secret-${value.code}`}>
                      {value.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="ciphertext">{t('admin.secrets.ciphertext')}</InputLabel>
              <TextField
                id="ciphertext"
                multiline
                rows={3}
                onChange={(event) => setCiphertext(event.target.value)}
                required={true}
                value={ciphertext}
              />
            </FormControl>
          </FormGroup>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" type="submit">
              {t('admin.secrets.submit')}
            </Button>
            <Button variant="outlined" type="reset">
              {t('admin.secrets.reset')}
            </Button>
          </Box>
        </SpacerBox>
      </SpacerBox>
    )

  const [plaintext, setPlaintext] = useState<string>('')

  const handleChange = (event: any) => setSelectedSecret(event.target.value)

  const handleHashSubmit = (event: FormEvent<HTMLElement>) => {
    event.preventDefault()
    setResult('')
    setResultReceived(false)
    setApiError(undefined)
    if (!selectedSecret || !plaintext) {
      return
    }
    const hash = async (): Promise<HashingDto> => {
      const request = {
        secretTypeCode: selectedSecret,
        plaintext: plaintext,
      } as HashingDto
      return hashPlaintext(request)
    }
    hash()
      .then((result: HashingDto) => setResult(result.hash ?? ''))
      .then(() => setResultReceived(true))
      .catch((error: any) => {
        if (error as ErrorDto) {
          setApiError(error)
          setResultReceived(false)
        }
        console.error(error)
      })
  }
  const hashForm = () =>
    formType === SecretsFormTypes.Hash && (
      <SpacerBox gap={3} variant="container">
        <Box component="section" sx={{ mb: 2 }}>
          <Typography variant="h1">{t('admin.secrets.hashTitle')}</Typography>
        </Box>
        <SpacerBox
          gap={3}
          sx={{ mb: 2 }}
          component="form"
          onSubmit={handleHashSubmit}
          onReset={handleReset}
        >
          {apiError && (
            <SnackBar
              apiType={ApiTypes.Secrets}
              type={SnackBarTypes.ERROR}
              open={!!apiError}
              errorCode={apiError}
              handleClose={() => setApiError(undefined)}
            />
          )}
          <FormGroup
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '100%' },
              gap: 4,
              mt: 2,
              width: '100%',
            }}
          >
            <FormControl>
              <InputLabel>{t('admin.secrets.secretType')}</InputLabel>
              <Select
                id="secretType"
                onChange={handleChange}
                value={selectedSecret}
                required={true}
                inputProps={{ 'aria-label': `${t('admin.secrets.secretType')}` }}
              >
                {secrets &&
                  secrets
                    .filter((secret: SecretDto) => !!secret.hashable)
                    .map((value: SecretDto, index: number) => (
                      <MenuItem key={index} value={value.code}>
                        {value.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="plaintext">{t('admin.secrets.plaintext')}</InputLabel>
              <TextField
                id="plaintext"
                multiline
                rows={3}
                onChange={(event) => setPlaintext(event.target.value)}
                required={true}
                value={plaintext}
              />
            </FormControl>
          </FormGroup>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" type="submit">
              {t('admin.secrets.hashTitle')}
            </Button>
            <Button variant="outlined" type="reset">
              {t('admin.secrets.reset')}
            </Button>
          </Box>
        </SpacerBox>
      </SpacerBox>
    )

  return (
    <Container>
      <BackButton />
      <Paper component="section" sx={{ p: { sm: 8, xs: 2 }, pb: { xs: 4 } }}>
        <Button variant="text" id="switchSecretType" onClick={changeFormType}>
          {formType === SecretsFormTypes.Secret
            ? t('admin.secrets.hashTitle')
            : t('admin.secrets.title')}
        </Button>
        {secretsForm()}
        {hashForm()}

        {!!resultReceived && (
          <SpacerBox
            component="section"
            gap={3}
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '100%' },
              gap: 4,
              mt: 2,
              width: '100%',
              mb: 2,
            }}
          >
            <Divider />
            <Typography variant="h2">
              {formType === SecretsFormTypes.Secret
                ? t('admin.secrets.plaintext')
                : t('admin.secrets.hash')}
            </Typography>
            <TextField multiline value={result} rows={5} />
          </SpacerBox>
        )}
      </Paper>
    </Container>
  )
}
