import { Divider, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventDto } from '../../generated/animare-management-api'

export interface EventTypeFilterProps {
  events: EventDto[]
  filter: string
  onFilterChange: (filter: string) => void
}

export const ALL_EVENT_TYPES = '__ALL'

export default function EventTypeFilter({ events, filter, onFilterChange }: EventTypeFilterProps) {
  const { t } = useTranslation('common')
  const eventTypeFilters: string[] = []

  // Keep only one of each event type
  events.forEach((event) => {
    if (event.type && typeof event.type === 'string' && !eventTypeFilters.includes(event.type)) {
      eventTypeFilters.push(event.type)
    }
  })

  const handleChange = (event: SelectChangeEvent) => {
    onFilterChange(event.target.value as string)
  }

  return (
    <>
      <InputLabel> {t('dogView.events.chooseEventType')} </InputLabel>
      <Select value={filter} onChange={handleChange} sx={{ mb: 4 }} data-qa="filterSelect">
        <MenuItem value={ALL_EVENT_TYPES}>{t('dogView.events.eventTypes.allTypes')}</MenuItem>
        <Divider />
        {eventTypeFilters.map((eventType, index) => {
          return (
            <MenuItem key={index} value={eventType} data-qa="filterOptions">
              {t(`dogView.events.eventTypes.${eventType}`)}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
}
