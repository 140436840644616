/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Criteria for puppy milling suspicion by possessor change search
 * @export
 * @interface PuppyMillingPossessorChangeSearchDto
 */
export interface PuppyMillingPossessorChangeSearchDto {
  /**
   * Municipality code.
   * @type {string}
   * @memberof PuppyMillingPossessorChangeSearchDto
   */
  municipalityCode: string
  /**
   * Minimum possessor change count
   * @type {number}
   * @memberof PuppyMillingPossessorChangeSearchDto
   */
  minimumPossessorChangeCount: number
}

/**
 * Check if a given object implements the PuppyMillingPossessorChangeSearchDto interface.
 */
export function instanceOfPuppyMillingPossessorChangeSearchDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'municipalityCode' in value
  isInstance = isInstance && 'minimumPossessorChangeCount' in value

  return isInstance
}

export function PuppyMillingPossessorChangeSearchDtoFromJSON(
  json: any,
): PuppyMillingPossessorChangeSearchDto {
  return PuppyMillingPossessorChangeSearchDtoFromJSONTyped(json, false)
}

export function PuppyMillingPossessorChangeSearchDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PuppyMillingPossessorChangeSearchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    municipalityCode: json['municipalityCode'],
    minimumPossessorChangeCount: json['minimumPossessorChangeCount'],
  }
}

export function PuppyMillingPossessorChangeSearchDtoToJSON(
  value?: PuppyMillingPossessorChangeSearchDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    municipalityCode: value.municipalityCode,
    minimumPossessorChangeCount: value.minimumPossessorChangeCount,
  }
}
