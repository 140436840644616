/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import type { OrganizationType } from './OrganizationType'
import {
  OrganizationTypeFromJSON,
  OrganizationTypeFromJSONTyped,
  OrganizationTypeToJSON,
} from './OrganizationType'

/**
 * Details of an animal possessor
 * @export
 * @interface PossessorDto
 */
export interface PossessorDto {
  /**
   *
   * @type {string}
   * @memberof PossessorDto
   */
  uid?: string
  /**
   *
   * @type {string}
   * @memberof PossessorDto
   */
  type: PossessorDtoTypeEnum
  /**
   * First name of the possessor.
   * @type {string}
   * @memberof PossessorDto
   */
  firstName?: string
  /**
   * Last name of the possessor.
   * @type {string}
   * @memberof PossessorDto
   */
  lastName?: string
  /**
   * The national identification number of the possessor if it is a person.
   * @type {string}
   * @memberof PossessorDto
   */
  personIdentifier?: string
  /**
   * Street address.
   * @type {string}
   * @memberof PossessorDto
   */
  address?: string
  /**
   * Postal code.
   * @type {string}
   * @memberof PossessorDto
   */
  postalCode: string
  /**
   * Municipality code.
   * @type {string}
   * @memberof PossessorDto
   */
  municipalityCode: string
  /**
   * Phone number.
   * @type {string}
   * @memberof PossessorDto
   */
  phone?: string
  /**
   * E-mail address.
   * @type {string}
   * @memberof PossessorDto
   */
  email?: string
  /**
   * Name of the organization, if an Organization.
   * @type {string}
   * @memberof PossessorDto
   */
  organizationName?: string
  /**
   *
   * @type {OrganizationType}
   * @memberof PossessorDto
   */
  organizationType?: OrganizationType
  /**
   * The business id of the possessor if it is an organization.
   * @type {string}
   * @memberof PossessorDto
   */
  organizationIdentifier?: string
  /**
   * If organizationType is company and but also a sole trader set this boolean to true. Should also have soleTraderIdentifier set when used.
   * @type {boolean}
   * @memberof PossessorDto
   */
  soleTrader?: boolean
  /**
   * The national identification number of the possessor if it is a sole trader.
   * @type {string}
   * @memberof PossessorDto
   */
  soleTraderIdentifier?: string
}

/**
 * @export
 */
export const PossessorDtoTypeEnum = {
  Organization: 'organization',
  Person: 'person',
} as const
export type PossessorDtoTypeEnum = (typeof PossessorDtoTypeEnum)[keyof typeof PossessorDtoTypeEnum]

/**
 * Check if a given object implements the PossessorDto interface.
 */
export function instanceOfPossessorDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'type' in value
  isInstance = isInstance && 'postalCode' in value
  isInstance = isInstance && 'municipalityCode' in value

  return isInstance
}

export function PossessorDtoFromJSON(json: any): PossessorDto {
  return PossessorDtoFromJSONTyped(json, false)
}

export function PossessorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PossessorDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    type: json['type'],
    firstName: !exists(json, 'firstName') ? undefined : json['firstName'],
    lastName: !exists(json, 'lastName') ? undefined : json['lastName'],
    personIdentifier: !exists(json, 'personIdentifier') ? undefined : json['personIdentifier'],
    address: !exists(json, 'address') ? undefined : json['address'],
    postalCode: json['postalCode'],
    municipalityCode: json['municipalityCode'],
    phone: !exists(json, 'phone') ? undefined : json['phone'],
    email: !exists(json, 'email') ? undefined : json['email'],
    organizationName: !exists(json, 'organizationName') ? undefined : json['organizationName'],
    organizationType: !exists(json, 'organizationType')
      ? undefined
      : OrganizationTypeFromJSON(json['organizationType']),
    organizationIdentifier: !exists(json, 'organizationIdentifier')
      ? undefined
      : json['organizationIdentifier'],
    soleTrader: !exists(json, 'soleTrader') ? undefined : json['soleTrader'],
    soleTraderIdentifier: !exists(json, 'soleTraderIdentifier')
      ? undefined
      : json['soleTraderIdentifier'],
  }
}

export function PossessorDtoToJSON(value?: PossessorDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    type: value.type,
    firstName: value.firstName,
    lastName: value.lastName,
    personIdentifier: value.personIdentifier,
    address: value.address,
    postalCode: value.postalCode,
    municipalityCode: value.municipalityCode,
    phone: value.phone,
    email: value.email,
    organizationName: value.organizationName,
    organizationType: OrganizationTypeToJSON(value.organizationType),
    organizationIdentifier: value.organizationIdentifier,
    soleTrader: value.soleTrader,
    soleTraderIdentifier: value.soleTraderIdentifier,
  }
}
