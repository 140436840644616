import { ControllerRenderProps } from 'react-hook-form'
import { FormAutoComplete } from '../FormAutoComplete'
import { OrganizationFormNames } from './OrganizationPossessorForm'
import { OrganizationType } from '../../generated/animare-management-api/models/OrganizationType'
import { useTranslation } from 'react-i18next'

type OrganizationTypeSelectProps = {
  error?: any
  field: ControllerRenderProps<any, OrganizationFormNames.organizationType>
}

export const OrganizationTypeSelect = (props: OrganizationTypeSelectProps) => {
  const { t } = useTranslation('common')

  const { error, field } = props

  const options: { label: string; value: OrganizationType }[] = [
    {
      label: `${t('common.organizationType.association')}`,
      value: OrganizationType.Association,
    },
    {
      label: `${t('common.organizationType.company')}`,
      value: OrganizationType.Company,
    },
    {
      label: `${t('common.organizationType.municipality')}`,
      value: OrganizationType.Municipality,
    },
    {
      label: `${t('common.organizationType.state')}`,
      value: OrganizationType.State,
    },
  ]

  return (
    <FormAutoComplete
      options={options}
      loading={false}
      field={field}
      isRequired
      dataQAString={field.name}
      error={error}
      inputTitle={t('organizationPossessorForm.form.organizationType')}
      placeholderText={`${t('organizationPossessorForm.form.organizationType')}`}
    />
  )
}
