import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes } from '../../..'
import RegistrationConfirmationPage from '../../../../components/RegistrationConfirmationPage'

const RegistrationConfirmationView = () => {
  const navigate = useNavigate()
  const { registrationUid } = useParams()
  if (!registrationUid) {
    navigate(AppRoutes.Dashboard)
    return null
  }

  return <RegistrationConfirmationPage registrationUid={registrationUid} />
}

export default RegistrationConfirmationView
