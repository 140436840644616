import { Paper, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  ErrorDto,
  FullRegistrationDto,
  PossessorDtoTypeEnum,
} from '../generated/animare-management-api'
import { useMonitoringCache } from '../hooks/useMonitoringCache'
import { usePossessor } from '../hooks/usePossessor'
import { ServiceDeskRegistrationFormValues, useRegistration } from '../hooks/useRegistration'
import { AppRoutes } from '../routes'
import BackButton from './BackButton'
import RegistrationForm from './Form/RegistrationForm'
import SpacerBox from './SpacerBox'

interface Props {
  possessorUid: string
}
const RegistrationFormPage = ({ possessorUid }: Props) => {
  const { t } = useTranslation('common')
  const { sendRegistrationForm } = useRegistration()
  const queryClient = useQueryClient()
  const { mutate: sendRegistrationFormMutation } = sendRegistrationForm({
    onSuccess: (data: FullRegistrationDto) => {
      navigate(
        `/search/possessor/${data.possessor.uid}/registration/${data.uid}/registration-confirmation`,
      )
    },
    onError: (error: ErrorDto) => {
      setApiError(error)
      console.error(error)
    },
    queryClient,
  })
  const navigate = useNavigate()
  const { getPossessorByUid } = usePossessor()
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)

  const { data: possessor } = getPossessorByUid({ uid: possessorUid })
  const { clear: clearMonitoringCache } = useMonitoringCache()

  const onSubmit = (form: ServiceDeskRegistrationFormValues) => {
    if (!possessor) {
      console.error('Possessor is not initialized for registration')
      navigate(AppRoutes.Dashboard)
      return
    }
    setApiError(undefined)
    sendRegistrationFormMutation({ form, possessor })
    clearMonitoringCache()
  }

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={3} centercontent={'true'}>
          <Typography variant="h1">{t('registrationFormPage.title')}</Typography>
          {!!possessor && (
            <>
              <SpacerBox gap={1}>
                <Typography variant="h2">{t('possessorDetails.name')}</Typography>
                {possessor.type === PossessorDtoTypeEnum.Person && (
                  <Typography variant="body1" data-qa="possessor-name">
                    {possessor.firstName} {possessor.lastName}
                  </Typography>
                )}
                {possessor.type === PossessorDtoTypeEnum.Organization && (
                  <Typography variant="body1" data-qa="possessor-name">
                    {possessor.organizationName}
                  </Typography>
                )}
              </SpacerBox>
            </>
          )}
          <RegistrationForm
            submitButtonText={t('registrationForm.button.register')}
            onSubmit={onSubmit}
            errorCode={apiError}
          />
        </SpacerBox>
      </Paper>
    </>
  )
}

export default RegistrationFormPage
