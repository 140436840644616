function EnvBanner() {
  const text = import.meta.env.VITE_APP_ENV_BANNER_TEXT
  return text ? (
    <div className="env-banner" aria-hidden="true">
      {text}
    </div>
  ) : (
    <></>
  )
}

export default EnvBanner
