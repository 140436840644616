import { Box, Divider, Typography, TypographyProps } from '@mui/material'
import { PropsWithChildren } from 'react'

export default function TitleWithUnderline(props: PropsWithChildren<TypographyProps>) {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h1" {...props} sx={{ lineHeight: 1.5, mb: 2 }}>
        {props.children}
      </Typography>
      <Divider
        aria-hidden="true"
        sx={{ width: '6rem', mt: { xs: 2, sm: 0 } }}
        color="decorTitle.main"
      />
    </Box>
  )
}
