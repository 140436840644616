/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * The possessor of the animal at the moment of the event.
 * @export
 * @interface EventDtoPossessor
 */
export interface EventDtoPossessor {
  /**
   * The unique identifier of the possessor.
   * @type {string}
   * @memberof EventDtoPossessor
   */
  uid?: string
  /**
   * The possessor type, person or organization.
   * @type {string}
   * @memberof EventDtoPossessor
   */
  type?: EventDtoPossessorTypeEnum
  /**
   * The name of the possessor.
   * @type {string}
   * @memberof EventDtoPossessor
   */
  name?: string
  /**
   * The identifier of the possessor (henkilötunnus, y-tunnus)
   * @type {string}
   * @memberof EventDtoPossessor
   */
  identifier?: string
}

/**
 * @export
 */
export const EventDtoPossessorTypeEnum = {
  Person: 'person',
  Organization: 'organization',
} as const
export type EventDtoPossessorTypeEnum =
  (typeof EventDtoPossessorTypeEnum)[keyof typeof EventDtoPossessorTypeEnum]

/**
 * Check if a given object implements the EventDtoPossessor interface.
 */
export function instanceOfEventDtoPossessor(value: object): boolean {
  let isInstance = true

  return isInstance
}

export function EventDtoPossessorFromJSON(json: any): EventDtoPossessor {
  return EventDtoPossessorFromJSONTyped(json, false)
}

export function EventDtoPossessorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EventDtoPossessor {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    type: !exists(json, 'type') ? undefined : json['type'],
    name: !exists(json, 'name') ? undefined : json['name'],
    identifier: !exists(json, 'identifier') ? undefined : json['identifier'],
  }
}

export function EventDtoPossessorToJSON(value?: EventDtoPossessor | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    type: value.type,
    name: value.name,
    identifier: value.identifier,
  }
}
