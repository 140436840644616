/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import type { ErrorDto, EventDto, LimitedAnimalResponseDto } from '../models'
import {
  ErrorDtoFromJSON,
  ErrorDtoToJSON,
  EventDtoFromJSON,
  EventDtoToJSON,
  LimitedAnimalResponseDtoFromJSON,
  LimitedAnimalResponseDtoToJSON,
} from '../models'

export interface GetAnimalRequest {
  microchipNumber: string
}

export interface GetEventsForAnimalRequest {
  uid: string
}

/**
 *
 */
export class AnimalRestControllerApi extends runtime.BaseAPI {
  /**
   * Provides information about the specified animal based on its microchip number.  **NOTE:** This endpoint returns only a limited subset of information and is intended for usage in e.g. animal shelters
   */
  async getAnimalRaw(
    requestParameters: GetAnimalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LimitedAnimalResponseDto>> {
    if (
      requestParameters.microchipNumber === null ||
      requestParameters.microchipNumber === undefined
    ) {
      throw new runtime.RequiredError(
        'microchipNumber',
        'Required parameter requestParameters.microchipNumber was null or undefined when calling getAnimal.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.microchipNumber !== undefined) {
      queryParameters['microchipNumber'] = requestParameters.microchipNumber
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/animal`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LimitedAnimalResponseDtoFromJSON(jsonValue),
    )
  }

  /**
   * Provides information about the specified animal based on its microchip number.  **NOTE:** This endpoint returns only a limited subset of information and is intended for usage in e.g. animal shelters
   */
  async getAnimal(
    requestParameters: GetAnimalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LimitedAnimalResponseDto> {
    const response = await this.getAnimalRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Provides events related to the given animal
   */
  async getEventsForAnimalRaw(
    requestParameters: GetEventsForAnimalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<EventDto>>> {
    if (requestParameters.uid === null || requestParameters.uid === undefined) {
      throw new runtime.RequiredError(
        'uid',
        'Required parameter requestParameters.uid was null or undefined when calling getEventsForAnimal.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken
      const tokenString = await token('BearerAuth', [])

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`
      }
    }
    const response = await this.request(
      {
        path: `/animal/{uid}/events`.replace(
          `{${'uid'}}`,
          encodeURIComponent(String(requestParameters.uid)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventDtoFromJSON))
  }

  /**
   * Provides events related to the given animal
   */
  async getEventsForAnimal(
    requestParameters: GetEventsForAnimalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<EventDto>> {
    const response = await this.getEventsForAnimalRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
