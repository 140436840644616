import {
  AnimalDto,
  AnimalDtoColoursEnum,
  AnimalImportingSearchDto,
  AnimalProtectionSearchDto,
  FullRegistrationDto,
  MicrochipDto,
  ModifyPossessorRequestDto,
  PossessorDto,
  PossessorDtoTypeEnum,
  ProfessionalActivitySearchDto,
  PuppyMillingPossessorChangeSearchDto,
  PuppyMillingRegistrationsSearchDto,
} from '../generated/animare-management-api'

import { OrganizationFormValues } from '../components/Form/OrganizationPossessorForm'
import { PersonPossessorFormValues } from '../components/Form/PersonPossessorForm'
import {
  AnimalImportingSearchFormValues,
  AnimalProtectionSearchFormValues,
  FirstRegistrationsFormValues,
  PossessorChangeSearchFormValues,
  ProfessionalActivitySearchFormValues,
} from '../components/Monitoring/MonitoringSearchSchema'
import { OTHER_BREED } from '../data/breeds'
import { ServiceDeskRegistrationFormValues } from './useRegistration'

type nullIfEmptySupportedTypes = string | null | Date | AnimalDtoColoursEnum[]

const nullIfEmpty = (s?: nullIfEmptySupportedTypes): any =>
  (s && `${s}`.trim().length === 0 ? null : s) || null

const checkBreedCodeForDB = (code: string | null) => {
  if (code === OTHER_BREED) return null
  return nullIfEmpty(code)
}

export function useRegistrationMapper() {
  /**
   *
   * @param form - ServiceDeskRegistrationFormValues
   * @param possessor - possessor information
   * @returns
   */
  const mapToFullRegistrationDto = (
    form: ServiceDeskRegistrationFormValues,
    possessor: PossessorDto,
  ): FullRegistrationDto => {
    return {
      arrivalDate: form.arrivalDate || new Date(),
      animal: {
        name: form.name,
        kennelName: nullIfEmpty(form.kennelName),
        birthDate: form.birthDate || undefined,
        birthCountryCode: form.birthCountryCode,
        breedCode: checkBreedCodeForDB(form.breedCode),
        breedSize: form.breedSize,
        breedDescription: nullIfEmpty(form.breedDescription),
        sexCode: nullIfEmpty(form.sexCode),
        colours: nullIfEmpty(form.colours),
      } as AnimalDto,
      microchip: {
        microchipDate: form.microchipDate || undefined,
        microchipNumber: nullIfEmpty(form.microchipNumber),
        microchipSetterName: form.microchipSetterName || undefined,
        microchipSetterAddress: form.microchipSetterAddress || undefined,
      } as MicrochipDto,
      possessor: possessor,
    } as FullRegistrationDto
  }

  /**
   *
   * @param fullRegistrationDto
   * @returns
   */
  const mapToServiceDeskRegistrationFormValues = (
    fullRegistrationDto: FullRegistrationDto,
  ): ServiceDeskRegistrationFormValues => {
    return {
      arrivalDate: fullRegistrationDto.arrivalDate || null,
      name: fullRegistrationDto.animal.name,
      kennelName: fullRegistrationDto.animal.kennelName,
      birthDate: fullRegistrationDto.animal.birthDate || null,
      birthCountryCode: fullRegistrationDto.animal.birthCountryCode,
      breedCode: fullRegistrationDto.animal.breedCode || OTHER_BREED,
      breedSize: fullRegistrationDto.animal.breedSize,
      breedDescription: fullRegistrationDto.animal.breedDescription,
      sexCode: fullRegistrationDto.animal.sexCode,
      colours: fullRegistrationDto.animal.colours ?? [],
      microchipDate: fullRegistrationDto.microchip.microchipDate || null,
      microchipNumber: fullRegistrationDto.microchip.microchipNumber,
      microchipSetterName: fullRegistrationDto.microchip.microchipSetterName,
      microchipSetterAddress: fullRegistrationDto.microchip.microchipSetterAddress,
    } as ServiceDeskRegistrationFormValues
  }

  return {
    mapToFullRegistrationDto,
    mapToServiceDeskRegistrationFormValues,
  }
}

export function usePossessorMapper() {
  const mapToModifyPossessorRequestDto = (
    possessor: PersonPossessorFormValues | OrganizationFormValues,
    type: PossessorDtoTypeEnum,
  ) => {
    if (type === PossessorDtoTypeEnum.Person) {
      return {
        arrivalDate: possessor.arrivalDate,
        possessor: {
          ...possessor,
          type: PossessorDtoTypeEnum.Person,
        },
      } as ModifyPossessorRequestDto
    }
    return {
      arrivalDate: possessor.arrivalDate,
      possessor: { ...possessor, type: PossessorDtoTypeEnum.Organization },
    } as ModifyPossessorRequestDto
  }
  return {
    mapToModifyPossessorRequestDto,
  }
}

export function useMonitoringMapper() {
  const mapToAnimalImportingSearchDto = (
    form: AnimalImportingSearchFormValues,
  ): AnimalImportingSearchDto => {
    return {
      birthCountryCode: form.birthCountryCode,
      allCountries: form.allCountries,
      municipalityCode: form.municipalityCode,
      registrationStartDate: !!form.registrationStartDate ? form.registrationStartDate : undefined,
      registrationEndDate: !!form.registrationEndDate ? form.registrationEndDate : undefined,
      birthStartDate: !!form.birthStartDate ? form.birthStartDate : undefined,
      birthEndDate: !!form.birthEndDate ? form.birthEndDate : undefined,
      possessorChangeStartDate: !!form.possessorChangeStartDate
        ? form.possessorChangeEndDate
        : undefined,
      possessorChangeEndDate: !!form.possessorChangeEndDate
        ? form.possessorChangeEndDate
        : undefined,
    }
  }

  const mapToAnimalProtectionSearchDto = (
    form: AnimalProtectionSearchFormValues,
  ): AnimalProtectionSearchDto => {
    return {
      municipalityCode: form.municipalityCode ? form.municipalityCode : '',
      postalCode: form.postalCode,
      address: form.address,
      firstName: form.firstName,
      lastName: form.lastName,
      organizationName: form.organizationName,
      breedCode: form.breedCode,
    }
  }

  const mapToProfessionalActivitySearchDto = (
    form: ProfessionalActivitySearchFormValues,
  ): ProfessionalActivitySearchDto => {
    return {
      municipalityCode: form.municipalityCode ? form.municipalityCode : '',
      minimumRegisteredAnimals: form.minimumRegisteredAnimals ? form.minimumRegisteredAnimals : 0,
    }
  }

  const mapToPuppyMillingPossessorChangeSearchDto = (
    form: PossessorChangeSearchFormValues,
  ): PuppyMillingPossessorChangeSearchDto => {
    return {
      municipalityCode: form.municipalityCode ? form.municipalityCode : '',
      minimumPossessorChangeCount: form.minimumPossessorChangeCount
        ? form.minimumPossessorChangeCount
        : 0,
    }
  }

  const mapToPuppyMillingRegistrationsSearchDto = (
    form: FirstRegistrationsFormValues,
  ): PuppyMillingRegistrationsSearchDto => {
    if (!form.endDate || !(form.endDate instanceof Date)) {
      throw new Error('endDate is required')
    }
    if (!form.startDate || !(form.startDate instanceof Date)) {
      throw new Error('startDate is required')
    }
    return {
      municipalityCode: form.municipalityCode ? form.municipalityCode : '',
      endDate: form.endDate,
      firstRegistrationCount: form.firstRegistrationCount ? form.firstRegistrationCount : 0,
      startDate: form.startDate,
    }
  }
  return {
    mapToAnimalImportingSearchDto,
    mapToAnimalProtectionSearchDto,
    mapToProfessionalActivitySearchDto,
    mapToPuppyMillingPossessorChangeSearchDto,
    mapToPuppyMillingRegistrationsSearchDto,
  }
}
