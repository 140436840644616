import { Box, Divider, Paper, Typography } from '@mui/material'
import {
  ErrorDto,
  PossessorDto,
  PossessorDtoTypeEnum,
  PossessorResponseDto,
} from '../generated/animare-management-api'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePossessorMapper } from '../hooks/useMapper'
import { usePossessor } from '../hooks/usePossessor'
import { useRegistration } from '../hooks/useRegistration'
import BackButton from './BackButton'
import { OrganizationFormValues, OrganizationPossessorForm } from './Form/OrganizationPossessorForm'
import PersonPossessorForm, { PersonPossessorFormValues } from './Form/PersonPossessorForm'
import PossessorChangeForm from './Form/PossessorChangeForm'
import { FormViewStepper } from './FormViewStepper'
import LoadingSpinner from './LoadingSpinner'
import Search from './Search'
import SpacerBox from './SpacerBox'

export const CHANGE_POSSESSOR_STEPS = [
  {
    label: 'possessorChangeView.views.search-possessor',
    index: 0,
  },
  {
    label: 'possessorChangeView.views.set-possessor',
    index: 1,
  },
  {
    label: 'possessorChangeView.views.possessor-set',
    index: 2,
  },
]

interface Props {
  registrationUid: string
}

const ChangePossessor = ({ registrationUid }: Props) => {
  const { t } = useTranslation('common')
  const { getRegistration } = useRegistration()
  const { mapToModifyPossessorRequestDto } = usePossessorMapper()
  const queryClient = useQueryClient()

  const [possessor, setPossessor] = useState<PossessorDto | undefined>()
  const [newPossessorIdentifier, setNewPossessorIdentifier] = useState<string | undefined>(
    undefined,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)

  const { createPossessor } = usePossessor()
  const { mutate: createPossessorMutation } = createPossessor({
    onSuccess: (response) => {
      setNewPossessorIdentifier(undefined)
      setPossessor(response)
      setActiveStepIndex(1)
      setLoading(false)
    },
    onError: (error: ErrorDto) => {
      setShowNotification(SnackBarTypes.ERROR)
      setApiError(error)
      setLoading(false)
      console.error(error)
    },
    queryClient,
  })

  const saveNewPossessor = (type: PossessorDtoTypeEnum) => {
    return (form: PersonPossessorFormValues | OrganizationFormValues) => {
      setLoading(true)
      setApiError(undefined)
      const request = mapToModifyPossessorRequestDto(form, type)
      return createPossessorMutation(request)
    }
  }

  const { data: registrationData, error: registrationFetchError } = getRegistration({
    uid: registrationUid,
  })

  if (registrationFetchError && !registrationData) {
    return <Box>{t('errors.general')}</Box>
  }

  return (
    <>
      <BackButton />
      <Box sx={{ backgroundColor: '#fff', padding: 2 }}>
        <FormViewStepper
          activeStepIndex={activeStepIndex}
          setActiveStep={setActiveStepIndex}
          steps={CHANGE_POSSESSOR_STEPS}
        />
      </Box>
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={3} variant="container">
          <Box component="section">
            <Typography variant="h1">{t('possessorChangeView.title')}</Typography>
          </Box>
          <Divider />

          <SpacerBox component="section" gap={2}>
            <Typography variant="h2">{t('possessorChangeView.search.title')}</Typography>
            <Search
              hasIdentifierSearch
              hasCompanyIdentifierSearch
              onPersonFound={(response: PossessorResponseDto) => {
                setPossessor(response.possessor)
                setNewPossessorIdentifier(undefined)
                setActiveStepIndex(1)
              }}
              onIdentifierNotFound={(identifier: string, possessorType: PossessorDtoTypeEnum) => {
                setActiveStepIndex(0)
                setNewPossessorIdentifier(identifier)
                setPossessor({ type: possessorType } as PossessorDto)
              }}
              onPersonFormReset={() => {
                setActiveStepIndex(0)
                setNewPossessorIdentifier(undefined)
                setPossessor(undefined)
              }}
              onOrganizationFound={(response: PossessorResponseDto) => {
                setPossessor(response.possessor)
                setNewPossessorIdentifier(undefined)
                setActiveStepIndex(1)
              }}
              onOrganizationFormReset={() => {
                setActiveStepIndex(0)
                setNewPossessorIdentifier(undefined)
                setPossessor(undefined)
              }}
            />
            <Divider sx={{ mt: 4, mb: 2 }} />
            {loading && <LoadingSpinner />}
            {!loading && apiError && (
              <SnackBar
                open={!!apiError && showNotification === SnackBarTypes.ERROR}
                type={SnackBarTypes.ERROR}
                errorCode={apiError}
                handleClose={() => {
                  setShowNotification(undefined)
                  setApiError(undefined)
                }}
              />
            )}
            {activeStepIndex === 1 && possessor && registrationData && (
              <PossessorChangeForm possessor={possessor} registration={registrationData} />
            )}
            {activeStepIndex === 0 && possessor?.type && newPossessorIdentifier && (
              <Box>
                <Typography variant="h2">{t('possessorEdit.newPossessor')}</Typography>
                {possessor.type === PossessorDtoTypeEnum.Person && (
                  <PersonPossessorForm
                    possessor={{ personIdentifier: newPossessorIdentifier } as PossessorDto}
                    onSubmit={saveNewPossessor(PossessorDtoTypeEnum.Person)}
                  />
                )}
                {possessor.type === PossessorDtoTypeEnum.Organization && (
                  <OrganizationPossessorForm
                    possessor={{ organizationIdentifier: newPossessorIdentifier } as PossessorDto}
                    onSubmit={saveNewPossessor(PossessorDtoTypeEnum.Organization)}
                  />
                )}
              </Box>
            )}
          </SpacerBox>
        </SpacerBox>
      </Paper>
    </>
  )
}

export default ChangePossessor
