import { ArrowBackSharp } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

type Props = {
  buttonAria?: string
  buttonContent?: string
  onClick?: () => void
}

export default function BackButton({
  buttonAria,
  buttonContent,
  onClick = () => {
    /* no-op*/
  },
}: Props) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const aria: string = !!buttonAria ? buttonAria : t('common.backAria')
  const content: string = !!buttonContent ? buttonContent : t('common.back')

  return (
    <Box mb={1}>
      <Button
        variant="text"
        startIcon={<ArrowBackSharp data-qa="arrow-icon" />}
        sx={{ alignSelf: 'flex-start', textTransform: 'unset' }}
        aria-label={aria}
        onClick={() => {
          onClick && onClick()
          navigate('..')
        }}
        data-qa="back-button"
      >
        {content}
      </Button>
    </Box>
  )
}
