export enum AppRoutes {
  ChangePossessor = 'change-possessor',
  ChangePossessor_Completed = 'change-possessor-completed',
  Dashboard = '/dashboard',
  Logout = '/logout',
  Monitoring_Search = '/monitoring',
  Possessor = 'possessor/:possessorUid',
  Possessor_Edit = 'edit',
  Registration = 'registration/:registrationUid',
  Registration_Confirmation = 'registration-confirmation',
  Registration_Edit = 'edit',
  Registration_Form = 'registration',
  Registration_Microchips = 'microchips',
  Root = '/',
  Search = '/search',
  SecretsView = '/admin/secrets',
  Shelter = '/shelter',
  Virtu = '/virtu',
}
