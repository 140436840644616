import { QueryClient, UseMutationResult, useMutation } from '@tanstack/react-query'
import { AnimareApiKeys } from '../data/apiKeys'
import {
  Configuration,
  ErrorDto,
  LimitedAnimalResponseDto,
  Middleware,
  querystring,
} from '../generated/animare-management-api'
import { AnimalRestControllerApi } from '../generated/animare-management-api/apis/AnimalRestControllerApi'
import { ApiErrorMiddleware } from '../middleware/api-error-middleware'
import { API_BASE_URL } from '../setup'

export const useAnimal = () => {
  const api = new AnimalRestControllerApi({
    basePath: API_BASE_URL,
    middleware: [new ApiErrorMiddleware()] as Middleware[],
    queryParamsStringify: querystring,
    credentials: 'include',
  } as Configuration)

  /**
   * Get limited registration info (for shelters)
   */

  const getLimitedRegistration = ({
    onSuccess,
    onError,
    onSettled,
    queryClient,
  }: {
    onSuccess: (res: LimitedAnimalResponseDto) => void
    onError: (error: ErrorDto) => void
    onSettled: () => void
    queryClient: QueryClient
  }): UseMutationResult<LimitedAnimalResponseDto, ErrorDto, string, unknown> => {
    const getLimitedRegistrationMutation = useMutation({
      mutationFn: (microchipNumber: string) => api.getAnimal({ microchipNumber }),
      onSuccess: (response: LimitedAnimalResponseDto) => {
        queryClient.invalidateQueries({ queryKey: [AnimareApiKeys.REGISTRATION] })
        onSuccess(response)
      },
      onError: (error: ErrorDto) => {
        console.error(error)
        onError(error)
      },
      onSettled: onSettled,
    })

    return getLimitedRegistrationMutation
  }

  return { getLimitedRegistration }
}
