export enum CodeApiKeys {
  BREEDS = 'breeds',
  COUNTRIES = 'countries',
  MUNICIPALITIES = 'municipalities',
}

export enum AnimareApiKeys {
  ANIMAL = 'animal',
  EVENT = 'event',
  MICROCHIP = 'microchip',
  POSSESSOR = 'possessor',
  REGISTRATION = 'registration',
}

export enum AnimareSecretsApiKeys {
  SECRETS = 'secrets',
}
