import * as yup from 'yup'

import { Box, Button, FormControl, FormGroup, TextField } from '@mui/material'
import { Message, useForm } from 'react-hook-form'
import { ErrorDto, PossessorResponseDto } from '../generated/animare-management-api'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePossessor } from '../hooks/usePossessor'
import { ErrorCodes } from '../utils/errorUtils'
import InlineValidationError from './Form/InlineValidationError'
import LoadingSpinner from './LoadingSpinner'

const IDENTIFIER_REGEX = /^\d{6}[\+\-A-Z]\d{3}[0-9A-Z]$/

interface Props {
  onPersonFound: (response: PossessorResponseDto) => void
  onPersonNotFound: (response: string) => void
  onReset: () => void
}

export default function IdentifierSearch({ onPersonFound, onPersonNotFound, onReset }: Props) {
  const { t } = useTranslation('common')
  const { getPersonPossessor } = usePossessor()
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const queryClient = useQueryClient()

  const { mutate: getPersonPossessorMutation } = getPersonPossessor({
    onSuccess: (response: PossessorResponseDto) => {
      onPersonFound(response)
    },
    onError: (error: ErrorDto) => {
      // If error is 404 from possessor search, it is ok
      if (error.code === ErrorCodes.PossessorNotFound) {
        onPersonNotFound(identifier)
      } else {
        showErrorNotification()
        setApiError(error)
      }
    },
    onSettled: () => setLoading(false),
    queryClient,
  })

  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const errorMessages = {
    generic: t('register.inlineError.ifEmpty.generic') as Message,
    wrongFormat: t('errors.validation.wrongFormat') as Message,
  }

  const schema = yup.object().shape({
    identifier: yup
      .string()
      .required(errorMessages.generic)
      .transform((value) => (value === undefined || '' ? null : value))
      .matches(IDENTIFIER_REGEX, errorMessages.wrongFormat),
  })

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(schema) })

  const identifier = watch('identifier')

  /**
   * Gets possessor and animal information.
   * @param event
   */
  const onSubmit = () => {
    setApiError(undefined)
    setLoading(true)
    return getPersonPossessorMutation({
      personIdentifier: identifier,
      fetchPastRegistrations: true,
    })
  }

  /**
   * Reset identifier and ui back to initial state
   */
  const handleReset = () => {
    setApiError(undefined)
    setLoading(false)
    setValue('identifier', undefined)
    onReset()
  }

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} onReset={() => handleReset()}>
        <FormGroup sx={{ display: 'flex', gap: 2 }}>
          <FormControl>
            <TextField
              {...register('identifier')}
              id={'identifier'}
              data-qa="identifier-input"
              label={t('registrationSearchPage.searchSection.identifierLabel')}
              error={!!errors.identifier}
              inputProps={{
                type: 'text',
                inputMode: 'text',
              }}
              autoComplete={'off'}
              sx={{ width: '40%' }}
            />
            {!!errors.identifier && <InlineValidationError content={errors.identifier?.message} />}
          </FormControl>
          <Box>
            <Button
              variant="contained"
              type="submit"
              sx={{ mr: 1 }}
              data-qa="person-identifier-submit"
            >
              {t('registrationSearchPage.searchSection.button')}
            </Button>
            <Button variant="outlined" type="reset" data-qa="person-identifier-reset">
              {t('registrationSearchPage.searchSection.reset')}
            </Button>
          </Box>
        </FormGroup>
      </Box>

      {loading && <LoadingSpinner />}

      {!loading && apiError && (
        <SnackBar
          type={SnackBarTypes.ERROR}
          open={!!apiError && showNotification === SnackBarTypes.ERROR}
          errorCode={apiError}
          handleClose={() => {
            setShowNotification(undefined)
            setApiError(undefined)
          }}
        />
      )}
    </>
  )
}
