import { useTranslation } from 'react-i18next'
import useBreeds from '../../hooks/useBreeds'
import AbstractCode from './AbstractCode'

export interface Props {
  code?: string
}

export default function Breed({ code }: Props) {
  const { t } = useTranslation('common')
  return (
    <AbstractCode
      code={code}
      generator={useBreeds().getBreedName}
      unknownResult={t('common.breed.unknown')}
    />
  )
}
