import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ShowFor } from '../../context/AuthProvider'
import { Roles } from '../../data/roles'
import { PossessorDto } from '../../generated/animare-management-api'
import { ErrorDto } from '../../generated/openapi'
import { ErrorCodes } from '../../utils/errorUtils'
import SpacerBox from '../SpacerBox'
import InlineValidationError from './InlineValidationError'
import InputArrivalDate from './InputArrivalDate'
import MunicipalitySelect from './MunicipalitySelect'
import {
  MAX_ADDRESS_LENGTH,
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  MAX_POSTALCODE_LENGTH,
  getPersonPossessorFormSchema,
} from './PersonPossessorFormSchema'

interface Props {
  possessor?: PossessorDto
  onSubmit: (form: PersonPossessorFormValues) => void
  errorCode?: ErrorDto
  isEdit?: boolean
  headerSize?: 'h2' | 'h3'
}

export type PersonPossessorFormValues = {
  arrivalDate: Date | null
  personIdentifier: string | null
  firstName: string | null
  lastName: string | null
  address: string | null
  postalCode: string | null
  municipalityCode: string | null
  phone: string | null
  email: string | null
}

/**
 * Form component for new owner
 * @param props
 * @returns
 */
const PersonPossessorForm = ({
  possessor,
  onSubmit,
  errorCode,
  isEdit = false,
  headerSize = 'h3',
}: Props) => {
  const { t } = useTranslation('common')
  const [identifierFieldDisabled, setIdentifierFieldDisabled] = useState<boolean>(true)

  const methods = useForm<PersonPossessorFormValues>({
    resolver: yupResolver(getPersonPossessorFormSchema()),
    defaultValues: {
      ...possessor,
      arrivalDate: null,
    },
  })

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isSubmitting },
  } = methods

  useEffect(() => {
    if (errorCode) {
      if (errorCode.code === ErrorCodes.DuplicateIdentifier) {
        setError(
          'personIdentifier',
          { type: 'custom', message: t('errors.duplicate-person-identifier') as string },
          { shouldFocus: true },
        )
      }
    }
  }, [errors, errorCode])

  useEffect(() => {
    if (isSubmitting && !identifierFieldDisabled) {
      setIdentifierFieldDisabled(true)
    }
  }, [isSubmitting, !identifierFieldDisabled])

  return (
    <SpacerBox
      component="form"
      gap={3}
      onSubmit={handleSubmit(onSubmit)}
      data-qa="person-possessor-form"
    >
      <Box>
        <Typography variant="body1" mb={2}>
          {t('registrationForm.requiredTitle')}
        </Typography>
        <Typography variant={headerSize}>{t('registrationSearchPage.arrivalDate')}</Typography>
        <FormControl sx={{ maxWidth: { xs: '100%', sm: '300px' }, mt: 2 }}>
          <InputArrivalDate control={control} errors={errors} />
        </FormControl>
      </Box>
      <Box>
        <Typography variant={headerSize} data-qa="possessorDetailsTitle" mb={2}>
          {t(
            isEdit ? 'possessorDetails.title' : 'registrationSearchPage.personPossessorForm.title',
          )}
        </Typography>
        {possessor?.uid ? (
          <ShowFor roles={[Roles.Admin]}>
            <Box>
              <FormControl>
                <InputLabel htmlFor="personIdentifier">
                  {t('registrationSearchPage.personPossessorForm.identifier')}
                  <i aria-hidden={true}>*</i>
                  <i className="visible-hidden">{t('registrationForm.field.required')}</i>
                </InputLabel>
                <TextField
                  {...register('personIdentifier')}
                  id="personIdentifier"
                  error={!!errors.personIdentifier}
                  inputProps={{
                    type: 'text',
                    inputMode: 'text',
                    maxLength: 11,
                  }}
                  autoComplete={'off'}
                  data-qa="personIdentifier"
                  disabled={identifierFieldDisabled}
                />
                {errors.personIdentifier && (
                  <InlineValidationError content={errors.personIdentifier.message} />
                )}
              </FormControl>
              <Button
                variant="text"
                onClick={() =>
                  identifierFieldDisabled
                    ? setIdentifierFieldDisabled(false)
                    : setIdentifierFieldDisabled(true)
                }
                disabled={identifierFieldDisabled ? false : true}
                sx={{ p: 2 }}
                data-qa="modifyIdentifierButton"
              >
                {t('registrationSearchPage.personPossessorForm.identifierEdit')}
              </Button>
            </Box>
          </ShowFor>
        ) : null}
        <ShowFor roles={[Roles.Editor, Roles.ServiceDesk]}>
          {possessor && (
            <Typography variant="body1" data-qa="personIdentifier">
              {`${t('registrationSearchPage.personPossessorForm.identifier')}: ${
                possessor.personIdentifier
              }`}
            </Typography>
          )}
        </ShowFor>
        <ShowFor roles={[Roles.Admin]}>
          {possessor && !possessor.uid && (
            <Typography variant="body1" data-qa="personIdentifier">
              {`${t('registrationSearchPage.personPossessorForm.identifier')}: ${
                possessor.personIdentifier
              }`}
            </Typography>
          )}
        </ShowFor>
      </Box>
      <FormGroup
        sx={{
          display: 'grid',
          gridTemplateColumns: { sm: 'repeat(2, 1fr)', xs: '100%' },
          gap: 4,
          mt: 2,
          width: '100%',
        }}
      >
        <FormControl>
          <InputLabel htmlFor="firstName">
            {t('registrationSearchPage.personPossessorForm.firstName')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('registrationForm.field.required')}</i>
          </InputLabel>
          <TextField
            {...register('firstName')}
            id="firstName"
            error={!!errors.firstName}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_NAME_LENGTH,
            }}
            autoComplete={'off'}
            data-qa="firstName"
          />
          {!!errors.firstName && <InlineValidationError content={errors.firstName.message} />}
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="lastName">
            {t('registrationSearchPage.personPossessorForm.lastName')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('registrationForm.field.required')}</i>
          </InputLabel>
          <TextField
            {...register('lastName')}
            id="lastName"
            error={!!errors.lastName}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_NAME_LENGTH,
            }}
            autoComplete={'off'}
            data-qa="lastName"
          />
          {!!errors.lastName && <InlineValidationError content={errors.lastName.message} />}
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="address">
            {t('registrationSearchPage.personPossessorForm.address')}
          </InputLabel>
          <TextField
            {...register('address')}
            id="address"
            error={!!errors.address}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_ADDRESS_LENGTH,
            }}
            autoComplete={'off'}
            data-qa="address"
          />
          {!!errors.address && <InlineValidationError content={errors.address.message} />}
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="postalCode">
            {t('registrationSearchPage.personPossessorForm.postalCode')} <i aria-hidden={true}>*</i>
            <i className="visible-hidden">{t('registrationForm.field.required')}</i>
          </InputLabel>
          <TextField
            {...register('postalCode')}
            id="postalCode"
            error={!!errors.postalCode}
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: MAX_POSTALCODE_LENGTH,
            }}
            autoComplete={'off'}
            data-qa="postalCode"
          />
          {!!errors.postalCode && <InlineValidationError content={errors.postalCode.message} />}
        </FormControl>
        <FormControl>
          <Controller
            name="municipalityCode"
            control={control}
            render={({ field }) => (
              <MunicipalitySelect
                field={field}
                error={!!errors.municipalityCode}
                errorContent={errors.municipalityCode?.message || ''}
              />
            )}
          />
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="phone">
            {t('registrationSearchPage.personPossessorForm.phoneNumber')}
          </InputLabel>
          <TextField
            {...register('phone')}
            id="phone"
            error={!!errors.phone}
            data-qa="phone"
            inputProps={{
              type: 'text',
              inputMode: 'numeric',
              maxLength: MAX_PHONE_NUMBER_LENGTH,
            }}
            autoComplete={'off'}
          />
          {!!errors.phone && <InlineValidationError content={errors.phone.message} />}
        </FormControl>
        <FormControl>
          <InputLabel htmlFor="email">
            {t('registrationSearchPage.personPossessorForm.emailAddress')}
          </InputLabel>
          <TextField
            {...register('email')}
            id="email"
            data-qa="email"
            error={!!errors.email}
            inputProps={{
              type: 'text',
              inputMode: 'text',
              maxLength: MAX_EMAIL_LENGTH,
            }}
            autoComplete={'off'}
          />
          {!!errors.email && <InlineValidationError content={errors.email.message} />}
        </FormControl>
      </FormGroup>
      <Box>
        <Button
          variant="contained"
          type="submit"
          sx={{ display: 'block', marginLeft: 'auto' }}
          disabled={isSubmitting}
          data-qa="submitForm"
        >
          {isSubmitting ? (
            <Box aria-label={t('common.loading')}>
              <CircularProgress size={10} /> {t('common.loading') + '...'}
            </Box>
          ) : (
            t('common.save')
          )}
        </Button>
      </Box>
    </SpacerBox>
  )
}

export default PersonPossessorForm
