import { Box, Divider, Paper, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { ErrorDto, PossessorDtoTypeEnum } from '../generated/animare-management-api'
import { OrganizationFormValues, OrganizationPossessorForm } from './Form/OrganizationPossessorForm'
import { SnackBar, SnackBarTypes } from './SnackBar'

import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { usePossessorMapper } from '../hooks/useMapper'
import { useMonitoringCache } from '../hooks/useMonitoringCache'
import { usePossessor } from '../hooks/usePossessor'
import BackButton from './BackButton'
import PersonPossessorForm, { PersonPossessorFormValues } from './Form/PersonPossessorForm'
import SpacerBox from './SpacerBox'

export interface Props {
  possessorUid: string
}

export default function EditPossessor({ possessorUid }: Props) {
  const { t } = useTranslation('common')

  const queryClient = useQueryClient()
  const { mapToModifyPossessorRequestDto } = usePossessorMapper()

  const { getPossessorByUid, updatePossessor } = usePossessor()
  const { mutate: updatePossessorMutation } = updatePossessor({
    onSuccess: () => {
      showSuccessNotification()
    },
    onError: (error: ErrorDto) => {
      setApiError(error)
      showErrorNotification()

      console.error(error)
    },
    queryClient,
  })

  const [apiError, setApiError] = useState<ErrorDto | undefined>(undefined)
  const [showNotification, setShowNotification] = useState<SnackBarTypes | undefined>()

  const { clear: clearMonitoringCache } = useMonitoringCache()

  const { data: possessor, error: possessorFetchError } = getPossessorByUid({
    uid: possessorUid,
    enabled: !!possessorUid,
  })

  useEffect(() => {
    if (possessorFetchError) {
      setApiError(possessorFetchError)
      showErrorNotification()
      console.error(possessorFetchError)
    }
  }, [possessorFetchError])

  const onPersonSubmit = (form: PersonPossessorFormValues) => {
    setApiError(undefined)
    const request = mapToModifyPossessorRequestDto(form, PossessorDtoTypeEnum.Person)
    updatePossessorMutation(request)
    clearMonitoringCache()
  }

  const onOrganizationSubmit = (form: OrganizationFormValues) => {
    setApiError(undefined)
    const request = mapToModifyPossessorRequestDto(form, PossessorDtoTypeEnum.Organization)
    updatePossessorMutation(request)
    clearMonitoringCache()
  }

  /**
   * Shows success notification on the top of the page
   */
  const showSuccessNotification = () => {
    setShowNotification(SnackBarTypes.SUCCESS)
  }

  /**
   * Shows error notification on the top of the page
   */
  const showErrorNotification = () => {
    setShowNotification(SnackBarTypes.ERROR)
  }

  return (
    <>
      <BackButton />
      <Paper sx={{ p: { sm: 8, xs: 2 } }}>
        <SpacerBox gap={3} variant="container">
          <Box component="section">
            <Typography variant="h1">{t('possessorEdit.title')}</Typography>
            <SnackBar
              open={showNotification === SnackBarTypes.SUCCESS}
              innerText={`${t('common.save-successful')}`}
              handleClose={() => setShowNotification(undefined)}
              type={SnackBarTypes.SUCCESS}
            />
            <SnackBar
              open={showNotification === SnackBarTypes.ERROR}
              type={SnackBarTypes.ERROR}
              errorCode={apiError}
              handleClose={() => {
                setShowNotification(undefined)
                return setApiError(undefined)
              }}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />
          {possessor && possessor.type === PossessorDtoTypeEnum.Person && (
            <PersonPossessorForm
              possessor={possessor}
              onSubmit={onPersonSubmit}
              errorCode={apiError}
              isEdit={true}
              headerSize="h2"
            />
          )}
          {possessor && possessor.type === PossessorDtoTypeEnum.Organization && (
            <OrganizationPossessorForm
              possessor={possessor}
              onSubmit={onOrganizationSubmit}
              errorCode={apiError}
              isEdit={true}
              headerSize="h2"
            />
          )}
        </SpacerBox>
      </Paper>
    </>
  )
}
