/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Microchip details.
 * @export
 * @interface MicrochipDto
 */
export interface MicrochipDto {
  /**
   *
   * @type {string}
   * @memberof MicrochipDto
   */
  uid?: string
  /**
   * The number of the microchip implanted in the animal.
   * @type {string}
   * @memberof MicrochipDto
   */
  microchipNumber: string
  /**
   * Date of installation of the microchip.
   * @type {Date}
   * @memberof MicrochipDto
   */
  microchipDate?: Date
  /**
   * Setter of the microchip as reported by the possessor.
   * @type {string}
   * @memberof MicrochipDto
   */
  microchipSetterName?: string
  /**
   * Address of the microchip setter as reported by the possessor.
   * @type {string}
   * @memberof MicrochipDto
   */
  microchipSetterAddress?: string
}

/**
 * Check if a given object implements the MicrochipDto interface.
 */
export function instanceOfMicrochipDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'microchipNumber' in value

  return isInstance
}

export function MicrochipDtoFromJSON(json: any): MicrochipDto {
  return MicrochipDtoFromJSONTyped(json, false)
}

export function MicrochipDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MicrochipDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    uid: !exists(json, 'uid') ? undefined : json['uid'],
    microchipNumber: json['microchipNumber'],
    microchipDate: !exists(json, 'microchipDate') ? undefined : new Date(json['microchipDate']),
    microchipSetterName: !exists(json, 'microchipSetterName')
      ? undefined
      : json['microchipSetterName'],
    microchipSetterAddress: !exists(json, 'microchipSetterAddress')
      ? undefined
      : json['microchipSetterAddress'],
  }
}

export function MicrochipDtoToJSON(value?: MicrochipDto | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    uid: value.uid,
    microchipNumber: value.microchipNumber,
    microchipDate:
      value.microchipDate === undefined
        ? undefined
        : value.microchipDate.toISOString().substr(0, 10),
    microchipSetterName: value.microchipSetterName,
    microchipSetterAddress: value.microchipSetterAddress,
  }
}
