/* tslint:disable */
/* eslint-disable */
/**
 * Animare Management API
 * This API provides methods for viewing and modifying animare database data for authorities.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 * Criteria for puppy milling suspicion by registrations search
 * @export
 * @interface PuppyMillingRegistrationsSearchDto
 */
export interface PuppyMillingRegistrationsSearchDto {
  /**
   * Municipality code.
   * @type {string}
   * @memberof PuppyMillingRegistrationsSearchDto
   */
  municipalityCode: string
  /**
   *
   * @type {number}
   * @memberof PuppyMillingRegistrationsSearchDto
   */
  firstRegistrationCount: number
  /**
   * Start date for created registrations
   * @type {Date}
   * @memberof PuppyMillingRegistrationsSearchDto
   */
  startDate: Date
  /**
   * End date for created registrations
   * @type {Date}
   * @memberof PuppyMillingRegistrationsSearchDto
   */
  endDate: Date
}

/**
 * Check if a given object implements the PuppyMillingRegistrationsSearchDto interface.
 */
export function instanceOfPuppyMillingRegistrationsSearchDto(value: object): boolean {
  let isInstance = true
  isInstance = isInstance && 'municipalityCode' in value
  isInstance = isInstance && 'firstRegistrationCount' in value
  isInstance = isInstance && 'startDate' in value
  isInstance = isInstance && 'endDate' in value

  return isInstance
}

export function PuppyMillingRegistrationsSearchDtoFromJSON(
  json: any,
): PuppyMillingRegistrationsSearchDto {
  return PuppyMillingRegistrationsSearchDtoFromJSONTyped(json, false)
}

export function PuppyMillingRegistrationsSearchDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PuppyMillingRegistrationsSearchDto {
  if (json === undefined || json === null) {
    return json
  }
  return {
    municipalityCode: json['municipalityCode'],
    firstRegistrationCount: json['firstRegistrationCount'],
    startDate: new Date(json['startDate']),
    endDate: new Date(json['endDate']),
  }
}

export function PuppyMillingRegistrationsSearchDtoToJSON(
  value?: PuppyMillingRegistrationsSearchDto | null,
): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    municipalityCode: value.municipalityCode,
    firstRegistrationCount: value.firstRegistrationCount,
    startDate: value.startDate.toISOString().substr(0, 10),
    endDate: value.endDate.toISOString().substr(0, 10),
  }
}
